export const keyEvents = [
    {
        title: "US Election Fears",
        eventStart: "2020-08-02",
        eventEnd: "2020-09-24",
    },
    {
        title: "Coronavirus",
        eventStart: "2020-02-01",
        eventEnd: "2020-03-23",
    },
    {
        title: "Recession Fears",
        eventStart: "2019-07-01",
        eventEnd: "2019-08-05",
    },
    {
        title: "Trade War, Tariffs",
        eventStart: "2018-05-01",
        eventEnd: "2021-06-03",
    },
    {
        title: "Rising Rates",
        eventStart: "2018-09-21",
        eventEnd: "2018-12-26",
    },
    {
        title: "Inflation Fears",
        eventStart: "2018-01-26",
        eventEnd: "2018-02-09",
    },
    // {
    //     title: "Election Fears",
    //     eventStart: "2016-08-15",
    //     eventEnd: "2016-11-04",
    // },
    // {
    //     title: "Brexit Concerns",
    //     eventStart: "2016-06-08",
    //     eventEnd: "2016-06-27",
    // },
    // {
    //     title: "China",
    //     eventStart: "2015-11-03",
    //     eventEnd: "2016-02-11",
    // },
    // {
    //     title: "Greece Default Concerns",
    //     eventStart: "2015-05-20",
    //     eventEnd: "2015-08-24",
    // },
    // {
    //     title: "Weak Earnings",
    //     eventStart: "2014-12-29",
    //     eventEnd: "2015-02-02",
    // },
    // {
    //     title: "Falling Oil",
    //     eventStart: "2014-12-05",
    //     eventEnd: "2014-12-16",
    // },
    // {
    //     title: "Ebola",
    //     eventStart: "2014-09-19",
    //     eventEnd: "2014-10-15",
    // },
    // {
    //     title: "European Deflation Fears",
    //     eventStart: "2014-01-15",
    //     eventEnd: "2014-02-05",
    // },
    // {
    //     title: "Fed Taper Fears",
    //     eventStart: "2013-05-22",
    //     eventEnd: "2013-06-24",
    // },
    // {
    //     title: "Fiscal Cliff Concerns",
    //     eventStart: "2012-09-14",
    //     eventEnd: "2012-11-16",
    // },
    // {
    //     title: "Europe Debt Crisis #3",
    //     eventStart: "2012-04-02",
    //     eventEnd: "2012-06-24",
    // },
    // {
    //     title: "Europe Debt Crisis #2",
    //     eventStart: "2011-10-27",
    //     eventEnd: "2011-11-25",
    // },
    // {
    //     title: "Europe Debt Crisis #2",
    //     eventStart: "2011-10-27",
    //     eventEnd: "2011-11-25",
    // },
    // {
    //     title: "Double-Dip Recession Fears",
    //     eventStart: "2011-05-02",
    //     eventEnd: "2011-10-04",
    // },
    // {
    //     title: "Japan Earthquake/Nuclear Disaster",
    //     eventStart: "2011-02-05",
    //     eventEnd: "2011-10-04",
    // },
    // {
    //     title: "Global Growth Concerns",
    //     eventStart: "2011-02-18",
    //     eventEnd: "2011-03-16",
    // },
];