import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function CompleteExternalAcVerification(external_account_id, amount1, amount2) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    var raw = JSON.stringify({
        external_account_id: external_account_id,
        amounts: [amount1, amount2]
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
        try {

            const response = await fetchWithTimeout(
                process.env.REACT_APP_COMPLETE_VERIFY_EXTERNAL_ACCOUNT_API,
                requestOptions
            );
            if (!response.ok) {
                return false;
            } else {
                const message = await response.json();
                return message;
            }
        } catch (error) {
            if (error.name === "AbortError") {
                //alert("The request timed out, Please try again");
                window.location.reload();
            }
            else {
                return 0
            }
        }
    }
    else{
        return 401
    }
}
