import React from "react";
import NavBar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import PaymentSuccessWindow from "../../Components/PaymentSuccessWindow/PaymentSuccess";
import withWidth from "@material-ui/core/withWidth";

function PaymentSuccess() {
  return (
    <div className="ai-content-part">
      <NavBar />
      <PageHeader
        heading="Payment Successful"
        addButton={false}
        routeTo="./MakePaymentScreen"
      />
      <PaymentSuccessWindow />
    </div>
  );
}

export default withWidth()(PaymentSuccess);
