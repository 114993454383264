import React from "react";
import "./ShieldRiskEquivalenceScreen.css";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Navbar from "../../Components/Navbar/Navbar_official";
import ShieldRiskEquivalence from "../../Components/ShieldRiskEquivalence/ShieldRiskEquivalence";

export default function ShieldEquivalenceScreen() {
  return (
    <div className="ai-content-part">
        <Navbar/>
        <div className="floathead alon big">
          <PageHeader heading="Shield Risk Equivalence" backButton={true} routeTo="./AdaptiveGenius"/>  
        </div>
        <ShieldRiskEquivalence/>
    </div>
    );
}