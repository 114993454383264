import React from "react";
import "./PortfolioTable.css";
import PageHeader from "../../Components/PageHeader/PageHeader";
import NavBar from "../../Components/Navbar/Navbar_official";
import StockTable from "../../Components/StockTable/StockTable";
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";
import Popup from "../../Components/Popup/Popup";
import { useLocation } from "react-router-dom";
import { TabContainer, TabPane, TabContent } from 'react-bootstrap';

//test comment
function PortfolioTable(props) {
  const [stocks, setStocks] = React.useState(
    window.sessionStorage.getItem("Data")
  );
  React.useEffect(() => {
    setStocks(window.sessionStorage.getItem("Data"));
  }, []);

  const location = useLocation();
  React.useEffect(() => {
  }, [location]);

  const [PortfolioValues, setValue] = React.useState([]);
  const [Flag, setFlag] = React.useState(false);

  function handleValueChange(newValue) {
    setLastUpdated(newValue[4]);
    setValue(newValue);
    setStocksData(newValue[5]);
  }
  
  const [popupState, setPopupState] = React.useState(false);
  const [routePath, setRoutePath] = React.useState();
  const [tabvalue, settabvalue] = React.useState('hedgeable');
  const [LastUpdated, setLastUpdated] = React.useState("");
  const [stocksData, setStocksData] = React.useState([]);
  function handleTabChange(val) {
    settabvalue(val)
  }

  // const history = useHistory();
  // const AnalyseScreen = () => history.push("/AnalyzePortfolio");
  // const save_n_back = () => history.push("/PortfolioSummaryScreen");
  // function goToAnalyse() {
  //   const hasStocks = PortfolioValues[3];
  //   if (hasStocks !== 0) {
  //     // AnalyseScreen();
  //     setRoutePath('./AnalyzePortfolio');
  //   } else {
  //     console.log("Please add atleast one stock to your portfolio to proceed.");
  //     setPopupState(true);
  //   }
  // }

  if (Flag === false){
    console.log("stocks",stocks)
    const hasStocks = PortfolioValues[3];
    console.log("has stocks",hasStocks);
    if (hasStocks !== 0 || stocks !== null) {
      setRoutePath('./AnalyzePortfolio');
      setFlag(true);
    }else{
      console.log("Please add atleast one stock to your portfolio to proceed.");
      setPopupState(true);
      setFlag(true);
    }
  }

  return (
    <div className="ai-content-part">
      <NavBar />
      <div className="floathead">
        <PageHeader heading="Add Stocks" backButton={true} routeTo='./PortfolioSummaryScreen' />
        <Popup trigger={popupState} setTrigger={setPopupState}
          title="Add Stocks"
          content="Please add at least one stock to your portfolio to proceed"
          alertType="error" />
          <PortfolioDetails
            key={new Date().getTime()}
            passing_id={true}
            name={PortfolioValues[0]}
            stocks={PortfolioValues[1]}
            value={PortfolioValues[2]}
            routeTo={routePath}
            forward_route={true}
            delete_button={true}
            enable_shield={true}
            show_refresh_button={true}
            imgSrc="Assets/proceed.png"
            routeText="Proceed to Analysis"
            tabvalue={tabvalue}
            LastUpdated={LastUpdated}
            onChange={handleTabChange}
            showTabs={true}
            portfolioid={location.state.portfolioid}
            stocksData={stocksData}
          />
      </div>
      <TabContainer defaultActiveKey={tabvalue}>
        <TabContent>
          <TabPane eventKey="hedgeable" active={tabvalue === 'hedgeable'} >
            <StockTable
              PortfolioValues={PortfolioValues}
              onChange={handleValueChange}
              editable={true}
              hedgeable={true}
              setStocksData={setStocksData}
            />
          </TabPane>
          <TabPane eventKey="unhedgeable" active={tabvalue === 'unhedgeable'}>
            <StockTable
              PortfolioValues={PortfolioValues}
              onChange={handleValueChange}
              hedgeable={false}
              setStocksData={setStocksData}
            />
          </TabPane>
        </TabContent>
      </TabContainer>
    </div>
  );
}

export default PortfolioTable;
