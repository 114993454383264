import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function DeletePortfolio(portfolio_id) {
  const Token = sessionStorage.getItem("Token");

  const header = { Authorization: Token };

  // const session_id = sessionStorage.getItem("sessionID");

  var raw = JSON.stringify({ 
    session_id: Token,
    portfolio_id: portfolio_id 
  });

  var requestOptions = {
    method: "DELETE",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)) {
    try {

      const response = await fetchWithTimeout(
        process.env.REACT_APP_PORTFOLIO_API,
        requestOptions
      );
      if (!response.ok) {
        console.log("Could not delete this portfolio");
        return false;
      } else {
        const message = await response.json();
        if (message['result'] !== "") {
          return message['result'];
        }
        else if (message["error"] !== "") {
          if (message["error"] === "Token Verification Failed") {
            return 404
          } else {
            return 0
          }
        }
        else {
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("The request timed out, Please try again");
        // window.location.reload();
        return 0
      }
      else {
        return 0
      }
    }
  } else {
    return 401
  }

}
