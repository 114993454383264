import React from "react";
import "./SelfAssemblyTable.css";
import Popup from "../Popup/Popup";
import SelfAssemblyWindow from "../SelfAssemblyWindow/SelfAssembly";
export default function SelfAssemblyTable({ data, total, showPopup }) {
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  return (
    <div className="selfassambly-body">
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Shield Protection Calculator"
        content={popupMessage}
        alertType={alertType}
      />

      <div className="ai-selfassembly-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Contract</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total $</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr>
                {row.map((cell) => (
                  <td>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="ai-selfassembly-cost">
        <p>
          Total Cost :{" "}
          <span className="price-Guaranteed">${total}</span>
        </p>
      </div>
    </div>
  );
}
