import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function AddFeedbackMessage(feedbackmessage,sorucepage) {

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token,
    feedback:feedbackmessage,
    source_page:sorucepage
  });
  
  var requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
    headers: header,
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_ADD_FEEDBACK,
        requestOptions
      );
      if (!response.ok) {
        console.log("Could not add your feedback");
        return false;
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          return message["result"];
        } else {
          console.log(message["error"]);
          return false
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        return 0
      }
      else {
        return 0
      }
    }
  }else{
    return 401
  }
  
}
