import React from "react";
import "./ShieldProtectionScreen.css";
import withWidth from "@material-ui/core/withWidth";
import NavBar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";
import ShieldProtectionWindow from "../../Components/ShieldProtectionWindow/ShieldProtectionWindow";

function ShieldProtectionScreen(props) {
  return (
    <div className="ai-content-part">
      <NavBar />
      <div className="floathead">
        <PageHeader
          heading="Shield Calculator"
          backButton={true}
          addButton={false}
          routeTo="./ShieldRecommendationScreen"
        />
        <PortfolioDetails />
      </div>
      <div className="ShieldProtect-holder">
        <ShieldProtectionWindow />
      </div>
    </div>
  );
}

export default withWidth()(ShieldProtectionScreen);
