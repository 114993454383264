import React, { useState, useRef } from 'react';
import "./RiskContribution.scss";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageLoader from "../PageLoader/PageLoader";
import { GetUserPortfolios } from "../../Apis/GetUserPortfolios";
import { FetchRiskContribution } from "../../Apis/FetchRiskContribution";
import Popup from "../Popup/Popup";
import TagManager from "react-gtm-module";
import { addZeroes } from "../Utilities/AddZeros";
import withWidth from "@material-ui/core/withWidth";
import Tooltip from "react-tooltip-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import RiskContributionGraph from '../RiskContributionGraph/RiskContributionGraph';
import { GetAdvisorGraph } from '../../Apis/GetAdvisorGraph';
import MediumFont from "../../fonts/Inter-Medium.woff";
import SemiBoldFont from "../../fonts/Inter-SemiBold.woff";
import BoldFont from "../../fonts/Inter-Bold.woff";
import RegularFont from "../../fonts/Inter-V.ttf";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Font,
  View,
  Image,
} from "@react-pdf/renderer";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { path } from 'd3';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


function RiskContribution(props) {

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const divRef = useRef(null);

  const [show, setShow] = useState(true);
  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [portfolioName, setPortfolioName] = React.useState([]);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [PortfolioID, setPortfolioID] = React.useState(0);
  const [selectedPortfolioTotalStocks, setSelectedPortfolioTotalStocks] = React.useState(0);
  const [selectedPortfolioTotalValue, setSelectedPortfolioTotalValue] = React.useState(0);
  const [riskCOntributionTableData, setRiskCOntributionTableData] = React.useState([]);
  const [riskCOntributionGraphData, setRiskCOntributionGraphData] = React.useState([]);
  const [riskPortfolioKeyList, setRiskPortfolioKeyList] = React.useState([]);
  const [PortfolioMarketValue, setPortfolioMarketValue] = React.useState(0);
  const [PortfolioRisk, setPortfolioRisk] = React.useState(0);
  const [GraphData, setGraphData] = React.useState([]);
  const [vixVal, setVixVal] = React.useState(0);
  const [totalIndividualRiskContribution, setTotalIndividualRiskContribution] = React.useState(0);
  const [selectedPortfolioName, setSelectedPortfolioName] = React.useState("");
  const [selectedPortfolioNumStocks, setSelectedPortfolioNumStocks] = React.useState(0);
  const [selectedPortfolioValue, setSelectedPortfolioValue] = React.useState(0);
  Font.register({ family: "Inter-Bold", src: BoldFont });
  Font.register({ family: "Inter-V", src: RegularFont });
  Font.register({ family: "Inter-SemiBold", src: SemiBoldFont });
  Font.register({ family: "Inter-Medium", src: MediumFont });


  React.useEffect(() => {
    setBdOpen(true);
    GetUserPortfolios().then((data) => {
      var portfoliolyst = [{ id: 0, name: "Select Portfolio" }];
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        for (let [key, value] of Object.entries(data)) {
          setGotData(true);
          portfoliolyst.push({
            id: key,
            name: value.portfolioname,
            no_stocks: value.nooflineitems,
            port_value: parseFloat(value.totalportfoliovalue).toFixed(2)
          });
        }
        setPortfolioName(portfoliolyst);
        setBdOpen(false)
      }
      else if (data === false) {
        setPopupState(true);
        setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
        setAlertType("error");
        setBdOpen(false);
        setGotData(true);
        setPopupLoginRedirect(false);
      }
      else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are facing issues connecting our servers, please try again later");
        setAlertType("error");
        setBdOpen(false);
        setGotData(true);
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setFlag(true)
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }
    });
  }, []);

  const changePortfolio = (portfolioid) => {
    if (portfolioid === 0) {
      setPopupState(true);
      setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      return;
    }
    var portfolio = portfolioName.find(data => data.id === portfolioid);
    setPortfolioID(portfolio.id);
    setSelectedPortfolioTotalStocks(portfolio.no_stocks)
    setSelectedPortfolioTotalValue(portfolio.port_value)
    fetchRiskContribute(portfolio.id);
  };

  const fetchRiskContribute = (portfolioid) => {
    setBdOpen(true);
    setSelectedPortfolioName(portfolioName.find(data => data.id === portfolioid).name)
    setSelectedPortfolioNumStocks(portfolioName.find(data => data.id === portfolioid).no_stocks)
    setSelectedPortfolioValue(portfolioName.find(data => data.id === portfolioid).port_value)
    if (portfolioName.find(data => data.id === portfolioid).no_stocks !== 0) {
    FetchRiskContribution(portfolioid).then((data) => {
      //call the advisor graph api
      GetAdvisorGraph().then((vix_data) => {
        vix_data = vix_data['vix']['6mo']['closeprice']
        const vix_value = vix_data[vix_data.length - 1].toFixed(2)
        // console.log("Report", data)
        var lyst = [];
        var i = 1;
        if (data !== false && data !== 0 && data !== undefined && data !== 401) {
          setRiskPortfolioKeyList(Object.keys(data));
          for (let [key, value] of Object.entries(data["risk_contribution"])) {
            setPortfolioID(key);
            lyst.push({
              sr: i,
              symbol: key,
              position_name: value["Name"],
              market_value: Number(
                addZeroes(Number(parseFloat(value["Market Value"]).toFixed(2)))
              ).toLocaleString("en-US", currObj),
              risk_contribution: parseFloat(value["Pct Risk Contribution"]).toFixed(2),
              position_risk: parseFloat(value["Pct Implied Downside Volatility"]).toFixed(2),
              vix_value: vix_value,
              TradeDate: value.TradeDate
            });
            i = i + 1;
          }
          setRiskCOntributionTableData([...lyst]);
          setRiskCOntributionGraphData([...lyst.slice(0, 10)]);
          setPortfolioMarketValue(data["portfolio_market_value"]);
          setPortfolioRisk(data["portfolio_risk"]);
          setTotalIndividualRiskContribution(data['total_individual_risk_contribution']);
          TagManager.dataLayer({
            dataLayer: {
              event: 'Risk Contribution',
              userType: sessionStorage.getItem("selectedAcType"),
              user_id: sessionStorage.getItem("clientId")
            },
          });
          setBdOpen(false);
        } else if (data === false) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "No Portfolios are available for this user, redirecting you to portfolio upload page."
          );
          setAlertType("error");
        } else if (data === 0 || data === undefined) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "We are facing issues connecting our servers, please try again later"
          );
          setAlertType("error");
          setPopupLoginRedirect(false);
          return;
        } else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        }
      });
    });
  }else{
    setBdOpen(false);
    setPopupState(true);
    setPopupMessage(
      "No stocks found. Please add atleast one stock to proceed."
    );
    setAlertType("error");
    setPopupLoginRedirect(false);
    return;
  }
  }

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    image: {
      marginVertical: 15,
      textAlign: "left",
      height: 40,
      width: 150,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    footer: {
      position: "absolute",
      fontSize: 8,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "left",
      color: "#3E4968",
      paddingLeft: 30,
      paddingRight: 30,
    },
    summaryHeader: {
      color: "#3E4968",
      fontSize: 18,
      textAlign: "left",
      fontWeight: "bold",
      marginVertical: 15,
      fontFamily: "Inter-Bold",
    },
    tableheader: {
      backgroundColor: "#ffffff",
      borderRadius: 5,
      color: "#60034c",
      fontSize: 8,
      padding: 10,
      fontFamily: "Inter-Bold",
    },

    tablebody: {
      fontSize: 8,
      padding: 8,
      textAlign: "left",
      fontFamily: "Inter-V",
      color: '#333333',
      textTransform: 'capitalize',
    },
    tableRow: {
      flexDirection: "row",
      height: 25,
      '&:nthOfType(even)': {
        backgroundColor: '#d3d3d3',
      },
      textTransform: 'capitalize',

    },

    detailsHeader: {
      backgroundColor: 'rgba(96, 3, 76, 0.1)',
      padding: 10,
      color: "#60034c",
      fontSize: 10,
      fontFamily: "Inter-Bold",
    },

    summaryFooter: {
      backgroundColor: 'rgba(96, 3, 76, 0.1)',
      padding: 10,
      color: "#3E4968",
      fontSize: 8,
      fontFamily: "Inter-Bold",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'align-items',
      alignItems: 'center',
    },
    portfolioinfoBar: {
      border: '0.5px solid #60034C',
      borderRadius: 5,
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: "Inter-V",
      marginBottom: 20,
      fontSize: 8,
    },

    Footnotes: {
      fontSize: 8,
      color: "#60034C",
      textAlign: "left",
      fontFamily: "Inter-Medium",
      marginTop: 20,
      display: 'flex',
    }
  });

  const DetailElement = (
    <div className=''>

    </div>
  )



  const [imgData, setImgData] = React.useState(null);
  const [triggerDownload, setTriggerDownload] = React.useState(false);

  // function PrintDocument() {

  // }

  // const MyDocument = () => (
  //   <Document>
  //     <Page size="A4">
  //       <View>
  //         <Image src={imgData} crossOrigin="anonymous" />
  //         <View style={{ flexDirection: 'row', marginTop: 20 }}>
  //           <Text style={{ fontWeight: 'bold' }}>Portfolio Name: {selectedPortfolioName}</Text>
  //           <Text style={{ marginLeft: 20 }}>Holdings: {selectedPortfolioNumStocks}</Text>
  //           <Text style={{ marginLeft: 20 }}>Portfolio Value: {selectedPortfolioValue}</Text>
  //         </View>
  //         <Text style={{ color: '#60034C', fontSize: 8, marginTop: 20 }}>
  //           Risk analysis as of market close for previous trading day.
  //         </Text>
  //         <Text style={{ color: '#60034C', fontSize: 8 }}>
  //           Portfolio Risk - Downside volatility of a portfolio as implied by options markets.
  //         </Text>
  //         <Text style={{ color: '#60034C', fontSize: 8 }}>
  //           Risk Contribution - Percentage of total portfolio risk attributable to individual holdings.
  //         </Text>
  //         <Text style={{ color: '#60034C', fontSize: 8 }}>
  //           Position Risk - Downside volatility of an individual holding as implied by option prices.
  //         </Text>
  //       </View>
  //     </Page>
  //   </Document>
  // );


  const updateImgData = (canvas) => {
    const dataURL = canvas.toDataURL('image/png');
    console.log(dataURL);
    setImgData(dataURL);
  }



  const element = (
    <html>
      <body>
        <div style={styles.portfolioinfoBar}>
          <p>Portfolio: {selectedPortfolioName} </p>
          <p>Holdings: {selectedPortfolioNumStocks}</p>
          <p>Portfolio Value: ${" "}{Number(addZeroes(Number(parseFloat(selectedPortfolioValue).toFixed(2)))).toLocaleString()}</p>
        </div>
        <div>
          <img src={imgData} />
        </div>
        <div>
          <div style={styles.detailsHeader}>
            <p>Portfolio Details</p>
          </div>
          <table>
            <thead style={styles.tableheader}>
              <tr>
                <th>#</th>
                <th>Symbol</th>
                <th>Position Name</th>
                <th style = {{paddingLeft: 10}}>Market Value</th>
                <th>Risk Contribution</th>
                <th>Position Risk</th>
              </tr>
            </thead>
            <tbody style={styles.tablebody}>
              {riskCOntributionTableData.map((row, index) => (
                <tr key={index} style={styles.tableRow}>
                  <td>{index + 1}</td>
                  <td>{row.symbol}</td>
                  <td>{row.position_name}</td>
                  <td style = {{paddingLeft: 10}}>{row.market_value}</td>
                  <td>{row.risk_contribution}</td>
                  <td>{row.position_risk}</td>
                </tr>
              ))}
              {/* Add more rows as needed */}
            </tbody>
          </table>
          <div style={styles.summaryFooter}>
            <p>Portfolio Total: </p>
            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 200}}>${" "}{Number(addZeroes(Number(parseFloat(selectedPortfolioValue).toFixed(2)))).toLocaleString()}</p>
            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 45 }}>{PortfolioRisk.toFixed(2)}%</p>
          </div>
          <div style={styles.Footnotes}>
            <p style={{ fontSize: 8, color: '#60034C' }}>Risk analysis as of market close for previous trading day.</p>
            <p style={{ fontSize: 8, color: '#60034C' }}>Portfolio Risk - Downside volatility of a portfolio as implied by options markets.</p>
            <p style={{ fontSize: 8, color: '#60034C' }}>Risk Contribution - Percentage of total portfolio risk attributable to individual holdings.</p>
            <p style={{ fontSize: 8, color: '#60034C' }}>Position Risk - Downside volatility of an individual holding as implied by option prices.</p>
          </div>
        </div>
      </body>
    </html>
  );


  const html = ReactDOMServer.renderToStaticMarkup(element);

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.image} src="Assets/adaptive-name.png" fixed />
        <Html resetStyles>{html}</Html>
        <Text style={styles.footer} fixed>
          Adaptive funds are not held in a brokerage account and are not
          protected by SIPC. Brokerage assets are held by Adaptive Investment
          Services, a division of Adaptive Investments LLC.
        </Text>
      </Page>
    </Document>
  );

const handleDownload = async () => {
  setBdOpen(true);
  const input = document.getElementById('divToPrint');

  await html2canvas(input, { useCORS: true }).then((canvas) => {
    updateImgData(canvas);
  });

  
  setTriggerDownload(true);
};

React.useEffect(() => {
  const createAndDownloadPdf = async () => {
    if (!imgData || !triggerDownload) {
      setBdOpen(false);
      return;
    }

    const doc = <MyDocument imgData={imgData} />;
    const pdfBlob = await pdf(doc).toBlob(); 

    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Portfolio-Report.pdf';
    link.click();
    URL.revokeObjectURL(url);
    setTriggerDownload(false);
    setBdOpen(false)
    TagManager.dataLayer({
        dataLayer: {
          event: 'Risk Report Downloaded',
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId")
        },
      });
  };

  createAndDownloadPdf();
}, [triggerDownload]);


  return (
    <div className="risk-cont portfolio_page-holder">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Run Forward Test"
        alertType={alertType}
        content={popupMessage}
        loginRedirect={popupLoginRedierct}
      />
      <div className="details_wrap self_assembly">
        <div className="infocard-wrap">
          <div id ="selectportfolio" className="infocard">
            <h3>Portfolio</h3>
            <Select
              className="ai-form-value-bt"
              onChange={(event) => changePortfolio(event.target.value)}
            >
              {portfolioName.map((value, i) => (
                <MenuItem value={value.id}>{value.name}</MenuItem>
              ))}
            </Select>
            <span className="ai-selfassembly-select-arrow"></span>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>Holdings</h3>
            <p id="portfolioholdings" >{selectedPortfolioTotalStocks}</p>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>Value</h3>
            <p id="portfoliovalue">
              ${Number(addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))).toLocaleString("en-US")}
            </p>
          </div>
        </div>
        {/* <PDFDownloadLink document={<MyDocument />}> */}
          <button id="downloadriskpdf" className="ai-btn primary solid" onClick={handleDownload}>Download PDF</button>
        {/* </PDFDownloadLink> */}
      </div>
      <div id='divToPrint' className='GraphDiv' ref={divRef}>
        <div className="riskhead" id='graphHeader'>
          <h2>Top 10 Risk Contributions</h2>
          <div className="riskstate">
            <h3 id="portfoliorisk">{PortfolioRisk.toFixed(2)} %</h3>
            <div className="lblrisk">Portfolio Risk
              <Tooltip content="Downside volatility of a portfolio as implied by options markets.">
                <img src="Assets/risk-info.png"></img>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='riskmodes'>
          <div><span className='risk'></span>Risk Contribution <Tooltip content="Percentage of total portfolio risk attributable to individual holdings.">
            <img src="Assets/risk-info.png"></img>
          </Tooltip></div>
          <div><span className='position'></span>Position Risk <Tooltip content="Downside volatility of an individual holding as implied by option prices.">
            <img src="Assets/risk-info.png"></img>
          </Tooltip></div>
          <div><span className='vix'></span>VIX <Tooltip content={(<div><span>Broad measure of market risk.</span> <br></br><a href='https://www.investopedia.com/terms/v/vix.asp' target='_blank' className='ai-link'>Learn more.</a></div>)}>
            <img src="Assets/risk-info.png"></img>
          </Tooltip></div>
        </div>
        <div className="riskchart">
          <p className='chartlbl left'>Risk Contribution</p>
          {riskCOntributionGraphData &&
            <RiskContributionGraph data={riskCOntributionGraphData} />
          }
          <p className='chartlbl right'>Position Risk</p>
        </div>
      </div>
      <div className="portfolio-table contributio-table">

        <div id="portfolioriskdetails" className="table_holder table_head m0i">
          <h3 onClick={() => setShow(!show)}>Portfolio Details {show && <span className="minus">-</span>} {!show && <span>+</span>}</h3>
          {show &&
            <TableContainer className="table_height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell size="small">#</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell>Position Name</TableCell>
                    <TableCell>Market Value</TableCell>
                    <TableCell>Risk Contribution
                      <Tooltip content="Percentage of total portfolio risk attributable to individual holdings.">
                        <img src="Assets/risk-info.png"></img>
                      </Tooltip>
                    </TableCell>
                    <TableCell>Position Risk
                      <Tooltip content="Downside volatility of an individual holding as implied by option prices.">
                        <img src="Assets/risk-info.png"></img>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {riskCOntributionTableData.map((value) => (
                    <TableRow>
                      <TableCell>{value.sr}</TableCell>
                      <TableCell>{value.symbol}</TableCell>
                      <TableCell>{value.position_name}</TableCell>
                      <TableCell>{value.market_value}</TableCell>
                      <TableCell>{value.risk_contribution}%</TableCell>
                      <TableCell>{value.position_risk}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan="3">Portfolio Total:</TableCell>
                    <TableCell>{Number(
                      addZeroes(Number(parseFloat(PortfolioMarketValue).toFixed(2)))
                    ).toLocaleString("en-US", currObj)}</TableCell>
                    <TableCell>{Number(parseFloat(totalIndividualRiskContribution).toFixed(2))} %</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          }
        </div>
      </div>
    </div>
  );
}

export default withWidth()(RiskContribution);
