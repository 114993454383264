import React from "react";
import "./StockTable.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetStocks } from "../../Apis/GetStocks";
import { AddStocks } from "../../Apis/AddStocks";
import { DeleteStocks } from "../../Apis/DeleteStocks";
import { UpdateQuantity } from "../../Apis/UpdateQuantity";
import { UpdatePortfolioRisk } from "../../Apis/UpdatePortfolioRisk";
import { FetchCustomerCsvUpdate } from "../../Apis/FetchCustomerCsvUpdate";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addZeroes } from "../Utilities/AddZeros";
import Popup from "../Popup/Popup";
import Tooltip from "react-tooltip-lite";
import TagManager from "react-gtm-module";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import check from "../../images/check_w.svg"
import shield from "../../images/shield.svg"
import editicon from "../../images/edit.svg"
import deleticon from "../../images/delete.svg"
import PageLoader from "../PageLoader/PageLoader";


// import Tooltip from "../Tooltip/Tooltip";
//Blooper

function StockTable(props) {
  const useStyles = makeStyles((theme) => ({
    del_button: {
      color: "#60034c",
      height: 10,
      width: 10,
    },
    edit_button: {
      color: "#60034c",
      height: 10,
      width: 10,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  const location = useLocation();
  React.useEffect(() => {
  }, [location]);

  const [Flag, setFlag] = React.useState(false);
  const [PortfolioTotalStocks, setPortfolioTotalStocks] = React.useState(
    sessionStorage.getItem("PortfolioTotalStocks")
  );
  const [PortfolioTotalValue, setPortfolioTotalValue] = React.useState(
    sessionStorage.getItem("PortfolioTotalValue")
  );
  const [GotID, setGotID] = React.useState(false);
  const [PortfolioID, setPortfolioID] = React.useState("");
  const [Ticker, setTicker] = React.useState("");
  const [Quantity, setQuantity] = React.useState("");
  const [DataL, SetData] = React.useState([]);
  const [HedgeableData, sethedgeabledata] = React.useState([]);
  const [UnhedgeableData, setunhedgeabledata] = React.useState([]);
  const [bdopen, setBdOpen] = React.useState(true);
  const [EditableValue, setEditableValue] = React.useState(0);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [stocksData, setStocksData] = React.useState([]);
  const [APICount, setAPICount] = React.useState(0);
  const [APICalled, setAPICalled] = React.useState(false);
  const hiddenFileInput = React.useRef(null);

  function handleValueChange(name, no_stocks, value, NoStocks, update_time,stockdata) {
    props.onChange([name, no_stocks, value, NoStocks, update_time,stockdata]);
  }

  const history = useHistory();

  const ShieldRecommendation = () => {
    if (stocksData.number_of_lineitems === undefined || stocksData.number_of_lineitems === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Please add atleast one stock to your portfolio to proceed.");
        setPopupTitle("No Stock Data");
        setPopupLoginRedirect(false);
        return false;
      } else {
      history.push({
        pathname: "./ShieldRecommendationScreen",
        state: { portfolioid: location.state.portfolioid,
                  stocksData: stocksData }
      });
    }
  }

  const ShieldProtectionScreen = () => {
    if (stocksData.number_of_lineitems === undefined || stocksData.number_of_lineitems === 0) {
      setBdOpen(false);
      setPopupState(true);
      setPopupMessage("Please add atleast one stock to your portfolio to proceed.");
      setPopupTitle("No Stock Data");
      setPopupLoginRedirect(false);
      return false;
    } 
    else {
      history.push({
        pathname: "./ShieldProtectionScreen",
        state: {
          portfolioid: location.state.portfolioid,
          stocksData: stocksData,
        },
      });
    }
  }

  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioTotalStocks", PortfolioTotalStocks);
  }, [PortfolioTotalStocks]);

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioTotalValue", PortfolioTotalValue);
  }, [PortfolioTotalValue]);

  const PortfolioShieldStatus = sessionStorage.getItem("PortfolioShieldStatus");

  const changeQuantity = (quant) => {
    setQuantity(quant);
  };

  const changeTicker = (tick) => {
    setTicker(tick);
  };

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUpload = (event) => {
    setBdOpen(true);
    event.persist();
    const [file] = event.target.files;
    var fileExt = file.name.split(".").pop();
    if (fileExt === "csv") {
      const reader = new FileReader();
      reader.onloadend = (onLoadEndEvent) => {
        console.log(onLoadEndEvent.target.result.split(",")[1]);
        FetchCustomerCsvUpdate(location.state.portfolioid, onLoadEndEvent.target.result.split(",")[1]).then(
          (data) => {
            console.log(' data', data);
            if (data !== false && data !== 0 && data !== "WRONG_FORMAT" && data !== 401) {
              history.push({
                pathname: "./CustomerLineItemChanges",
                state: { portfolioid: location.state.portfolioid,
                        CsvData: data }
                });
              setBdOpen(false);
            } else if (data === false) {
              setBdOpen(false);
              setPopupState(true);
              setPopupMessage(
                "We are unable to proccess the csv, please try again later."
              );
              setPopupTitle("Unable to process CSV");
              setPopupLoginRedirect(false);
            } else if (data === 0) {
              setBdOpen(false);
              setPopupState(true);
              setPopupMessage(
                "We are facing server issues, please try again later."
              );
              setPopupTitle("Server Error");
              setPopupLoginRedirect(false);
            }
            else if (data === "WRONG_FORMAT") {
              setBdOpen(false);
              setPopupState(true);
              setPopupMessage(
                "Unable to process CSV. Please try again or contact support@adaptive-investments.com."
              );
              setPopupTitle("Server Error");
              setAlertType("error");
              setPopupLoginRedirect(false);
            } else if (data === 401) {
              setBdOpen(false);
              setPopupState(true);
              setPopupMessage(
                "Your session timed out for security reasons. Please sign-in again."
              );
              setAlertType("error");
              setPopupLoginRedirect(true);
              setPopupTitle("Session Expired");
            }
          }
        );
      };
      reader.readAsDataURL(file);
    } else {
      setPopupState(true);
      setPopupMessage("Unable to process CSV. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
    }
  };

  const fetchStockTable = () => {
    setBdOpen(true);
    try {
      GetStocks(location.state.portfolioid).then((data) => {
        setGotID(true);
        setAPICalled(true);
        console.log("data",data);
        var lyst = [];
        var lyst1 = [];
        var i = 1;
        if (data !== null && data !== false && data !== undefined && data !== 0 && Object.keys(data).length !== 0 && data !== 401 && data !== 404) {
          setStocksData(data);
          setPortfolioTotalStocks(data["number_of_lineitems"]);
          setPortfolioTotalValue(data["total_portfolio_value"]);
          for (let [key, value] of Object.entries(data['hedgeable'])) {
            if (key !== "number_of_lineitems") {
              if (key !== "total_portfolio_value") {
                if (props.editable === true) {
                  lyst.push([
                    key,
                    value.quantity,
                    Number(
                      addZeroes(Number(parseFloat(value.todays_close).toFixed(2)))
                    ).toLocaleString("en-US", currObj),
                    Number(
                      addZeroes(
                        Number((value.quantity * value.todays_close).toFixed(2))
                      )
                    ).toLocaleString("en-US", currObj),
                    <div>
                      {value.shieldstatus === "partial" && (
                        <img
                          src="Assets/shield-orange.png"
                          style={{ height: "28px" }}
                        />
                      )}
                      {value.shieldstatus === "active" && (
                        <img
                          src="Assets/shield-green.png"
                          style={{ height: "28px" }}
                        />
                      )}
                      {value.shieldstatus === "inactive" && (
                        <img
                          src="Assets/shield-red.png"
                          style={{ height: "28px" }}
                        />
                      )}
                    </div>,
                    value.shieldedquantity,
                    Number(
                      addZeroes(
                        Number(
                          (value.shieldedquantity * value.todays_close).toFixed(2)
                        )
                      )
                    ).toLocaleString("en-US", currObj),
                    <div>
                      <IconButton
                        className={classes.edit_button}
                        aria-label="edit"
                        color="secondary"
                        onClick={() => MakeEditable(lyst, key)}
                      ><img src={editicon}></img></IconButton>
                      <IconButton
                        className={classes.del_button}
                        aria-label="delete"
                        color="secondary"
                        onClick={() =>
                          DeleteStock(key, value.quantity)
                        }
                      ><img src={deleticon}></img></IconButton>
                    </div>
                  ]);
                } else {
                  lyst.push([
                    key,
                    value.quantity,
                    Number(
                      addZeroes(Number(parseFloat(value.todays_close).toFixed(2)))
                    ).toLocaleString("en-US", currObj),
                    Number(
                      addZeroes(
                        Number((value.quantity * value.todays_close).toFixed(2))
                      )
                    ).toLocaleString("en-US", currObj),
                  ]);
                }

                i = i + 1;
                sethedgeabledata([...lyst]);
                handleValueChange(
                  value.portfolioname,
                  data.number_of_lineitems,
                  parseFloat(data.total_portfolio_value).toFixed(2),
                  lyst.length,
                  data.update_time,
                  data
                );
              }
            }
          }
          for (let [key, value] of Object.entries(data['unhedgeable'])) {
            if (key !== "number_of_lineitems") {
              if (key !== "total_portfolio_value") {
                if (props.editable === true) {
                  lyst1.push([
                    key,
                    value.quantity,
                    Number(
                      addZeroes(Number(parseFloat(value.todays_close).toFixed(2)))
                    ).toLocaleString("en-US", currObj),
                    Number(
                      addZeroes(
                        Number((value.quantity * value.todays_close).toFixed(2))
                      )
                    ).toLocaleString("en-US", currObj),
                    <div>
                      {value.shieldstatus === "partial" && (
                        <img
                          src="Assets/shield-orange.png"
                          style={{ height: "28px" }}
                        />
                      )}
                      {value.shieldstatus === "active" && (
                        <img
                          src="Assets/shield-green.png"
                          style={{ height: "28px" }}
                        />
                      )}
                      {value.shieldstatus === "inactive" && (
                        <img
                          src="Assets/shield-red.png"
                          style={{ height: "28px" }}
                        />
                      )}
                    </div>,
                    value.shieldedquantity,
                    Number(
                      addZeroes(
                        Number(
                          (value.shieldedquantity * value.todays_close).toFixed(2)
                        )
                      )
                    ).toLocaleString("en-US", currObj),
                    <div>
                      <IconButton
                        className={classes.edit_button}
                        aria-label="edit"
                        color="secondary"
                        onClick={() => MakeEditable(lyst, key)}
                      ><img src={editicon}></img></IconButton>
                      <IconButton
                        className={classes.del_button}
                        aria-label="delete"
                        color="secondary"
                        onClick={() =>
                          DeleteStock(key, value.quantity)
                        }
                      ><img src={deleticon}></img></IconButton>
                    </div>
                  ]);
                } else {
                  lyst1.push([
                    key,
                    value.quantity,
                    "-",
                    "-",
                    " ",
                    "-",
                    "-"
                  ]);
                }

                i = i + 1;
                setunhedgeabledata([...lyst1]);
                // handleValueChange(
                //   value.portfolioname,
                //   data.number_of_lineitems,
                //   parseFloat(data.total_portfolio_value).toFixed(2),
                //   lyst.length,
                //   data.update_time
                // );
              }
            }
          }
          setBdOpen(false);
        } else if (data === false) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
          setPopupTitle("Error");
          setPopupLoginRedirect(false);
          return;
        } else if (data === null) {
          setPopupState(true);
          setPopupMessage(
            "Portfolio is empty: Please add portfolio holdings"
          );
          setPopupTitle("No Holdings Found");
          setFlag(true);
          setBdOpen(false);
          setunhedgeabledata([]);
          sethedgeabledata([]);
          setPopupLoginRedirect(false);
        }else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setFlag(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        }else if (data === 404) {
          setBdOpen(false);
          setPopupState(true);
          setFlag(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        } else if (data === 0 || Object.keys(data).length === 0) {
          setPopupState(true);
          setPopupMessage(
            "We are facing issues connecting our servers, please try again later."
          );
          setPopupTitle("Error");
          setFlag(true);
          setBdOpen(false);
          setPopupLoginRedirect(false);
          return;
        } 

      });
    } catch (error) {
      setBdOpen(false);
      setAPICalled(true);
      setPopupState(true);
      setPopupMessage(
        "We are facing issues connecting our servers, please try again later"
      );
      setAlertType("error");
      return;
    }
  }

  if (Flag === false) {
    if (APICalled === false && APICount === 0) {
      setAPICount(1);
      setAPICalled(true);
      fetchStockTable();
    }
    if (GotID === true && APICalled === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  function DeleteStock(ticker) {
    setBdOpen(true);
    DeleteStocks(ticker,location.state.portfolioid).then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data!==404) {
        setFlag(false);
        fetchStockTable();
        UpdatePortfolioRisk().then((update_data) => {
          if (update_data === true) {
            console.log(" portoflio risk updates");
          }
        });
        // setBdOpen(false);
      } else if (data === false) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Unable to delete stock. Please try again or contact support@adaptive-investments.com.");
        setPopupTitle("Error");
        setPopupLoginRedirect(false);
      } else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("We are faicng server issues, please try again later");
        setPopupTitle("Server Error");
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(false);
        setPopupTitle("Session Expired");
      }
    });
    TagManager.dataLayer({
      dataLayer: {
        'event': 'Delete Ticker',
        'userType':sessionStorage.getItem("selectedAcType"),
        'user_id': sessionStorage.getItem("clientId")
      },
    });
  }

  function changeQuant(event, ticker, value) {
    if (event.key === "Enter") {
      setBdOpen(true);
      console.log(ticker, value);
      UpdateQuantity(ticker, value, location.state.portfolioid).then((data) => {
        if (
          data !== false &&
          data !== null &&
          data !== undefined &&
          data !== 0 &&
          data !== 404
        ) {
          setFlag(false);
          fetchStockTable();
          UpdatePortfolioRisk().then((update_data) => {
            if (update_data === true) {
              console.log("portoflio risk updated");
              TagManager.dataLayer({
                dataLayer: {
                  'event': 'Edit Ticker',
                  'userType':sessionStorage.getItem("selectedAcType"),
                  'user_id': sessionStorage.getItem("clientId")
                },
              });
            }
          });
          // setBdOpen(false);
        } else if (data === null) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("Holding quantity not recognized. Please try again or contact support@adaptive-investments.com.");
          setPopupLoginRedirect(false);
          setPopupTitle("Update Stock Quantity");
        } else if (data === undefined) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("Holding quantity not recognized. Please try again or contact support@adaptive-investments.com.");
          setPopupLoginRedirect(false);
          setPopupTitle("Update Stock Quantity");
        } else if (data === 0) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "We are having server issues, please try again later"
          );
          setPopupLoginRedirect(false);
          setPopupTitle("Server Error");
        } else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        }else if (data === 404) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(false);
          setPopupTitle("Session Expired");
          setFlag(false);
        }
      });
    }
  }

  function changeQuant2(event, ticker, value) {
    setBdOpen(true);
    UpdateQuantity(ticker, value).then((data) => {
      if (data !== false && data !== null && data !== undefined && data !== 0 && data !== 401) {
        setFlag(false);
        fetchStockTable();
        UpdatePortfolioRisk().then((update_data) => {
          if (update_data === true) {
            console.log(" portoflio risk updates");
            TagManager.dataLayer({
              dataLayer: {
                'event': 'Edit Ticker',
                'userType':sessionStorage.getItem("selectedAcType"),
                'user_id': sessionStorage.getItem("clientId")
              },
            });
          }
        });
        // setBdOpen(false);
      }
      if (data === null) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Holding quantity not recognized. Please try again or contact support@adaptive-investments.com.");
        setPopupTitle("Update Stock Quantity");
        setPopupLoginRedirect(false);
      } else if (data === undefined) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Holding quantity not recognized. Please try again or contact support@adaptive-investments.com.");
        setPopupTitle("Update Stock Quantity");
        setPopupLoginRedirect(false);
      } else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("We are facing server issues, please try again later");
        setPopupTitle("Server Error");
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
    });
  }

  function MakeEditable(lyst, ticker) {
    for (var i = 0; i < lyst.length; i++) {
      if (lyst[i][0] === ticker) {
        lyst[i][1] = (
          <input
            type="text"
            id="editquantity"
            className="sname_field"
            // value={EditableValue}
            placeholder="Value"
            onChange={(event) => setEditableValue(event.target.value)}
            onKeyUp={(event) =>
              changeQuant(event, ticker, event.target.value)
            }
            onBlur={(event) =>
              changeQuant2(event, ticker, event.target.value)
            }
            autoFocus="true"
          ></input>
        );
      }
    }
    sethedgeabledata([...lyst]);
  }

  function AddStock() {
    if (!(Ticker.toUpperCase() in stocksData)) {
      setBdOpen(true);
      AddStocks(Ticker, Quantity, location.state.portfolioid).then((data) => {
        if (data !== false) {
          setFlag(false);
          fetchStockTable();
          UpdatePortfolioRisk().then((update_data) => {
            if (update_data === true) {
              console.log(" portoflio risk updates");
            }
          });
        }
        console.log(data);
        setTicker("");
        setQuantity("");
        if (data === null) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("Value not recognized. Please try again or contact support@adaptive-investments.com.");
          setPopupLoginRedirect(false);
          setPopupTitle("Add Stocks");
        } else if (data === undefined) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("Holding quantity not recognized. Please try again or contact support@adaptive-investments.com.");
          setPopupLoginRedirect(false);
          setPopupTitle("Add Stocks");
        } else if (data === false) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Unable to add portfolio holding. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Add Stocks");
          setPopupLoginRedirect(false);
          setAlertType("error");
        } else if (data === 0) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later"
          );
          setPopupTitle("Server Error");
          setPopupLoginRedirect(false);
          setAlertType("error");
        } else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
        }else if (data === 404) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(false);
          setPopupTitle("Session Expired");
        }
      });
    } else {
      setPopupState(true);
      setPopupMessage(
        "Holding already exists in the portfolio."
      );
      setPopupLoginRedirect(false);
      setPopupTitle("Add Stocks");
    }
    TagManager.dataLayer({
      dataLayer: {
        'event': 'Add Ticker',
        'userType':sessionStorage.getItem("selectedAcType"),
        'user_id': sessionStorage.getItem("clientId")
      },
    });
  }

  const headers = [
    { title: "STOCK" },
    { title: "QUANTITY", tooltip: "Number of shares held for each stock" },
    {
      title: "LAST PRICE",
      tooltip: "Price of a security at the EOD previous market close",
    },
    {
      title: "MARKET VALUE",
      tooltip: "Market value of each security based on last price",
    },
    { title: "" },
    {
      title: "SHIELDED QUANTITY",
      tooltip: "Quantity of shares protected under existing Shield agreement",
    },
    {
      title: "SHIELDED MARKET VALUE",
      tooltip:
        "AUP (Assets Under Protection) is the total value of Assets Protected by Adaptive Shield",
    },
    { title: "" },
  ];

  return (
    <div className="portfolio-table">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      ></Popup>
      <div className="table_holder portfoliotable stocklist">
        {props.editable && (
          <div className="porttop">
            <div className="tiker">
              <input
                id="ticker"
                type="text"
                placeholder="Ticker"
                className="ai_input"
                value={Ticker}
                onChange={(event) => changeTicker(event.target.value)}
              ></input>
              <input
                id="quantity"
                type="text"
                placeholder="Quantity"
                className="ai_input"
                value={Quantity}
                onChange={(event) => changeQuantity(event.target.value)}
              ></input>
              <IconButton
                className="ai-btn round primary small"
                aria-label="delete"
                color="secondary"
                onClick={AddStock}
                id="add_stock_button"
              >
                +
              </IconButton>
            </div>
            <div className="listbtn">
            <button className="ai-btn secondary solid" onClick={handleFileClick}>
              <FileDownloadOutlinedIcon />
              <input
                type="file"
                id="fileUpload"
                accept=".csv"
                ref={hiddenFileInput}
                onChange={handleUpload}
                style={{ display: "none" }}
              />{" "}
              Update from Csv
            </button>
              <a id = "shieldrecommendations" className="ai-btn primary solid" onClick={ShieldRecommendation}><img src={check}></img>Shield Recommendations</a>
              <a id = "customizeshield" className="ai-btn primary line" onClick={ShieldProtectionScreen}><img src={shield}></img>Customize Shield</a>
            </div>
          </div>
        )}
        {props.hedgeable && (
          <div className="table_holder">
            <TableContainer className="table_height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                  {props.editable ? headers.map((header) => (
                      <TableCell>
                        <Tooltip content={header.tooltip}>
                          <div className="stck_table_heading">{header.title}</div>
                        </Tooltip>
                      </TableCell>
                    )): headers.slice(0,4).map((header) => (
                      <TableCell>
                        <Tooltip content={header.tooltip}>
                          <div className="stck_table_heading">{header.title}</div>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody id="hedegabletable">
                  {HedgeableData.map((row) => (
                    <TableRow>
                      {row.map((cell) => (
                        <TableCell>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {!props.hedgeable && (
          <div className="table_holder">
            <TableContainer className="table_height">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableCell>
                        <Tooltip content={header.tooltip}>
                          <div className="stck_table_heading">{header.title}</div>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody id="unhedegabletable">
                  {UnhedgeableData.map((row) => (
                    <TableRow>
                      {row.map((cell) => (
                        <TableCell>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
}

export default withWidth()(StockTable);
