import React from "react";
import "./PortfolioSummaryScreen.css";
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioSummary from "../../Components/PortfolioSummary/PortfolioSummary";
import Navbar from "../../Components/Navbar/Navbar_official"
export default function PortfolioSummaryScreen() {
  return (
    <div className="ai-content-part">
        <Navbar/>
        <PageHeader heading="My Portfolios" backButton={false} addButton={true} buttonText = 'Add new Portfolio'/>           
        <div className="portfolio_page-holder">
          <PortfolioSummary />
        </div>
    </div>
    );
}