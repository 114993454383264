import "./AddNewClient.css";
import React from 'react';
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Popup from "../Popup/Popup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageHeader from "../../Components/PageHeader/PageHeader";
import { AddNewAdvisorClient } from "../../Apis/AddNewAdvisorClient";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";

function AddNewClient() {

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  const [AdaptiveId, setAdaptiveId] = React.useState("");
  const [ExternalId, setExternalId] = React.useState("");
  const [ClientName, setClientName] = React.useState("");
  const [ClientPhone, setClientPhone] = React.useState("");
  const [ClientEmail, setClientEmail] = React.useState("");
  const [ClientAddress, setClientAddress] = React.useState("");
  const [Errors, setErrors] = React.useState({ "eIdError": "", "nameError": "", "phoneError": "", "emailError": "" })
  const [bdopen, setBdOpen] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);

  const refreshPage = () => {
    window.location.reload();
  }
  const history = useHistory();
  const formSubmit = () => history.push("/ClientList");

  const validateClientForm = () => {
    let formIsValid = true;
    if (!ExternalId) {
      formIsValid = false;
      setErrors(d => ({ ...d, "eIdError": "Cannot be empty" }));
    }
    else {
      setErrors(d => ({ ...d, "eIdError": "" }));
    }
    if (!ClientName) {
      formIsValid = false;
      setErrors(d => ({ ...d, "nameError": "Cannot be empty" }));
    } else if (typeof ClientName !== "undefined") {
      if (!ClientName.match(/^[a-zA-Z\s]+$/)) {
        formIsValid = false;
        setErrors(d => ({ ...d, "nameError": "Name should contain only letters" }));
      } else {
        setErrors(d => ({ ...d, "nameError": "" }));
      }
    }
    if (!ClientPhone) {
      formIsValid = false;
      setErrors(d => ({ ...d, "phoneError": "Cannot be empty" }));
    } else if (typeof ClientPhone !== "undefined") {
      if (!ClientPhone.match(/^[0-9]+$/)) {
        formIsValid = false;
        setErrors(d => ({ ...d, "phoneError": "Phone number should contain only digits" }));
      } else {
        setErrors(d => ({ ...d, "phoneError": "" }));
      }
    }
    if (!ClientEmail) {
      formIsValid = false;
      setErrors(d => ({ ...d, "emailError": "Cannot be empty" }));
    } else {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ClientEmail)) {
        formIsValid = false;
        setErrors(d => ({ ...d, "emailError": "Incorrect E-mail Id" }));
      }
      else {
        formIsValid = true;
        setErrors(d => ({ ...d, "emailError": "" }));
      }
    }
    return formIsValid
  };

  const addNewClient = (e) => {
    e.preventDefault();
    if (validateClientForm()) {
        setBdOpen(true);
        AddNewAdvisorClient(ExternalId, ClientName, ClientPhone, ClientEmail, ClientAddress ).then((data) => {
          // setBdOpen(true);
          if (data !== false && data !== 0 && data !== 404) {
            formSubmit();
            TagManager.dataLayer({
              dataLayer: {
                event: 'New Client Added',
                userType:sessionStorage.getItem("selectedAcType"),
                user_id: sessionStorage.getItem("clientId")
              },
            });
          }
          else if (data === 0) {
            setPopupState(true);
            setPopupMessage("We are having server issues please try again later.");
            setPopupTitle("Server Issues");
            setBdOpen(false);
            setPopupLoginRedirect(false);
          }
          else if (data === false) {
            setPopupState(true);
            setPopupMessage("Unable to add client. Please try again or contact support@adaptive-investments.com.");
            setPopupTitle("Error while adding new client");
            setBdOpen(false);
            setPopupLoginRedirect(false);
          }
          else if (data === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupTitle("Session Expired");
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            return;
          }else if (data === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupTitle("Session Expired");
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            return;
          }
        });
    }
  };

  const cancelNewClient = (e) => {
    e.preventDefault();
    formSubmit();
  };

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      ></Popup>
      <PageHeader heading="Add New Client" backButton={true} addbutton={false} routeTo="./ClientList" />
      <div className="main-lg">
        <form className="ai-form-group portfolioform">
          <div className="form-container">
            <label className="form-label-lg">ADAPTIVE ID</label>
            <input
              className="ai-form-value"
              type="text"
              placeholder="#AutoGenerate"
              readonly="readonly"
              onChange={(event) => setAdaptiveId(event.target.value)}
            />
          </div>
          <div className="form-container">
            <label className="form-label-lg">EXTERNAL ID<span className="required"/></label>
            <input
              className="ai-form-value"
              type="text"
              placeholder="Enter Value {e.g. ADI123456}"
              onChange={(event) => setExternalId(event.target.value)}
            />
            <p className="invalidMessage">{Errors["eIdError"]}</p>
          </div>
          <div className="form-container">
            <label className="form-label-lg">CLIENT NAME<span className="required"/></label>
            <input
              name="name"
              className="ai-form-value"
              type="text"
              placeholder="Enter client name"
              onChange={(event) => setClientName(event.target.value)}
            />
            <p className="invalidMessage">{Errors["nameError"]}</p>
          </div>
          <div className="form-container">
            <label className="form-label-lg">PHONE<span className="required"/></label>
            <input
              name="phone"
              className="ai-form-value"
              type="text"
              placeholder="Enter client phone number"
              onChange={(event) => setClientPhone(event.target.value)}
            />
            <p className="invalidMessage">{Errors["phoneError"]}</p>
          </div>
          <div className="form-container">
            <label className="form-label-lg">EMAIL<span className="required"/></label>
            <input
              name="email"
              className="ai-form-value"
              type="text"
              placeholder="Enter your email"
              onChange={(event) => setClientEmail(event.target.value)}
            />
            <p className="invalidMessage">{Errors["emailError"]}</p>
          </div>
          <div className="form-container">
            <label className="form-label-lg">ADDRESS</label>
            <input
              className="ai-form-value"
              type="text"
              placeholder="Enter your address"
              onChange={(event) => setClientAddress(event.target.value)}
            />
          </div>
          <div className="button-div">
            <button
              type="cancel"
              onClick={cancelNewClient}
              className="ai-btn secondary solid"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={addNewClient}
              className="ai-btn primary solid"
            > Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default (AddNewClient);