import React from "react";
import "./AnalyzePortfolio.css";
import NavBar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";
import AnalyzePortfolioWindow from "../../Components/AnalyzePortfolioWindow/AnalyzePortfolioWindow";
import withWidth from "@material-ui/core/withWidth";
import { useLocation } from "react-router-dom";

function AnalyzePortfolio(props) {
  const location = useLocation();
  const portfolioShieldStatus = sessionStorage.getItem("PortfolioShieldStatus");
  return (
    <div className="ai-content-part">
      <NavBar />
      <div className="floathead">
        <PageHeader
          heading="Analysis"
          backButton={true}
          addButton={false}
          routeTo="./PortfolioSummaryScreen"
        />
        {portfolioShieldStatus === "active" ||
        portfolioShieldStatus === "partial" ? (
          <PortfolioDetails
            routeTo="./ShieldRecommendationScreen"
            forward_route={true}
            imgSrc="Assets/buyshield.png"
            routeText="Shield Recommendations"
            active={false}
            stocksData = {location.state.stocksData}
          />
        ) : (
          <PortfolioDetails
            routeTo="./ShieldRecommendationScreen"
            forward_route={true}
            imgSrc="Assets/buyshield.png"
            routeText="Shield Recommendations"
            active={false}
            stocksData = {location.state.stocksData}
          />
        )}
      </div>
      {/* <div className="analyze-portfolios"> */}
      {/* <div className="analyze-portfolio-text-pos">
              <p className="analyze-portfolio-text">
                The combination of stocks in your portfolio is at risk of losing significant value during the next 6 months.
              </p>
          </div> */}
      <div className="analyze-portfolio-options ">
        <AnalyzePortfolioWindow stocksData = {location.state.stocksData}/>
      </div>
    </div>
  );
}

export default withWidth()(AnalyzePortfolio);
