import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetStocks(portfolio_id) {
  // const session_id = sessionStorage.getItem("sessionID");
  const Token = sessionStorage.getItem("Token");

  const header = { Authorization: Token };
  var requestOptions = {
    method: "GET",
    headers: header,
    redirect: "follow"
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetch(
        process.env.REACT_APP_PORTFOLIO_LINEITEM_API +"?portfolio_id="+portfolio_id+"&session_id="+Token,
        requestOptions
      );
      if (!response.ok) {
        return false;
      } else {
        const data = await response.json();
        if (data["result"]!==""){
        if (data["result"] !== "EMPTY_PORTFOLIO") {
          return data["result"];
        }
        else if (data["result"] === "EMPTY_PORTFOLIO") {
          return null
        }}
        else if (data["error"]==="Token Verification Failed"){
          return 404
        }
        else {
          console.log(data["error"]);
          console.log("There was an error fetching your data");
          return false;
        }
      }
    }catch (error) {
      if (error.name === "AbortError") {
        console.log("The request timed out, Please try again");
        // window.location.reload();
      }
      else{
        return 0
      }
    }
  }
  else{
    return 401
  }
}
