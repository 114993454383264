import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function AddPortfolio(portfolio_name, portfolio_desc = " ", account_id = " ", brokername = " ") {

  const Token = sessionStorage.getItem("Token");
  // const session_id = sessionStorage.getItem("sessionID");

  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token,
    portfolioname: portfolio_name,
    portfoliodesc: portfolio_desc,
    account_id: account_id,
    brokername: brokername
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
    headers: header,
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_PORTFOLIO_API,
        requestOptions
      );
      if (!response.ok) {
        console.log("Could not add your portfolio");
        return false;
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          return message["result"];
        } else if (message["error"] !== "") {
          if (message["error"] === "Token Verification Failed") {
            return 404
          } else {
            return 0
          }
        }
        else {
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // window.location.reload();
        return 0
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }
}
