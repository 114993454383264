import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import React, { memo } from 'react';
import { addZeroes } from "../Utilities/AddZeros";

function PortfolioReturnGraph({ data, gradientId, xMin, xMax, yMax, range }) {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Return : ${payload[0]["payload"].Return_percent[0]}% to ${payload[0]["payload"].Return_percent[1]}%`}</p>
                    <p className="label">{`Probality of occuring: ${payload[0]["payload"].Probability_of_occuring}%`}</p>
                    <p className="label">{`Return $: $${Number(addZeroes(Number(parseFloat(payload[0]["payload"].Return_value[0]).toFixed(2)))).toLocaleString("en-US")} to $${Number(addZeroes(Number(parseFloat(payload[0]["payload"].Return_value[1]).toFixed(2)))).toLocaleString("en-US")}`}</p>
                    <p className="label">{`Cumulative expected loss: $${Number(addZeroes(Number(parseFloat(payload[0]["payload"].Cumulative_expected_loss).toFixed(2)))).toLocaleString("en-US")}`}</p>
                </div>
            );
          }
        return null;
    };

    return (
        <div style={{ width: "100%" }}>
            <ResponsiveContainer
                width='100%'
                height={400}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <defs>
                        <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#60034C" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#60034C" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="interval"
                        // angle={-80}
                        domain={[xMin, xMax]}
                        tick={{ fontSize: 8 }}
                        tickFormatter={(tick) => `${tick}%`}
                        label={{ value: "Portfolio Return", position: 'insideBottom', offset: -5, fill: "#60034C" }} >
                    </XAxis>
                    <YAxis
                        tick={{ fontSize: 12 }}
                        domain={[0, yMax]}
                        label={{ value: "Probability", position: 'insideLeft', angle: -90, fill: "#60034C" }}>
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="count"
                        fill={`url(#${gradientId})`}
                        barSize={10}
                        gradientUnits={0.5}
                        radius={[10, 10, 0, 0]}
                        xvalue="Portfolio Return" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default memo (PortfolioReturnGraph);