import {fetchWithTimeout} from "./Timeout";
import {CheckSessionTimeout} from "./CheckSessionTimeout";

export async function FetchAssetAllocationEquivalence(portfolio_id) {
  const token = sessionStorage.getItem("Token");
  const header = {
    "Authorization": token,
    "Content-Type": "application/json"
  };
  var payload = JSON.stringify({
    session_id: token,
    portfolio_id: portfolio_id
  });

  var requestOptions = {
    "method": "POST",
    "headers": header,
    "body": payload,
    // "redirect": "follow",
  };

  if (!CheckSessionTimeout(token)) {
    try {
      const response = await fetch(
        process.env.REACT_APP_FETCH_ASSET_ALLOCATION_EQUIVALENCE_API,
        requestOptions
      );
      if (response.ok) {
        const message = await response.json();
        return message["result"];
      } else {
        return response.status;
      };
    } catch (error) {
      console.log("error", error);
      return 0;
    };
  } else {
    return 0;
  };
}