import React from "react";
import "./PlaidStocksSummary.css";
import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import StocksSummary from "../../Components/StocksSummary/StocksSummary";

export default function PlaidStocksScreen() {
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#E58B76" },
      "& .MuiStepIcon-completed": { color: "#E58B76" },
    },
  }));

  const classes = useStyles();

  return (
    <div className="ai-content-part">
      <Navbar />
      <PageHeader
        heading="Review of Stocks"
        backButton={true}
        routeTo="./PlaidAccounts"
        exportButton={true}
        importType="plaid"
      />
      <div className="plaidstock_page-holder">
        <Box sx={{ width: "100%" }}>
          <Stepper className={classes.root} activeStep={3} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <StocksSummary />
      </div>
    </div>
  );
}
