import React from "react";
import "./AdvisorStats.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { GetAdvisorDashboardStats } from "../../Apis/GetAdvisorDashboardStats";
import { GetAdvisorGraph } from "../../Apis/GetAdvisorGraph";
import AdvisorGraph from "../../Components/Graph/AdvisorGraph";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { GetMarketRiskData } from "../../Apis/GetMarketRiskData";
import Popup from "../Popup/Popup";
import { addZeroes } from "../Utilities/AddZeros";
// import Tooltip from "../Tooltip/Tooltip";
import Tooltip from "react-tooltip-lite";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PageLoader from "../PageLoader/PageLoader";


function AdvisorStats(props) {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [AdvisorID, setAdvisorID] = React.useState("");
  const [activeClients, setActiveClients] = React.useState("");
  const [shieldedPortfolio, setShieldedPortfolio] = React.useState("");
  const [totalPortfolio, setTotalPortfolio] = React.useState("");
  const [aum, setAUM] = React.useState("");
  const [GotstatData, setGotstatData] = React.useState(false);
  const [GotGraphData, setGotGraphData] = React.useState(false);
  const [GotMarketRiskData, setGotMarketRiskData] = React.useState(false);
  const [selectedPeriod, setSelectedPeriod] = React.useState("1y");
  const [GraphData, setGraphData] = React.useState([]);
  const [vixData, setVixData] = React.useState();
  const [snpData, setSnpData] = React.useState();
  const [count, setCount] = React.useState(0);
  const [marketDataCount, setMarketDataCount] = React.useState(0);
  const [MarketRisk, setMarketRisk] = React.useState(0);
  const [RiskText, setRiskText] = React.useState("");
  const [UpBy, setUpBy] = React.useState("");
  const [PrevRisk, setPrevRisk] = React.useState("");
  const [PerChange, setPerChange] = React.useState("");
  const [clientData, setClientData] = React.useState("");
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [StatCounter, setStatCounter] = React.useState(0);
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const option = [
    { value: "5y", label: "5 Years" },
    { value: "3y", label: "3 Years" },
    { value: "1y", label: "1 Year" },
    { value: "6mo", label: "6 Months" },
    { value: "3mo", label: "3 Months" },
    { value: "1mo", label: "1 Month" },
    { value: "5d", label: "5 Days" },
    { value: "1d", label: "1 Day" },
  ];

  const changePeriod = (period) => {
    setSelectedPeriod(period);
    setVixData(GraphData["vix"][period]);
    setSnpData(GraphData["snp"][period]);
  };

  if (flag === false) {
    if (bdopen === false) {
      setBdOpen(true);
    }
    if (GotstatData === false && StatCounter === 0) {
      setStatCounter(StatCounter + 1);
      GetAdvisorDashboardStats().then((data) => {
        if (data !== false && data !== 0 && data !== 401 && data !== 404) {
          setGotstatData(true);
          setAdvisorID(data["advisorid"]);
          setActiveClients(data["total_clients"]);
          setShieldedPortfolio(data["shielded_portfolio"]);
          setTotalPortfolio(data["total_portfolio"]);
          setAUM(
            Number(
              addZeroes(Number(parseFloat(data["total_aum"]).toFixed(2)))
            ).toLocaleString("en-US", currObj)
          );
          var lyst = [];
          for (let [key, value] of Object.entries(data.client_details)) {
            lyst.push({
              clientid: key,
              externalclientid: !value.externalid ? "-" : value.externalid,
              clientname: value.name,
              noofportfolio: Number(value.total_portfolio),
              aum: Number(
                addZeroes(
                  Number(parseFloat(value.total_portfolio_value).toFixed(2))
                )
              ),
            });
          }
          setClientData([...lyst]);
          setBdOpen(false);
        } else if (data === false) {
          setBdOpen(false);
          setGotstatData(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Unable to fetch Dashboard Data");
          setPopupLoginRedirect(false);
        } else if (data === 0) {
          setBdOpen(false);
          setGotstatData(true);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later."
          );
          setPopupTitle("Server Error");
          setPopupLoginRedirect(false);
        } else if (data === false) {
          setGotstatData(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Unable to fetch Dashboard Data");
          setPopupLoginRedirect(false);
        } else if (data === 0) {
          setGotstatData(true);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later."
          );
          setPopupTitle("Server Error");
          setPopupLoginRedirect(false);
        } else if (data === 401) {
          setBdOpen(false);
          setGotstatData(true);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        } else if (data === 404) {
          setBdOpen(false);
          setGotstatData(true);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        }
      });
    }
    if (GotGraphData === false && count === 0) {
      setCount(count + 1);
      GetAdvisorGraph().then((data) => {
        if (data !== false && data !== 0 && data !== 401) {
          setGotGraphData(true);
          setGraphData(data);
          setVixData(data["vix"][selectedPeriod]);
          var diff = (
            Number(
              data["vix"][selectedPeriod]["closeprice"][
                data["vix"][selectedPeriod]["closeprice"].length - 1
              ]
            ) -
            Number(
              data["vix"][selectedPeriod]["closeprice"][
                data["vix"][selectedPeriod]["closeprice"].length - 2
              ]
            )
          ).toFixed(2);
          setUpBy(diff);
          var prev = Number(
            data["vix"][selectedPeriod]["closeprice"][
              data["vix"][selectedPeriod]["closeprice"].length - 2
            ].toFixed(2)
          );
          setPrevRisk(prev);
          setPerChange(Math.abs((diff / prev) * 100).toFixed(2));
          setSnpData(data["snp"][selectedPeriod]);
          setBdOpen(false);
        } else if (data === false) {
          setGotGraphData(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Unable to fetch Graph Data");
          setBdOpen(false);
          setPopupLoginRedirect(false);
        } else if (data === 0) {
          setBdOpen(false);
          setGotGraphData(true);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later."
          );
          setPopupTitle("Server Error");
          setPopupLoginRedirect(false);
        } else if (data === false) {
          setGotGraphData(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Unable to fetch Graph Data");
          setPopupLoginRedirect(false);
        } else if (data === 0) {
          setGotGraphData(true);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later."
          );
          setPopupTitle("Server Error");
          setPopupLoginRedirect(false);
        } else if (data === 401) {
          setBdOpen(false);
          setGotGraphData(true);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
        }
      });
    }
    if (GotMarketRiskData === false && marketDataCount === 0) {
      setMarketDataCount(marketDataCount + 1);
      GetMarketRiskData().then((data) => {
        if (data !== false && data !== 0 && data !== 401) {
          setGotMarketRiskData(true);
          const risk = Number(data["closeprice"].toFixed(2));
          setMarketRisk(risk);
          if (risk < 15) {
            setRiskText("Low");
          } else if (risk > 15 && risk < 20) {
            setRiskText("Medium");
          } else if (risk > 20 && risk < 30) {
            setRiskText("High");
          } else {
            setRiskText("Very High");
          }
          setBdOpen(false);
        } else if (data === false) {
          setGotMarketRiskData(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Unable to fetch Market Risk Data");
          setBdOpen(false);
          setPopupLoginRedirect(false);
        } else if (data === 0) {
          setGotMarketRiskData(true);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later."
          );
          setPopupTitle("Server Error");
          setBdOpen(false);
          setPopupLoginRedirect(false);
        } else if (data === false) {
          setGotMarketRiskData(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Unable to fetch Market Risk Data");
          setPopupLoginRedirect(false);
        } else if (data === 0) {
          setGotMarketRiskData(true);
          setPopupState(true);
          setPopupMessage(
            "We are facing server issues, please try again later."
          );
          setPopupTitle("Server Error");
          setPopupLoginRedirect(false);
        } else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setGotMarketRiskData(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
        }
      });
    }
    if (
      GotstatData === true &&
      GotGraphData === true &&
      GotMarketRiskData === true
    ) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  function SetColor() {
    if (MarketRisk < 15) {
      return "#38bb55";
    } else if (MarketRisk > 15 && MarketRisk < 20) {
      return "#FFA654";
    } else if (MarketRisk > 20 && MarketRisk < 30) {
      return "#ef3838";
    } else {
      return "#800000";
    }
  }

  return (
    <div className="advisor-holder">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      ></Popup>
      <div className="advisor-details-holder">
        <Tooltip
          hoverDelay="2000"
          content="Number of Advisor's Linked Client Investment Accounts."
          className="infocard"
        >
          <img src="Assets/a_clients.svg"></img>
          <div className="subdetails-wrap">
            <h3>Active Clients</h3>
            <p>{activeClients}</p>
          </div>
        </Tooltip>
        <Tooltip
          hoverDelay="2000"
          content="Number of Client Portfolios with Adaptive Shield Turned On."
          className="infocard"
        >
          <img src="Assets/s_portfolio.svg"></img>
          <div className="subdetails-wrap">
            <h3>Shielded Portfolios</h3>
            <p>{shieldedPortfolio}</p>
          </div>
        </Tooltip>
        <Tooltip
          hoverDelay="2000"
          content="Number of Client Portfolios."
          className="infocard"
        >
          <img src="Assets/t_portfolio.svg"></img>
          <div className="subdetails-wrap">
            <h3>Total Portfolios</h3>
            <p>{totalPortfolio}</p>
          </div>
        </Tooltip>
        <Tooltip
          hoverDelay="2000"
          content="Aggregate Linked Assets Under Management, per Advisor."
          className="infocard"
        >
          <img src="Assets/AUM.svg"></img>
          <div className="subdetails-wrap">
            <h3>AUM</h3>
            <p>{aum}</p>
          </div>
        </Tooltip>
      </div>
      <div className="risk-details-holder">
        <Tooltip
          hoverDelay="2000"
          content="VIX (CBOE S&P500 Implied Volatility Index).
Low = 0% - 15%
Medium = 15% - 20%
High = 20% - 30%
Very High = 30% +"
          className="market-risk-holder"
        >
          <p>Market Risk</p>
          <div className="risk-progress-holder">
            <div className="advisor-market-risk">
              <div
                className="advisor-market-risk-value"
                style={{
                  opacity: 1,
                  height: `${MarketRisk * 2.5}%`,
                  background: SetColor(),
                }}
              >
                <p>{RiskText}</p>
              </div>
            </div>
          </div>
        </Tooltip>
        <div className="GraphArea">
          <div className="GraphTab">
            <div className="legendBar">
              <div className="per_up_tab">
                <p
                  className="percentage_text"
                  style={{ color: setUpBy > 0 ? "#38bb55" : "#ef3838" }}
                >
                  {PerChange}%
                </p>
                {Number(UpBy) > 0 ? (
                  <p className="desc_text">Up since yesterday</p>
                ) : (
                  <p className="desc_text">Down since yesterday</p>
                )}
              </div>
              <div className="per_up_tab">
                {Number(UpBy) > 0 ? (
                  <ArrowUpOutlined
                    style={{
                      paddingRight: "5px",
                      color: "#38bb55",
                      fontFamily: "Inter",
                      fontWeight: 600,
                    }}
                  />
                ) : (
                  <ArrowDownOutlined
                    style={{
                      paddingRight: "5px",
                      color: "#ef3838",
                      fontFamily: "Inter",
                      fontWeight: 600,
                    }}
                  ></ArrowDownOutlined>
                )}
                <p
                  className="percentage_text"
                  style={{ color: UpBy > 0 ? "#38bb55" : "#ef3838" }}
                >
                  {Math.abs(UpBy)}
                </p>
              </div>
              <div className="Legend_first">
                <div className="key_1"></div>
                <Tooltip
                  hoverDelay="2000"
                  content="Historical levels of the VIX (CBOE S&P500 implied volatility index)"
                >
                  <p className="desc_text">Historical Risk</p>
                </Tooltip>
              </div>
              <div className="Legend_first">
                <div className="key_2"></div>
                <div>
                  <p className="desc_text">S&P 500</p>
                </div>
              </div>
            </div>
            <Select
              className="ai-form-value-mui"
              onChange={(event) => changePeriod(event.target.value)}
              value={selectedPeriod}
            >
              {option.map((value, i) => (
                <MenuItem value={value.value}>{value.label}</MenuItem>
              ))}
            </Select>
          </div>
          <div className="GraphHolder">
            <AdvisorGraph
              vix_data={vixData}
              snp_data={snpData}
              key={new Date().getTime()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default withWidth()(AdvisorStats);
