import { fetchWithTimeout } from "./Timeout";

export async function CheckCustomerAccess(username,firstname,lastname,email,customer_type,mobile,companyname) {

    var contact_no = "";
    var company_name = "";
    if (customer_type === "ADVISOR") {
        contact_no = mobile
        company_name = companyname
    }

    var raw = JSON.stringify({
        user_name: username,
        first_name: firstname,
        last_name: lastname,
        email_id: email,
        customer_type: customer_type,
        contact_no: contact_no,
        company_name: company_name,
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
    };

    try {

        const response = await fetchWithTimeout(
            process.env.REACT_APP_CHECK_CUSTOMER_ACCESS,
            requestOptions
        );
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        } else {
            const message = await response.json();
            if (message["result"] !== "") {
                return message["result"];
            } else {
                console.log("error", message["error"]);
                return "";
            }
        }
    } catch (error) {
        if (error.name === "AbortError") {
            //alert("The request timed out, Please try again");
            window.location.reload();
        }
        else {
            return 0
        }
    }
}
