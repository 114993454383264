import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function FetchExternalAccountDetails() {

    const Token = sessionStorage.getItem("Token");
    // const session_id = sessionStorage.getItem("sessionID");

    const header = { Authorization: Token };

    var requestOptions = {
        method: "GET",
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetch(
                process.env.REACT_APP_FETCH_EXTERNAL_ACCOUNT_API,
                requestOptions
            );
            if (response.message === "Internal server error") {
                //alert("We are facing server issues");
            } else if (response.message === "The incoming token has expired") {
                //alert("Your session has expired please log-in again");
            } else {
                const data = await response.json();
                console.log("response",data)
                if (data["result"] !== "") {
                    return data["result"];
                } else if (data["error"] === "Token Verification Failed") {
                    return 404;
                }
                else {
                    console.log(data["error"]);
                    console.log("There was an error fetching your data");
                    return false;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("The request timed out, Please try again");
                return 0
                // window.location.reload();
            }
            else {
                return 0
            }
        }
    } else {
        return 401
    }
}
