import React from "react";
import "./ForwardTestScreen.css";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Navbar from "../../Components/Navbar/Navbar_official";
import ForwardTest from "../../Components/ForwardTest/ForwardTest";

export default function ForwardTestScreen() {
  return (
    <div className="ai-content-part">
        <Navbar/>
        <div className="floathead alon big">
          <PageHeader heading="Monte Carlo Simulation: Shield Comparison" backButton={true} routeTo="./PortfolioSummaryScreen"/>  
        </div>
        <ForwardTest/>
    </div>
    );
}