import React from "react";
import AdaptiveGeniusProducts from "../../Components/AdaptiveGeniusProducts/AdaptiveGeniusProducts";
import "./AdaptiveGenius.css";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Navbar from "../../Components/Navbar/Navbar_official";
import {
  BackwardOutlined,
  FileSearchOutlined,
  PieChartOutlined,
} from "@ant-design/icons";

function ServicesScreen(props) {
  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["product_buttons genius_product_buttons"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["product_buttons genius_product_buttons"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["product_buttons genius_product_buttons"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["product_buttons genius_product_buttons"];
    }
  }

  const rendering = getRendering(props.width);

  const buttons = [
    {
      title: "Shield Backtest",
      routeTo: "/Backtest",
      color: "white",
      icon: "Assets/shield_backtest.svg",
      tooltip: "",
    },
    {
      title: "Self Assembly",
      routeTo: "/SelfAssemblyScreen",
      color: "white",
      icon: "Assets/self_assembly.svg",
      tooltip: "",
    },
    {
      title: "Shield forward test",
      routeTo: "/ForwardTestScreen",
      color: "white",
      icon: "Assets/shield_forward_test.svg",
      tooltip: "",
    },
    {
      title: "risk Contribution",
      routeTo: "/RiskContributionScreen",
      color: "white",
      icon: "Assets/risk_contribution.svg",
      tooltip: "",
    },
    {
      title: "Portfolio Report",
      routeTo: "/ComingSoon",
      color: "white",
      icon: "Assets/portfolio_report.svg",
      tooltip: "",
    },
    {
      title: "Portfolio Analyser",
      routeTo: "/ComingSoon",
      color: "white",
      icon: "Assets/portfolio_analyser.svg",
      tooltip: "",
    },
    {
      title: "Factor Analysis",
      routeTo: "/FactorAnalysisScreen",
      color: "white",
      icon: "Assets/portfolio_analyser.svg",
      tooltip: "",
    },
    {
      title: "Terminal Return",
      routeTo: "/TerminalReturn",
      color: "white",
      icon: "Assets/portfolio_report.svg",
      tooltip: "",
    },
    // {
    //   title: "Shield Risk Equivalence",
    //   routeTo: "/ShieldRiskEquivalence",
    //   color: "white",
    //   icon: "Assets/portfolio_report.svg",
    //   tooltip: "",
    // },
  ];
  return (
    <div className="ai-content-part">
      <Navbar />
      <PageHeader
        heading="Adaptive Genius"
        backButton={true}
        routeTo="/ProductScreen"
      />
      <div className="product_buttons">
        <div className="genius_product_buttons">
          {buttons.map((button) => (
            <AdaptiveGeniusProducts
              key={button.title}
              title={button.title}
              routeTo={button.routeTo}
              color={button.color}
              icon={button.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
export default withWidth()(ServicesScreen);
