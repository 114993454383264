import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function DeleteStocks(ticker, portfolioid) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };
  // const session_id = sessionStorage.getItem("sessionID");

  var raw = JSON.stringify({ session_id: Token, symbol: ticker, portfolio_id: portfolioid });

  var requestOptions = {
    method: "DELETE",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_PORTFOLIO_LINEITEM_API,
        requestOptions
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      } else {
        //if the call is successful, store the data in json format
        const message = await response.json();
        if (message["result"] !== "") {
          return message["result"];
        }else if (message["error"]==="Token Verification Failed"){
          return 404
        }
        else {
          console.log(message["error"]);
          console.log("There was an error fetching your data");
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }
}
