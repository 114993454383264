import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./Popup.css";
import { color } from "d3";
import { useHistory } from "react-router-dom";
import UserPool from "../../UserPool";
import AdvisorPool from "../../AdvisorPool";
import TagManager from "react-gtm-module";

export default function Popup(props) {
  var alertType = props.alertType;

  const alertColor = {
    error: "error",
    warning: "warning",
    success: "success",
  };

  const history = useHistory();
  const redirect = () => history.push(props.redirectLink);
  const handleLogOut = () => history.push("/");
  const routeTo = ()=> history.push(props.onSuccessRouteTo);
  
  const colors = alertColor[alertType];
  function logout() {
    if (sessionStorage.getItem("selectedAcType") === "INVESTOR") {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.signOut();
        window.sessionStorage.clear();
        window.localStorage.clear();
      }
    } else {
      const user = AdvisorPool.getCurrentUser();
      if (user) {
        user.signOut();
        window.sessionStorage.clear();
      }
    }
    TagManager.dataLayer({
      dataLayer: {
        'event': 'User LogOut',
        'userType':sessionStorage.getItem("selectedAcType"),
        'user_id': sessionStorage.getItem("clientId")
      },
    });
  }

  function logUserOut() {
    logout();
    handleLogOut();
  }
  function okButton(){
    if(props.loginRedirect){
      return <button className="ai-btn primary solid small" onClick={logUserOut}>
        {" "}OK{" "}
      </button>
    } else if(props.onSuccessRouteTo){
      return <button className="ai-btn primary solid small" onClick={routeTo}>
        {" "}OK{" "}
      </button>
    }else{
      return <button className="ai-btn primary solid small" onClick={() => props.setTrigger(false)}>
        {" "}OK{" "}
      </button>
    }
  }
  return props.trigger ? (
    <div className="popup" id="popup">
      <div className={alertType}>
        <div className="popup-object">
          <div className="heading">
            <InfoOutlinedIcon className="icon" style={{ color: colors }} />
            <span>{props.title}</span>
          </div>
          <div className="body-message">
            <p> {props.content}{props.redirectLink !== undefined && props.redirectLink !== "" ? <a style={{ color: "Blue" }} onClick={redirect}> (link)</a> : ""}</p>
          </div>
          {okButton()}
          {props.children}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
