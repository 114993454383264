import React from "react";
import Popup from "../Popup/Popup";
import "./MarketShieldSummary.css";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { PurchaseShield } from "../../Apis/PurchaseShield";
import { addZeroes } from "../Utilities/AddZeros";
import { newConvertDate, convertDate } from "../Utilities/ConvertDate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TagManager from "react-gtm-module";
import exlm from "../../images/exlm.svg";
import PageLoader from "../PageLoader/PageLoader";

function MarketShieldSummary(props) {
  const useStyles = makeStyles((theme) => ({
    checkbox_button: {
      color: "#60034c",
      width: "13px",
      height: "13px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const history = useHistory();
  const checkOutClick = () =>
    history.push({
      pathname: "/PaymentSuccessScreen",
      state: {
        detail: "marketshield",
        ShieldData: props.ShieldData,
        checkoutdata: props.checkoutdata,
        agreementContractData: props.agreementContractData,
        period: props.selectedPeriod,
        customerInfo: props.customerInfo,
        portfolioData: props.portfolioData,
      },
    });

  const purchaseFailure = () => history.push("/ShieldOrderComplition");

  function shieldSetupPage() {
    props.closeMarketSummaryPopup()
  }

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar",
        "summary-header",
        "summary-TC",
        "TC-text",
      ];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar",
        "summary-header",
        "summary-TC-sm",
        "TC-text-sm",
      ];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar-md",
        "summary-header",
        "summary-TC-sm",
        "TC-text-sm",
      ];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar-md",
        "summary-header-sm",
        "summary-TC-sm",
        "TC-text-sm",
      ];
    }
  }

  const rendering = getRendering(props.width);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const protection_level_string = {
      80: "0.8",
      85: "0.85",
      90: "0.9",
      95: "0.95",
      100: "1"
    };

  const headers = [
    // {
    //   title: "#",
    //   tooltip: "",
    // },
    {
      title: "Tracking Instrument",
      tooltip: "",
    },
    {
      title: "Quantity",
      tooltip: "",
    },
    {
      title: "Current Index Level",
      tooltip: "",
    },
    {
      title: "NOTIONAL VALUE",
      tooltip: "",
    },
    {
      title: "Protected Value",
      tooltip: "",
    },
  ];

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  function handlePurchaseShield() {
    var selectedData = JSON.stringify({
      session_id: sessionStorage.getItem("Token"),
      shield_type: props.shield_type,
      protection_period: props.period_str,
      protection_level: protection_level_string[props.checkoutdata["ProtLevel"]]
    });

    PurchaseShield(selectedData).then((data) => {
      console.log(" purchase", data);
      if (data === true) {
        checkOutClick();
        TagManager.dataLayer({
          dataLayer: {
            event: "Shield Purchased",
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId"),
            protection_level: props.checkoutdata["ProtLevel"],
            protection_period: props.selectedPeriod,
            shield_type: "Market Shield",
          },
        });
      } else if (data === "Insufficient balance." || data === "Wallet doesn't exist") {
        props.handleClose(
          "Your current balance is insufficient to purchase Shield. Please add funds or contact support@adaptive-investments.com."
        );
        return;
      } else if (data === "IB WALLET INSUFFICIENT" || "IB FUNDS AVAILABILITY - FALSE") {
        props.handleClose(
          "We are facing some issues processing your purchase order. Please contact our administrator"
        );
        return;
      } else if (data === "Unable to fetch wallet details") {
        props.handleClose(
          "Unable to fetch wallet details. Please contact our administrator"
        );
        return;
      } else if (data === "Shield Price Expired") {
        props.handleClose(
          "Please click Recalculate to get the latest prices, the Price Quoted have expired"
        );
        return;
      } else if (data === false) {
        props.handleClose(
          "Unable to Purchase Market Shield, Please try again later."
        );
        return;
      } else if (data === 0) {
        purchaseFailure();
        return;
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      }
      else if (data.startsWith("No live price for the option")) {
        props.handleClose(
          "Could not purchase shield. Market prices not available"
        );
        return;
      } else {
        props.handleClose(
          "We are having issues connecting to our servers, please try again later."
        );
        return;
      }
    });
  }

  const TurnShieldOn = () => {
    if (checked === false) {
      setPopupState(true);
      setBdOpen(false);
      setPopupMessage(
        "Please read and agree to our terms and conditions to proceed."
      );
      setAlertType("warning");
      return;
    } else {
      handlePurchaseShield();
    }
    setBdOpen(true);
  };

  const checkLiveMarket = () => {
    if (props.checkoutdata["Purchaseable"] === true) {
      return (
        <div className="acceptterm">
          <Checkbox
            className={classes.checkbox_button}
            checked={checked}
            onChange={handleChange}
            color="default"
          ></Checkbox>
          <span className="accept-TC-Text">
            <b>I have read and I accept the terms and conditions.</b>
            {" "}For the avoidance of doubt, the Market Shield does not provide protection
            for the Purchaser's designated securities portfolio.The downside protection provided
            by this Agreement is tied to changes in the Market Portfolio, distinct from changes in the
            Purchaser's designated securities portfolio. See Market Shield Purchase Agreement.
          </span>
        </div>
      );
    } else {
      return (
        <span className="notice">
          <img src={exlm}></img> Could not purchase shield. Market prices not
          available
        </span>
      );
    }
  };

  return (
    <div className="sheild-summary-wrapper">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Confirm Shield Purchase"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />

      <div className="summary-header">{props.PopupTitle}</div>
      <div className="popupcontentpart">
        <div>
          <div className={rendering[3]}>
            <div className={rendering[0]}>
              <div className="checkout-details">
                <label>Market Portfolio Notional Value</label>
                <span className="col-sap">:</span>
                <span className="value">
                  $
                  {Number(
                    Number(props.checkoutdata["TotalNotionalVal"]).toFixed(
                      2
                    )
                  ).toLocaleString("en-US")}
                </span>
              </div>
              <div className="checkout-details">
                <label>For a period of</label>
                <span className="col-sap">:</span>
                <span className="value">{props.selectedPeriod}</span>
              </div>
              <div className="checkout-details">
                <label>Market Portfolio Protected Level</label>
                <span className="col-sap">:</span>
                <span className="value">
                  {props.checkoutdata["ProtLevel"]}%
                </span>
              </div>
              <div className="checkout-details">
                <label>Start Date</label>
                <span className="col-sap">:</span>
                <span className="value">{props.prevPath === 'activeshield' ? props.startDate : convertDate(new Date())}</span>
              </div>
              <div className="checkout-details">
                <label>Market Portfolio Protected Value</label>
                <span className="col-sap">:</span>
                <span className="value">
                  $
                  {Number(
                    Number(props.checkoutdata["TotalProtectedVal"]).toFixed(
                      2
                    )
                  ).toLocaleString("en-US")}
                </span>
              </div>
              <div className="checkout-details">
                <label>Expiration Date</label>
                <span className="col-sap">:</span>
                <span className="value">
                  {newConvertDate(props.checkoutdata["ExpirationDate"])}
                </span>
              </div>
              <div className="checkout-details">
                <label>For a Market Portfolio Loss of no more than</label>
                <span className="col-sap">:</span>
                <span className="value">
                  {parseFloat(
                    100 - props.checkoutdata["ProtLevel"]
                  )}
                  %
                </span>
              </div>
              <div className="checkout-details">
                <label>Market Shield Fee</label>
                <span className="col-sap">:</span>
                <span className="value">
                  {Number(
                    addZeroes(
                      Number(
                        parseFloat(props.checkoutdata["Cost"]).toFixed(2)
                      )
                    )
                  ).toLocaleString("en-US", currObj)}
                </span>
              </div>
              <div className="checkout-details">
                <label>Shield Agreement ID</label>
                <span className="col-sap">:</span>
                <span className="value">{props.ShieldData["agreement_id"]}</span>
              </div>
            </div>
          </div>
          <div className="ai-shieldsummary">
            <h3>Tracking Instruments Details</h3>
            <div className="table_holder portfolio-table">
              <TableContainer className="table_height">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <th>
                          <div className="stck_table_heading">
                            {header.title}
                          </div>
                        </th>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody id='trackinginstrumentsdetails'>
                    {props.checkoutdata && props.checkoutdata.ClosePrice
                      && props.checkoutdata.ClosePrice.length > 0 
                      &&
                    props.checkoutdata.ClosePrice.map((_, i) => (
                      <tr key={i}>
                        <td>{props.checkoutdata.SecDesc[i]}</td>
                        <td>{props.checkoutdata.ConQty[i]}</td>
                        <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.ClosePrice[i]).toFixed(2)))
                        ).toLocaleString()}</td>
                        <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.NotionalVal[i]).toFixed(2)))
                          ).toLocaleString()}</td>
                        <td>${Number(addZeroes(Number(parseFloat(
                          props.checkoutdata.ProtectedVal[i]).toFixed(2)))).toLocaleString()}</td>
                      </tr>
                    ))}
                    <TableRow>
                      <td className="bold"></td>
                      <td className="bold"></td>
                      <td className="bold">Total</td>
                      <td className="bold">
                        ${Number(
                          addZeroes(
                            Number(
                              parseFloat(
                                props.checkoutdata["TotalNotionalVal"]
                              ).toFixed(2)
                            )
                          )
                        ).toLocaleString()}
                      </td>
                      <td className="bold">
                        ${Number(
                          addZeroes(
                            Number(
                              parseFloat(
                                props.checkoutdata["TotalProtectedVal"]
                              ).toFixed(2)
                            )
                          )
                        ).toLocaleString()}
                      </td>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="shield-text">
          {props.showPurchaseShield && (
            <div>
              <div>{checkLiveMarket()}</div>
              <div className="summarybtn">
                <button id="popuppurchasebtn"
                  disabled={!props.checkoutdata["Purchaseable"]}
                  className="ai-btn primary solid"
                  onClick={() => TurnShieldOn()}
                >
                  {
                    (() => {
                      switch (props.shield_type) {
                        case "ms": return "Purchase Market Shield";
                        case "msp": return "Purchase Market Shield Plus";
                        case "msc": return "Purchase Market Shield Concentrated";
                        case "fs": return "Factor Shield";
                        default: return "Purchase Shield";
                      }
                    })()
                  }
                </button>
                <button
                  type="cancel"
                  onClick={shieldSetupPage}
                  className="ai-btn secondary solid "
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <h4 className="small-heading">
            <span className="txt-bold">Market Shield Purchase Agreement</span>
          </h4>
          <p className="market-para">
            This Market Shield Purchase Agreement (the “
            <strong><u>Agreement</u></strong>”), effective as of
            <strong>{" "}{props.prevPath === 'activeshield' ? props.startDate : convertDate(new Date())}</strong>, is made by and between Adaptive Capital
            Management, LLC, a Delaware limited liability company (the “
            <strong><u>Company</u></strong>”), and the purchaser party and/or such
            purchaser party’s authorized agent hereto designated on the
            signature page hereof (collectively, the “<strong><u>Purchaser</u></strong>
            ”).
          </p>
          <h5>RECITALS</h5>
          <p className="para-5">
            WHEREAS, the Company offers to purchasers a contractual obligation
            to pay such purchaser an amount based on certain losses that a
            hypothetical market portfolio may suffer over a fixed time period
            and within the parameters established by this Agreement (the “
            <strong><u>Market Shield</u></strong>”); and
          </p>
          <p className="para-5">
            WHEREAS, Purchaser has reviewed all materials with respect to the
            Market Shield and this Agreement and has completed the information
            necessary to calculate the protection and Market Shield price
            parameters set forth on <u>Exhibit A</u> hereto;
          </p>
          <p className="para-5">
            NOW, THEREFORE, in consideration of the mutual agreements contained
            herein, and for other good and valuable consideration, the receipt
            and sufficiency of which is hereby acknowledged, the Purchaser and
            Company hereby agree as follows:
          </p>

          <ul className="basis-details">
            <li className="basic-details-list">
              <strong>Description of Market Shield. </strong>The Market Shield
              is designed to provide certain downside risk protection for the
              Purchaser based upon a hypothetical market portfolio assessed to
              be a reasonably close facsimile of a Purchaser’s designated
              securities portfolio. Because the Market Shield is an
              approximation of the Purchaser’s designated securities portfolio,
              it may be subject to varying divergences either narrower or wider,
              depending in part but not exclusively on the diversification of
              the Purchaser’s designated securities portfolio. Pursuant to a
              Market Shield purchased pursuant to this Agreement, the Company
              will pay to the Purchaser the amounts set forth on <u>Exhibit A</u>
              hereto if the provisions of this Agreement (including the
              parameters set forth on <u>Exhibit A</u>) are met. The Company does not
              use the Purchaser’s assets or portfolio in any way with respect to
              the Market Shield, but rather makes its own investments to hedge
              its obligations to the Purchaser with respect to the Market
              Shield. The Market Portfolio established in connection with this
              Agreement is used by the Company to provide a measurement tool for
              the value of the Market Shield downside protection being purchased
              and does not involve the sale or purchase of any securities for
              the Purchaser’s account.
            </li>
            <li className="basic-details-list">
              <strong>The Fee.</strong> The fee to be paid by Purchaser hereunder for
              purchase of the Market Shield is set forth on <u>Exhibit A</u> hereto and
              shall be paid contemporaneously with the purchase of the Market
              Shield and execution of this Agreement. Note that a portion of the
              fee to be paid by Purchaser to the Company will be paid to the
              Company’s affiliate Adaptive Advisers, LLC (“
              <strong><u>Adaptive Advisers</u></strong>”), an SEC registered investment
              adviser, for advisory services rendered to the Company with
              respect to the Market Shield (as described in the Form ADV filed
              by Adaptive Advisers).
            </li>
            <li className="basic-details-list">
              <strong>Downside Protection.</strong> Depending on the parameters
              set forth on <u>Exhibit A</u> with respect to the Market Shield being
              purchased hereby, the Company will, within (<strong>2</strong>) business days
              from the Expiration Date of the Market Shield (as set forth on <u>Exhibit A</u>), pay to the Purchaser the “
              <strong><u>Protection Payout</u></strong>” calculated as set forth on <u>Exhibit A</u>, if any.
              <strong>
                {" "}
                For the avoidance of doubt, the Market Shield does not provide
                protection for the Purchaser’s designated securities portfolio.
                Rather, based on the Purchaser’s inputs with respect to such
                Purchaser’s designated securities portfolio, the Company has created a Market Portfolio as designed in <u>Exhibit A</u>,
                based on such Purchaser's designated securities portfolio (the "Designated Portfolio").
                The Market Portfolio is designed to rise and fall with
                approximately the same magnitude of the Purchaser’s designated
                portfolio. The downside protection provided by this Agreement is
                therefore tied to changes in the Market Portfolio and will not
                provide the exact same protection as such downside protection would for the Purchaser's Designated Portfolio.
                The details of the Market Portfolio and the protection provided by this Agreement
                are set forth on <u>Exhibit A</u> attached hereto.
              </strong>
            </li>
            <li className="basic-details-list">
              <strong>Term.</strong>{" "}The term of this Agreement (“
              <strong><u>Term</u></strong>”) shall commence on the date of this
              Agreement and terminate (i) if no Protection Payout is due to the
              Purchaser at the Expiration Date of the Market Shield, on the
              Expiration Date, or (ii) if a Protection Payout is due to the
              Purchaser, the date such Protection Payout is made to the
              Purchaser.
            </li>
            <li className="basic-details-list">
              <strong>Assignment & Transfer</strong>
              <ul className="assign-menu">
                <li className="assign-lists">
                  <span className="txt-underline txt-underlinebold">
                    Transfer or Assignment by Purchaser.
                  </span>{" "}
                  This Agreement is intended for and shall inure to the benefit
                  of the Purchaser, and each and every reference herein to the
                  Purchaser shall also include and refer to each and every
                  successor or assignee of the Purchaser to the rights under
                  this Agreement. This Agreement shall be transferable by the
                  Purchaser with written notice to the Company.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline txt-underlinebold">
                    Transfer or Assignment by Company.
                  </span>{" "}
                  It is hereby agreed that Company may not, without the express
                  prior written consent of Purchaser, transfer or assign any of
                  its rights, powers, duties or obligations hereunder.
                </li>
              </ul>
            </li>
            <li className="basic-details-list">
              <strong>Miscellaneous</strong>
              <ul className="assign-menu">
                <li className="assign-lists">
                  <span className="txt-underline">Waiver.</span> No failure to
                  exercise, and no delay in exercising, on the part of the
                  Purchaser, any right hereunder shall operate as a waiver
                  thereof, nor shall any single or partial exercise thereof
                  preclude any other or further exercise thereof or the exercise
                  of any other right. The rights of Purchaser hereunder shall be
                  in addition to all other rights provided by law. No
                  modification or waiver of any provision of this Agreement, nor
                  consent to departure therefrom, shall be effective unless in
                  writing and no such consent or waiver shall extend beyond the
                  particular case and purpose involved. No notice or demand
                  given in any case shall constitute a waiver of the right to
                  take other action in the same, similar or other instances
                  without such notice or demand.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">Notices.</span> It is agreed
                  that:
                  <ul className="assign-menu">
                    <li className="notice-lists">
                      Any notice or demand to the Company hereunder or in connection herewith may be given and shall conclusively be deemed and considered to have been given and received (i) upon the receipt of an email to the Company addressed to marketshield@adaptivecapitalmgmt.com or (ii) upon the third day after the deposit thereof, in writing, in the United States mail, duly stamped and addressed to the Company at the address of the Company shown below (whether actually received or not), but actual notice, however given or received, shall always be effective.
                      <p className="notice-center">
                        <p>Adaptive Capital Management, LLC</p>
                      </p>
                      <p className="notice-center">
                        <p>6 Liberty Square, No. 2352</p>
                      </p>
                      <p className="notice-center">
                        <p>Boston, Massachusetts 02109</p>
                      </p>
                      <p>
                        The last preceding sentence shall not be construed in anywise to affect or impair any
                        waiver of notice or demand herein provided or to require giving of notice or demand to
                        or upon the Company in any situation or for any reason.
                      </p>
                    </li>
                    <li className="notice-lists">
                      Any notice which Company may or shall give to the Purchaser shall be delivered via electronic mail or personally delivered or mailed by prepaid certified or registered mail return receipt requested, delivery restricted to addressee only at the address of the Purchaser as shown below.
                    </li>
                    <li className="notice-lists">
                      In the event that Purchaser or Company changes its e-mail or mailing address, notice shall be given to the other party in the manner described in this Section.
                    </li>
                  </ul>

                </li>
                <li className="assign-lists">
                  <span className="txt-underline">Invalid Provisions.</span> If
                  any provision of this Agreement is held to be illegal, invalid
                  or unenforceable under present or future laws effective during
                  the term of this Agreement, such provision shall be fully
                  severable and this Agreement shall be construed and enforced
                  as if such illegal, invalid or unenforceable provision had
                  never comprised a part of this Agreement, and the remaining
                  provisions of this Agreement shall, to the extent permitted by
                  law, remain in full force and effect and shall not be affected
                  by the illegal, invalid or unenforceable provision.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">No Conditions.</span> The
                  Company acknowledges, represents and warrants to the Purchaser
                  that (a) no promises or inducements have been made to the
                  Company to execute and deliver this Agreement to the
                  Purchaser, other than those promises and inducements (if any)
                  which are expressly set forth in this Agreement and (b) either
                  there are no conditions or all conditions (if any) which the
                  Company may require to be satisfied as a condition to the
                  execution and delivery of this Agreement by the Company to the
                  Purchaser have been satisfied and (c) the Company is not
                  relying upon or requiring the guaranty of any other person or
                  entity for all or any part of the Market Shield in executing
                  and delivering this Agreement to the Purchaser.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">
                    GOVERNING LAW; JURISDICTION.
                  </span>{" "}
                  THE SUBSTANTIVE LAWS OF THE COMMONWEALTH OF MASSACHUSETTS
                  SHALL GOVERN THE VALIDITY, CONSTRUCTION, ENFORCEMENT AND
                  INTERPRETATION OF THIS AGREEMENT. FOR PURPOSES OF THIS
                  AGREEMENT AND THE RESOLUTION OF DISPUTES HEREUNDER, COMPANY
                  HEREBY IRREVOCABLY SUBMITS AND CONSENTS TO, AND WAIVES ANY
                  OBJECTION TO, THE NON-EXCLUSIVE JURISDICTION OF THE STATE AND
                  FEDERAL COURTS LOCATED IN THE COMMONWEALTH OF MASSACHUSETTS.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">Headings.</span> Section
                  headings are for convenience of reference only and shall in no
                  way affect the interpretation of this Agreement
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">Rights and Remedies.</span>{" "}
                  The exercise by Purchaser of any right or remedy hereunder or
                  under any other instrument, or at law or in equity, shall not
                  preclude the concurrent or subsequent exercise of any other
                  right or remedy.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">Multiple Counterparts.</span>{" "}
                  To facilitate execution, this Agreement may be executed in any
                  number of counterparts as may be convenient or necessary, and
                  it shall not be necessary that the signatures of all parties
                  hereto be contained on any one counterpart hereof.
                  Additionally, the parties hereto hereby agree that (a) the
                  signature pages taken from separate individually executed
                  counterparts of this Agreement may be combined to form
                  multiple fully executed counterparts and (b) a facsimile
                  transmission shall be deemed to be an original signature. All
                  executed counterparts of this Agreement shall be deemed to be
                  originals, but all such counterparts taken together or
                  collectively, as the case may be, shall constitute one and the
                  same agreement.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">WAIVER OF JURY TRIAL.</span>{" "}
                  COMPANY AND THE PURCHASER HEREBY KNOWINGLY, VOLUNTARILY,
                  IRREVOCABLY AND INTENTIONALLY WAIVE, TO THE MAXIMUM EXTENT
                  PERMITTED BY LAW, ALL RIGHT TO TRIAL BY JURY IN ANY ACTION,
                  PROCEEDING OR CLAIM ARISING OUT OF OR RELATED TO THIS
                  AGREEMENT OR THE NOTE, THE GUARANTEED DEBT OR THE TRANSACTIONS
                  CONTEMPLATED THEREBY.
                </li>
                <li className="assign-lists">
                  <span className="txt-underline">NO OTHER AGREEMENTS.</span>{" "}
                  THIS WRITTEN AGREEMENT REPRESENTS THE FINAL AGREEMENT BETWEEN
                  THE PURCHASER AND THE COMPANY AND MAY NOT BE CONTRADICTED BY
                  EVIDENCE OF PRIOR, CONTEMPORANEOUS, OR SUBSEQUENT ORAL
                  AGREEMENTS OF THE PURCHASER AND THE COMPANY. THERE ARE NO
                  UNWRITTEN ORAL AGREEMENTS BETWEEN THE PURCHASER AND THE
                  COMPANY.
                </li>
              </ul>
            </li>
          </ul>
          <p className="t-center">
            <u>EXHIBIT A</u>
          </p>
          <p className="t-center">
            <u>Market Shield Information</u>
          </p>
          <p className="t-center">
          </p>
          <p className="t-center">
          </p>
          <p>Market Portfolio Notional Value: $
            {Number(
              Number(props.checkoutdata["TotalNotionalVal"]).toFixed(
                2
              )
            ).toLocaleString("en-US")}</p>
          <p>Market Portfolio Protected Level:{" "}{props.checkoutdata["ProtLevel"]}%</p>
          <p>Market Portfolio Protected Value: $
            {Number(
              Number(props.checkoutdata["TotalProtectedVal"]).toFixed(
                2
              )
            ).toLocaleString("en-US")}</p>
          <p>For a Market Portfolio Loss of no more than:{" "}{parseFloat(
            100 - props.checkoutdata["ProtLevel"]
          )}
            %</p>
          <p>Protection Period:{" "}{props.selectedPeriod}</p>
          <p>Start Date:{" "}{props.prevPath === 'activeshield' ? props.startDate : convertDate(new Date())}</p>
          <p>Expiration Date:{" "}{newConvertDate(props.checkoutdata["ExpirationDate"])}</p>
          <p>Market Shield Fee:{" "}{Number(
            addZeroes(
              Number(
                parseFloat(props.checkoutdata["Cost"]).toFixed(2)
              )
            )
          ).toLocaleString("en-US", currObj)}</p>
          <p>Market Shield Agreement ID:{" "}{props.ShieldData["agreement_id"]}</p>
          <p>Tracking Instrument Index:{" "}{props.ShieldData["IndexName"]}</p>
          <br></br>
          <div className="ai-shieldsummary">
            <h3>Tracking Instruments Details</h3>
            <div className="table_holder portfolio-table">
              <TableContainer className="table_height">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <th>
                          <div className="stck_table_heading">
                            {header.title}
                          </div>
                        </th>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.checkoutdata && props.checkoutdata.ClosePrice
                      && props.checkoutdata.ClosePrice.length > 0 
                      &&
                    props.checkoutdata.ClosePrice.map((_, i) => (
                        <tr key={i}>
                          <td>{props.checkoutdata.SecDesc[i]}</td>
                          <td>{props.checkoutdata.ConQty[i]}</td>
                          <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.ClosePrice[i]).toFixed(2)))
                          ).toLocaleString()}</td>
                          <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.NotionalVal[i]).toFixed(2)))
                            ).toLocaleString()}</td>
                          <td>${Number(addZeroes(Number(parseFloat(
                            props.checkoutdata.ProtectedVal[i]).toFixed(2)))).toLocaleString()}</td>
                        </tr>
                      ))}
                    <TableRow>
                      <td className="bold"></td>
                      <td className="bold"></td>
                      <td className="bold">Total</td>
                      <td className="bold">
                        ${Number(
                          addZeroes(
                            Number(
                              parseFloat(
                                props.checkoutdata["TotalNotionalVal"]
                              ).toFixed(2)
                            )
                          )
                        ).toLocaleString()}
                      </td>
                      <td className="bold">
                        ${Number(
                          addZeroes(
                            Number(
                              parseFloat(
                                props.checkoutdata["TotalProtectedVal"]
                              ).toFixed(2)
                            )
                          )
                        ).toLocaleString()}
                      </td>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <br></br>
          <p>
            The Market Shield <strong>“<u>Protection Period</u>”</strong> above specified the duration
            of the downside protection starting on the date the Market Shield is
            purchased (the <strong>“<u>Start Date</u>”</strong>) and ending on the <strong>“<u>Expiration Date</u>”</strong>.
          </p>
          <p>
            The Market Shield <strong>“<u>Protected Level</u>”</strong> specifies the protected value as
            a percentage of the current value of the Market Portfolio.
          </p>
          <p>
            The <strong>“<u>Market Portfolio</u>”</strong> is set forth above. The Market Portfolio
            <strong>“<u>Actual Value</u>”</strong> means the value of the securities in the Market
            Portfolio as of the date of measurement.
          </p>
          <p>
            The Market Shield <strong>“<u>Protection Payout</u>”</strong>, if any, is calculated and
            settled at the last exchange closing on or before the Expiration
            Date of the Market Shield (which is usually the exchange closing on
            the Expiration Date). The Market Shield Protection Payout, if any,
            shall equal the amount by which the Market Portfolio’s Actual Value
            as of the Expiration Date is lower than the Market Portfolio
            Protected Value. For the purposes of clarity, if the Market
            Portfolio Actual Value on the Expiration Date is higher than the
            Market Portfolio Protected Value there will be no Market Shield
            Protection Payout. For example, if a Market Portfolio with a
            $100,000 Notional Value and a Protected Level of 90% (resulting in a
            Protected Value of $90,000) experiences a market crash similar to
            the 1987 “Black Monday” magnitude (22.6% down) resulting in an
            Actual Value of the Market Shield on the Expiration Date of $77,400,
            the resulting Market Shield Protection Payout would equal $12,600.
          </p>
          <p>
            <strong>Calculation of Market Shield Protection Payout</strong>:
            All calculations of the payout are conducted in the basic unit of Mini-SPX Index Put Options
            on the S&P 500 Market Index (the “Mini SPX Index”), and will pay out in accordance with the payouts
            in such Mini SPX Index contracts, based on the Tracking Instrument quantity,
            as identized above, in the Market Shield. <strong>For the avoidance of doubt, While the Market Shield Protection
              Payout is calculated based on the Mini SPX Index, purchase of the Market Shield does not reflect
              purchase of any units of the Mini SPX Index on behalf of the Purchaser, and merely
              relies on the calculation formula set forth therein.</strong> The Mini-SPX Index is based on 1/10th
            the value of the Standard & Poor’s 500 Index (“SPX”). The Standard & Poor’s 500 Index
            is a capitali ation weighted index of 500 stocks from a broad range of industries. The
            component stocks are weighted according to the total market value of their outstanding
            “free foat” shares.
          </p>
          <p>
            The exercise settlement amount is equal to the difference in the exercise-settlement
            value and the exercise price of the option, multiplied by *100. For more information
            on the calculation of Mini-SPX Index Put Options, please refer to the <a target="_blank" href="https://www.cboe.com/tradable_products/sp_500/mini_spx_options/specifications/">XSP Options
              Product Specification</a>.
          </p>
          <p>
            While such basic unit of payout calculation is in the Mini-SPX Index
            Put Options, the Purchaser does not own the Mini-SPX Index Options.
            The Purchaser is entitled only to any payout set forth in this
            Market Shield Agreement.
          </p>
          {props.showPurchaseShield && (
            <p className="end-agreement">
              <span>--</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default withWidth()(MarketShieldSummary);
