import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function PostMultiShield(portfolioid,protection_period,unsel_con,sel_con) {

  const Token = sessionStorage.getItem("Token");

  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token,
    portfolio_id: portfolioid,
    protection_period:protection_period,
    unsel_con:unsel_con,
    sel_con:sel_con
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
    headers: header,
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetch(
        process.env.REACT_APP_POST_MULTI_SHIELD_API,
        requestOptions
      );
      if (!response.ok) {
        return false;
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          console.log(message["result"]);
          return message["result"];
        } else {
          console.log(message["error"]);
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        window.location.reload();
      }
      else{
        return 0
      }
    }
  }
  else{
    return 401
  }
}
