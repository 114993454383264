import React, { useEffect } from "react";
import "./ShieldProtectionWindow.css";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import RiskDial from "../../Components/RiskDial/RiskDial";
import ProjectionCone from "../../Components/Graph/ProjectionCone";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CustomPopup from "../Popup/Popup";
import Popup from "reactjs-popup";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { GetQuoteEngineData } from "../../Apis/GetQuoteEngineData";
import { GetRiskMonitorData } from "../../Apis/GetRiskMonitorData";
import { GetProjectionConeData } from "../../Apis/GetProjectionConeData";
import { findClosest } from "../Utilities/ClosestPercent";
import { addZeroes } from "../Utilities/AddZeros";
import { convertDate } from "../Utilities/ConvertDate";
import { selectedPeriodBackend } from "../Utilities/GetProtectionPeriodValue";
import IconButton from "@material-ui/core/IconButton";
import ShieldSummary from "../../Components/ShieldSummary/ShieldSummary";
import close from "../../images/close.svg";
import {
  protection_period_str,
  protection_level,
} from "../../Components/RecommendationBanner/RecommendationBanner";
import { TabContainer, TabPane, TabContent } from 'react-bootstrap';
import BellCurve from "../TerminalBellCurve/TerminalBellCurve";
import BarGraph from "../TerminalBarGraph/TerminalBarGraph";
import { FetchTerminalReturn } from "../../Apis/FetchTerminalReturn";
import PageLoader from "../PageLoader/PageLoader";

function ShieldProtectionWindow(props) {

  const history = useHistory();

  const MarketShield = () => history.push({
    pathname: "/PortfolioSolutions",
    state: {
      "period": period,
      "level": level,
      "risk_dial_data": RiskData,
      "projection_cone_data": ProjectionData,
      "portfolio_shield_data": data,
      "stocksData": location.state.stocksData,
      "shieldPrice": shieldPrice,
      "shieldExpires": shieldExpires,
      "percentPortfolioValue": percentPortfolioValue,
      "portfolioid": location.state.portfolioid
    }
  });

  const location = useLocation();
  React.useEffect(() => {
  }, [location]);

  const useStyles = makeStyles((theme) => ({
    button: {
      color: "white",
      marginTop: "2px",
    },
    checkbox_button: {
      color: "#60034c",
      width: "14px",
      height: "14px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  const [levels, removeFirstLevel] = React.useState([
    { percent: 80, level: "80%" },
    { percent: 85, level: "85%" },
    { percent: 90, level: "90%" },
    { percent: 95, level: "95%" },
    { percent: 100, level: "100%" },
  ]);
  const periods = [
    "Weekly",
    "2 Weeks",
    "3 Weeks",
    "1 Month",
    "2 Months",
    "3 Months",
    "6 Months",
    "Year",
  ];



  const periods_dict = {
    "Select": 0,
    "Weekly": 7,
    "2 Weeks": 14,
    "3 Weeks": 21,
    "1 Month": 30,
    "2 Months": 60,
    "3 Months": 91,
    "6 Months": 182,
    "Year": 365,
  }


  const PortfolioID = location.state.portfolioid !== undefined ? location.state.portfolioid : "";
  const [level, setLevel] = React.useState(90);
  const [period, setPeriod] = React.useState("1 Month");
  const selectedPortfolioTotalValue = sessionStorage.getItem(
    "PortfolioTotalValue"
  );
  const [data, setData] = React.useState();
  const [percentPortfolioValue, setPercentPortfolioValue] = React.useState(0);
  const [shieldPrice, setShieldPrice] = React.useState(0);
  const [selectedPercent, setSelectedPercent] = React.useState(0);
  const [Flag, setFlag] = React.useState(false);
  const [GotQuoteData, setGotQuoteData] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const PortfolioShieldStatus = sessionStorage.getItem("PortfolioShieldStatus");
  const [marketRiskData, setMarketRiskData] = React.useState();
  const [portfolioRiskData, setPortfolioRiskData] = React.useState();
  const [shieldRiskData, setShieldRiskData] = React.useState();
  const [Risk, setRisk] = React.useState();
  const [PortfolioValueArray, setPortfolioValueArray] = React.useState([]);
  const [ProtectedValue, setProtectedValue] = React.useState([]);
  const [GraphData, setGraphData] = React.useState([]);
  const [key, setKey] = React.useState(0);
  const [shieldExpirationDate, setShieldExpirationDate] = React.useState("");
  const [shieldExpires, setShieldExpires] = React.useState("");
  const [startTimer, setStartTimer] = React.useState(false);
  const [showRefresh, setShowRefresh] = React.useState(false);
  const [timerDuration, setTimerDuration] = React.useState(60);
  const [RiskData, setRiskData] = React.useState([]);
  const [ProjectionData, setProjectionData] = React.useState([]);
  const [GotRiskData, setGotRiskData] = React.useState(false);
  const [GotProjectionData, setGotProjectionData] = React.useState(false);
  const [APICalledCount, setAPICalledCount] = React.useState(0);
  const [monitorCount, setMonitorCount] = React.useState(0);
  const [PcCount, setPcCount] = React.useState(0);
  const [QuoteCount, setQuoteCount] = React.useState(0);
  const [showPopup, setShowPopup] = React.useState(false);
  const [SelectedData, setSelectedData] = React.useState({});
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [lackPeriodMsg, setLackPeriodMsg] = React.useState("");
  const [disablePortfolioShieldBuyButton, setDisablePortfolioShieldBuyButton] = React.useState(false);
  const [tabvalue, setTabValue] = React.useState("Projected Value");
  const [isLoading, setIsLoading] = React.useState(true);

  function handleTabChange(tabvalue) {
    setTabValue(tabvalue);
  }

  var selectedProtectionLevel = 0;
  var selectedPeriod = "";
  var new_date = new Date();
  const refetchTrData = () => {
    setGotTerminalData(false);
    setTerminalCount(0);
    // setIsLoading(true);
    // setBdOpen(true);
    setAPICalledCount(0);
    setFlag(false);
  }

  const changePeriod = (event) => {
    if (levels.length > 5) {
      levels.shift();
      removeFirstLevel(levels);
    }

    setPeriod(event.target.value);
    selectedPeriod = event.target.value;
    if (level !== 0) {
      valuetext(selectedPeriod, level);
    }
    refetchTrData();
  };
  console.log(periods_dict[period])
  const changeLevel = (event) => {
    setLevel(event.target.value);
    selectedProtectionLevel = event.target.value;
    if (period !== " ") {
      valuetext(period, selectedProtectionLevel);
    }
    refetchTrData();
  };

  function BuyShield() {
    setSelectedData({
      portfolio_value: parseFloat(selectedPortfolioTotalValue),
      shielded_value: parseFloat(percentPortfolioValue),
      shield_price: parseFloat(shieldPrice),
      shieldexpirationdate: shieldExpirationDate,
      protection_period: period,
      protection_level: level,
      closest_percent: selectedPercent,
      portfolio_id: location.state.portfolioid
    });
    setShowPopup(true);
  }

  var val = 0;
  //function to get the selected the protection and level and fetch the price based on it.
  function valuetext(period, value) {
    // if (Flag === true) {
    val = +(100 - value).toFixed(2).toString();
    //calling the fetch_price function passing selected protection period and percentage as parameters.
    fetch_price(period, value);
    // }
  }

  //function to get the required price from the data.
  async function fetch_price(period, percent) {
    setStartTimer(true);
    var protectionPeriod = 0;
    const { period_str, period_int } = selectedPeriodBackend(period);
    var tp = data[period_str];
    if (RiskData.length > 0 || typeof RiskData === "object") {
      var risk_tp = RiskData[period_str];
    }

    if (ProjectionData.length > 0 || typeof RiskData === "object") {
      var projection_cone_data = ProjectionData[period_str];
    }
    protectionPeriod = period_int;

    const org_percent = percent;

    percent = findClosest(tp, percent);
    // setSelectedPercent(percent);
    var shieldPercent = addZeroes(
      Number((100 - percent).toFixed(2).toString())
    );
    setSelectedPercent(shieldPercent);
    percent = (percent / 100).toFixed(2);

    var risk_percent = addZeroes(
      Number((org_percent / 100).toFixed(2).toString())
    );

    var projection_cone_percent = addZeroes(
      Number((org_percent / 100).toFixed(2).toString())
    );
    if (
      typeof ProjectionData === "object" &&
      Array.isArray(ProjectionData) === false
    ) {
      setGraphData(projection_cone_data[org_percent]);
      var new_lyst = Array(
        projection_cone_data[org_percent]["upper_bound"].length
      ).fill(Number(selectedPortfolioTotalValue));
      var protected_lyst = Array(
        projection_cone_data[org_percent]["upper_bound"].length
      ).fill((Number(selectedPortfolioTotalValue) * org_percent) / 100);
    }

    setPortfolioValueArray(new_lyst);
    setProtectedValue(protected_lyst);

    if (!data['missing_time_p_list'].includes(Number(period_int))) {
      let percentValue = parseFloat(
        selectedPortfolioTotalValue * percent
      ).toFixed(2);
      if (org_percent === "100") {
        setPercentPortfolioValue(selectedPortfolioTotalValue);
      } else {
        setPercentPortfolioValue(percentValue);
      }
      let shieldprice = tp[shieldPercent]["shieldprice"].toFixed(2);
      setShieldPrice(shieldprice);
      setLackPeriodMsg("");
      setDisablePortfolioShieldBuyButton(false);
    } else {
      setLackPeriodMsg("Sorry we cannot calculate portfolio shield prices for given period");
      setShieldPrice(0);
      setDisablePortfolioShieldBuyButton(true);
    }

    // Setting ShieldExpiration
    new_date.setDate(new_date.getDate() + protectionPeriod);
    setShieldExpires(convertDate(new_date));

    new_date = new_date.toISOString().split("T")[0].toString();
    setShieldExpirationDate(new_date);
    // Setting the Risk Monitor Data

    //Change to include risk data for 1 month and multiple levels
    var risk_tp = RiskData["month"];

    let risk_color_data = [];
    if (RiskData.length > 0 || typeof RiskData === "object") {
      if (org_percent === "100") {
        risk_percent = "1.0";
      }
      var data_dict = risk_tp[risk_percent];

      var items = Object.keys(data_dict).map(function (key) {
        return [key, data_dict[key]];
      });

      items.sort(function (first, second) {
        return second[1] - first[1];
      });
      risk_color_data.push({
        [items.slice(0, 1)[0][0]]: {
          priority: "High",
          value: items.slice(0, 1)[0][1],
          color: "#ED6663",
        },
        [items.slice(1, 2)[0][0]]: {
          priority: "Medium",
          value: items.slice(1, 2)[0][1],
          color: "#FFA654",
        },
        [items.slice(2, 3)[0][0]]: {
          priority: "Low",
          value: items.slice(2, 3)[0][1],
          color: "#FFA654",
        },
      });

      setRisk(risk_color_data);
      setMarketRiskData(risk_tp[risk_percent]["market_risk"] * 100);
      setPortfolioRiskData(risk_tp[risk_percent]["risk_wo_shield"] * 100);
      setShieldRiskData(risk_tp[risk_percent]["risk_w_shield"] * 100);
    }
  }

  function FetchPricesAgain() {
    window.location.reload(false);
    // setBdOpen(true);
    // setFlag(false);
    // GotQuoteData(false);
    // setQuoteCount(0);
    // setStartTimer(false);
    // setShowRefresh(false);
  }

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const [TrData, setTrData] = React.useState({});
  const [GotTerminalData, setGotTerminalData] = React.useState(false);
  const [TerminalCount, setTerminalCount] = React.useState(0);

  useEffect(() => {
    if (Flag === false && APICalledCount === 0) {
      console.log("called")
      setAPICalledCount(1);
      if (GotQuoteData === false && QuoteCount === 0) {
        setQuoteCount(1);
        GetQuoteEngineData(PortfolioID).then((quote_data) => {
          if (quote_data === "Not enough data for ticker(s)") {
            setGotQuoteData(true);
            setBdOpen(false);
            setLackPeriodMsg(
              "Insufficient Historical Data."
            );
          }
          else if (quote_data === "No Option Data Available For Stocks") {
            setGotQuoteData(true);
            setBdOpen(false);
            setLackPeriodMsg(
              "Insufficient Options Data."
            );
          }
          else if (quote_data !== false && quote_data !== 0 && quote_data !== 401 && quote_data !== 404) {
            setGotQuoteData(true);
            setData(quote_data);
            var protection_period = 30;
            var percent = 90;
            if (protection_level !== 0 && protection_period_str !== "") {
              setLevel(protection_level);
              setPeriod(protection_period_str);
              percent = protection_level;
              if (protection_period_str === "2 Weeks") {
                quote_data = quote_data["week_2"];
                protection_period = 14;
              } else if (protection_period_str === "1 Month") {
                quote_data = quote_data["month"];
                protection_period = 30;
              }
            } else {
              quote_data = quote_data["month"];
            }
            new_date.setDate(new_date.getDate() + protection_period);
            setShieldExpires(convertDate(new_date));
            new_date = new_date.toISOString().split("T")[0].toString();
            setShieldExpirationDate(new_date);
            var percent = findClosest(quote_data, percent);
            // setSelectedPercent(percent);
            var shieldPercent = addZeroes(
              Number((100 - percent).toFixed(2).toString())
            );
            setSelectedPercent(shieldPercent);
            let shieldprice = quote_data[shieldPercent]["shieldprice"].toFixed(2);
            setShieldPrice(shieldprice);
            percent = (percent / 100).toFixed(2);
            let percentValue = parseFloat(
              selectedPortfolioTotalValue * percent
            ).toFixed(2);
            setPercentPortfolioValue(percentValue);
            setStartTimer(true);
            setBdOpen(false);
          } else if (quote_data === false) {
            setBdOpen(false);
            setGotQuoteData(true);
            setPopupState(true);
            setPopupMessage(
              "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
            );
            setPopupTitle("Error");
            setPopupLoginRedirect(false);
            setAlertType("error");
          } else if (quote_data === 0) {
            setBdOpen(false);
            setGotQuoteData(true);
            setPopupState(true);
            setPopupMessage(
              "We are having issues connecting to our servers, please try again later."
            );
            setPopupLoginRedirect(false);
            setPopupTitle("Error");
            setAlertType("error");
          } else if (quote_data === 401) {
            setBdOpen(false);
            setGotQuoteData(true);
            setPopupState(true);
            setPopupMessage(
              "Server issue. Please try again or contact support@adaptive-investments.com."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
          }
          else if (quote_data === 404) {
            setBdOpen(false);
            setGotQuoteData(true);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
          }
        });
      }
      if (GotRiskData === false && monitorCount === 0) {
        setMonitorCount(1);
        GetRiskMonitorData().then((risk_data) => {
          if (risk_data !== 0 && risk_data !== false && risk_data !== 401 && risk_data !== 404) {
            setGotRiskData(true);
            setRiskData(risk_data);
            let risk_color_data = [];
            setRisk(risk_color_data);
            setMarketRiskData(risk_data["month"]["0.9"]["market_risk"] * 100);
            setPortfolioRiskData(
              risk_data["month"]["0.9"]["risk_wo_shield"] * 100
            );
            setShieldRiskData(risk_data["month"]["0.9"]["risk_w_shield"] * 100);
            // setBdOpen(false);
          } else if (risk_data === false) {
            setRiskData([]);
            setBdOpen(false);
            setPopupMessage(
              "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
            );
            setPopupLoginRedirect(false);
            setPopupTitle("No data found");
            setPopupState(true);
            setGotRiskData(true);
          } else if (risk_data === 0) {
            setRiskData([]);
            setBdOpen(false);
            setPopupState(true);
            setPopupLoginRedirect(false);
            setPopupMessage(
              "We are facing server issues. Please try again later"
            );
            setPopupTitle("We are facing server issues");
            setGotRiskData(true);
          } else if (risk_data === 401) {
            setBdOpen(false);
            setRiskData([]);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            setGotRiskData(true);
            return;
          }
          else if (risk_data === 404) {
            setBdOpen(false);
            setRiskData([]);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            setGotRiskData(true);
            return;
          }
        });
      }
      if (GotProjectionData === false && PcCount === 0) {
        setPcCount(1);
        GetProjectionConeData().then((projection_data) => {
          if (projection_data !== false && projection_data !== 0 && projection_data !== 401 && projection_data !== 404) {
            setGotProjectionData(true);
            setProjectionData(projection_data);
            setGraphData(projection_data["month"]["90"]);
            var new_lyst = Array(
              projection_data["month"]["90"]["upper_bound"].length
            ).fill(Number(selectedPortfolioTotalValue));
            var protected_lyst = Array(
              projection_data["month"]["90"]["upper_bound"].length
            ).fill(Number(selectedPortfolioTotalValue) * 0.90);
            setPortfolioValueArray(new_lyst);
            setProtectedValue(protected_lyst);
            // setBdOpen(false);
          } else if (projection_data === false) {
            setProjectionData([]);
            setBdOpen(false);
            setGotProjectionData(true);
            setPopupLoginRedirect(false);
            setPopupMessage(
              "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
            );
            setPopupTitle("No data found");
            setPopupState(true);
          } else if (projection_data === 0) {
            setProjectionData([]);
            setBdOpen(false);
            setGotProjectionData(true);
            setPopupMessage(
              "We are facing server issues at this point, please try again later."
            );
            setPopupTitle("No data found");
            setPopupLoginRedirect(false);
            setPopupState(true);
          }
          else if (projection_data === 401) {
            setBdOpen(false);
            setGotProjectionData(true);
            setProjectionData([]);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            return;
          }
          else if (projection_data === 404) {
            setBdOpen(false);
            setGotProjectionData(true);
            setProjectionData([]);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            return;
          }
        });
      }


      if (GotTerminalData === false && TerminalCount === 0) {
        setTerminalCount(1);
        FetchTerminalReturn(location.state.portfolioid, periods_dict[period], level / 100).then((TerminalData) => {
          console.log("selected values: ", period, level)
          setGotTerminalData(true);
          if (TerminalData !== false && TerminalData !== 0 && TerminalData !== undefined && TerminalData !== 401) {
            console.log("TRDAta", TerminalData)
            setTrData(TerminalData);
            setBdOpen(false);
            setIsLoading(false);
          } else if (TerminalData === false) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "We are facing some issues while loading the data. Please try again after sometime."
            );
            setAlertType("error");
          } else if (TerminalData === 0 || TerminalData === undefined) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "We are facing some issues while loading the data. Please try again after sometime."
            );
            setAlertType("error");
            setPopupLoginRedirect(false);
            return;
          } else if (TerminalData === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
          }
        });
      }
      if (
        GotQuoteData === true &&
        GotRiskData === true &&
        GotProjectionData === true &&
        GotTerminalData === true
      ) {
        setFlag(true);
        setBdOpen(false);
        setStartTimer(true);
        valuetext(period, level);
      }
    }
  }, [Flag, APICalledCount])


  const renderTime = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return (
      <div className="timer">
        <div className="value">
          {minutes}:{seconds}
        </div>
        <div className="text">Expires In</div>
      </div>
    );
  };
  const timerProps = {
    isPlaying: startTimer,
  };

  function RefreshTimer() {
    setStartTimer(false);
    setShowRefresh(true);
  }

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <CustomPopup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Shield Protection Calculator"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <div className="ai-shield-calculator-container">
        <div className="shield-row">
          <div className="ai-calc-plan-details">
            <div className="ai-stocks">
              <text>Calculate Shield Cost for</text>
            </div>
          </div>

          <div className="ai-shield-calculator-select infocard">
            <label className="ai-form-label">DURATION</label>
            <select
              className="ai-form-value"
              value={period}
              onChange={changePeriod}
            >
              {periods.map((i) => (
                <option value={i}>{i}</option>
              ))}
            </select>
            <span className="ai-shield-calculator-select-arrow"></span>
            <div className="ai-percent-value">
              <p>{shieldExpires}</p>
            </div>
          </div>

          <div className="ai-shield-calculator-select infocard">
            <label className="ai-form-label">Protection level</label>
            <select
              className="ai-form-value"
              value={level}
              onChange={changeLevel}
            >
              {levels.map((value, i) => (
                <option value={value.percent}>{value.level}</option>
              ))}
            </select>
            <span className="ai-shield-calculator-select-arrow"></span>
            <div className="ai-percent-value">
              <p>
                {Number(
                  addZeroes(
                    Number(parseFloat(percentPortfolioValue).toFixed(2))
                  )
                ).toLocaleString("en-US", currObj)}
              </p>
            </div>
          </div>

          <div className="ai-calc-plan-price">
            {Number(
              addZeroes(Number(parseFloat(shieldPrice).toFixed(2)))
            ).toLocaleString("en-US", currObj)}
            <p>{lackPeriodMsg}</p>
          </div>

          <div className="timer-wrapper">
            <CountdownCircleTimer
              {...timerProps}
              key={key}
              size={80}
              strokeWidth={5}
              // isPlaying
              duration={timerDuration}
              colors={"#E58B76"}
              onComplete={() => RefreshTimer()}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>

          {showRefresh === false ? (
            <div className="buttonbuy">
              <button id="turnshieldon" className="ai-btn primary solid" onClick={BuyShield} disabled={disablePortfolioShieldBuyButton} >
                <img className="ai-img" src="Assets/buyshield.png" />
                Turn Shield On
              </button>
              <button id="buymarketshield" className="ai-btn secondary solid" onClick={MarketShield}>
                <img className="ai-img" src="Assets/buyshield.png" />
                Buy Market Shield
              </button>
            </div>
          ) : (
            <button id="recalculateprice"
              className="ai-btn secondary solid"
              onClick={FetchPricesAgain}
            >
              <img className="ai-img" src="Assets/refresh.png" />
              Recalculate Price
            </button>
          )}
        </div>
        <hr className="hr-line" />
        <div className="new_row">
          <div className="analyze-portfolio-column1">
            <label>Projected Risk</label>
            {RiskData.length === 0 ? (
              <div>
                <p>Rendering Risk Dial, No Data Available Yet.</p>
              </div>
            ) : (
              <div>
                {/* {level === 90 && period === "1 Month" ? (
                  <RiskDial
                    marketRiskValue={RiskData["month"]["0.9"]["market_risk"] * 100}
                    portfolioRiskValue={RiskData["month"]["0.9"]["risk_wo_shield"] * 100}
                    shieldRiskValue={RiskData["month"]["0.9"]["risk_w_shield"] * 100}
                    PortfolioShieldStatus={PortfolioShieldStatus}
                    risk_color_data={Risk}
                  />
                ) : ( */}
                <RiskDial
                  marketRiskValue={marketRiskData}
                  portfolioRiskValue={portfolioRiskData}
                  shieldRiskValue={shieldRiskData}
                  PortfolioShieldStatus={PortfolioShieldStatus}
                  risk_color_data={Risk}
                />
                {/* )} */}
              </div>
            )}
          </div>
          <div className="analyze-portfolio-column2">
            <div className="shieldstates">
              <div className="contentshield">
                <ul className="subnavbar-menu navtab">
                  <li>
                    <a className={tabvalue === "Projected Value" ? "SUBITEM-selected " : "SUBITEM"} eventKey="Projected Value" onClick={() => handleTabChange('Projected Value')} > Projected Value </a>
                  </li>
                  <li>
                    <a className={tabvalue === "Terminal Distribution" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Terminal Distribution" onClick={() => handleTabChange('Terminal Distribution')}>Terminal Distribution </a>
                  </li>
                  <li>
                    <a className={tabvalue === "S.D. Terminal Returns" ? "SUBITEM-selected" : "SUBITEM"} eventKey="S.D. Terminal Returns" onClick={() => handleTabChange('S.D. Terminal Returns')}>S.D. Terminal Returns </a>
                  </li>
                </ul>
              </div>
            </div>
            <TabContainer defaultActiveKey={tabvalue}>
              <TabContent>
                <TabPane key="Projected Value" eventKey="Projected Value" active={tabvalue === 'Projected Value'} >
                  <label>Projected Value</label>
                  {ProjectionData.length === 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <p>Rendering Projection Cone, No Data Available Yet.</p>
                    </div>
                  ) : (
                    <div>
                      {/* {level === 90 && period === "1 Month" ? (
                        <ProjectionCone
                          key={new Date().getTime()}
                          data={ProjectionData["month"]["90"]}
                          portfolioValue={Array(
                            ProjectionData["month"]["90"]["upper_bound"].length
                          ).fill(Number(selectedPortfolioTotalValue))}
                          protectedValue={Array(
                            ProjectionData["month"]["90"]["upper_bound"].length
                          ).fill(Number(selectedPortfolioTotalValue) * 0.9)}
                        />
                      ) : ( */}
                      <ProjectionCone
                        key={new Date().getTime()}
                        data={GraphData}
                        portfolioValue={PortfolioValueArray}
                        protectedValue={ProtectedValue}
                      />
                      {/* )} */}
                    </div>
                  )}
                </TabPane>
                <TabPane key="terminal-distribution" eventKey="Terminal Distribution" active={tabvalue === 'Terminal Distribution'}>
                  <div className="barchart">
                    {
                      isLoading === true ? "" :
                        <BellCurve data={TrData} />
                    }
                  </div>
                </TabPane>

                <TabPane key="S.D. Terminal Returns" eventKey="S.D. Terminal Returns" active={tabvalue === 'S.D. Terminal Returns'}>
                  <div className="barchart">
                    {
                      isLoading === true ? "" :
                        <BarGraph data={TrData} />
                    }
                  </div>
                </TabPane>
              </TabContent>
            </TabContainer>
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup id="PortfolioPopUp" open={true} modal>
          <div className="add-portfolio-popup">
            <IconButton
              onClick={() => setShowPopup(false)}
              className="closepopup"
            >
              <img src={close}></img>
            </IconButton>
            <ShieldSummary buyShield={true} marketShield={false} showPurchaseShield={true} selectedData={SelectedData} />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default withWidth()(ShieldProtectionWindow);
