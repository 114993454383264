import React from "react";
// import "./MarketShield.scss";
import Navbar from "../../Components/Navbar/Navbar_official"
import PageHeader from "../../Components/PageHeader/PageHeader";
import MarketShield from "../../Components/MarketShield/MarketShield"
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";

function MarketShieldScreen(props) {
    return (
        <div className="ai-content-part">
            <Navbar></Navbar>
            <div className="floathead">
                <PageHeader heading="Shield Recommendations" backButton={true} addButton={false} routeTo='./PortfolioSummaryScreen' />
                <PortfolioDetails/>
            </div>
            <MarketShield />
        </div>
    )
}

export default (MarketShieldScreen);