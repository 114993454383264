export async function GetBacktestGraphData(portfolio_id, date1, date2, level, period) {

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    portfolio_id: portfolio_id,
    protection_period: period,
    protection_level: level,
    from_date: date1,
    to_date: date2
  });
  console.log("raw", raw)
  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
    timeout: 30000
  };

  if (d1 >= d2) {
    return false
  }
  else {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACK_TEST,
        requestOptions
      );

      if (!response.ok) {
        return false;
      }
      const data = await response.json();
      console.log("grph", data);
      if (data) {
        return data;
      } else {
        return false;
      }

    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
}
