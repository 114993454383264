import React from "react";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioForm from "../../Components/PortfolioForm/PortfolioForm";

export default function EditPortfolioScreen() {
  return (
    <div className="ai-content-part">
      <Navbar></Navbar>
      <PageHeader
        heading="Edit Portfolio"
        backButton={true}
        routeTo="./PortfolioSummaryScreen"
      ></PageHeader>
      <PortfolioForm editFlag={true}></PortfolioForm>
    </div>
  );
}
