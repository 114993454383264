import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetRiskMonitorData(portfolio_id) {

  // const id = window.sessionStorage.getItem("sessionID");
  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var requestOptions = {
    method: "GET",
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_PORTFOLIO_RISK + "?session_id=" + Token,
        requestOptions
      );

      if (!response.ok) {
        return false;
      }
      const data = await response.json();
      if (data['error'] === "") {
        return (data['result']);
      } else if (data["error"] !== "") {
        if (data["error"] === "Token Verification Failed") {
          return 404
        } else {
          return 0
        }
      }
      else {
        return false;
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }

}
