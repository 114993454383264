import React from "react";
import "./AdvisorCsvLineItemChangesTable.css";

export default function AdvisorCsvLineItemChangesTable(props) {
  console.log(props)
  return (
    <div>
      <div className="LineItemChangesTableBody">
        <div className="LineItemChangesWindowHeader">
          <div className="WindowHeaderTextBlock">
            <p className="WindowHeaderLabels">Client ID - </p>
            <p className="WindowText">&nbsp;{props.clientId}</p>
          </div>
          <div className="VerticalSeparator"></div>
          <div className="WindowHeaderTextBlock">
            <p className="WindowText">{props.clientName}</p>
          </div>
          <div className="VerticalSeparator"></div>
          <div className="WindowHeaderTextBlock">
            <p className="WindowHeaderLabels">Account ID - </p>
            <p className="WindowText">&nbsp;{props.accountId}</p>
          </div>
          <div className="VerticalSeparator"></div>
          <div className="WindowHeaderTextBlock">
            <p className="WindowText">{props.AccountName}</p>
          </div>
        </div>
        <div className="lineItemChangesTableHolder">
          <table className="lineItemChangesTable">
            <thead>
              <th>SYMBOL</th>
              <th>CUSIP</th>
              <th>NEW QUANTITY</th>
              <th>OLD QUANTITY</th>
              <th>ACTION</th>
            </thead>
            <tbody>
              {props.LineItemChanges.map((row) => (
                <tr>
                  <td>{row.symbol}</td>
                  <td>-</td>
                  <td>{row.newquantity}</td>
                  <td>{row.oldquantity}</td>
                  <td>{row.version}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
