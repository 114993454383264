import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function UploadCustomerFile(file) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };
    // const session_id = sessionStorage.getItem("sessionID");

    var raw = JSON.stringify({
        file: file,
        session_id: Token
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        headers: header,
        redirect: "follow",
    };

    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_PROCESS_CUSTOMER_FILE,
                requestOptions
            );
            if (response.message === "Internal server error") {
                //alert("We are facing server issues");
            } else if (response.message === "The incoming token has expired") {
                //alert("Your session has expired please log-in again");
            } else {
                const data = await response.json();
                if (data["result"] !== "") {
                    console.log("file details", data["result"]);
                    return data["result"];
                } else if (data["error"] !== "") {
                    if (data["error"] === "Token Verification Failed") {
                        return 404
                    } else {
                        return 0
                    }
                }
                else {
                    return false;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                //alert("The request timed out, Please try again");
                window.location.reload();
            }
            else {
                return 0
            }
        }
    } else {
        return 401
    }
}
