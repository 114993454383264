import React from "react";
import Navbar from "../../Components/Navbar/Navbar_official";
import ImportStatusNav from "../../Components/ImportStatusNav/ImportStatusNav";
import { Container, Row, Col } from "react-bootstrap";
import AdvisorErrors from "../../Components/AdvisorErrors/AdvisorErrors";

export default function AdvisorErrorsScreen() {
  return (
    <div className="ai-content-part advisor">
      <Navbar />
      <section className="portfolio-table-wrapper">        
        <ImportStatusNav />
        <AdvisorErrors />
      </section>
    </div>
  );
}
