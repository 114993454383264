import React, { Component } from 'react';

class AiInput extends Component {
    render() {
        if(this.props.label) {
            return (
                <div className="ai-form-group">
                    <label className="ai-form-label">{this.props.label}</label>
                    <input 
                        type={this.props.type} 
                        placeholder={this.props.placeholder} 
                        name={this.props.name}
                        className="ai-form-value" 
                        onChange={this.props.onChange} 
                        autoComplete={this.props.autoComplete}/>
                </div>
            );
        }
        else {
            return (
                <div className="ai-form-group">
                    <input 
                        type={this.props.type} 
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        className="ai-form-value" 
                        onChange={this.props.onChange}
                        autoComplete={this.props.autoComplete} />
                </div>
            );
        }
        
    }
}

export default AiInput;