import React from "react";
import PortfolioShield from "../../Components/PortfolioShield/PortfolioShield";
import Navbar from "../../Components/Navbar/Navbar_official"
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioSolutionsNavbar from "../../Components/PortfolioSolutionsNavbar/PortfolioSolutionsNavbar"
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";

function PortfolioShieldScreen(props) {
    return (
        <div className="ai-content-part">
            <Navbar></Navbar>
            <div className="floathead">
                <PageHeader heading="Portfolio Solutions" backButton={true} addButton={false} routeTo='./PortfolioSummaryScreen' />
                <PortfolioDetails/>
            </div>
            <PortfolioSolutionsNavbar />
            <PortfolioShield/>
        </div>
    )
}

export default (PortfolioShieldScreen);