import React from "react";
import "./AdvisorClientPortfolioTab.css";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "@material-ui/core/Checkbox";
import Popup from "../Popup/Popup";
import { useHistory } from "react-router-dom";
import { GetAdvisorClientPortfolio } from "../../Apis/GetAdvisorClientPortfolio";
import { UpdateAdvisorSessionID } from "../../Apis/UpdateAdvisorSessionID";
import { AddPortfolioIDSession } from "../../Apis/AddPortfolioIDSession";
import { addZeroes } from "../Utilities/AddZeros";
import { GetPeriodValue } from "../Utilities/GetProtectionPeriodValue";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import searchicon from "../../images/search.svg";
import PageLoader from "../PageLoader/PageLoader";
import { GetStocks } from "../../Apis/GetStocks";


function AdvisorDashboardStockTab(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["ClientPortfolio"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["ClientPortfolio"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["ClientPortfolio-2"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["ClientPortfolio-2"];
    }
  }

  const rendering = getRendering(props.width);

  const [Flag, setFlag] = React.useState(false);
  const [GotID, setGotID] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [PortfolioID, setPortfolioID] = React.useState("");
  const [ClientPortfolioData, setClientPortfolioData] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState("ASC");
  const [searchKey, setSearchKey] = React.useState("");
  const [sortIcon, setSortIcon] = React.useState("Assets/sort_icon.png");
  const [sortCol, setSortCol] = React.useState("");
  const [filterCol, setFilterCol] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterMinValue, setfilterMinValue] = React.useState(null);
  const [filterMaxValue, setfilterMaxValue] = React.useState(null);
  const [filterStartDate, setfilterStartDate] = React.useState(new Date());
  const [filterEndDate, setfilterEndDate] = React.useState(new Date());
  const [filterFormErrors, setFilterFormErrors] = React.useState({});
  const [unfilteredPortfolios, setunfilteredPortfolios] = React.useState([]);
  const [filterList, setFilterList] = React.useState([]);
  const [selectedshieldstatus, setselectedstatus] = React.useState({
    inactive: false,
    active: false,
  });
  const [selectedrisk, setselectedrisk] = React.useState({
    low: false,
    medium: false,
    high: false,
    veryhigh: false,
  });
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [ClientApiCount, setClientApiCount] = React.useState(0);
  const [PortfolioName, SetPortfolioName] = React.useState("");
  const [PortfolioTotalStocks, setPortfolioTotalStocks] = React.useState(0);
  const [PortfolioTotalValue, setPortfolioTotalValue] = React.useState(0);
  const [stocksData, setStocksData] = React.useState([]);

  const history = useHistory();
  const PortfolioSummary = () => history.push("./PortfolioSummaryScreen");
  const ShieldRecommendationScreen = () =>
    history.push("./ShieldRecommendationScreen");

  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioName", PortfolioName);
  }, [PortfolioName]);

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioTotalStocks", PortfolioTotalStocks);
  }, [PortfolioTotalStocks]);

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioTotalValue", PortfolioTotalValue);
  }, [PortfolioTotalValue]);

  function risktext(risknum) {
    if (risknum < 15) {
      return "low";
    } else if (risknum > 15 && risknum < 20) {
      return "medium";
    } else if (risknum > 20 && risknum < 30) {
      return "high";
    } else {
      return "veryhigh";
    }
  }

  function SetRiskColor(riskValue) {
    if (riskValue === "low") {
      return "#38bb55";
    } else if (riskValue === "medium") {
      return "#ffb800";
    } else if (riskValue === "high") {
      return "#ef3838";
    } else if (riskValue === "veryhigh") {
      return "#800000";
    }
  }

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  if (Flag === false && ClientApiCount === 0) {
    setClientApiCount(ClientApiCount + 1);
    GetAdvisorClientPortfolio().then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        setGotID(true);
        var lyst = [];
        for (let [key, value] of Object.entries(data)) {
          let risklevel = risktext(Number(Number(value.risk * 100).toFixed(2)));
          lyst.push({
            portfolioid: key,
            username: value.username,
            customername: value.customername,
            portfolioname: value.portfolioname,
            protectionlevel: !value.protectionlevel ? 0 : value.protectionlevel,
            protectionvalue: !value.protectionvalue ? 0 : Number(parseFloat(value.protectionvalue).toFixed(2)),
            protectionperiod: !value.protectionperiod
              ? "-"
              : GetPeriodValue(value.protectionperiod),
            risk: Number(Number(value.risk * 100).toFixed(2)),
            risklevel: risklevel,
            shieldstatus: value.shieldstatus,
            shieldexpirationdate: value.shieldexpirationdate.replace(
              "None",
              "-"
            ),
            total_portfolio_value: Number(
              addZeroes(
                Number(parseFloat(value.total_portfolio_value).toFixed(2))
              )
            ),
          });
        }
        setClientPortfolioData([...lyst]);
        setunfilteredPortfolios([...lyst]);
        setBdOpen(false);
      } else if (data === false) {
        setPopupMessage("Unable to fetch data. Please try again later.");
        setPopupState(true);
        setPopupTitle("Unable to fetch data");
        setBdOpen(false);
        setPopupLoginRedirect(false);
      } else if (data === 0) {
        setPopupMessage("We are facing server issues, please try again later.");
        setPopupState(true);
        setPopupTitle("Server Error");
        setBdOpen(false);
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupTitle("Session Expired");
        setPopupLoginRedirect(true);
        return;
      }else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupTitle("Session Expired");
        setPopupLoginRedirect(true);
        return;
      }
    });
    if (GotID === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  const GetClientPortfolios = (clientname, customername) => {
    setBdOpen(true);
    UpdateAdvisorSessionID(clientname).then((data) => {
      if (data !== false && data !== 0 && data !== 401) {
        sleep(100).then(() => {
          PortfolioSummary();
        });
      } else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Unable to retrieve portfolio details. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === false) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }
    });
  };

  const GetCompareShield = (portfolioid,portfolioname,clientname, customername) => {
    setBdOpen(true);
    UpdateAdvisorSessionID(clientname).then((data) => {
      if (data !== false && data !== 0 && data !== 401) {
          setPortfolioID(portfolioid);
          SetPortfolioName(portfolioname);
          GetStocks(portfolioid).then((data) => {
            if (data !== null && data !== false && data !== undefined && data !== 0 && Object.keys(data).length !== 0 && data !== 401) {
              setStocksData(data);
              setPortfolioTotalStocks(data["number_of_lineitems"]);
              setPortfolioTotalValue(data["total_portfolio_value"]);
              history.push({
                pathname: "./PortfolioSolutions",
                state: {
                  portfolioid: portfolioid,
                  stocksData: data
                }
              }
              );
            } else {
              setPopupState(true);
              setPopupMessage(
                "Please add atleast one stock to your portfolio to proceed."
              );
              setAlertType("error");
              setBdOpen(false);
            }
          });
      } else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Unable to retrieve portfolio details. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === false) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }
    });
  };

  const GoToRecommendationScreen = (row_val) => {
    setPortfolioID(row_val["portfolioid"]);
    AddPortfolioIDSession(row_val["portfolioid"]).then((data) => {
      if (data !== false && data !== 0 && data !== 401) {
        // sleep(100).then(() => {
        //   ShieldRecommendationScreen();
        // });
      } else if (data === false) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Unable to retrieve portfolio details. Please try again or contact support@adaptive-investments.com.");
        setAlertType("Error");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
        setAlertType("Error");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }
    });
  };

  const requestSort = (col) => {
    let sortCol = col;
    let periodList = [
      "-",
      "Day",
      "Weekly",
      "2 Weeks",
      "3 Weeks",
      "1 Month",
      "2 Months",
      "3 Months",
      "6 Months",
      "Year",
    ];
    console.log("sort col", sortCol);
    if (sortOrder === "ASC") {
      const sortedPortfolioData = [...ClientPortfolioData];
      if (
        [
          "customername",
          "portfolioname",
          "shieldstatus",
          "shieldexpirationdate",
        ].indexOf(sortCol) >= 0
      ) {
        sortedPortfolioData.sort((a, b) =>
          a[sortCol].toLowerCase() > b[sortCol].toLowerCase() ? 1 : -1
        );
      } else if (sortCol === "protectionperiod") {
        sortedPortfolioData.sort((a, b) =>
          periodList.indexOf(a[sortCol]) > periodList.indexOf(b[sortCol])
            ? 1
            : -1
        );
      } else {
        sortedPortfolioData.sort((a, b) =>
          Number(parseFloat(a[sortCol]).toFixed(2)) >
            Number(parseFloat(b[sortCol]).toFixed(2))
            ? 1
            : -1
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_asc_icon.png");
      setClientPortfolioData(sortedPortfolioData);
      setSortOrder("DESC");
    }
    if (sortOrder === "DESC") {
      const sortedPortfolioData = [...ClientPortfolioData];
      if (
        [
          "customername",
          "portfolioname",
          "shieldstatus",
          "shieldexpirationdate",
        ].indexOf(sortCol) >= 0
      ) {
        sortedPortfolioData.sort((a, b) =>
          a[sortCol].toLowerCase() < b[sortCol].toLowerCase() ? 1 : -1
        );
      } else if (sortCol === "protectionperiod") {
        sortedPortfolioData.sort((a, b) =>
          periodList.indexOf(a[sortCol]) < periodList.indexOf(b[sortCol])
            ? 1
            : -1
        );
      } else {
        sortedPortfolioData.sort((a, b) =>
          Number(parseFloat(a[sortCol]).toFixed(2)) <
            Number(parseFloat(b[sortCol]).toFixed(2))
            ? 1
            : -1
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_desc_icon.png");
      setClientPortfolioData(sortedPortfolioData);
      setSortOrder("ASC");
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, col) => {
    setAnchorEl(event.currentTarget);
    setFilterCol(col);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterCol(null);
    setfilterMinValue(null);
    setfilterMaxValue(null);
    setfilterStartDate(new Date());
    setfilterEndDate(new Date());
    setFilterFormErrors({});
    setselectedrisk({
      high: false,
      low: false,
      medium: false,
      veryhigh: false,
    });
    setselectedstatus({ active: false, inactive: false });
  };

  const validateValueFilter = () => {
    let validFilterFlag = true;
    let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
    let error = {};
    if (!filterMinValue) {
      validFilterFlag = false;
      error.minfilter = "Please enter min value";
    }
    if (!filterMaxValue) {
      validFilterFlag = false;
      error.maxfilter = "Please enter max value";
    }
    if (
      Number(parseFloat(filterMaxValue).toFixed(2)) <
      Number(parseFloat(filterMinValue).toFixed(2))
    ) {
      error.maxfilter = "Please enter max value greater than min value";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMinValue)) {
      error.minfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMaxValue)) {
      error.maxfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    setFilterFormErrors(error);
    return validFilterFlag;
  };

  const validateDateFilter = () => {
    let validFilterFlag = true;
    let sdate = filterStartDate.toISOString().split("T")[0].toString();
    let edate = filterEndDate.toISOString().split("T")[0].toString();
    let error = {};
    if (!sdate) {
      validFilterFlag = false;
      error.filterStartDate = "Please enter a start date to apply filter";
    }
    if (!edate) {
      validFilterFlag = false;
      error.filterStartDate = "Please enter a end date to appy filter";
    }
    if (sdate > edate) {
      validFilterFlag = false;
      error.filterStartDate =
        "Start date can not be greater than end date to apply filter";
    }
    setFilterFormErrors(error);
    return validFilterFlag;
  };

  const validateRiskFilter = () => {
    let selectedFlag = true;
    let error = {};
    if (
      !(
        selectedrisk.high ||
        selectedrisk.low ||
        selectedrisk.medium ||
        selectedrisk.veryhigh
      )
    ) {
      error.filterRiskCheckbox = "Please select atleast one risk level";
      selectedFlag = false;
    }
    setFilterFormErrors(error);
    return selectedFlag;
  };
  const validateShieldFilter = () => {
    let selectedFlag = true;
    let error = {};
    if (!(selectedshieldstatus.active || selectedshieldstatus.inactive)) {
      error.filterSheildCheckbox = "Please select atleast one shield status";
      selectedFlag = false;
    }
    setFilterFormErrors(error);
    return selectedFlag;
  };

  const requestFilter = (e) => {
    e.preventDefault();
    if (filterCol === "total_portfolio_value" && validateValueFilter()) {
      const filteredPortfolioData = ClientPortfolioData.filter(
        (data) =>
          Number(parseFloat(data[filterCol]).toFixed(2)) >=
            Number(parseFloat(filterMinValue).toFixed(2)) &&
          Number(parseFloat(data[filterCol]).toFixed(2)) <=
          Number(parseFloat(filterMaxValue).toFixed(2))
      );
      setClientPortfolioData(filteredPortfolioData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: filterStartDate.toISOString().split("T")[0].toString(),
        enddate: filterEndDate.toISOString().split("T")[0].toString(),
        risklevel: selectedrisk,
        shieldstatus: selectedshieldstatus,
      });
      handleClose();
    } else if (filterCol === "shieldstatus" && validateShieldFilter()) {
      let shieldlyst = [];
      for (const [key, value] of Object.entries(selectedshieldstatus)) {
        if (value === true) {
          shieldlyst.push(key);
        }
      }
      const filteredPortfolioData = ClientPortfolioData.filter((data) =>
        shieldlyst.includes(data[filterCol])
      );
      setClientPortfolioData(filteredPortfolioData);
      console.log("setClientPortfolioData", ClientPortfolioData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: filterStartDate.toISOString().split("T")[0].toString(),
        enddate: filterEndDate.toISOString().split("T")[0].toString(),
        risklevel: selectedrisk,
        shieldstatus: selectedshieldstatus,
      });
      handleClose();
    } else if (filterCol === "shieldexpirationdate" && validateDateFilter()) {
      let fsdate = filterStartDate.toISOString().split("T")[0].toString();
      let fedate = filterEndDate.toISOString().split("T")[0].toString();
      const filteredPortfolioData = ClientPortfolioData.filter(
        (data) => data[filterCol] >= fsdate && data[filterCol] <= fedate
      );
      setClientPortfolioData(filteredPortfolioData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: fsdate,
        enddate: fedate,
        risklevel: selectedrisk,
        shieldstatus: selectedshieldstatus,
      });
      handleClose();
    } else if (filterCol === "risk" && validateRiskFilter()) {
      let risklyst = [];
      for (const [key, value] of Object.entries(selectedrisk)) {
        if (value === true) {
          risklyst.push(key);
        }
      }
      const filteredPortfolioData = ClientPortfolioData.filter((data) =>
        risklyst.includes(data["risklevel"])
      );
      setClientPortfolioData(filteredPortfolioData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: filterStartDate.toISOString().split("T")[0].toString(),
        enddate: filterEndDate.toISOString().split("T")[0].toString(),
        risklevel: selectedrisk,
        shieldstatus: selectedshieldstatus,
      });
      handleClose();
    }
    console.log("filterList", filterList);
  };

  const removeFilter = (data) => {
    let lyst = [...unfilteredPortfolios];
    var removefilterIndex = filterList
      .map(function (item) {
        return item.filterno;
      })
      .indexOf(data.filterno);
    filterList.splice(removefilterIndex, 1);
    setFilterList([...filterList]);
    if (filterList.length !== 0) {
      for (let [key, value] of Object.entries(filterList)) {
        if (value.column === "total_portfolio_value") {
          lyst = lyst.filter(
            (data) =>
              Number(parseFloat(data[value.column]).toFixed(2)) >=
              Number(parseFloat(value.minvalue).toFixed(2)) &&
              Number(parseFloat(data[value.column]).toFixed(2)) <=
              Number(parseFloat(value.maxvalue).toFixed(2))
          );
          setClientPortfolioData([...lyst]);
        } else if (value.column === "shieldexpirationdate") {
          lyst = lyst.filter(
            (data) =>
              data[value.column] >= value.startdate &&
              data[value.column] <= value.enddate
          );
          setClientPortfolioData([...lyst]);
        } else if (value.column === "risk") {
          let risklyst = [];
          for (const [k, v] of Object.entries(value.risklevel)) {
            if (v === true) {
              risklyst.push(k);
            }
          }
          lyst = lyst.filter((data) =>
            risklyst.includes(data[value.risklevel])
          );
          setClientPortfolioData([...lyst]);
        } else if (value.column === "shieldstatus") {
          let shieldlyst = [];
          for (const [k, v] of Object.entries(value.shieldstatus)) {
            if (v === true) {
              shieldlyst.push(k);
            }
          }
          lyst = lyst.filter((data) => shieldlyst.includes(data[value.column]));
          setClientPortfolioData([...lyst]);
        }
      }
    } else {
      setClientPortfolioData([...unfilteredPortfolios]);
    }
  };

  const handleShieldStausChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setselectedstatus({ ...selectedshieldstatus, [e.target.name]: value });
  };

  const handleRiskChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setselectedrisk({ ...selectedrisk, [e.target.name]: value });
  };

  const headers = [
    {
      tableHeader: "CLIENT",
      sort_icon: (
        <IconButton onClick={() => requestSort("customername")}>
          <img
            src={sortCol === "customername" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
      classname:"stck_table_heading ",
    },
    {
      tableHeader: "PORTFOLIO",
      sort_icon: (
        <IconButton onClick={() => requestSort("portfolioname")}>
          <img
            src={
              sortCol === "portfolioname" ? sortIcon : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
      classname:"stck_table_heading ",
    },
    {
      tableHeader: "VALUE",
      sort_icon: (
        <IconButton onClick={() => requestSort("total_portfolio_value")}>
          <img
            src={
              sortCol === "total_portfolio_value"
                ? sortIcon
                : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "total_portfolio_value")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      classname:"stck_table_heading monohead",
    },
    {
      tableHeader: "RISK - %",
      sort_icon: (
        <IconButton onClick={() => requestSort("risk")}>
          <img
            src={sortCol === "risk" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "risk")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      classname:"stck_table_heading ",
    },
    {
      tableHeader: "PROTECTION VALUE",
      sort_icon: (
        <IconButton onClick={() => requestSort("protectionvalue")}>
          <img
            src={
              sortCol === "protectionvalue" ? sortIcon : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
      classname:"stck_table_heading monohead",
    },
    {
      tableHeader: "PROTECTION PERIOD",
      sort_icon: (
        <IconButton onClick={() => requestSort("protectionperiod")}>
          <img
            src={
              sortCol === "protectionperiod" ? sortIcon : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
      classname:"stck_table_heading ",
    },
    {
      tableHeader: "EXPIRES ON",
      sort_icon: (
        <IconButton onClick={() => requestSort("shieldexpirationdate")}>
          <img
            src={
              sortCol === "shieldexpirationdate"
                ? sortIcon
                : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "shieldexpirationdate")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      classname:"stck_table_heading ",
    },
    {
      tableHeader: "SHIELD",
      sort_icon: (
        <IconButton onClick={() => requestSort("shieldstatus")}>
          <img
            src={sortCol === "shieldstatus" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "shieldstatus")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      classname:"stck_table_heading ",
    },
  ];

  function return_button(value, row) {
    if (value === "inactive") {
      return (
        <button
          className="ai-btn primary solid small"
          onClick={() => GetCompareShield(row.portfolioid,row.portfolioname,row.username,row.customername)}
        >
          <img src="Assets/buy-shield-red.png" alt="" />
          Compare shield
        </button>
      );
    } else if (value === "active") {
      return (
        <img src="Assets/shield-green.png" style={{ height: "28px" }} alt="" />
      );
    }
  }

  function chip_data(data) {
    if (data.column === "total_portfolio_value") {
      let label = "Value $" + data.minvalue + " - $" + data.maxvalue;
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    } else if (data.column === "shieldexpirationdate") {
      let label = "Expire on " + data.startdate + " - " + data.enddate;
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    } else if (data.column === "risk") {
      let label = "Risk ";
      for (const [k, v] of Object.entries(data.risklevel)) {
        if (v === true) {
          label = label + " - " + k;
        }
      }
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    } else if (data.column === "shieldstatus") {
      let label = "Shield ";
      for (const [k, v] of Object.entries(data.shieldstatus)) {
        if (v === true) {
          label = label + " - " + k;
        }
      }
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    }
  }

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      ></Popup>
      <div className="search_bar">
      <div className="searchinput">
        <img src={searchicon}></img>
        <input
          className="ai-form-value"
          type="text"
          placeholder="Search"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
        />
      </div>
      </div>
      <Paper className="filter-paper">
        {filterList.map((data) => chip_data(data))}
      </Paper>
      <div className="table_holder">
        <TableContainer className="table_height">
          <Table  stickyHeader aria-label="sticky table">
          {
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell>
                    <div className={header.classname}>
                      {header.tableHeader}
                      {header.sort_icon}
                      {header.filter_icon}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {ClientPortfolioData.filter((val) => {
              if (searchKey === "") {
                return val;
              } else if (
                val.customername.toLowerCase().includes(searchKey.toLowerCase())
              ) {
                return val;
              }
            }).map((row) => (
              <TableRow>
                <TableCell>
                  <a
                    onClick={() =>
                      GetClientPortfolios(row.username, row.customername)
                    }
                  >
                    {row.customername}
                  </a>
                </TableCell>
                <TableCell>
                    {row.portfolioname}
                </TableCell>
                <TableCell className="mono">
                  {row.total_portfolio_value.toLocaleString("en-US", currObj)}
                </TableCell>
                <TableCell className="risk-transform">
                  <a style={{ color: SetRiskColor(row.risklevel) }}>
                    {row.risklevel}{" "}
                  </a>{" "}
                  / <span className="mono">{row.risk}%</span>
                </TableCell>
                <TableCell className="mono">{row.protectionvalue.toLocaleString("en-US",currObj)}</TableCell>
                <TableCell>{row.protectionperiod}</TableCell>
                <TableCell>{row.shieldexpirationdate}</TableCell>
                <TableCell>
                  <div>{return_button(row.shieldstatus, row)}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </TableContainer>
        {filterCol === "shieldexpirationdate" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group cardpopup p16">
              <DatePicker
                className="ai-form-value"
                placeholder="Start Date"
                selected={filterStartDate}
                onChange={(date) => setfilterStartDate(date)}
                dateFormat="yyyy-MM-dd"
              />
              <p className="error-message">
                {filterFormErrors.filterStartDate}
              </p>
              <p className="filter-label">To</p>
              <DatePicker
                className="ai-form-value"
                placeholder="End Date"
                selected={filterEndDate}
                onChange={(date) => setfilterEndDate(date)}
                dateFormat="yyyy-MM-dd"
              />
              <p className="error-message">{filterFormErrors.filterEndDate}</p>
              <div className="button-div">
                <button
                  type="Submit"
                  className="ai-btn primary small solid"
                  onClick={requestFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </Popover>
        )}
        {filterCol === "total_portfolio_value" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group cardpopup vertical p16">
              <input
                type="text"
                className="ai-form-value"
                placeholder="Min Value"
                onChange={(event) => setfilterMinValue(event.target.value)}
              />
              <p className="error-message">{filterFormErrors.minfilter}</p>
              <p className="filter-label">To</p>
              <input
                type="text"
                className="ai-form-value"
                placeholder="Max Value"
                onChange={(event) => setfilterMaxValue(event.target.value)}
              />
              <p className="error-message">{filterFormErrors.maxfilter}</p>
              <button
                type="Submit"
                className="ai-btn primary small solid"
                onClick={requestFilter}
              >
                Apply
              </button>
            </div>
          </Popover>
        )}
        {filterCol === "risk" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group cardpopup">
              <label className="check-box-labels low">
                <Checkbox
                  className="check-box"
                  name="low"
                  checked={selectedrisk.low}
                  onChange={(e) => handleRiskChange(e)}
                />{" "}
                Low
              </label>
              <label className="check-box-labels medium">
                <Checkbox
                  className="check-box"
                  name="medium"
                  checked={selectedrisk.medium}
                  onChange={(e) => handleRiskChange(e)}
                />{" "}
                Medium
              </label>
              <label className="check-box-labels high">
                <Checkbox
                  className="check-box"
                  name="high"
                  checked={selectedrisk.high}
                  onChange={(e) => handleRiskChange(e)}
                />{" "}
                High
              </label>
              <label className="check-box-labels vhigh">
                <Checkbox
                  className="check-box"
                  name="veryhigh"
                  checked={selectedrisk.veryhigh}
                  onChange={(e) => handleRiskChange(e)}
                />{" "}
                Very High
              </label>
              <p className="error-message">
                {filterFormErrors.filterRiskCheckbox}
              </p>
              <button
                type="Submit"
                className="ai-btn primary small solid"
                onClick={requestFilter}
              >
                Apply
              </button>
            </div>
          </Popover>
        )}
        {filterCol === "shieldstatus" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group cardpopup">
              <label className="check-box-labels low">
                <Checkbox
                  className="check-box"
                  checked={selectedshieldstatus.active}
                  onChange={handleShieldStausChange}
                  name="active"
                />{" "}
                Active
              </label>
              <label className="check-box-labels high">
                <Checkbox
                  type="checkbox"
                  className="check-box"
                  checked={selectedshieldstatus.inactive}
                  onChange={handleShieldStausChange}
                  name="inactive"
                />{" "}
                Inactive
              </label>
              <p className="error-message">
                {filterFormErrors.filterSheildCheckbox}
              </p>
              <button
                type="Submit"
                className="ai-btn primary small solid"
                onClick={requestFilter}
              >
                Apply
              </button>
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
}

export default withWidth()(AdvisorDashboardStockTab);