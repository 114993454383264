import React from "react";
import "./ImportStatusNav.css";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Popup from "../Popup/Popup";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { UploadAdvisorCsvFile } from "../../Apis/UploadAdvisorCsvFile";
import PageLoader from "../PageLoader/PageLoader";

function ImportStatusNav(props) {
  const useStyles = makeStyles((theme) => ({
    button: {
      color: "white",
      marginTop: "2px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    list: {
      width: 300,
      fontColor: "#60034c",
    },
    fullList: {
      width: "auto",
    },
  }));

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return [
        "navbar",
        "navbar-logo",
        "navbar-menu",
        "navbar-noti",
        "navbar-account",
      ];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return [
        "navbar-2",
        "navbar-logo-2",
        "navbar-menu-2",
        "navbar-noti-2",
        "navbar-account-2",
      ];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return [
        "navbar-3",
        "navbar-logo-3",
        "navbar-menu-3",
        "navbar-noti-3",
        "navbar-account-3",
      ];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return [
        "navbar-3",
        "navbar-logo-3",
        "navbar-menu-3",
        "navbar-noti-3",
        "navbar-account-3",
      ];
    }
  }

  const rendering = getRendering(props.width);
  const history = useHistory();

  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [AdvisorCsvData, setAdvisorCsvData] = React.useState(
    JSON.parse(sessionStorage.getItem("AdvisorCsvData"))
  );
  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(false);
  const [GotData, setGotData] = React.useState(false);

  const AdvisorClientChanges = () => history.push("./AdvisorClientChanges");

  const hiddenFileInput = React.useRef(null);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

  React.useEffect(() => {
    window.sessionStorage.setItem(
      "AdvisorCsvData",
      JSON.stringify(AdvisorCsvData)
    );
  }, [AdvisorCsvData]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const classes = useStyles();

  const returnSubMenuClassname = (currentPath) => {
    if (history.location.pathname === currentPath) {
      return "SUBITEM-selected";
    } else {
      return "SUBITEM";
    }
  };

  const AdvisorCsvStatusItems = [
    {
      title: "Client Changes Detected",
      url: "/AdvisorClientChanges",
    },
    {
      title: "Account Changes Detected",
      url: "/AdvisorAccountChanges",
    },
    {
      title: "Portfolio Changes Detected",
      url: "/AdvisorLineItemChanges",
    },
    {
      title: "Errors Detected",
      url: "/AdvisorErrorsScreen",
    },
  ];

  function opennav() {
    var element = document.getElementById("navigation");
    element.classList.toggle("nav-open");
  }

  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUpload = (event) => {
    setBdOpen(true);
    event.persist();
    const [file] = event.target.files;
    var fileExt = file.name.split(".").pop();
    if (fileExt === "csv") {
      const reader = new FileReader();
      reader.onloadend = (onLoadEndEvent) => {
        console.log(onLoadEndEvent.target.result.split(",")[1]);
        UploadAdvisorCsvFile(onLoadEndEvent.target.result.split(",")[1]).then(
          (data) => {
            if (data !== false && data !== 0 && data !== "WRONG_FORMAT" && data !== 401) {
              setGotData(true);
              console.log(data);
              if (Object.entries(data['changes']).length > 0) {
                if (Object.entries(data["changes"]["customers"]).length === 0 && Object.entries(data["changes"]["portfolios"]).length === 0 && Object.entries(data["changes"]["portfolio_line_items"]).length === 0) {
                  data["changes"] = {};
                }
              }
              setAdvisorCsvData(data);

              console.log("return", data)
              if (Object.keys(data.errors).length !== 0) {
                history.push("/AdvisorErrorsScreen");
              } else {
                AdvisorClientChanges();
              }
              window.location.reload();
              setBdOpen(false);
            }
            else {
              sessionStorage.removeItem("AdvisorCsvData");
              if (data === false) {
                setBdOpen(false);
                setGotData(true);
                setPopupState(true);
                setPopupMessage(
                  "We are unable to proccess the csv, please try again later."
                );
                setPopupTitle("Unable to process CSV");
                //go to the error screen
                // history.push("/AdvisorErrorsScreen");
                setPopupLoginRedirect(false);
              } else if (data === 0) {
                setBdOpen(false);
                setGotData(true);
                setPopupState(true);
                setPopupMessage(
                  "We are facing server issues, please try again later."
                );
                setPopupTitle("Server Error");
                // history.push("/AdvisorErrorsScreen");
                setPopupLoginRedirect(false);
              }
              else if (data === "WRONG_FORMAT") {
                setBdOpen(false);
                setGotData(true);
                setPopupState(true);
                setPopupMessage(
                  "You have selected a wrong csv format. Please try again or contact support@adaptive-investments.com."
                );
                setPopupTitle("Server Error");
                setAlertType("error");
                // history.push("/AdvisorErrorsScreen");
                setPopupLoginRedirect(false);
              } else if (data === 401) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                  "Your session timed out for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
                setPopupTitle("Session Expired");
              }
              else if (data === 404) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                  "Your session was terminated for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
                setPopupTitle("Session Expired");
              }
            }
          }
        );
      };
      reader.readAsDataURL(file);
    } else {
      setPopupState(true);
      setPopupMessage("Unable to process CSV. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
    }
  };

  return (
    <div className="nav-wrap-csv">
      <PageLoader bdopen={bdopen} />
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Import Csv"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <a className="nav-toggle" href="javascript:;" onClick={() => opennav()}>
        <span class=""></span>
        <span></span>
        <span></span>
      </a>
      <nav className="csvnavbar" id="navigation">
        <ul className="csvnavbar-menu">
          {AdvisorCsvStatusItems.map((item) => {
            return (
              <li key={item.id}>
                <a
                  key={item.title}
                  className={returnSubMenuClassname(item.url)}
                  href={item.url}
                >
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
        <button className="ai-btn primary solid" onClick={handleFileClick}>
          <FileDownloadOutlinedIcon />
          <input
            type="file"
            id="fileUpload"
            accept=".csv"
            ref={hiddenFileInput}
            onChange={handleUpload}
            style={{ display: "none" }}
          />{" "}
          Import from CSV
        </button>

      </nav>
    </div>
  );
}
export default withWidth()(ImportStatusNav);
