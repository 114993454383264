import React, { useState } from 'react';
import "./FactorAnalysis.scss";
import {makeStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Popup from "../Popup/Popup";
import TagManager from "react-gtm-module";
import { addZeroes } from "../Utilities/AddZeros";
import { GetUserPortfolios } from "../../Apis/GetUserPortfolios";
import { FetchFactorAnalytics } from '../../Apis/FetchFactorAnalytics';
import PageLoader from "../PageLoader/PageLoader";

function FactorAnalysis(props) {
  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(true);
  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [portfolioName, setPortfolioName] = React.useState([]);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [FATable, setFATable] = React.useState([]);
  const [FactorTable, setFactorTable] = React.useState([]);
  const [HedgeableData, sethedgeabledata] = React.useState([]);
  const [UnhedgeableData, setunhedgeabledata] = React.useState([]);
  const [tabletabvalue, setTableTabValue] = React.useState("Hedgeable Assets");
  const [factortabvalue, setFactorTabValue] = React.useState("Factor Analysis");
  const [selectedPortfolioTotalStocks, setSelectedPortfolioTotalStocks] = React.useState(0);
  const [selectedPortfolioTotalValue, setSelectedPortfolioTotalValue] = React.useState(0);
  const [selectedPortfolioName, setSelectedPortfolioName] = React.useState("");
  const [R2Benchmark, setR2Benchmark] = React.useState(0);
  const [R2Factor, setR2Factor] = React.useState(0);
  const [BetaBenchmark, setBetaBenchmark] = React.useState(0);
  const [BetaFactor, setBetaFactor] = React.useState([]);
  const [TEBenchmark, setTEBenchmark] = React.useState(0);
  const [TEFactor, setTEFactor] = React.useState(0);
  const [R2BenchmarkNum, setR2BenchmarkNum] = React.useState(25);
  const [R2FactorNum, setR2FactorNum] = React.useState(25);



  if (Flag === false) {
    if (bdopen === false) {
      setBdOpen(true);
    }
    GetUserPortfolios().then((data) => {
      var portfoliolyst = [{ id: 0, name: "Select Portfolio" }];
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        for (let [key, value] of Object.entries(data)) {
          setGotData(true);
          portfoliolyst.push({
            id: key,
            name: value.portfolioname,
            no_stocks: value.nooflineitems,
            port_value: parseFloat(value.totalportfoliovalue).toFixed(2)
          });
        }
        setPortfolioName(portfoliolyst);
        setBdOpen(false)
      }
      else if (data === false) {
        setPopupState(true);
        setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
        setAlertType("error");
        setBdOpen(false);
        setGotData(true);
        setPopupLoginRedirect(false);
      }
      else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are facing issues connecting our servers, please try again later");
        setAlertType("error");
        setBdOpen(false);
        setGotData(true);
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setFlag(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }
    });
    if (GotData === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }
  const changePortfolio = (portfolioid) => {
    if (portfolioid === 0) {
      setPopupState(true);
      setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      return;
    }
    var portfolio = portfolioName.find(data => data.id === portfolioid);
    setSelectedPortfolioTotalStocks(portfolio.no_stocks)
    setSelectedPortfolioTotalValue(portfolio.port_value)
    fetchFactorAnalysis(portfolio.id);
  };

  const fetchFactorAnalysis = (portfolioid) => {
    setBdOpen(true);
    setSelectedPortfolioName(portfolioName.find(data => data.id === portfolioid).name)
    FetchFactorAnalytics(portfolioid).then((data) => {
      if (data !== false && data !== 0 && data !== undefined && data !== 401) {
        console.log(' data', data)
        setFATable(data['factor_analysis']);
        setFactorTable(data['factor']);
        sethedgeabledata(data['recognised']);
        setunhedgeabledata(data['unrecognised']);
        setR2Benchmark(data['r2_benchmark'] === "N.A." ? data['r2_benchmark'] : (data['r2_benchmark'] * 100).toFixed(2) + "%");
        setR2BenchmarkNum(data['r2_benchmark'] * 100);
        setR2Factor(data['r2_factor'] === "N.A." ? data['r2_factor'] : (data['r2_factor'] * 100).toFixed(2) + "%");
        if (data['r2_factor'] !== 'N.A.') {
          console.log('data', data['r2_factor'])
          setR2FactorNum(data['r2_factor'] * 100);
        }
        setBetaBenchmark(parseFloat(data['beta_benchmark']).toFixed(2));
        formatBetaFactor(data['beta_factor']);
        setTEBenchmark(data['te_benchmark'] === "N.A." ? data['te_benchmark'] : (data['te_benchmark'] * 100).toFixed(2) + "%");
        setTEFactor(data['te_factor'] === "N.A." ? data['te_factor'] : (data['te_factor'] * 100).toFixed(2) + "%");
        TagManager.dataLayer({
          dataLayer: {
            event: 'Factor Analytics',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
          },
        });
        setBdOpen(false);
      } else if (data === false) {
        setPopupState(true);
        setBdOpen(false);
        setPopupMessage(
          "Unable to fetch data"
        );
        setAlertType("error");
      } else if (data === 0 || data === undefined) {
        setPopupState(true);
        setBdOpen(false);
        setPopupMessage(
          "We are facing issues connecting our servers, please try again later"
        );
        setAlertType("error");
        setPopupLoginRedirect(false);
        return;
      } else if (data === 401) {
        setPopupState(true);
        setBdOpen(false);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }
    });
  }

  function handleTableTabChange(tabletabvalue) {
    setTableTabValue(tabletabvalue);
  }

  function handleFactorTableTabChange(tabletabvalue) {
    setFactorTabValue(tabletabvalue);
  }

  const headers = ["", "Portfolio", "Benchmark", "Factor"];
  const factor_headers = ["#", "Factor", "Weight", "Category"];

  const formatBetaFactor = (value) => {
    const formattedValue = value.split(',').map((num) => parseFloat(num).toFixed(2)).join(', ');
    setBetaFactor(formattedValue);
  };

  const updatedData = Object.entries(FATable).map(([key, value]) => {
    const updatedValue = Object.entries(value).reduce((acc, [k, v]) => {
      const newKey = k === "imp_vol" ? "Implied Volatility" : k === "hist_vol" ? "Historical Volatility" : k === "symbol" ? "Symbol" : k;
      if (newKey === "Implied Volatility" || newKey === "Historical Volatility") {
        v = v === "N.A." ? "N.A." : (parseFloat(v) * 100).toFixed(2) + "%";
      }
      return { ...acc, [newKey]: v };
    }, {});
    const newValue = { Symbol: value.Symbol || selectedPortfolioName, ...updatedValue }
    return { [key]: newValue };
  });

  const uniqueKeys = Array.from(new Set(updatedData.flatMap(obj => Object.keys(obj[Object.keys(obj)[0]]))));


  const TwoCircles = ({ apart = 22 }) => {
    const svgWidth = 200;
    const circleRadius = 40;

    const circle1X = (svgWidth - apart) / 2;
    const circle2X = (svgWidth - apart) / 2 + apart;

    return (
      <svg width={svgWidth} height="100" xmlns="http://www.w3.org/2000/svg">
        <circle cx={circle1X} cy="45" r={circleRadius} fill="#60034C" stroke="#666666" strokeWidth="5" />
        <circle cx={circle2X} cy="45" r={circleRadius} fill="#60034C" stroke="#666666" strokeWidth="5" />
        <path
          d={`M${circle1X},45
              m-${circleRadius},0
              a${circleRadius},${circleRadius} 0 1,0 ${circleRadius * 2},0
              a${circleRadius},${circleRadius} 0 1,0 -${circleRadius * 2},0
              M${circle2X},45
              m-${circleRadius},0
              a${circleRadius},${circleRadius} 0 1,1 ${circleRadius * 2},0
              a${circleRadius},${circleRadius} 0 1,1 -${circleRadius * 2},0`}
          fill="#ffffff"
          fill-rule="evenodd"
        />
      </svg>
    );
  };


  return (
    <>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Factor Analysis"
        alertType={alertType}
        content={popupMessage}
        loginRedirect={popupLoginRedierct}
      />
      <div className="risk-cont portfolio_page-holder factoranalysis">
        <div className="details_wrap self_assembly">
          <div className="infocard-wrap">
            <div id="selectportfolio" className="infocard">
              <h3>Portfolio</h3>
              <Select className="ai-form-value-bt"
                defaultValue={0}
                onChange={(event) => changePortfolio(event.target.value)}>
                {portfolioName.map((value, i) => (
                  <MenuItem value={value.id}>{value.name}</MenuItem>
                ))}
              </Select>
              <span className="ai-selfassembly-select-arrow"></span>
            </div>
            <div className="infocard">
              <h3>Holdings</h3>
              <p id="portfolioholdings">{selectedPortfolioTotalStocks}</p>
            </div>
            <div className="infocard">
              <h3>Portfolio Value</h3>
              <p id="portfoliovalue">${Number(addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))).toLocaleString("en-US")}</p>
            </div>
          </div>
        </div>
        <div className='factorexposure'>
          <div className="portfolio-table contributio-table factortbl1">
            <div className="table_holder table_head">
              <div className="tabheader">
                <ul className="subnavbar-menu fixed">
                  <li>
                    <a id="factoranalysistab" className={factortabvalue === "Factor Analysis" ? "SUBITEM-selected " : "SUBITEM"} onClick={() => handleFactorTableTabChange('Factor Analysis')}>Factor Analysis </a>
                  </li>
                  <li>
                    <a id="factorvaluetab" className={factortabvalue === "Factor" ? "SUBITEM-selected" : "SUBITEM"} onClick={() => handleFactorTableTabChange('Factor')}>Factor</a>
                  </li>
                </ul> {show && <span onClick={() => setShow(!show)} className="minus">-</span>} {!show && <span onClick={() => setShow(!show)}>+</span>}
              </div>
              {show &&
                <>
                  {factortabvalue === "Factor Analysis" &&
                    <TableContainer id="factoranalysistable" className="table_height">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {headers.map((header, index) => (
                              <TableCell key={index}>{header}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {uniqueKeys.map((key, index) => (
                            <TableRow key={index}>
                              <TableCell>{key}</TableCell>
                              {updatedData.map((obj, i) => {
                                const subObj = obj[Object.keys(obj)[0]];
                                return <TableCell key={i}>{subObj[key]}</TableCell>;
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TableFooter>
                        <TableRow>
                        </TableRow>
                      </TableFooter>
                    </TableContainer>
                  }
                  {factortabvalue === "Factor" &&
                    <TableContainer id="factortable" className="table_height">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {factor_headers.map((header, index) => (
                              <TableCell key={index}>{header}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(FactorTable).map((item, index) => (
                            <TableRow key={item.number}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{item.StockTicker}</TableCell>
                              <TableCell>{(parseFloat(item.Weight) * 100).toFixed(2) + "%"}</TableCell>
                              <TableCell>{item.Description}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TableFooter>
                        <TableRow>
                        </TableRow>
                      </TableFooter>
                    </TableContainer>
                  }
                </>
              }
            </div>
          </div>
          <div className="portriskgrid">
            <h3 onClick={() => setShow1(!show1)}>Benchmark & Factor Analysis {show1 && <span className="minus">-</span>} {!show1 && <span>+</span>}</h3>
            {show1 &&
              <div className='errorgrid'>
                <div id="benchmarkportfolio" className='trackingerror'>
                  <h3>Benchmark Portfolio</h3>
                  <TwoCircles apart={(100 - R2BenchmarkNum)} />
                  <div className='errolist'>
                    <div className='tagspan'>
                      <h4>R-Squared</h4>
                      <span className='tag primary'>{R2Benchmark}</span>
                    </div>
                    <div className='tagspan'>
                      <h4>Tracking Error</h4>
                      <span className='tag secondary'>{TEBenchmark}</span>
                    </div>
                    <div className='tagspan'>
                      <h4>Beta</h4>
                      <span className='tag gray'>{BetaBenchmark}</span>
                    </div>
                  </div>
                </div>
                <div id="factorportfolio" className='trackingerror'>
                  <h3>Factor Portfolio</h3>
                  <TwoCircles apart={100 - R2FactorNum} />
                  <div className='errolist'>
                    <div className='tagspan'>
                      <h4>R-Squared</h4>
                      <span className='tag primary'>{R2Factor}</span>
                    </div>
                    <div className='tagspan'>
                      <h4>Tracking Error</h4>
                      <span className='tag secondary'>{TEFactor}</span>
                    </div>
                    <div className='tagspan'>
                      <h4>Beta</h4>
                      <span className='tag gray'>{BetaFactor}</span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="portfolio-table contributio-table nogap factortbl">
          <ul className="subnavbar-menu fixed">
            <li>
              <a className={tabletabvalue === "Hedgeable Assets" ? "SUBITEM-selected " : "SUBITEM"} onClick={() => handleTableTabChange('Hedgeable Assets')} > Hedgeable Assets </a>
            </li>
            <li>
              <a className={tabletabvalue === "Unhedgeable Assets" ? "SUBITEM-selected" : "SUBITEM"} onClick={() => handleTableTabChange('Unhedgeable Assets')}>Unhedgeable Assets</a>
            </li>
          </ul>
          <div id="portfoliodetailstable" className="table_holder table_head">
            <h3 onClick={() => setShow2(!show2)}>Portfolio Details {show2 && <span className="minus">-</span>} {!show2 && <span>+</span>}</h3>
            {show2 &&
              <TableContainer className="table_height">
                {tabletabvalue === "Hedgeable Assets" &&
                  (<Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Closing Price</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Market Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(HedgeableData).map((item) => (
                        <TableRow key={item.number}>
                          <TableCell>{item.number + 1}</TableCell>
                          <TableCell>{item.Ticker}</TableCell>
                          <TableCell>{item.Name}</TableCell>
                          <TableCell>{item.Quantity}</TableCell>
                          <TableCell>{Number(
                            addZeroes(Number(parseFloat(item.ClosePrice).toFixed(2)))
                          ).toLocaleString("en-US", currObj)}</TableCell>
                          <TableCell>{(item.Weight * 100).toFixed(2) + "%"}</TableCell>
                          <TableCell>{Number(
                            addZeroes(Number(parseFloat(item["Market Value"]).toFixed(2)))
                          ).toLocaleString("en-US", currObj)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>)}
                {tabletabvalue === "Unhedgeable Assets" &&
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    {Object.values(UnhedgeableData).map((item) => (
                      <TableRow key={item.number}>
                        <TableCell>{item.number + 1}</TableCell>
                        <TableCell>{item.Ticker}</TableCell>
                        <TableCell>{item.Quantity}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                }
              </TableContainer>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default withWidth()(FactorAnalysis);
