import React from "react";
import "./AddNewPaymentAccount.scss";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddExternalAccount } from "../../Apis/AddExternalAccount";
import { AddNewWallet } from "../../Apis/AddNewWallet";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Backdrop from "@material-ui/core/Backdrop";
import Popup from "../Popup/Popup";
import close from "../../images/close_w.svg";
import save from "../../images/save_w.svg";
import TagManager from "react-gtm-module";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PageLoader from "../PageLoader/PageLoader";



export default function AddNewPaymentAccount(props) {
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();
    const history = useHistory();
    const formSubmit = () => history.push("/PaymentInstructions");
    const [bdopen, setBdOpen] = React.useState(false);
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [popupTitle, setPopupTitle] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [AccountNickName, setAccountNickName] = React.useState("");
    const [NameonAc, setNameonAc] = React.useState("");
    const [RoutingNumber, setRoutingNumber] = React.useState("");
    const [AccountNumber, setAccountNumber] = React.useState("");
    const [AccountType, setAccountType] = React.useState("");
    const [RoutingNoType, setRoutingNoType] = React.useState("aba");
    const [AddPaymentAcPopup, setAddPaymentAcPopup] = React.useState(false);
    const [Errors, setErrors] = React.useState({ "AccNoError": "", "AccNameError": "", "RoutingNoError": "", "AccTypeError": "", "NameonAc": "" })
    const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
    const toTitleCase = (name) => {
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const addNewPaymentAccount = (e) => {
        e.preventDefault();
        setBdOpen(true);
        if (validateAccForm()) {
            AddExternalAccount(AccountType, AccountNickName, toTitleCase(NameonAc), RoutingNumber, AccountNumber, RoutingNoType).then((data) => {
                if (data !== false && data !== 0 && data !== "INVALID_ROUTING_NO" && data !== 401) {
                    AddNewWallet().then((van_data) => {
                        if (van_data === "" || van_data === "Virtual account created successfully") {
                            setBdOpen(false);
                            setAddPaymentAcPopup(true);
                            TagManager.dataLayer({
                                dataLayer: {
                                  event: 'Payment Account Added',
                                  userType:sessionStorage.getItem("selectedAcType"),
                                  user_id: sessionStorage.getItem("clientId")
                                },
                              });
                        } else if (van_data === false || van_data === 0 || (van_data !== "" && van_data !== "Virtual account created successfully")) {
                            setPopupState(true);
                            setPopupMessage("Unable to create new wallet. Please try again or contact support@adaptive-investments.com.");
                            setPopupTitle("Error while Adding New Account");
                            setAlertType("Error");
                            setBdOpen(false);
                        }
                    });
                }
                else if (data === 0) {
                    setPopupState(true);
                    setPopupMessage("We are having server issues please try again later.");
                    setPopupTitle("Server Issues");
                    setAlertType("Error");
                    setBdOpen(false);
                }
                else if (data === false) {
                    setPopupState(true);
                    setPopupMessage("Unable to add new wallet. Please try again or contact support@adaptive-investments.com.");
                    setPopupTitle("Error while Adding New Account");
                    setAlertType("Error");
                    setBdOpen(false);
                }
                else if (data === "INVALID_ROUTING_NO") {
                    setPopupState(true);
                    setPopupMessage("Routing number not recognized. Please verify and try again or contact support@adaptive-investments.com.")
                    setPopupTitle("Error while Adding New Account");
                    setAlertType("Error");
                    setBdOpen(false);
                }
                else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupTitle("Session Expired");
                    setPopupMessage(
                      "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                  }
                  else if (data === 404) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupTitle("Session Expired");
                    setPopupMessage(
                        "Your session was terminated for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                }
            });
        }
        else {
            setBdOpen(false);
        }
    };

    const handleNewPaymentAccount = () => {
        formSubmit();
        setAddPaymentAcPopup(false);
        props.handleAddAccountClose();
        window.location.reload();
    };

    const validateAccForm = () => {
        let formIsValid = true;
        if (!AccountType) {
            formIsValid = false;
            setErrors(d => ({ ...d, "AccTypeError": "Please select a account Type" }));
        }
        else {
            setErrors(d => ({ ...d, "AccTypeError": "" }));
        }
        if (!AccountNickName) {
            formIsValid = false;
            setErrors(d => ({ ...d, "AccNameError": "Cannot be empty" }));
        } else if (typeof AccountNickName !== "undefined") {
            setErrors(d => ({ ...d, "AccNameError": "" }));
        }
        if (!AccountNumber) {
            formIsValid = false;
            setErrors(d => ({ ...d, "AccNoError": "Cannot be empty" }));
        } else if (typeof AccountNumber !== "undefined") {
            if (!AccountNumber.match(/^[0-9]+$/)) {
                formIsValid = false;
                setErrors(d => ({ ...d, "AccNoError": "Account no only in digits" }));
            } else {
                setErrors(d => ({ ...d, "AccNoError": "" }));
            }
        }
        if (!RoutingNumber) {
            formIsValid = false;
            setErrors(d => ({ ...d, "RoutingNoError": "Cannot be empty" }));
        } else if (typeof RoutingNumber !== "undefined") {
            if (!RoutingNumber.match(/^[0-9]+$/)) {
                formIsValid = false;
                setErrors(d => ({ ...d, "RoutingNoError": "Routing no only in digits" }));
            } else {
                setErrors(d => ({ ...d, "RoutingNoError": "" }));
            }
        }
        if (!NameonAc) {
            formIsValid = false;
            setErrors(d => ({ ...d, "NameonAc": "Cannot be empty" }));
        } else if (typeof NameonAc !== "undefined") {
            setErrors(d => ({ ...d, "NameonAc": "" }));
        }
        return formIsValid
    };

    return (
        <div className="accoutnpage">
            <PageLoader bdopen={bdopen}/>
            <div className="Holder">
                <h3>ADD NEW ACCOUNT</h3>
            </div>
            <Popup
                trigger={popupState}
                setTrigger={setPopupState}
                title={popupTitle}
                content={popupMessage}
                alertType={alertType}
                loginRedirect = {popupLoginRedierct}
            />
            <form className="ai-form-group addpaymentform portfolioform">
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Account Type
                    </label>
                    <select className="ai-form-value" onChange={(e) => setAccountType(e.target.value)}>
                        <option selected disabled>Select account type</option>
                        <option>checking</option>
                        <option>savings</option>
                    </select>
                    <p className="invalidMessage">{Errors["AccTypeError"]}</p>
                </div>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Name on account
                    </label>
                    <input
                        className="ai-form-value"
                        type="text"
                        placeholder="Name on account"
                        onChange={(event) => setNameonAc(event.target.value)}
                    />
                    <p className="invalidMessage">{Errors["NameonAc"]}</p>
                </div>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Account nick name
                    </label>
                    <input
                        className="ai-form-value"
                        type="text"
                        placeholder="Account nick name"
                        onChange={(event) => setAccountNickName(event.target.value)}
                    />
                    <p className="invalidMessage">{Errors["AccNameError"]}</p>
                </div>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Routing Number
                    </label>
                    <input
                        className="ai-form-value"
                        type="text"
                        defaultValue={RoutingNumber}
                        placeholder="Routing Number"
                        onChange={(event) => setRoutingNumber(event.target.value)}
                    />
                    <p className="invalidMessage">{Errors["RoutingNoError"]}</p>
                </div>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Account Number
                    </label>
                    <input
                        className="ai-form-value"
                        type="text"
                        placeholder="Account Number"
                        onChange={(event) => setAccountNumber(event.target.value)}
                    />
                    <p className="invalidMessage">{Errors["AccNoError"]}</p>
                </div>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Routing Number Type
                    </label>
                    <select className="ai-form-value" onChange={(e) => setRoutingNoType(e.target.value)}>
                        <option selected disabled>Select Routing Number type</option>
                        <option value="aba" selected >ABA</option>
                    </select>
                </div>
                <div className="button-div">
                    <button
                        type="cancel"
                        className="ai-btn secondary solid"
                        onClick={props.handleClose}
                    ><img src={close} />
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="ai-btn primary solid"
                        onClick={addNewPaymentAccount}
                    ><img src={save} /> Save
                    </button>
                </div>
            </form>
            {AddPaymentAcPopup && (
                <div>
                    <div className="popup">
                        <div className="popup-object">
                            <div className="confirm-heading">
                                <CheckCircleOutlineRoundedIcon className="icon" />
                                Thank you for providing your new account / payment instructions.
                            </div>
                            <div className="body-message">
                                <p>The next step will be for us to verify your account. When you see two small deposits in your account from Adaptive Capital Management, please return to your payment instructions screen, and enter in those two amounts. This way we can be sure that the account belongs to you, and we therefore keep your account secure. Please complete this within 5 days, or we will have to repeat the process</p>
                            </div>
                            <button
                                className="ai-btn secondary solid"
                                onClick={handleNewPaymentAccount}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
