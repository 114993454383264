import React from "react";
import "./VerifyPaymentAccount.scss";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import close from "../../images/close_w.svg";
import check from "../../images/check_w.svg";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { CompleteExternalAcVerification } from "../../Apis/CompleteExternalAcVerification";
import { InitiateExternalAcVerification } from "../../Apis/InitiateExternalAcVerification";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";

export default function VerifyPaymentAccount(props) {
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();
    const [bdopen, setBdOpen] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [Amount1, setAmount1] = React.useState();
    const [Amount2, setAmount2] = React.useState();
    const [VerifyPaymentAcPopup, setVerifyPaymentAcPopup] = React.useState(false);
    const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
    const [popupState, setPopupState] = React.useState(false);
    const [popupTitle, setPopupTitle] = React.useState("");
    const [alertType, setAlertType] = React.useState("error");

    function CompleteVerification() {
        CompleteExternalAcVerification(props.account_id, Amount1, Amount2).then((data) => {
            if (data["result"] !== '') {
                setBdOpen(false);
                setVerifyPaymentAcPopup(true);
                setPopupMessage("Your external account for fund transfers is successfully verified.");
                TagManager.dataLayer({
                    dataLayer: {
                      event: 'Payment Account Verification Completed',
                      userType:sessionStorage.getItem("selectedAcType"),
                      user_id: sessionStorage.getItem("clientId")
                    },
                  });
                return;
            } else if (data["error"]["code"] !== "") {
                setBdOpen(false);
                setPopupMessage(
                    data["error"]["message"]
                );
                setVerifyPaymentAcPopup(true);
                setPopupLoginRedirect(false);
                return;
            } else if (data === 0 || data === undefined) {
                setBdOpen(false);
                setPopupMessage(
                    "We are facing issues connecting our servers, please try again later"
                );
                setPopupLoginRedirect(false);
                return;
            } else if (data === 401) {
                setBdOpen(false);
                setPopupMessage(
                  "Your session timed out for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
                setPopupTitle("Session Expired");
                return;
              }
        });
    }

    function VerifyAccount(e) {
        e.preventDefault();
        setBdOpen(true);
        try {
            CompleteVerification();
        } catch (error) {
            setBdOpen(false);
            setVerifyPaymentAcPopup(true);
            setPopupMessage(
                "We are facing issues connecting our servers, please try again later"
            );
            return;
        }
    }

    const handleVerifyPaymentAccount = () => {
        setVerifyPaymentAcPopup(false);
        props.handleClose();
    };

    return (
        <div className="accoutnpage">
            <PageLoader bdopen={bdopen}/>
            <div className="Holder">
                <h3>VERIFY ACCOUNT</h3>
            </div>
            {VerifyPaymentAcPopup && (
                <div>
                    <div className="popup">
                        <div className="popup-object">
                            <div className="confirm-heading">
                                <CheckCircleOutlineRoundedIcon className="icon" />
                                Verify New Account
                            </div>
                            <div className="body-message">
                                <p>{popupMessage}</p>
                            </div>
                            <button
                                className="ai-btn secondary solid"
                                onClick={handleVerifyPaymentAccount}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <form className="ai-form-group addpaymentform portfolioform" onSubmit={(event) => VerifyAccount(event)}>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Amount 1
                    </label>
                    <input
                        className="ai-form-value"
                        type="text"
                        placeholder="Enter amount 1"
                        onChange={(event) => setAmount1(event.target.value)}
                    />
                </div>
                <div className="form-container">
                    <label class="form-label-lg" htmlFor="AddNewPaymentAccount" >
                        Amount 2
                    </label>
                    <input
                        className="ai-form-value"
                        type="text"
                        placeholder="Enter amount 2"
                        onChange={(event) => setAmount2(event.target.value)}
                    />
                </div>
                <div className="button-div">
                    <button
                        type="cancel"
                        className="ai-btn secondary solid"
                        onClick={() => props.handleClose("cancel")}
                    ><img src={close} />
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="ai-btn primary solid"
                    ><img src={check} /> Verify
                    </button>
                </div>
            </form>
        </div>
    );
}
