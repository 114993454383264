import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "./confirmPopup.css";

export default function ConfirmPopup(props) {
  const styles = {
    icon: {
      width: 28,
      height: 28,
      marginBottom: "-3px",
      marginRight: "8px",
      color: "#D16002",
    },
  };

  return props.trigger ? (
    <div id="confirmationpopup" className="popup">
      <div className="warning">
        <div className="popup-object">
          <div className="heading">
            <InfoOutlinedIcon className style={styles.icon} /> Warning
          </div>
          <div className="body-message">
            <p> {props.popupMessage} </p>
          </div>
          <div className="button-row">
            <button
              className="ai-btn primary solid"
              onClick={() => props.setTriggerYes(false)}
            >
              {" "}
              Yes{" "}
            </button>
            <button
              className="ai-btn secondary solid"
              onClick={() => props.setTriggerNo(false)}
            >
              {" "}
              No{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
