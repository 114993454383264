import React, {useEffect, useState} from "react";
import "./PortfolioSolutionsNavbar.scss";
import refresh from "../../images/refresh.svg";
import CustomPopup from "../Popup/Popup";
import {useLocation} from "react-router-dom";
import {GetQuoteEngineData} from "../../Apis/GetQuoteEngineData";
import {GetRiskMonitorData} from "../../Apis/GetRiskMonitorData";
import {GetProjectionConeData} from "../../Apis/GetProjectionConeData";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {findClosest} from "../Utilities/ClosestPercent";
import {addZeroes} from "../Utilities/AddZeros";
import {convertDate} from "../Utilities/ConvertDate";
import {oldToNewProtectionPeriodString} from "../Utilities/GetProtectionPeriodValue";
import {GetWalletDetails} from "../../Apis/GetWalletDetails";
import MarketShield from "../MarketShield/MarketShield";
import PortfolioShield from "../PortfolioShield/PortfolioShield";
import PortfolioSolutionsSummary from "../PortfolioSolutionSummary/PortfolioSolutionSummary";
import MarketShieldPlus from "../MarketShieldPlus/MarketShieldPlus";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {FetchTerminalReturn} from "../../Apis/FetchTerminalReturn";
import {FetchAssetAllocationEquivalence} from "../../Apis/FetchAssetAllocationEquivalence";
import ConcentratedShield from "../ConcentratedShield/ConcentratedShield";
import PageLoader from "../PageLoader/PageLoader";
import {PostMultiShield} from "../../Apis/PostMultiShield";
import Switch from '@mui/material/Switch';

function PortfolioSolutionsNavbar(props) {
  const location = useLocation();

  const portfolioID = location.state.portfolioid !== undefined ? parseInt(location.state.portfolioid) : -1;
  const selectedPortfolioTotalValue = location.state.stocksData.total_portfolio_value !== undefined ? Math.round((location.state.stocksData.total_portfolio_value + Number.EPSILON) * 100) / 100 : 0;
  const [assetAllocationEquivalenceData, setAssetAllocationEquivalenceData] = useState(null);
  const [backDropOpen, setBackDropOpen] = useState(true);
  var [protectionPeriod, setProtectionPeriod] = useState(location.state.period !== undefined ? oldToNewProtectionPeriodString(location.state.period)["new_period"] : "Monthly");
  var [defaultLevelType, setDefaultLevelType] = useState(true);
  const [protectionLevel, setProtectionLevel] = useState("90%");
  var [assetAllocation, setAssetAllocation] = useState("0-100");
  const [recalculateFlag, setRecalculateFlag] = useState(false);
  const [tabvalue, setTabValue] = useState("Projected Value");

  // Shield Constants
  const [guaranteeShieldData, setGuaranteeShieldData] = useState({});
  const [multiShieldData, setMultiShieldData] = useState({});
  const [marketShieldFlag, setMarketShieldFlag] = useState(false);
  const [marketShieldPlusFlag, setMarketShieldPlusFlag] = useState(false);
  const [marketShieldConcentratedFlag, setMarketShieldConcentratedFlag] = useState(false);
  const [filteredShieldMenu, setFilteredShieldMenu] = useState([]);
  const [freshMultiShieldFlag, setFreshMultiShieldFlag] = useState(false);

  const [shieldExpires, setShieldExpires] = useState(location.state.shieldExpires !== undefined ? location.state.shieldExpires : "");
  // const [shieldExpirationDate, setShieldExpirationDate] = useState("");
  const [shieldPrice, setShieldPrice] = useState(location.state.shieldPrice !== undefined ? location.state.shieldPrice : 0);
  const [protPortData, setProtPortData] = useState([]);

  const [msSelectedData, setMsSelectedData] = useState({});
  const [mspSelectedData, setMspSelectedData] = useState({});
  const [mscSelectedData, setMscSelectedData] = useState({});
  const [mscSelCon, setMscSelCon] = useState([]);
  const [mscUnselCon, setMscUnselCon] = useState([]);
  const [mscCon, setMscCon] = useState([]);

  // Displaying information constants
  const [showSummary, setShowSummary] = useState(true);
  const [showGuaranteeShield, setShowGuaranteeShield] = useState(false);
  const [showMarketShield, setShowMarketShield] = useState(false);
  const [showMarketShieldPlus, setShowMarketShieldPlus] = useState(false);
  const [showMarkeShieldConcentrated, setShowMarketShieldConcentrated] = useState(false);

  // Timer Related constants
  const [timerDuration, setTimerDuration] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const timerProps = {
    isPlaying: startTimer,
  };
  const [timerOver, setTimerOver] = useState(true);

  // Variables
  let todayDate = new Date();

  // Wallet related constants
  const [walletBalance, setWalletBalance] = useState(0);

  // Terminal return constants
  let [terminalReturnData, setTerminalReturnData] = useState({});

  // Risk monitor data
  const [riskData, setRiskData] = useState({});

  // Projection Cone data
  const [projectionConeData, setProjectionConeData] = useState({});

  // Error constants
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [lackPeriodMsg, setLackPeriodMsg] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

  // Setting mess 

  const protectionPeriods = [
    "Weekly",
    "Biweekly",
    "Triweekly",
    "Monthly",
    "Bimonthly",
    "Quarterly",
    "Half-yerly",
    "Yearly",
  ];

  const protectionPeriodMap = {
    "Weekly": 7,
    "Biweekly": 14,
    "Triweekly": 21,
    "Monthly": 30,
    "Bimonthly": 60,
    "Quarterly": 91,
    "Half-yerly": 182,
    "Yearly": 365,
  };

  const protectionPeriodMapStr = {
    "Weekly": "week",
    "Biweekly": "week_2",
    "Triweekly": "week_3",
    "Monthly": "month",
    "Bimonthly": "month_2",
    "Quarterly": "quarter",
    "Half-yerly": "half_year",
    "Yearly": "year",
  };

  const newToOldMap = {
    "Weekly": "Weekly",
    "Biweekly": "2 Weeks",
    "Triweekly": "3 Weeks",
    "Monthly": "1 Month",
    "Bimonthly": "2 Months",
    "Quarterly": "3 Months",
    "Half-yerly": "6 Months",
    "Yearly": "Year",
  };

  const assetAllocations = [
    "100-0",
    "90-10",
    "80-20",
    "70-30",
    "60-40",
    "50-50",
    "40-60",
    "30-70",
    "20-80",
    "10-90",
    "0-100",
  ];

  const defaultProtectionLevels = [
    "80%",
    "85%",
    "90%",
    "95%",
    "100%",
  ];

  const defaultProtectionLevelsMap = {
    "80%": "0.8",
    "85%": "0.85",
    "90%": "0.9",
    "95%": "0.95",
    "100%": "1.0",
  };

  const allShieldMenu = [
    {
      id: "summary",
      title: "Summary",
    },
    {
      id: "portfolioshield",
      title: "Portfolio Shield",
    },
    {
      id: "marketshield",
      title: "Market Shield",
    },
    {
      id: "marketshieldplus",
      title: "Market Shield Plus",
    },
    {
      id: "marketshieldconcentrated",
      title: "Market Shield Concentrated",
    }
  ];

  // API Calls
  // Fetch Asset Allocation Equivalence 
  useEffect(() => {
    FetchAssetAllocationEquivalence(portfolioID).then((data) => {
      setAssetAllocationEquivalenceData(data);
    });
  }, [portfolioID]);

  // Fetch Guarantee Shield
  useEffect(() => {
    GetQuoteEngineData(portfolioID, protectionPeriodMap[protectionPeriod], [], []).then((data) => {
      if (data === "Not enough data for ticker(s)") {
        setLackPeriodMsg(
          "Insufficient Historical Data."
        );
      } else if (data === "No Option Data Available For Stocks") {
        setLackPeriodMsg(
          "Insufficient Options Data."
        );
      } else if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        setGuaranteeShieldData(data);
      } else if (data === false) {
        setPopupState(true);
        setPopupMessage("We were unable to retrieve the Quote Engine data for this portfolio. Please try again later.");
        setPopupTitle("Error");
        setPopupLoginRedirect(false);
        setAlertType("error");
      } else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are having issues connecting to our servers, please try again later.");
        setPopupLoginRedirect(false);
        setPopupTitle("Error");
        setAlertType("error");
      } else if (data === 401) {
        setPopupState(true);
        setPopupMessage("Your session has timed out, Please sign in again");
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      } else if (data === 404) {
        setPopupState(true);
        setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
    });
  }, [portfolioID]);

  // Fetch Multi Shield 
  useEffect(() => {
    setBackDropOpen(true);
    PostMultiShield(portfolioID, protectionPeriodMap[protectionPeriod], mscUnselCon, mscSelCon).then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        setTimerOver(false);
        setMultiShieldData(data);
      } else if (data === false) {
        setPopupState(true);
        setPopupMessage("We were unable to retrieve the Market Shiekd data for this portfolio. Please try again later.");
        setPopupTitle("Error");
        setPopupLoginRedirect(false);
        setAlertType("error");
        setBackDropOpen(false);
      } else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are having issues connecting to our servers, please try again later.");
        setPopupLoginRedirect(false);
        setPopupTitle("Error");
        setAlertType("error");
        setBackDropOpen(false);
      } else if (data === 401) {
        setPopupState(true);
        setPopupMessage("Your session has timed out, Please sign in again");
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        setBackDropOpen(false);
      } else if (data === 404) {
        setPopupState(true);
        setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        setBackDropOpen(false);
      }
    });
  }, [portfolioID, protectionPeriod]);

  useEffect(() => {
    if (recalculateFlag) {
      setBackDropOpen(true);
      PostMultiShield(portfolioID, protectionPeriodMap[protectionPeriod], mscUnselCon, mscSelCon).then((data) => {
        if (data !== false && data !== 0 && data !== 401 && data !== 404) {
          setTimerOver(false);
          setMultiShieldData(data);
        } else if (data === false) {
          setPopupState(true);
          setPopupMessage("We were unable to retrieve the Market shield data for this portfolio. Please try again later.");
          setPopupTitle("Error");
          setPopupLoginRedirect(false);
          setAlertType("error");
          setBackDropOpen(false);
        } else if (data === 0) {
          setPopupState(true);
          setPopupMessage("We are having issues connecting to our servers, please try again later.");
          setPopupLoginRedirect(false);
          setPopupTitle("Error");
          setAlertType("error");
          setBackDropOpen(false);
        } else if (data === 401) {
          setPopupState(true);
          setPopupMessage("Your session has timed out, Please sign in again");
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          setBackDropOpen(false);
        } else if (data === 404) {
          setPopupState(true);
          setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          setBackDropOpen(false);
        }
      });
    };
  }, [portfolioID, protectionPeriod, recalculateFlag, mscUnselCon]);

  // Fetch Wallet Balance
  useEffect(() => {
    GetWalletDetails().then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        if (data["wallet_value"] === undefined) {
          setWalletBalance(0);
        } else {
          setWalletBalance(data["wallet_value"]);
        }
      } else if (data === false) {
      } else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are having issues connecting to our servers, please try again later.");
        setPopupLoginRedirect(false);
        setPopupTitle("Error");
        setAlertType("error");
      } else if (data === 401) {
        setPopupState(true);
        setPopupMessage("Your session has timed out, Please sign in again");
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      } else if (data === 404) {
        setPopupState(true);
        setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
    });
  }, []);

  // Fetch Terminal Returns
  useEffect(() => {
    if (defaultLevelType && defaultProtectionLevels.includes(protectionLevel)) {
      FetchTerminalReturn(portfolioID, protectionPeriodMap[protectionPeriod], defaultProtectionLevelsMap[protectionLevel]).then((data) => {
        setTerminalReturnData(data);
      });
    } else if (!defaultLevelType && !defaultProtectionLevels.includes(protectionLevel)) {
      FetchTerminalReturn(
        portfolioID,
        protectionPeriodMap[protectionPeriod],
        assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]['ProtLevelFloat']
      ).then((data) => {
        setTerminalReturnData(data);
      });
    }
  }, [defaultLevelType, protectionPeriod, protectionLevel, assetAllocationEquivalenceData]);

  // Risk Monitor Data
  useEffect(() => {
    GetRiskMonitorData().then((data) => {
      setRiskData(data);
    });
  }, []);

  // Risk Monitor Data
  useEffect(() => {
    GetProjectionConeData().then((data) => {
      setProjectionConeData(data);
    });
  }, []);


  // State Update Logic 
  // Protection Level change when asset allocation changes 
  useEffect(() => {
    if (defaultLevelType === true) {
      setProtectionLevel("90%");
    } else if (assetAllocationEquivalenceData && !defaultLevelType === true) {
      setProtectionLevel(assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]['PortLevelPercent'].toString());
    }
  }, [defaultLevelType, protectionPeriod, assetAllocationEquivalenceData, assetAllocation]);

  // Rendering countdown timer
  const renderTime = ({remainingTime}) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return (
      <div className="timer">
        <div className="value">
          {minutes.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:{seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}
        </div>
        <div className="text">Expires In</div>
      </div>
    );
  };

  function onTimerOver() {
    setTimerOver(true);
  }

  const onSubMenuItemClick = (item) => {
    if (item === "Market Shield") {
      setShowSummary(false);
      setShowGuaranteeShield(false);
      setShowMarketShield(true);
      setShowMarketShieldPlus(false);
      setShowMarketShieldConcentrated(false);
    }
    else if (item === "Portfolio Shield") {
      setShowSummary(false);
      setShowGuaranteeShield(true);
      setShowMarketShield(false);
      setShowMarketShieldPlus(false);
      setShowMarketShieldConcentrated(false);
    }
    else if (item === "Summary") {
      setShowSummary(true);
      setShowGuaranteeShield(false);
      setShowMarketShield(false);
      setShowMarketShieldPlus(false);
      setShowMarketShieldConcentrated(false);
    }
    else if (item === "Market Shield Plus") {
      setShowSummary(false);
      setShowGuaranteeShield(false);
      setShowMarketShield(false);
      setShowMarketShieldPlus(true);
      setShowMarketShieldConcentrated(false);
    }
    else if (item === "Market Shield Concentrated") {
      setShowSummary(false);
      setShowGuaranteeShield(false);
      setShowMarketShield(false);
      setShowMarketShieldPlus(false);
      setShowMarketShieldConcentrated(true);
    }
    props.onChange(item);
  };

  useEffect(() => {
    setFreshMultiShieldFlag(true);
    if (Object.keys(multiShieldData).length !== 0) {
      setRecalculateFlag(false);
      setTimerDuration(60);
      setTimerKey((prevKey) => prevKey + 1);
      setStartTimer(true);

      if ('ms' in multiShieldData) {
        setMarketShieldFlag(true);
      } else {
        setMarketShieldFlag(false);
      };

      if ('msp' in multiShieldData) {
        setMarketShieldPlusFlag(true);
      } else {
        setMarketShieldPlusFlag(false);
      };

      if ('msc' in multiShieldData) {
        setMarketShieldConcentratedFlag(true);
      } else {
        setMarketShieldConcentratedFlag(false);
      };

      var port_list = [];
      for (var i = 0; i < multiShieldData["ProtPort"]["ClosePrice"].length; i++) {
        port_list.push({
          id: i,
          symbol: multiShieldData["ProtPort"]['Ticker'][i],
          quantity: multiShieldData["ProtPort"]['Quantity'][i],
          close_price: multiShieldData["ProtPort"]['ClosePrice'][i],
          market_value: multiShieldData["ProtPort"]['MktVal'][i]
        });
      };
      setProtPortData([...port_list]);
    }
  }, [multiShieldData]);

  function refreshSelectedContracts(selectedContracts) {
    var dict = {};
    selectedContracts.forEach(function (key) {
      dict[key] = true;
    });
    return dict;
  }

  function refreshUnSelectedContracts(unselectedContracts) {
    var dict = {};
    if (unselectedContracts !== undefined) {
      unselectedContracts.forEach(function (key) {
        dict[key] = false;
      });
    }
    return dict;
  }



  useEffect(() => {
    setFilteredShieldMenu(
      allShieldMenu.filter((item) => !(
        item.title === "Market Shield Plus" && !marketShieldPlusFlag)
        && !(item.title === "Market Shield Concentrated" && !marketShieldConcentratedFlag)
      ));
  }, [marketShieldPlusFlag, marketShieldConcentratedFlag]);

  const returnSubMenuClassname = (currentTitle) => {
    if (
      (currentTitle === "Summary" && showSummary)
      || (currentTitle === "Portfolio Shield" && showGuaranteeShield)
      || (currentTitle === "Market Shield" && showMarketShield)
      || (currentTitle === "Market Shield Plus" && showMarketShieldPlus)
      || (currentTitle === "Market Shield Concentrated" && showMarkeShieldConcentrated)
    ) {
      return "SUBITEM-selected";
    } else {
      return "SUBITEM";
    }
  };

  useEffect(() => {
    setFreshMultiShieldFlag(false);

    todayDate.setDate(todayDate.getDate() + protectionPeriodMap[protectionPeriod]);
    setShieldExpires(convertDate(todayDate));

    todayDate = todayDate.toISOString().split('T')[0].toString();
    // setShieldExpirationDate(todayDate);
  }, [protectionPeriod]);

  useEffect(() => {
    if (defaultLevelType) {
      var protection_level = defaultProtectionLevelsMap[protectionLevel];
    } else {
      var protection_level = assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]['ProtLevelFloat'];
    };

    if ((Object.keys(guaranteeShieldData).length !== 0)) {
      var closest_prot_level = findClosest(guaranteeShieldData[protectionPeriodMapStr[protectionPeriod]], protection_level * 100);
      var closest_prot_level_str = addZeroes(Number((100 - closest_prot_level).toFixed(2).toString()));

      let shield_price = 0;
      if (Object.keys(guaranteeShieldData[protectionPeriodMapStr[protectionPeriod]]).length !== 0) {
        shield_price = guaranteeShieldData[protectionPeriodMapStr[protectionPeriod]][closest_prot_level_str]["shieldprice"].toFixed(2);
      } else {
        shield_price = -1;
      }
      setShieldPrice(shield_price);
    }
  }, [defaultLevelType, guaranteeShieldData, assetAllocationEquivalenceData, protectionPeriod, protectionLevel, assetAllocation]);

  // To fetch the selected data
  useEffect(() => {
    if (Object.keys(multiShieldData).length !== 0 && freshMultiShieldFlag) {
      if (defaultLevelType && defaultProtectionLevels.includes(protectionLevel)) {
        if ('ms' in multiShieldData) {
          setMsSelectedData(multiShieldData["ms"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][defaultProtectionLevelsMap[protectionLevel]]);
        };

        if ('msp' in multiShieldData) {
          setMspSelectedData(multiShieldData["msp"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][defaultProtectionLevelsMap[protectionLevel]]);
        };

        if ('msc' in multiShieldData) {
          setMscSelectedData(multiShieldData["msc"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][defaultProtectionLevelsMap[protectionLevel]]);
          let selCon = refreshSelectedContracts(multiShieldData["msc"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][defaultProtectionLevelsMap[protectionLevel]]["SecDesc"]);
          let unselCon = refreshUnSelectedContracts(multiShieldData["msc"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][defaultProtectionLevelsMap[protectionLevel]]["UnSelSecDesc"]);
          setMscCon({...selCon, ...unselCon});
        };


      } else {
        let protection_level = Math.round(assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]['ProtLevelFloat'] * 10000) / 10000;
        if ('ms' in multiShieldData) {
          setMsSelectedData(multiShieldData["ms"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][protection_level]);
        };

        if ('msp' in multiShieldData) {
          setMspSelectedData(multiShieldData["msp"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][protection_level]);
        };

        if ('msc' in multiShieldData) {
          setMscSelectedData(multiShieldData["msc"]["choosen_opt"][protectionPeriodMapStr[protectionPeriod]][protection_level]);
        };
      }
      // setBackDropOpen(false);
    }
  }, [multiShieldData, assetAllocationEquivalenceData, assetAllocation, defaultLevelType, protectionLevel, freshMultiShieldFlag]);

  const multiShieldSelectedContrats = (selectedContracts) => {
    let l1 = [];
    let l2 = [];
    for (let [key, value] of Object.entries(selectedContracts)) {
      if (!['XND', 'XSP', 'MRUT'].some(word => key.startsWith(word))) {
        value ? l1.push(key) : l2.push(key);
      }
    }
    setMscSelCon([...l1]);
    setMscUnselCon([...l2]);
    setRecalculateFlag(true);
  };

  useEffect(() => {
    setShowSummary(true);
    setShowGuaranteeShield(false);
    setShowMarketShield(false);
    setShowMarketShieldPlus(false);
    setShowMarketShieldConcentrated(false);
  }, [protectionPeriod]);


  // // Print Statements
  // useEffect(() => {
  //   const now = new Date();
  //   console.log("backDropOpen", backDropOpen, now.getHours(), now.getMinutes(), now.getSeconds());
  // }, [backDropOpen]);

  // useEffect(() => {
  //   if (assetAllocationEquivalenceData) {
  //     console.log("assetAllocationEquivalenceData", assetAllocationEquivalenceData);
  //   }
  // }, [assetAllocationEquivalenceData]);

  // useEffect(() => {
  //   if (portfolioID) {
  //     console.log("portfolioID", portfolioID);
  //   }
  // }, [portfolioID]);

  // useEffect(() => {
  //   if (protectionPeriod) {
  //     console.log("protectionPeriod", protectionPeriod);
  //   }
  // }, [protectionPeriod]);

  // useEffect(() => {
  //   if (protectionLevel) {
  //     console.log("protectionLevel", protectionLevel);
  //   }
  // }, [protectionLevel]);

  // useEffect(() => {
  //   if (assetAllocation) {
  //     console.log("assetAllocation", assetAllocation);
  //   }
  // }, [assetAllocation]);

  useEffect(() => {
    if (Object.keys(multiShieldData).length !== 0) {
      // console.log("multiShieldData", multiShieldData);
      setBackDropOpen(false);
    }
  }, [multiShieldData]);

  // useEffect(() => {
  //   console.log("defaultLevel", defaultLevelType);
  // }, [defaultLevelType]);

  // useEffect(() => {
  //   if (walletBalance) {
  //     console.log("walletBalance", walletBalance);
  //   }
  // }, [walletBalance]);

  // useEffect(() => {
  //   if (terminalReturnData) {
  //     console.log("terminalReturnData", terminalReturnData);
  //   }
  // }, [terminalReturnData]);

  // useEffect(() => {
  //   if (riskData) {
  //     console.log("riskData", riskData);
  //   }
  // }, [riskData]);

  // useEffect(() => {
  //   if (projectionConeData) {
  //     console.log("projectionConeData", projectionConeData);
  //   }
  // }, [projectionConeData]);

  // useEffect(() => {
  //   if (filteredShieldMenu) {
  //     console.log("filteredShieldMenu", filteredShieldMenu);
  //   }
  // }, [filteredShieldMenu]);

  // useEffect(() => {
  //   if (guaranteeShieldData) {
  //     console.log("guaranteeShieldData", guaranteeShieldData);
  //   }
  // }, [guaranteeShieldData]);

  // useEffect(() => {
  //   if (todayDate) {
  //     console.log("todayDate", todayDate);
  //   }
  // }, [todayDate]);

  // useEffect(() => {
  //   if (shieldExpires) {
  //     console.log("shieldExpires", shieldExpires);
  //   }
  // }, [shieldExpires]);

  // useEffect(() => {
  //   if (shieldExpirationDate) {
  //     console.log("shieldExpirationDate", shieldExpirationDate);
  //   }
  // }, [shieldExpirationDate]);

  // useEffect(() => {
  //   console.log("timerDuration", timerDuration);
  // }, [timerDuration]);

  // useEffect(() => {
  //   console.log("startTimer", startTimer);
  // }, [startTimer]);

  // useEffect(() => {
  //   console.log("timerKey", timerKey);
  // }, [timerKey]);

  // useEffect(() => {
  //   console.log("timerProps", timerProps);
  // }, [timerProps]);

  // useEffect(() => {
  //   console.log("marketShieldFlag", marketShieldFlag);
  // }, [marketShieldFlag]);

  // useEffect(() => {
  //   console.log("marketShieldPlusFlag", marketShieldPlusFlag);
  // }, [marketShieldPlusFlag]);

  // useEffect(() => {
  //   console.log("marketShieldConcentratedFlag", marketShieldConcentratedFlag);
  // }, [marketShieldConcentratedFlag]);

  // useEffect(() => {
  //   console.log("shieldExpires", shieldExpires);
  // }, [shieldExpires]);

  // useEffect(() => {
  //   console.log("shieldPrice", shieldPrice);
  // }, [shieldPrice]);

  // useEffect(() => {
  //   console.log("msSelectedData", msSelectedData);
  // }, [msSelectedData]);

  // useEffect(() => {
  //   console.log("freshMultiShieldFlag", freshMultiShieldFlag);
  // }, [freshMultiShieldFlag]);

  // useEffect(() => {
  //   console.log("mscSelCon", mscSelCon);
  // }, [mscSelCon]);

  // useEffect(() => {
  //   console.log("mscUnselCon", mscUnselCon);
  // }, [mscUnselCon]);

  // useEffect(() => {
  //   console.log("timerOver", timerOver);
  // }, [timerOver]);

  // useEffect(() => {
  //   console.log("mscCon", mscCon);
  // }, [mscCon]);


  return (
    <>
      <div className="shieldcompare_page">
        <PageLoader bdopen={backDropOpen} />
        <CustomPopup
          trigger={popupState}
          setTrigger={setPopupState}
          title={popupTitle}
          content={popupMessage}
          alertType={alertType}
          loginRedirect={popupLoginRedierct}
        />
        <div className="page_filter noborder">
          <div className="shield-row top-align">
            <div className="infocard">
              <label className="ai-form-label">Target Duration</label>
              <div id="period" className="det_sel">
                <Select
                  className="ai-form-value-mui"
                  defaultValue={protectionPeriod ? protectionPeriod : " "}
                  onChange={(event) => setProtectionPeriod(event.target.value)}
                >
                  {protectionPeriods.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
                <div className="infotext">{shieldExpires}</div>
              </div>
            </div>
            <div className="infocard" id="protection-level" disabled={!defaultLevelType}>
              <label className="ai-form-label">Target Protection Level</label>
              {defaultLevelType === true && <div>
                <div id="level" className="det_sel">
                  <Select
                    className="ai-form-value-mui"
                    // defaultValue={protectionLevel ? protectionLevel : " "}
                    value={protectionLevel ? protectionLevel : " "}
                    onChange={(event) => setProtectionLevel(event.target.value)}
                  >
                    {defaultProtectionLevels.map((value) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                  <div className="infotext">{protectionLevel} of ${Math.round((defaultProtectionLevelsMap[protectionLevel] * selectedPortfolioTotalValue) * 100) / 100} of<br></br>portfolio value protected</div>
                </div>
              </div>}
            </div>
            <div>
              <Switch defaultChecked checked={defaultLevelType} type='checkbox' onChange={(event) => setDefaultLevelType(event.currentTarget.checked)}/>              
            </div>
            <div className="infocard">
              <label className="ai-form-label">Asset Allocation Equivalent</label>
              {defaultLevelType === false && <div>
                <div id="asset-allocation-equivalence" className="det_sel">
                  <Select
                    className="ai-form-value-mui"
                    value={assetAllocation ? assetAllocation : " "}
                    onChange={(event) => setAssetAllocation(event.target.value)}
                  >
                    {assetAllocations.map((value) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                  <div className="infotext">{protectionLevel}% of ${Math.round((assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]['ProtLevelFloat'] * selectedPortfolioTotalValue) * 100) / 100} of<br></br>portfolio value protected</div>
                </div>
              </div>}
            </div>
          </div>
          <div className="timer_sec">
            <CountdownCircleTimer
              {...timerProps}
              key={timerKey}
              size={80}
              strokeWidth={5}
              duration={timerDuration}
              colors={"#E58B76"}
              onComplete={onTimerOver}
            >
              {renderTime}
            </CountdownCircleTimer>
            <div className="infocard">
              <label className="ai-form-label">
                WALLET BALANCE
              </label>
              <div className="balance">
                <div className="available_balance">
                  ${Number(Number(walletBalance).toFixed(2)).toLocaleString("en-US")}
                </div>
              </div>
            </div>
            <a className="ai-btn primary line" onClick={() => setRecalculateFlag(true)}>
              <img src={refresh}></img>Recalculate Prices
              {renderTime}
            </a>
          </div>
        </div>
        <nav className="csvnavbar portsolution" id="navigation">
          <ul className="csvnavbar-menu">
            {filteredShieldMenu?.map((item) => {
              return (
                <li key={item.id}>
                  <a
                    id={item.id}
                    key={item.title}
                    className={returnSubMenuClassname(item.title)}
                    onClick={() => onSubMenuItemClick(item.title)}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      {showSummary && Object.keys(multiShieldData).length !== 0 && freshMultiShieldFlag && (
        <PortfolioSolutionsSummary
          MSPlusAvailFlag={marketShieldPlusFlag}
          period_value={newToOldMap[protectionPeriod]}
          level_value={defaultLevelType ? parseInt(Number(defaultProtectionLevelsMap[protectionLevel]) * 100) : assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]['PortLevelPercent']}
          shieldExpires={shieldExpires}
          showRefresh={timerOver && walletBalance > 0}
          shieldPrice={shieldPrice}
          market_data={multiShieldData['ms']}
          marketShield={true}
          marketShieldButtonFlag={marketShieldFlag}
          showPurchaseShield={true}
          AgreementID={multiShieldData['ms']['agreement_id']}
          selectedPeriod={protectionPeriodMap[protectionPeriod]}
          contractData={[]}
          agreementContractData={[]}
          LivePriceFlag={true}
          percentPortfolioValue={Math.round((defaultProtectionLevelsMap[protectionLevel] * selectedPortfolioTotalValue) * 100) / 100}
          MSPlusData={marketShieldPlusFlag ? multiShieldData['msp'] : []}
          marketShieldPlus={marketShieldPlusFlag}
          marketShieldPlusButtonFlag={marketShieldPlusFlag}
          showMSPPurchaseShield={true}
          MSPAgreementID={marketShieldPlusFlag ? multiShieldData['msp']["agreement_id"] : ""}
          MSPcontractData={[]}
          mspagreementContractData={[]}
          PeriodLackMsg={""} //TODO: lackMessage changes pending
          MSPlusAvailableContract={""}
          customerInfo={multiShieldData["CustInfo"]}
          ms_chosenIndex={marketShieldFlag ? multiShieldData["ms"]["IndexName"] : ""}
          msp_chosenIndex={marketShieldPlusFlag ? multiShieldData["msp"]["IndexName"] : ""}
          protectedPortfolioData={protPortData}
          MSConcentratedData={marketShieldConcentratedFlag ? multiShieldData["msc"] : []}
          MultiShieldSelectedData={marketShieldConcentratedFlag ? mscSelectedData : []}
          showMultiShield={showMarkeShieldConcentrated}
          MultiDataAvailable={marketShieldConcentratedFlag}
          MarketShieldData={multiShieldData["ms"]}
          MarketShieldSelectedData={msSelectedData}
          MSPlusSelectedData={marketShieldPlusFlag ? mspSelectedData : []}
        />
      )}
      {showGuaranteeShield && Object.keys(guaranteeShieldData).length !== 0 && Object.keys(terminalReturnData).length !== 0 && (
        <PortfolioShield
          period_value={newToOldMap[protectionPeriod]}
          level_value={defaultLevelType ? parseInt(Number(defaultProtectionLevelsMap[protectionLevel]) * 100) : assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]["PortLevelPercent"]}
          risk_dial_data={riskData}
          projection_cone_data={projectionConeData}
          stocksData={location.state.stocksData}
          selectedPeriod={protectionPeriodMap[protectionPeriod]}
          shieldExpires={shieldExpires}
          shieldPrice={shieldPrice}
          percentPortfolioValue={Math.round((defaultProtectionLevelsMap[protectionLevel] * selectedPortfolioTotalValue) * 100) / 100}
          PeriodLackMsg={""}
          customerInfo={multiShieldData["CustInfo"]}
          TerminalData={terminalReturnData}
          tabvalue={tabvalue}
          setTabValue={setTabValue}
        />
      )}
      {showMarketShield && Object.keys(multiShieldData).length !== 0 && freshMultiShieldFlag && Object.keys(terminalReturnData).length !== 0 && (
        <MarketShield
          period_value={newToOldMap[protectionPeriod]}
          level_value={defaultLevelType ? parseInt(Number(defaultProtectionLevelsMap[protectionLevel]) * 100) : assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]["PortLevelPercent"]}
          risk_dial_data={riskData}
          projection_cone_data={projectionConeData}
          market_data={multiShieldData['ms']}
          marketShield={true}
          showPurchaseShield={true}
          AgreementID={multiShieldData['ms']['agreement_id']}
          selectedPeriod={protectionPeriodMap[protectionPeriod]}
          contractData={[]}
          agreementContractData={[]}
          LivePriceFlag={true}
          percentPortfolioValue={Math.round((defaultProtectionLevelsMap[protectionLevel] * selectedPortfolioTotalValue) * 100) / 100}
          marketShieldButtonFlag={marketShieldFlag}
          showRefresh={timerOver}
          custommerInfo={multiShieldData["CustInfo"]}
          ms_chosenIndex={marketShieldFlag ? multiShieldData["ms"]["IndexName"] : ""}
          protectedPortfolioData={protPortData}
          TerminalData={terminalReturnData}
          tabvalue={tabvalue}
          setTabValue={setTabValue}
          MarketShieldData={multiShieldData["ms"]}
          MarketShieldSelectedData={msSelectedData}
          defaultLevelType={defaultLevelType}
        />
      )}
      {showMarketShieldPlus && Object.keys(multiShieldData).length !== 0 && freshMultiShieldFlag && Object.keys(terminalReturnData).length !== 0 && (
        <MarketShieldPlus
          MSPlusAvailFlag={marketShieldPlusFlag}
          period_value={newToOldMap[protectionPeriod]}
          level_value={defaultLevelType ? parseInt(Number(defaultProtectionLevelsMap[protectionLevel]) * 100) : assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]["PortLevelPercent"]}
          risk_dial_data={riskData}
          projection_cone_data={projectionConeData}
          market_data={multiShieldData['msp']}
          marketShield={true}
          showPurchaseShield={true}
          AgreementID={multiShieldData['msp']['agreement_id']}
          selectedPeriod={protectionPeriodMap[protectionPeriod]}
          contractData={[]}
          agreementContractData={[]}
          LivePriceFlag={true}
          percentPortfolioValue={Math.round((defaultProtectionLevelsMap[protectionLevel] * selectedPortfolioTotalValue) * 100) / 100}
          marketShieldButtonFlag={marketShieldPlusFlag}
          showRefresh={timerOver}
          MSPlusAvailableContract={""}
          customerInfo={multiShieldData["CustInfo"]}
          protectedPortfolioData={protPortData}
          msp_chosenIndex={marketShieldPlusFlag ? multiShieldData["msp"]["IndexName"] : ""}
          TerminalData={terminalReturnData}
          tabvalue={tabvalue}
          setTabValue={setTabValue}
          MSPlusData={marketShieldPlusFlag ? multiShieldData['msp'] : []}
          MSPlusSelectedData={marketShieldPlusFlag ? mspSelectedData : []}
        />
      )}
      {showMarkeShieldConcentrated && Object.keys(multiShieldData).length !== 0 && freshMultiShieldFlag && defaultLevelType && Object.keys(terminalReturnData).length !== 0 && (
        <ConcentratedShield
          period_value={newToOldMap[protectionPeriod]}
          level_value={defaultLevelType ? parseInt(Number(defaultProtectionLevelsMap[protectionLevel]) * 100) : assetAllocationEquivalenceData['ProtectionPeriod'][protectionPeriod]['Allocation'][assetAllocation]["PortLevelPercent"]}
          risk_dial_data={riskData}
          projection_cone_data={projectionConeData}
          selectedPeriod={protectionPeriodMap[protectionPeriod]}
          shieldExpires={""}
          shieldPrice={shieldPrice}
          percentPortfolioValue={Math.round((defaultProtectionLevelsMap[protectionLevel] * selectedPortfolioTotalValue) * 100) / 100}
          ms_chosenIndex={marketShieldFlag ? multiShieldData["ms"]["IndexName"] : ""}
          customerInfo={multiShieldData["CustInfo"]}
          TerminalData={terminalReturnData}
          tabvalue={tabvalue}
          setTabValue={setTabValue}
          multi_data={multiShieldData["ms"]}
          contractData={[]}
          MSConcentratedData={marketShieldConcentratedFlag ? multiShieldData["msc"] : []}
          MultiShieldSelectedData={mscSelectedData}
          showMultiShield={showMarkeShieldConcentrated}
          showRefresh={timerOver}
          MultiDataAvailable={marketShieldConcentratedFlag}
          MultiShieldContracts={mscCon}
          MultiShieldSelectedContrats={multiShieldSelectedContrats}
        />
      )}
    </>
  );
}

export default PortfolioSolutionsNavbar;
