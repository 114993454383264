import React from "react";
import { useState } from "react";
import Popup from "../Popup/Popup";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth from "@material-ui/core/withWidth";
import { addZeroes } from "../Utilities/AddZeros";
import { AddPortfolioIDSession } from "../../Apis/AddPortfolioIDSession";
import { FetchActiveShield } from "../../Apis/FetchActiveShield";
import { FetchShieldedPortfolioDetails } from "../../Apis/FetchShieldedPortfolioDetails";
import {GetPeriodValue} from "../Utilities/GetProtectionPeriodValue";
import "./ActiveShields.scss";
import searchicon from "../../images/search.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import download from "../../images/download.svg";
import TagManager from "react-gtm-module";
import Checkbox from "@material-ui/core/Checkbox";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "@material-ui/core/IconButton";
import ActiveShieldPDF from "../../Components/ActiveShields/ActiveShieldPDF";
import PageLoader from "../PageLoader/PageLoader";


function ActiveShields(props) {
    const [Flag, setFlag] = useState(false);
    const [ActiveShieldData, SetActiveShieldData] = useState([]);
    const [bdopen, setBdOpen] = useState(true);
    const history = useHistory();
    var customerInfo = {
      client_name : "",
      client_address:"",
      client_email:"",
      advisor_status:"",
      advisor_firstname:"",
      advisor_lastname:"",
      advisor_email:"",
      advisor_address: ""
    };
    var AgreementID = "";
    var checkoutData = [];
    var contractData = [];
    var selectedPeriod = "";
    var chosenIndex ="";
    var chosenaction = "";
    var portfolioData = [];
    var ShieldData = [];
    const PaymentSuccess = () => history.push({
        pathname: '/PaymentSuccessScreen',
        state: {
            detail: 'marketshield',
            AgreementID: AgreementID,
            checkoutdata: checkoutData,
            period: selectedPeriod,
            customerInfo: customerInfo,
            chosenIndex: chosenIndex,
            portfolioData: portfolioData,
            chosenAction: chosenaction,
            previousPath : 'activeshield',
            agreementContractData: contractData,
            ShieldData: ShieldData
        }
    });

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [portfolioAPICalled, setPortfolioAPICalled] = React.useState(false);
    const [portfolioAPICount, setPortfolioAPICount] = React.useState(0);
    const [activeshieldAPICalled, setActiveShieldAPICalled] = React.useState(false);
    const [activeshieldAPICount, setactiveshieldAPICount] = React.useState(0);
    const [searchKey, setSearchKey] = React.useState("");
    const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
    const [PortfolioName,setPortfolioName] = React.useState("");
    const [sortOrder, setSortOrder] = React.useState("ASC");
    const [sortIcon, setSortIcon] = React.useState("Assets/sort_icon.png");
    const [sortCol, setSortCol] = React.useState("");
    const [filterCol, setFilterCol] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterMinValue, setfilterMinValue] = React.useState(null);
    const [filterMaxValue, setfilterMaxValue] = React.useState(null);
    const [filterStartDate, setfilterStartDate] = React.useState(new Date());
    const [filterEndDate, setfilterEndDate] = React.useState(new Date());
    const [filterFormErrors, setFilterFormErrors] = React.useState({});
    const [unfilteredShieldData, setunfilteredShieldData] = React.useState([]);
    const [filterList, setFilterList] = React.useState([]);
    const [shieldStatus, setShieldStatus] = React.useState({
        active: false,
        Expired: false,
    });
    const PortfolioTable = () => history.push({
        pathname: '/PortfolioTable',
        state: { portfolioid: selectedPortfolioId}
      });
    
      var selectedPortfolioId = 0;
      const GoToPortfolioScreen = (row) => {
        if (row.id !== "") {
            setBdOpen(true);
            sleep(200).then(() => {
              selectedPortfolioId = row.id
              setPortfolioName(row.name)
              PortfolioTable();
            });
          } else {
            setPopupState(true);
            setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
            setAlertType("warning");
          }
      };
    
      React.useEffect(() => {
        window.sessionStorage.setItem("PortfolioName", PortfolioName);
      }, [PortfolioName]);
    

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    async function GetContractData(data) {
        try {
          var first_lyst = [];
          for (var i = 0; i < data['SecDesc'].length; i++) {
            first_lyst.push([
                data['SecDesc'][i],
              data['ConQty'][i],
              Number(
                addZeroes(Number(parseFloat(data['ClosePrice'][i]).toFixed(2)))
              ).toLocaleString(),
              Number(
                addZeroes(Number(parseFloat(data['NotionalVal'][i]).toFixed(2)))
              ).toLocaleString(),
              Number(
                addZeroes(Number(parseFloat(data['ProtLevel'][i]).toFixed(2)))
              ).toLocaleString(),
            ]);
            customerInfo = {
              client_name : data['client_name'][i],
              client_address:data['client_address'][i],
              client_email:data['client_email'][i],
              advisor_status:data['advisor_status'][i],
              advisor_firstname:data['advisor_firstname'][i],
              advisor_lastname:data['advisor_lastname'][i],
              advisor_email:data['advisor_email'][i],
              advisor_address: data['advisor_address'][i]};
          }
        } catch (e) {
          console.log(e.message);
        }
        contractData = [...first_lyst];
      }

    async function getPortfolioData(data){
      var lyst = []
      for (let [key, value] of Object.entries(data)) {
          lyst.push({
            id: key,
            symbol: value.Ticker,
            quantity: value.Quantity,
            close_price: value.ClosePrice,
            market_value : Number((Number(value.ClosePrice)*Number(value.Quantity)).toFixed(2)).toLocaleString("en-US")
          })
        }
        portfolioData = [...lyst];
    }

    const AccountSelected = (selectedValues,action) => {
        if (selectedValues.shield_id !== "") {
            setBdOpen(true);
            FetchShieldedPortfolioDetails(selectedValues.shield_id).then((data) => {
              console.log(" data in activ", data);
                if (data !== false && data !== 0 && data !== 401) {
                    ShieldData = data
                    AgreementID = data['agreement_id'];
                    chosenIndex = data['IndexName'];
                    chosenaction = action;
                    GetContractData(data);
                    getPortfolioData(data['ProtPort']);
                    selectedPeriod = GetPeriodValue(data['ProtPeriod'][0]);
                    checkoutData = data;
                    TagManager.dataLayer({
                        dataLayer: {
                          event: 'Active Shield Summary Viewed',
                          userType:sessionStorage.getItem("selectedAcType"),
                          user_id: sessionStorage.getItem("clientId")
                        },
                      });
                      if(action === 'redirect'){
                        PaymentSuccess();
                      }
                      else if(action === 'download'){
                      sleep(200).then(() =>{
                        const pdf_data = {
                            detail: 'marketshield',
                            AgreementID: AgreementID,
                            checkoutdata: checkoutData,
                            period: selectedPeriod,
                            customerInfo: customerInfo,
                            chosenIndex: chosenIndex,
                            portfolioData: portfolioData,
                            chosenAction: chosenaction,
                            previousPath : 'activeshield',
                            agreementContractData: contractData,
                            initial_total_portfolio_value: portfolioData.map(item => item.market_value).reduce((prev, next) => prev + next)
                        };
                        const DownloadPdf = ActiveShieldPDF(pdf_data);
                        DownloadPdf();
                        setBdOpen(false)
                      })
                    }

                } else if (data === false) {
                    setBdOpen(false);
                    setPortfolioAPICalled(true);
                    setPopupState(true);
                    setPopupMessage(
                        "Unable to retrieve portfolio details. Please try again or contact support@adaptive-investments.com."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    return;
                } else if (data === 0) {
                    setBdOpen(false);
                    setPortfolioAPICalled(true);
                    setPopupState(true);
                    setPopupMessage(
                        "We are facing issues connecting our servers, please try again later"
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    return;
                }
                else if (data === 401) {
                    setBdOpen(false);
                    setPortfolioAPICalled(true);
                    setPopupState(true);
                    setPopupMessage(
                      "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                  }
                
            });
        } else {
            setPopupState(true);
            setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
            setAlertType("warning");
        }
    };

    if (Flag === false) {
        if (activeshieldAPICalled === false && activeshieldAPICount < 1) {
            setactiveshieldAPICount(1);
            try {
                FetchActiveShield().then((data) => {
                    setActiveShieldAPICalled(true);
                    var lyst = [];
                    if (data !== false && data !== 0 && data !== undefined && data !== 401 && data !== 404) {
                        for (let [key, value] of Object.entries(data)) {
                            lyst.push({
                                id: value.portfolioid,
                                name: value.portfolioname,
                                original_value: parseFloat(value.original_value).toFixed(2),
                                shieldstatus: value.shieldstatus,
                                no_stocks: value.nooflineitems,
                                protection_period: value.shield_start_date.split(" ")[0] + " - " + value.shieldexpirationdate,
                                prot_level: value.protectionlevel,
                                prot_value: parseFloat(value.protectionvalue).toFixed(2),
                                current_value: parseFloat(value.totalportfoliovalue).toFixed(2),
                                status: value.shieldstatus,
                                shield_id: value.shield_id
                            });
                            SetActiveShieldData([...lyst]);
                            setunfilteredShieldData([...lyst]);
                        }
                        setBdOpen(false);
                    } else if (data === false) {
                        setBdOpen(false);
                        setActiveShieldAPICalled(true);
                        setPopupState(true);
                        setPopupMessage(
                            "We are facing issues connecting our servers, please try again later"
                        );
                        setAlertType("error");
                    } else if (data === 0 || data === undefined) {
                        setBdOpen(false);
                        setActiveShieldAPICalled(true);
                        setPopupState(true);
                        setPopupMessage(
                            "We are facing issues connecting our servers, please try again later"
                        );
                        setAlertType("error");
                        return;
                    }
                    else if (data === 401) {
                        setBdOpen(false);
                        setActiveShieldAPICalled(true);
                        setPopupState(true);
                        setPopupMessage(
                          "Your session timed out for security reasons. Please sign-in again."
                        );
                        setAlertType("error");
                        setPopupLoginRedirect(true);
                        return;
                      }else if (data === 404) {
                        setBdOpen(false);
                        setActiveShieldAPICalled(true);
                        setPopupState(true);
                        setPopupMessage(
                          "Your session was terminated for security reasons. Please sign-in again."
                        );
                        setAlertType("error");
                        setPopupLoginRedirect(true);
                        return;
                      }
                });
            } catch (error) {
                setBdOpen(false);
                setActiveShieldAPICalled(true);
                setPopupState(true);
                setPopupMessage(
                    "We are facing issues connecting our servers, please try again later"
                );
                setAlertType("error");
                return;
            }
        }
        if (activeshieldAPICalled === true) {
            setFlag(true);
            setBdOpen(false);
        }
    }

    function onClickRedirect(row){
        if(row.status === "active"){
            return <TableCell><a onClick={() => AccountSelected(row,'redirect')}>{row.name}</a></TableCell>
        }else if (row.status === "Expired"){
            return <TableCell><a onClick={() => GoToPortfolioScreen(row)}>{row.name}</a></TableCell>
        }
    }

    function showDownloadButton(row){
      if(row.status === "active"){
          return <TableCell><button className="ai-btn plain"><a onClick={() => AccountSelected(row,'download')}><img src={download}></img></a></button></TableCell>
      }else if (row.status === "Expired"){
          return <TableCell></TableCell>
      }
  }
    const requestSort = (col) => {
        let sortCol = col;
        if (sortOrder === "ASC") {
          const sortedShieldData = [...ActiveShieldData];
          if (
            [
              "name",
              "status",
              "protection_period",
            ].indexOf(sortCol) >= 0
          ) {
            sortedShieldData.sort((a, b) =>
              a[sortCol].toLowerCase() > b[sortCol].toLowerCase() ? 1 : -1
            );
          } else {
            sortedShieldData.sort((a, b) =>
              Number(parseFloat(a[sortCol]).toFixed(2)) >
                Number(parseFloat(b[sortCol]).toFixed(2))
                ? 1
                : -1
            );
          }
          setSortCol(sortCol);
          setSortIcon("Assets/sort_asc_icon.png");
          SetActiveShieldData(sortedShieldData);
          setSortOrder("DESC");
        }
        if (sortOrder === "DESC") {
          const sortedShieldData = [...ActiveShieldData];
          if (
            [
              "name",
              "status",
              "protection_period",
            ].indexOf(sortCol) >= 0
          ) {
            sortedShieldData.sort((a, b) =>
              a[sortCol].toLowerCase() < b[sortCol].toLowerCase() ? 1 : -1
            );
          } else {
            sortedShieldData.sort((a, b) =>
              Number(parseFloat(a[sortCol]).toFixed(2)) <
                Number(parseFloat(b[sortCol]).toFixed(2))
                ? 1
                : -1
            );
          }
          setSortCol(sortCol);
          setSortIcon("Assets/sort_desc_icon.png");
          SetActiveShieldData(sortedShieldData);
          setSortOrder("ASC");
        }
      };

    const open = Boolean(anchorEl);
    const handleClick = (event, col) => {
        setAnchorEl(event.currentTarget);
        setFilterCol(col);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setFilterCol(null);
        setfilterMinValue(null);
        setfilterMaxValue(null);
        setfilterStartDate(new Date());
        setfilterEndDate(new Date());
        setFilterFormErrors({});
        setShieldStatus({ active: false, Expired: false });
    };

    const validateValueFilter = () => {
        let validFilterFlag = true;
        let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
        let error = {};
        if (!filterMinValue) {
          validFilterFlag = false;
          error.minfilter = "Please enter min value";
        }
        if (!filterMaxValue) {
          validFilterFlag = false;
          error.maxfilter = "Please enter max value";
        }
        if (
          Number(parseFloat(filterMaxValue).toFixed(2)) <
          Number(parseFloat(filterMinValue).toFixed(2))
        ) {
          error.maxfilter = "Please enter max value greater than min value";
          validFilterFlag = false;
        }
        if (!pattern.test(filterMinValue)) {
          error.minfilter = "Please enter valid input";
          validFilterFlag = false;
        }
        if (!pattern.test(filterMaxValue)) {
          error.maxfilter = "Please enter valid input";
          validFilterFlag = false;
        }
        setFilterFormErrors(error);
        return validFilterFlag;
    };

    const validateDateFilter = () => {
        let validFilterFlag = true;
        let sdate = filterStartDate.toISOString().split("T")[0].toString();
        let edate = filterEndDate.toISOString().split("T")[0].toString();
        let error = {};
        if (!sdate) {
          validFilterFlag = false;
          error.filterStartDate = "Please enter a start date to apply filter";
        }
        if (!edate) {
          validFilterFlag = false;
          error.filterStartDate = "Please enter a end date to appy filter";
        }
        if (sdate > edate) {
          validFilterFlag = false;
          error.filterStartDate =
            "Start date can not be greater than end date to apply filter";
        }
        setFilterFormErrors(error);
        return validFilterFlag;
    };

    const validateStatusFilter = () => {
        let selectedFlag = true;
        let error = {};
        if (!(shieldStatus.active || shieldStatus.Expired)) {
          error.filterStatusCheckbox = "Please select atleast one shield status";
          selectedFlag = false;
        }
        setFilterFormErrors(error);
        return selectedFlag;
    };

    const requestFilter = (e) => {
        e.preventDefault();
        if (filterCol === "original_value" && validateValueFilter()) {
          const filteredActiveShieldData = ActiveShieldData.filter(
            (data) =>
              Number(parseFloat(data[filterCol]).toFixed(2)) >=
                Number(parseFloat(filterMinValue).toFixed(2)) &&
              Number(parseFloat(data[filterCol]).toFixed(2)) <=
              Number(parseFloat(filterMaxValue).toFixed(2))
          );
          SetActiveShieldData(filteredActiveShieldData);
          filterList.push({
            filterno: filterList.length,
            column: filterCol,
            minvalue: filterMinValue,
            maxvalue: filterMaxValue,
            startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
            enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
            status: shieldStatus,
          });
          handleClose();
        } else if (filterCol === "status" && validateStatusFilter()) {
          let statuslyst = [];
          for (const [key, value] of Object.entries(shieldStatus)) {
            if (value === true) {
              statuslyst.push(key);
            }
          }
          const filteredActiveShieldData = ActiveShieldData.filter((data) =>
            statuslyst.includes(data[filterCol])
          );
          SetActiveShieldData(filteredActiveShieldData);
          filterList.push({
            filterno: filterList.length,
            column: filterCol,
            minvalue: filterMinValue,
            maxvalue: filterMaxValue,
            startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
            enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
            status: shieldStatus,
          });
          handleClose();
        } else if (filterCol === "protection_period" && validateDateFilter()) {
          let fsdate = filterStartDate.toISOString().split("T")[0].toString();
          let fedate = filterEndDate.toISOString().split("T")[0].toString();
    
          const filteredActiveShieldData = ActiveShieldData.filter(
            (data) => data[filterCol].split(" ")[0].toString() >= fsdate && data[filterCol].split(" ")[0].toString() <= fedate
          );
    
          SetActiveShieldData(filteredActiveShieldData);
          filterList.push({
            filterno: filterList.length,
            column: filterCol,
            minvalue: filterMinValue,
            maxvalue: filterMaxValue,
            startdate: fsdate,
            enddate: fedate,
            status: shieldStatus,
          });
          handleClose();
        }
        else if (filterCol === "current_value" && validateValueFilter()) {
            const filteredActiveShieldData = ActiveShieldData.filter(
              (data) =>
                Number(parseFloat(data[filterCol]).toFixed(2)) >=
                  Number(parseFloat(filterMinValue).toFixed(2)) &&
                Number(parseFloat(data[filterCol]).toFixed(2)) <=
                Number(parseFloat(filterMaxValue).toFixed(2))
            );
            SetActiveShieldData(filteredActiveShieldData);
            filterList.push({
              filterno: filterList.length,
              column: filterCol,
              minvalue: filterMinValue,
              maxvalue: filterMaxValue,
              startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
              enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
              status: shieldStatus,
            });
            handleClose();
        }
        else if (filterCol === "prot_level" && validateValueFilter()) {
            const filteredActiveShieldData = ActiveShieldData.filter(
              (data) =>
                Number(parseFloat(data[filterCol]).toFixed(2)) >=
                  Number(parseFloat(filterMinValue).toFixed(2)) &&
                Number(parseFloat(data[filterCol]).toFixed(2)) <=
                Number(parseFloat(filterMaxValue).toFixed(2))
            );
            SetActiveShieldData(filteredActiveShieldData);
            filterList.push({
              filterno: filterList.length,
              column: filterCol,
              minvalue: filterMinValue,
              maxvalue: filterMaxValue,
              startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
              enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
              status: shieldStatus,
            });
            handleClose();
        }
        else if (filterCol === "prot_value" && validateValueFilter()) {
            const filteredActiveShieldData = ActiveShieldData.filter(
              (data) =>
                Number(parseFloat(data[filterCol]).toFixed(2)) >=
                  Number(parseFloat(filterMinValue).toFixed(2)) &&
                Number(parseFloat(data[filterCol]).toFixed(2)) <=
                Number(parseFloat(filterMaxValue).toFixed(2))
            );
            SetActiveShieldData(filteredActiveShieldData);
            filterList.push({
              filterno: filterList.length,
              column: filterCol,
              minvalue: filterMinValue,
              maxvalue: filterMaxValue,
              startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
              enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
              status: shieldStatus,
            });
            handleClose();
        }
    };
    
    const removeFilter = (data) => {
        let lyst = [...unfilteredShieldData];
        var removefilterIndex = filterList
          .map(function (item) {
            return item.filterno;
          })
          .indexOf(data.filterno);
        filterList.splice(removefilterIndex, 1);
        setFilterList([...filterList]);
        if (filterList.length !== 0) {
          for (let [key, value] of Object.entries(filterList)) {
            if (value.column === "original_value") {
              lyst = lyst.filter(
                (data) =>
                  Number(parseFloat(data[value.column]).toFixed(2)) >=
                  Number(parseFloat(value.minvalue).toFixed(2)) &&
                  Number(parseFloat(data[value.column]).toFixed(2)) <=
                  Number(parseFloat(value.maxvalue).toFixed(2))
              );
              SetActiveShieldData([...lyst]);
            } else if (value.column === "protection_period") {
              lyst = lyst.filter(
                (data) =>
                  data[value.column] >= value.startdate.split(" ") &&
                  data[value.column] <= value.enddate.split(" ")
              );
              SetActiveShieldData([...lyst]);
            } else if (value.column === "status") {
              let statuslyst = [];
              for (const [k, v] of Object.entries(value.status)) {
                if (v === true) {
                    statuslyst.push(k);
                }
              }
              lyst = lyst.filter((data) => statuslyst.includes(data[value.column]));
              SetActiveShieldData([...lyst]);
            } else if (value.column === "current_value") {
                lyst = lyst.filter(
                  (data) =>
                    Number(parseFloat(data[value.column]).toFixed(2)) >=
                    Number(parseFloat(value.minvalue).toFixed(2)) &&
                    Number(parseFloat(data[value.column]).toFixed(2)) <=
                    Number(parseFloat(value.maxvalue).toFixed(2))
                );
                SetActiveShieldData([...lyst]);
              } else if (value.column === "prot_value") {
                lyst = lyst.filter(
                  (data) =>
                    Number(parseFloat(data[value.column]).toFixed(2)) >=
                    Number(parseFloat(value.minvalue).toFixed(2)) &&
                    Number(parseFloat(data[value.column]).toFixed(2)) <=
                    Number(parseFloat(value.maxvalue).toFixed(2))
                );
                SetActiveShieldData([...lyst]);
              } else if (value.column === "prot_level") {
                lyst = lyst.filter(
                  (data) =>
                    Number(parseFloat(data[value.column]).toFixed(2)) >=
                    Number(parseFloat(value.minvalue).toFixed(2)) &&
                    Number(parseFloat(data[value.column]).toFixed(2)) <=
                    Number(parseFloat(value.maxvalue).toFixed(2))
                );
                SetActiveShieldData([...lyst]);
              } 
          }
        } else {
            SetActiveShieldData([...unfilteredShieldData]);
        }
      };

    const handleShieldStausChange = (e) => {
        const value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
          setShieldStatus({ ...shieldStatus, [e.target.name]: value });
    };

    const headers = [
        {
          tableHeader: "PORTFOLIO",
          sort_icon: (
            <IconButton onClick={() => requestSort("name")}>
              <img
                src={sortCol === "name" ? sortIcon : "Assets/sort_icon.png"}
                alt=""
              />
            </IconButton>
          ),
          filter_icon: "",
        },
        {
          tableHeader: "STOCKS",
          sort_icon: "",
          filter_icon: "",
        },
        {
          tableHeader: "ORIGINAL VALUE",
          sort_icon: (
            <IconButton onClick={() => requestSort("original_value")}>
              <img
                src={
                  sortCol === "original_value"
                    ? sortIcon
                    : "Assets/sort_icon.png"
                }
                alt=""
              />
            </IconButton>
          ),
          filter_icon: (
            <IconButton onClick={(e) => handleClick(e, "original_value")}>
              <img src="Assets/filter_icon.png" alt="" />
            </IconButton>
          ),
        },
        {
          tableHeader: "PROTECTION PERIOD",
          sort_icon: (
            <IconButton onClick={() => requestSort("protection_period")}>
              <img
                src={
                  sortCol === "protection_period"
                    ? sortIcon
                    : "Assets/sort_icon.png"
                }
                alt=""
              />
            </IconButton>
          ),
          filter_icon: (
            <IconButton onClick={(e) => handleClick(e, "protection_period")}>
              <img src="Assets/filter_icon.png" alt="" />
            </IconButton>
          ),
        },
        {
            tableHeader: "PROTECTED LEVEL ",
            sort_icon: (
              <IconButton onClick={() => requestSort("prot_level")}>
                <img
                  src={
                    sortCol === "prot_level"
                      ? sortIcon
                      : "Assets/sort_icon.png"
                  }
                  alt=""
                />
              </IconButton>
            ),
            filter_icon: (
              <IconButton onClick={(e) => handleClick(e, "prot_level")}>
                <img src="Assets/filter_icon.png" alt="" />
              </IconButton>
            ),
        },
        {
            tableHeader: "PROTECTED VALUE ",
            sort_icon: (
              <IconButton onClick={() => requestSort("prot_value")}>
                <img
                  src={
                    sortCol === "prot_value"
                      ? sortIcon
                      : "Assets/sort_icon.png"
                  }
                  alt=""
                />
              </IconButton>
            ),
            filter_icon: (
              <IconButton onClick={(e) => handleClick(e, "prot_value")}>
                <img src="Assets/filter_icon.png" alt="" />
              </IconButton>
            ),
        },
        {
            tableHeader: "CURRENT VALUE",
            sort_icon: (
              <IconButton onClick={() => requestSort("current_value")}>
                <img
                  src={
                    sortCol === "current_value"
                      ? sortIcon
                      : "Assets/sort_icon.png"
                  }
                  alt=""
                />
              </IconButton>
            ),
            filter_icon: (
              <IconButton onClick={(e) => handleClick(e, "current_value")}>
                <img src="Assets/filter_icon.png" alt="" />
              </IconButton>
            ),
        },
        {
            tableHeader: "STATUS",
            sort_icon: (
              <IconButton onClick={() => requestSort("status")}>
                <img
                  src={
                    sortCol === "status"
                      ? sortIcon
                      : "Assets/sort_icon.png"
                  }
                  alt=""
                />
              </IconButton>
            ),
            filter_icon: (
              <IconButton onClick={(e) => handleClick(e, "status")}>
                <img src="Assets/filter_icon.png" alt="" />
              </IconButton>
            ),
        },
    ];
    
    function chip_data(data) {
        if (data.column === "original_value") {
          let label = "Original Value $" + data.minvalue + " - $" + data.maxvalue;
          return (
            <Chip
              className="chip"
              label={label}
              variant="outlined"
              sx={{ color: "#E58B76" }}
              deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
              onDelete={() => removeFilter(data)}
            />
          );
        } else if (data.column === "protection_period") {
          let label = "Date Between : " + data.startdate + " - " + data.enddate;
          return (
            <Chip
              className="chip"
              label={label}
              variant="outlined"
              sx={{ color: "#E58B76" }}
              deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
              onDelete={() => removeFilter(data)}
            />
          );
        } else if (data.column === "status") {
          let label = "Status ";
          for (const [k, v] of Object.entries(data.status)) {
            if (v === true) {
              label = label + " - " + k;
            }
          }
          return (
            <Chip
              className="chip"
              label={label}
              variant="outlined"
              sx={{ color: "#E58B76" }}
              deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
              onDelete={() => removeFilter(data)}
            />
          );
        } else if (data.column === "current_value") {
            let label = "Current Value $" + data.minvalue + " - $" + data.maxvalue;
            return (
              <Chip
                className="chip"
                label={label}
                variant="outlined"
                sx={{ color: "#E58B76" }}
                deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
                onDelete={() => removeFilter(data)}
              />
            );
        } else if (data.column === "prot_level") {
            let label = "Protected Level $" + data.minvalue + " - $" + data.maxvalue;
            return (
              <Chip
                className="chip"
                label={label}
                variant="outlined"
                sx={{ color: "#E58B76" }}
                deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
                onDelete={() => removeFilter(data)}
              />
            );
        } else if (data.column === "prot_value") {
            let label = "Protected Value $" + data.minvalue + " - $" + data.maxvalue;
            return (
              <Chip
                className="chip"
                label={label}
                variant="outlined"
                sx={{ color: "#E58B76" }}
                deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
                onDelete={() => removeFilter(data)}
              />
            );
          }
      }

    return (
        <div>
            <PageLoader bdopen={bdopen}/>
            <Popup
                trigger={popupState}
                setTrigger={setPopupState}
                title="Portfolio Summary"
                content={popupMessage}
                alertType={alertType}
                loginRedirect = {popupLoginRedierct}
            />
            <div className="search_bar">
                <div className="searchinput">
                    <img src={searchicon}></img>
                    <input
                        className="ai-form-value"
                        type="text"
                        placeholder="Search"
                        onChange={(event) => {
                            setSearchKey(event.target.value);
                        }}
                    />
                </div>
                <button className="ai-btn primary solid">Export</button>
            </div>
            <Paper className="filter-paper">
                {filterList.map((data) => chip_data(data))}
            </Paper>
            <div className="table_holder">
                <TableContainer className="table_height">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {headers.map((header) => (
                                <TableCell>
                                    <div className="stck_table_heading">
                                    {header.tableHeader}
                                    {header.sort_icon}
                                    {header.filter_icon}
                                    </div>
                                </TableCell>
                                ))}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ActiveShieldData.filter((val) => {
                                if (searchKey === "") {
                                    return val;
                                } else if (
                                    val.name.toLowerCase().includes(searchKey.toLowerCase())
                                ) {
                                    return val;
                                }
                            }).map((row) => (
                                <TableRow>
                                    {onClickRedirect(row)}
                                    <TableCell>{row.no_stocks}</TableCell>
                                    <TableCell>{Number(
                                        addZeroes(Number(parseFloat(row.original_value).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}
                                    </TableCell>
                                    <TableCell>{row.protection_period}</TableCell>
                                    <TableCell>{row.prot_level} %</TableCell>
                                    <TableCell>{Number(
                                        addZeroes(Number(parseFloat(row.prot_value).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}
                                    </TableCell>
                                    <TableCell>{Number(
                                        addZeroes(Number(parseFloat(row.current_value).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}
                                    </TableCell>
                                    <TableCell className={row.status=== "active" ? "c-green":"c-error"}>{row.status.toUpperCase()}</TableCell>
                                    {showDownloadButton(row)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {filterCol === "protection_period" && (
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <div className="ai-form-group p16">
                        <DatePicker
                            className="ai-form-value"
                            placeholder="Start Date"
                            selected={filterStartDate}
                            onChange={(date) => setfilterStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                        />
                        <p className="error-message">
                            {filterFormErrors.filterStartDate}
                        </p>
                        <p className="filter-label">To</p>
                        <DatePicker
                            className="ai-form-value"
                            placeholder="End Date"
                            selected={filterEndDate}
                            onChange={(date) => setfilterEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                        />
                        <p className="error-message">{filterFormErrors.filterEndDate}</p>
                        <div className="button-div">
                            <button
                            type="Submit"
                            className="ai-btn primary small solid"
                            onClick={requestFilter}
                            >
                            Apply
                            </button>
                        </div>
                        </div>
                    </Popover>
                )}
                {(filterCol === "original_value" || filterCol ==="current_value" || filterCol ==="prot_level" || filterCol ==="prot_value") && (
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <div className="ai-form-group p16">
                        <input
                            type="text"
                            className="ai-form-value"
                            placeholder="Min Value"
                            onChange={(event) => setfilterMinValue(event.target.value)}
                        />
                        <p className="error-message">{filterFormErrors.minfilter}</p>
                        <p className="filter-label">To</p>
                        <input
                            type="text"
                            className="filter-inputs-max"
                            placeholder="Max Value"
                            onChange={(event) => setfilterMaxValue(event.target.value)}
                        />
                        <br />
                        <p className="error-message">{filterFormErrors.maxfilter}</p>
                        <button
                            type="Submit"
                            className="ai-btn primary small solid"
                            onClick={requestFilter}
                        >
                            Apply
                        </button>
                        </div>
                    </Popover>
                )}
                {filterCol === "status" && (
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <div className="ai-form-group">
                        <label className="check-box-labels low">
                            <Checkbox
                            className="check-box"
                            checked={shieldStatus.active}
                            onChange={handleShieldStausChange}
                            name="active"
                            />{" "}
                            ACTIVE
                        </label>
                        <label className="check-box-labels high">
                            <Checkbox
                            type="checkbox"
                            className="check-box"
                            checked={shieldStatus.Expired}
                            onChange={handleShieldStausChange}
                            name="Expired"
                            />{" "}
                            EXPIRED
                        </label>
                        <p className="error-message">
                            {filterFormErrors.filterStatusCheckbox}
                        </p>
                        <button
                            type="Submit"
                            className="ai-btn primary small solid"
                            onClick={requestFilter}
                        >
                            Apply
                        </button>
                        </div>
                    </Popover>
                )}
            </div>
        </div>
    );
}

export default withWidth()(ActiveShields);
