import React from "react";
import "./ProductCard.css";
import { useHistory } from "react-router-dom";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Tooltip from "react-tooltip-lite";

function ProductCard(props) {
  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["product_card product_card"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["product_card product_card"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["product_card product_card"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["product_card product_card"];
    }
  }
  const rendering = getRendering(props.width);
  const test = <h1>Test Message</h1>;
  const history = useHistory();
  const executeRoute = () => history.push(props.routeTo);
  return (
    <Tooltip hoverDelay="2000" content={props.tooltip}>
      <a onClick={executeRoute} className={rendering[0] + "-" + props.colorTo}>
        
          <div className="card_title">
            <p className="title_text">{props.title}</p>
            <img className="product_card_img" src={props.imgSrc}></img>
          </div>
      </a>
    </Tooltip>
  );
}
export default withWidth()(ProductCard);
