import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function UpdateCsvToDb() {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    var raw = JSON.stringify({
        session_id: Token
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        headers: header,
        redirect: "follow",
    };

    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_CUSTOMER_CSV_TO_DB,
                requestOptions
            );
            const data = await response.json();
            if (data["error"] === "") {
                console.log("data backend:", data);
                return true
            } else if (data["error"] !== "") {
                if (data["error"] === "Token Verification Failed") {
                    return 404
                } else {
                    return 0
                }
            }
            else {
                return false;
            }
        } catch (error) {
            if (error.name === "AbortError") {
                //alert("The request timed out, Please try again");
                window.location.reload();
            }
            else {
                return 0
            }
        }
    }
    else {
        return 401
    }
}
