import React from "react";
import ProductCard from "../../Components/ProductCard/ProductCard";
import "./ServicesScreen.scss";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Navbar from "../../Components/Navbar/Navbar_official";

function ServicesScreen(props) {
  const [HasPortfolios, setHasPortfolios] = React.useState("/PortfolioSummaryScreen");

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["product_buttons"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["product_buttons"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["product_buttons"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["product_buttons"];
    }
  }

  const rendering = getRendering(props.width);

  const buttons = [
    {
      title: "SHIELD",
      routeTo: HasPortfolios,
      imgSrc: "Assets/product-shield.png",
      colorTo: "purple",
      tooltip: "One Click Downside Protection",
    },
    {
      title: "INCOME",
      routeTo: "/ProductScreen",
      imgSrc: "Assets/product-income.png",
      colorTo: "orange",
      tooltip: "Future product: Income Generation",
    },
    {
      title: "BLACK SWAN",
      routeTo: "/ProductScreen",
      imgSrc: "Assets/product-swan.png",
      colorTo: "black",
      tooltip: "Future product: catastophe protection",
    },
    {
      title: "Crypto SHIELD",
      routeTo: "/ProductScreen",
      imgSrc: "Assets/bitcoin.png",
      colorTo: "peach",
      tooltip:
        "Future product: one click downside protection for Crypto holdings",
    },
  ];
  return (
    <div className="ai-content-part a-dashbord">
      <Navbar />
      <div className={rendering[0]}>
        {buttons.map((button, i) => (
          <ProductCard
            title={button.title}
            routeTo={button.routeTo}
            key={button.title}
            imgSrc={button.imgSrc}
            colorTo={button.colorTo}
            tooltip={button.tooltip}
          />
        ))}
      </div>
    </div>
  );
}
export default withWidth()(ServicesScreen);
