import * as AWS from "aws-sdk/global";

export async function Establish_advisor_session(result) {
  AWS.config.region = "us-east-1";

  const arn =
    "cognito-idp.us-east-1.amazonaws.com/" +
    process.env.REACT_APP_COGNITO_ADVISOR_POOL_ID;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_ADVISOR_IDENTITY_POOL_ID,
    Logins: {
      [arn]: result.getIdToken().getJwtToken(),
    },
  });
  AWS.config.credentials.clearCachedId()
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_ADVISOR_IDENTITY_POOL_ID,
    Logins: {
      [arn]: result.getIdToken().getJwtToken(),
    },
  });

  AWS.config.credentials.refresh((error) => {
    if (error) {
      console.error(error);
    } else {
      console.log("Advisor Successfully logged!");
    }
  });
}
