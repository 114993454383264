import React from "react";
import "./PortfolioShield.scss";
import por_1 from "../../images/portfolio_shield-1.svg";
import Popup from "reactjs-popup";
import CustomPopup from "../Popup/Popup";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import ProjectionCone from "../../Components/Graph/ProjectionCone";
import CircularProgress from "@material-ui/core/CircularProgress";
import close from "../../images/close.svg";
import { addZeroes } from "../Utilities/AddZeros";
import { selectedPeriodBackend } from "../Utilities/GetProtectionPeriodValue";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Tooltip from "react-tooltip-lite";
import { TabContainer, TabPane, TabContent } from 'react-bootstrap';
import TablePagination from '@mui/material/TablePagination';
import BellCurve from "../TerminalBellCurve/TerminalBellCurve";
import PageLoader from "../PageLoader/PageLoader";

function PortfolioShield(props) {

  const useStyles = makeStyles((theme) => ({
    button: {
      color: "white",
      marginTop: "2px",
    },
    checkbox_button: {
      color: "#60034c",
      width: "14px",
      height: "14px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const [bdopen, setBdOpen] = React.useState(false);
  const selectedPortfolioTotalValue = sessionStorage.getItem(
    "PortfolioTotalValue"
  );
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState("hedgeable")
  const [hedgeableStocks, setHedgeableStocks] = React.useState([]);
  const [unhedgeableStocks, setUnhedgeableStocks] = React.useState([]);
  const [StocksFlag, setStocksFlag] = React.useState(false);
  const [PortfolioTotalStocks, setPortfolioTotalStocks] = React.useState("");
  const [PortfolioTotalValue, setPortfolioTotalValue] = React.useState("");
  const [stocksData, setStocksData] = React.useState(props.stocksData);


  var risk_percent = 0;
  const { period_str } = selectedPeriodBackend(props.period_value);

  if (props.level_value === 100) {
    risk_percent = "1.0";
  } else {
    risk_percent = addZeroes(
      Number((props.level_value / 100).toFixed(2).toString())
    );
  }

  if (Object.keys(stocksData).length !== 0 && StocksFlag === false) {
    setBdOpen(true);
    var lyst = [];
    var lyst1 = [];
    var i = 1;
    setPortfolioTotalStocks(stocksData["number_of_lineitems"]);
    setPortfolioTotalValue(stocksData["total_portfolio_value"]);
    for (let [key, value] of Object.entries(stocksData['hedgeable'])) {
      if (key !== "number_of_lineitems") {
        if (key !== "total_portfolio_value") {
          lyst.push([
            i,
            key,
            value.name,
            value.quantity,
            Number(
              addZeroes(Number(parseFloat(value.todays_close).toFixed(2)))
            ).toLocaleString("en-US", currObj),
            Number(
              addZeroes(
                Number((value.quantity * value.todays_close).toFixed(2))
              )
            ).toLocaleString("en-US", currObj),
          ]);
        }
        i = i + 1;
        setHedgeableStocks([...lyst]);
      }
    }
    i = 1;
    for (let [key, value] of Object.entries(stocksData['unhedgeable'])) {
      if (key !== "number_of_lineitems") {
        if (key !== "total_portfolio_value") {
          lyst1.push([
            i,
            key,
            value.name === 0 ? "-" : value.name,
            value.quantity
          ]);

          i = i + 1;
          setUnhedgeableStocks([...lyst1]);
        }
      }
    }
    setBdOpen(false);
    setStocksFlag(true);
  }


  const headers = [
    { title: "#" },
    { title: "STOCK" },
    { title: "POSITION NAME" },
    { title: "QUANTITY", tooltip: "Number of shares held for each stock" },
    {
      title: "CLOSING PRICE",
      tooltip: "Price of a security at the EOD previous market close",
    },
    {
      title: "MARKET VALUE",
      tooltip: "Market value of each security based on last price",
    }
  ];

  const headers_unhedgeable = [
    { title: "#" },
    { title: "STOCK" },
    { title: "POSITION NAME" },
    { title: "QUANTITY", tooltip: "Number of shares held for each stock" },
  ];

  function GetRiskBarColor(risk_value) {
    if (risk_value <= 15) {
      return "#38bb55";
    } else if (risk_value <= 20 && risk_value > 15) {
      return "#ffa654";
    } else if (risk_value <= 30 && risk_value > 20) {
      return "#ed6663";
    } else if (risk_value > 30) {
      return "#aa4a48";
    }
  }
  function handleTabChange(tabvalue) {
    setTabSelected(tabvalue);
  }

  const [HTpage, setHTPage] = React.useState(0);
  const [htrowsPerPage, setHTRowsPerPage] = React.useState(10);

  const [UTpage, setUTPage] = React.useState(0);
  const [utrowsPerPage, setUTRowsPerPage] = React.useState(10);

  const handleChangePageHedgeable = (event, newPage) => {
    setHTPage(newPage);
  };

  const handleChangeRowsPerPageHedgeable = (event) => {
    setHTRowsPerPage(+event.target.value);
    setHTPage(0);
  };

  const handleChangePageUnhedgeable = (event, newPage) => {
    setUTPage(newPage);
  };

  const handleChangeRowsPerPageUnhedgeable = (event) => {
    setUTRowsPerPage(+event.target.value);
    setUTPage(0);
  };

  function handleGraphTabChange(tabvalue) {
    props.setTabValue(tabvalue);
  }

  console.log(" cd ", props.risk_dial_data, props.projection_cone_data)
  return (
    <div className="ShieldProtect-holder">
      <PageLoader bdopen={bdopen}/>
      <CustomPopup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <div className="shieldcompare_page portsolution">
        <div className="shildcompare">
          <div className="shildhead">
            <div>duration</div>
            <div>Protection Level</div>
            <div>Protection Price</div>
          </div>
          <div className="shieldcard info">
            <div>
              <h3>{props.period_value}</h3>
              <p>{props.shieldExpires}</p>
            </div>
            <div>
              <h3>{props.level_value}%</h3>
            </div>
            <div>
              <h3>{Number(
                addZeroes(Number(parseFloat(props.shieldPrice).toFixed(2)))
              ).toLocaleString("en-US", currObj)}
              </h3>
              <p>{Number(
                addZeroes(Number(parseFloat((props.shieldPrice / selectedPortfolioTotalValue) * 100).toFixed(2)))
              )}%</p>
            </div>
            <div className="btncard">
              <a className="ai-btn small primary line ai-disabled">Coming in 2023</a>
            </div>
          </div>
        </div>
        <div className="shieldstates">
          <div className="contentshield">
            <ul className="subnavbar-menu navtab">
              <li>
                <a className={tabSelected === "hedgeable" ? "SUBITEM-selected" : "SUBITEM"} eventKey="hedgeable" onClick={() => handleTabChange('hedgeable')} > Hedgeable Assets <Tooltip content="Eligible Assets that can be protected by Adaptive Shield."><img src="Assets/risk-info.png"></img></Tooltip></a>
              </li>
              <li>
                <a className={tabSelected === "unhedgeable" ? "SUBITEM-selected" : "SUBITEM"} eventKey="unhedgeable" onClick={() => handleTabChange('unhedgeable')}>Unhedgeable Assets <Tooltip content="Non-Eligible Assets that can't be protected by Adaptive Shield."><img src="Assets/risk-info.png"></img></Tooltip></a>
              </li>
            </ul>
            <div className="table_holder">
              <div className="tableheading">Portfolio Shield Details</div>
              <TabContainer defaultActiveKey={tabSelected}>
                <TabContent>
                  <TabPane eventKey="hedgeable" active={tabSelected === 'hedgeable'} >
                    <TableContainer className="table_height">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {headers.map((header) => (
                              <TableCell>
                                <Tooltip content={header.tooltip}>
                                  <div className="stck_table_heading">{header.title}</div>
                                </Tooltip>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody id="hedgeableticker">
                          {hedgeableStocks.slice(HTpage * htrowsPerPage, HTpage * htrowsPerPage + htrowsPerPage).map((row) => (
                            <TableRow>
                              {row.map((cell) => (
                                <TableCell>{cell}</TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter >
                          <TableRow >
                            <TableCell colSpan="5">Total:</TableCell>
                            <TableCell>{Number(
                              addZeroes(Number(parseFloat(PortfolioTotalValue).toFixed(2)))
                            ).toLocaleString("en-US", currObj)}</TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={hedgeableStocks.length}
                      rowsPerPage={htrowsPerPage}
                      page={HTpage}
                      onPageChange={handleChangePageHedgeable}
                      onRowsPerPageChange={handleChangeRowsPerPageHedgeable}
                    />
                  </TabPane>
                  <TabPane eventKey="unhedgeable" active={tabSelected === 'unhedgeable'}>
                    <TableContainer className="table_height">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {headers_unhedgeable.slice(UTpage * utrowsPerPage, UTpage * utrowsPerPage + utrowsPerPage).map((header) => (
                              <TableCell>
                                <Tooltip content={header.tooltip}>
                                  <div className="stck_table_heading">{header.title}</div>
                                </Tooltip>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {unhedgeableStocks.map((row) => (
                            <TableRow>
                              {row.map((cell) => (
                                <TableCell>{cell}</TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={unhedgeableStocks.length}
                      rowsPerPage={utrowsPerPage}
                      page={UTpage}
                      onPageChange={handleChangePageUnhedgeable}
                      onRowsPerPageChange={handleChangeRowsPerPageUnhedgeable}
                    />
                  </TabPane>
                </TabContent>
              </TabContainer>
            </div>
          </div>
          <div className="solutiontrack">
            <div className="trackstates">
              <h3>portfolio analytics <Tooltip content="Compare the trade-offs between protection cost and fit for different Shield solutions, as measured by tracking error and correlation."><img src="Assets/risk-info.png"></img></Tooltip></h3>
              <div className="statesview">
                <div className="trackrisk">
                  <img src={por_1}></img>
                  <p id="trackingerror">
                    Tracking error
                    <span>{Number(addZeroes(Number(parseFloat(0).toFixed(2))))}%</span>
                  </p>
                </div>
                {props.risk_dial_data && props.risk_dial_data["month"] && props.risk_dial_data["month"][risk_percent] ? (
                  <div className="portfolio-bars">
                    <div className="portfolio-risk-bar">
                      <div className="market-risk-progress">
                        <div
                          className="market-risk-bar"
                          style={{
                            opacity: 1,
                            background:
                              props.risk_dial_data["month"][risk_percent]["market_risk"] !== undefined
                                ? GetRiskBarColor(props.risk_dial_data["month"][risk_percent]["market_risk"] * 100)
                                : 0,
                            width:
                              props.risk_dial_data["month"][risk_percent]["market_risk"] !== undefined
                                ? `${props.risk_dial_data["month"][risk_percent]["market_risk"] * 200}%`
                                : 0,
                          }}
                        ></div>
                      </div>
                      <p>
                        Market risk
                        <Tooltip content={(
                          <div className="tiptext">
                            <p>VIX (CBOE S&P500 Implied Volatility Index).</p>
                            <p>Low = 0% - 15%</p>
                            <p>Medium = 15% - 20%</p>
                            <p>High = 20% - 30%</p>
                            <p>Very High = 30% +</p>
                          </div>
                        )}><img src="Assets/risk-info.png"></img></Tooltip>
                      </p>
                    </div>
                    <div className="portfolio-risk-bar">
                      <div className="market-risk-progress">
                        <div
                          className="progress-done-2"
                          style={{
                            opacity: 1,
                            background:
                              props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] !== undefined
                                ? GetRiskBarColor(props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] * 100)
                                : 0,
                            width:
                              props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] !== undefined
                                ? `${props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] * 200}%`
                                : 0,
                          }}
                        ></div>
                      </div>
                      <p>
                        Portfolio risk
                        <Tooltip content={(
                          <div className="tiptext">
                            <p>Analyzed risk level of portfolio: </p>
                            <p>semi variance measure (annualized standard deviation of downside risk or negative returns) </p>
                            <p>Low = 0% - 15% </p>
                            <p>Medium = 15% - 20% </p>
                            <p>High = 20% - 30% </p>
                            <p>Very High = 30% +</p>
                          </div>
                        )}><img src="Assets/risk-info.png"></img></Tooltip>
                      </p>
                    </div>
                    <div className="portfolio-risk-bar">
                      <div className="market-risk-progress">
                        <div
                          className="progress-done-3"
                          style={{
                            opacity: 1,
                            background:
                              props.risk_dial_data["month"][risk_percent]["risk_w_shield"] !== undefined
                                ? GetRiskBarColor(props.risk_dial_data["month"][risk_percent]["risk_w_shield"] * 100)
                                : 0,
                            width:
                              props.risk_dial_data["month"][risk_percent]["risk_w_shield"] !== undefined
                                ? `${props.risk_dial_data["month"][risk_percent]["risk_w_shield"] * 200}%`
                                : 0,
                          }}
                        ></div>
                      </div>
                      <p>
                        Shielded risk
                        <Tooltip content={(
                          <div className="tiptext">
                            <p>Analyzed risk level of portfolio, accounting for any Shield in place.</p>
                            <p>Low = 0% - 15%</p>
                            <p>Medium = 15% - 20% </p>
                            <p>High = 20% - 30% </p>
                            <p>Very High = 30% +</p>
                          </div>
                        )}><img src="Assets/risk-info.png"></img></Tooltip>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p>Risk Data Not Found.</p>
                  </div>
                )}
              </div>
            </div>
            {props.projection_cone_data[period_str] && props.projection_cone_data[period_str][props.level_value] ? (
              <>
                <div className="shieldstates">
                  <div className="contentshield">
                    <ul className="subnavbar-menu navtab">
                      <li>
                        <a className={props.tabvalue === "Projected Value" ? "SUBITEM-selected " : "SUBITEM"} eventKey="Projected Value" onClick={() => handleGraphTabChange('Projected Value')} > Projected Value <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                      </li>
                      <li>
                        <a id="terminaldistribution" className={props.tabvalue === "Terminal Distribution" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Terminal Distribution" onClick={() => handleGraphTabChange('Terminal Distribution')}>Terminal Distribution <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <TabContainer defaultActiveKey={props.tabvalue}>
                  <TabContent>
                    <TabPane key="Projected Value" eventKey="Projected Value" active={props.tabvalue === 'Projected Value'} >
                      <ProjectionCone
                        key={new Date().getTime()}
                        data={props.projection_cone_data[period_str][props.level_value]}
                        portfolioValue={Array(
                          props.projection_cone_data[period_str][props.level_value]["upper_bound"].length
                        ).fill(Number(selectedPortfolioTotalValue))}
                        protectedValue={Array(
                          props.projection_cone_data[period_str][props.level_value]["upper_bound"].length
                        ).fill(Number(selectedPortfolioTotalValue) * 0.90)}
                      />
                    </TabPane>
                    <TabPane key="Terminal Distribution" eventKey="Terminal Distribution" active={props.tabvalue === 'Terminal Distribution'}>
                    {
                        Object.keys(props.TerminalData).length === 0 ?
                          <div style={{ textAlign: "center" }}>
                            <br />
                            <p>No Data found for Terminal Risk</p>
                          </div> :
                          <div id = "terminalbellcurve" className="barchart">
                            <BellCurve data={props.TerminalData} />
                          </div>
                      }
                    </TabPane>
                  </TabContent>
                </TabContainer>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <br />
                <p>No Data found for Projection Cone</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup id="PortfolioPopUp" open={true} modal>
          <div className="add-portfolio-popup">
            <IconButton
              onClick={() => setShowPopup(false)}
              className="closepopup"
            >
              <img src={close}></img>
            </IconButton>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default PortfolioShield;
