import React from "react";
import "./RecommendationBanner.css";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { GetQuoteEngineData } from "../../Apis/GetQuoteEngineData";
import { GetQuoteEngineEvents } from "../../Apis/GetQuoteEngineEvents";
import { findClosest } from "../Utilities/ClosestPercent";
import { addZeroes } from "../Utilities/AddZeros";
import { GetPeriodValue } from "../Utilities/GetProtectionPeriodValue";
import Popup from "../Popup/Popup";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";

export let protection_period_int = 0;
export let protection_period_str = '';
export let protection_level = 0;

function RecommendationBanner(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
  }, [location]);

  const SheidSetupScreen = () => history.push({
    pathname: "/ShieldProtectionScreen",
    state: {
      portfolioid: location.state.portfolioid,
      stocksData: location.state.stocksData
    }
  });

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return {
        "banner-odd": "banner-odd-lg",
        "banner-even": "banner-even-lg",
        "recommendation-holder": "recommendation-holder-lg",
        "recommendation-title": "recommendation-title-lg",
        "message-panel": "message-panel-lg",
        message: "message-lg",
        "money-image": "money-image-lg",
        "coin-image": "coin-image-lg",
        description: "description-lg",
        "cost-saving": "cost-saving-lg",
        "total-panel": "total-panel-lg",
        "card-image": "card-image-lg",
        "credit-card-image": "credit-card-image-lg",
        total: "total-lg",
      };
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return {
        "banner-odd": "banner-odd-lg-md",
        "banner-even": "banner-even-lg-md",
        "recommendation-holder": "recommendation-holder-lg-md",
        "recommendation-title": "recommendation-title-lg-md",
        "message-panel": "message-panel-lg-md",
        message: "message-lg-md",
        "money-image": "money-image-lg-md",
        "coin-image": "coin-image-lg-md",
        description: "description-lg-md",
        "cost-saving": "cost-saving-lg-md",
        "total-panel": "total-panel-lg-md",
        "card-image": "card-image-lg-md",
        "credit-card-image": "credit-card-image-lg-md",
        total: "total-lg-md",
      };
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return {
        "banner-odd": "banner-odd-lg-md",
        "banner-even": "banner-even-lg-md",
        "recommendation-holder": "recommendation-holder-lg-md",
        "recommendation-title": "recommendation-title-lg-md",
        "message-panel": "message-panel-lg-md",
        message: "message-lg-md",
        "money-image": "money-image-lg-md",
        "coin-image": "coin-image-lg-md",
        description: "description-lg-md",
        "cost-saving": "cost-saving-lg-md",
        "total-panel": "total-panel-lg-md",
        "card-image": "card-image-lg-md",
        "credit-card-image": "credit-card-image-lg-md",
        total: "total-lg-md",
      };
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return {
        "banner-odd": "banner-odd-lg-md",
        "banner-even": "banner-even-lg-md",
        "recommendation-holder": "recommendation-holder-sm-xs",
        "recommendation-title": "recommendation-title-lg-md",
        "message-panel": "message-panel-lg-md",
        message: "message-lg-md",
        "money-image": "money-image-lg-md",
        "coin-image": "coin-image-lg-md",
        description: "description-lg-md",
        "cost-saving": "cost-saving-lg-md",
        "total-panel": "total-panel-lg-md",
        "card-image": "card-image-lg-md",
        "credit-card-image": "credit-card-image-lg-md",
        total: "total-lg-md",
      };
    }
  }
  const classes = useStyles();
  const rendering = getRendering(props.width);
  const [spacing, setSpacing] = React.useState(2);
  const [Flag, setFlag] = React.useState(false);
  const [GotID, setGotID] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [diyPrice1, setDiyPrice1] = React.useState(0);
  const [shieldPrice1, setShieldPrice1] = React.useState(0);
  const [costSaved1, setCostSaved1] = React.useState(0);
  const [diyPrice2, setDiyPrice2] = React.useState(0);
  const [shieldPrice2, setShieldPrice2] = React.useState(0);
  const [costSaved2, setCostSaved2] = React.useState(0);
  const [selectedPercentage1, setSelectedPercentage1] = React.useState(0);
  const [selectedPercentage2, setSelectedPercentage2] = React.useState(0);
  const [selectedData1, setSelectedData1] = React.useState({});
  const [selectedData2, setSelectedData2] = React.useState({});
  const [recommendationEvent, setRecommendationEvent] = React.useState(false);
  const [EventsData, setEventsData] = React.useState([]);
  const [GotEvents, setGotEvents] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  var new_date = new Date();
  // const session_id = sessionStorage.getItem("sessionID");
  const Token = sessionStorage.getItem("Token");
  const selectedPortfolioTotalValue = sessionStorage.getItem(
    "PortfolioTotalValue"
  );
  const [QuoteCount, setQuoteCount] = React.useState(0);
  const [eventCount, setEventCount] = React.useState(0);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  if (Flag === false && QuoteCount === 0) {
    setQuoteCount(1);
    GetQuoteEngineData(location.state.portfolioid).then((data) => {
      if (data === "Not enough data for ticker(s)") {
        setBdOpen(false);
        setGotID(true);
        setPopupState(true);
        setPopupMessage(
          "The tickers in your portfolio dont have enough historical data. Contact support@adaptive-investments.com for more information."
        );
        setPopupTitle("Warning");
        setPopupLoginRedirect(false);
        setAlertType("warning");
      }
      else if (data === "No Option Data Available For Stocks") {
        setBdOpen(false);
        setGotID(true);
        setPopupState(true);
        setPopupMessage(
          "The tickers in your portfolio dont have enough option data . Contact support@adaptive-investments.com for more information."
        );
        setPopupTitle("Warning");
        setPopupLoginRedirect(false);
        setAlertType("warning");
      }
      else if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        setGotID(true);
        var selectedPercent1 = 85;
        var selectedPercent2 = 95;
        var tp1 = data["month"];
        var tp2 = data["week_2"];

        var percent1 = findClosest(tp1, selectedPercent1);
        setSelectedPercentage1(selectedPercent1);

        var percent2 = findClosest(tp2, selectedPercent2);
        setSelectedPercentage2(selectedPercent2);

        percent1 = addZeroes(Number((100 - percent1).toFixed(2).toString()));
        percent2 = addZeroes(Number((100 - percent2).toFixed(2).toString()));

        setSelectedData1(tp1[percent1]);
        setSelectedData2(tp2[percent2]);
        setShieldPrice1(tp1[percent1]["shieldprice"].toFixed(2));
        setShieldPrice2(tp2[percent2]["shieldprice"].toFixed(2));
        setDiyPrice1(tp1[percent1]["diyprice"].toFixed(2));
        setDiyPrice2(tp2[percent2]["diyprice"].toFixed(2));
        var price_saved1 =
          tp1[percent1]["diyprice"].toFixed(2) -
          tp1[percent1]["shieldprice"].toFixed(2);
        setCostSaved1(price_saved1);
        var price_saved2 =
          tp2[percent2]["diyprice"].toFixed(2) -
          tp2[percent2]["shieldprice"].toFixed(2);
        setCostSaved2(price_saved2);
        setBdOpen(false);
      } else if (data === false) {
        setBdOpen(false);
        setGotID(true);
        setPopupState(true);
        setPopupMessage(
          "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
        );
        setPopupTitle("Error");
        setPopupLoginRedirect(false);
        setAlertType("error");
      } else if (data === 0) {
        setBdOpen(false);
        setGotID(true);
        setPopupState(true);
        setPopupMessage(
          "We are having issues connecting to our servers, please try again later."
        );
        setPopupTitle("Error");
        setPopupLoginRedirect(false);
        setAlertType("error");
      } else if (data === 401) {
        setBdOpen(false);
        setGotID(true);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setGotID(true);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }

      GetQuoteEngineEvents("recommendation").then((event_data) => {
        if (event_data !== false && event_data !== 0 && event_data !== 401 && event_data !== 404) {
          setGotEvents(true);
          var lyst = [];
          var events_arry = [];
          for (let [key, value] of Object.entries(event_data)) {
            var period_percent = Object.keys(data[value.protectionperiod]);
            for (var i = 0; i < period_percent.length; i++) {
              events_arry.push(100 - parseFloat(period_percent[i]).toFixed(2));
            }
            var closest_percent = findClosest(
              events_arry,
              value.protectionlevel
            );
            var shieldPercent = addZeroes(
              Number((100 - closest_percent).toFixed(2).toString())
            );
            lyst.push({
              id: key,
              title: value.eventname,
              protection: value.protectionlevel,
              percent_protection: value.protectionlevel + "%",
              protection_period: value.protectionperiod,
              time: GetPeriodValue(value.protectionperiod),
              shield_price:
                data[value.protectionperiod][shieldPercent]["shieldprice"],
              diy_cost: data[value.protectionperiod][shieldPercent]["diyprice"],
              saved:
                data[value.protectionperiod][shieldPercent]["diyprice"] -
                data[value.protectionperiod][shieldPercent]["shieldprice"],
              description: value.eventdescription,
              img_src: "Assets/shield-orange.png",
              selected_data: data[value.protectionperiod][shieldPercent],
            });
          }
          console.log("events lyst", lyst);
          setEventsData([...lyst]);
          setRecommendationEvent(true);
        } else if (event_data === false) {
          setGotEvents(true);
          setPopupState(true);
          setPopupMessage(
            "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
          );
          setPopupTitle("Error");
          setPopupLoginRedirect(false);
          setAlertType("error");
          setBdOpen(false);
        } else if (event_data === 0) {
          setGotEvents(true);
          setPopupState(true);
          setPopupMessage(
            "We are having issues connecting to our servers, please try again later."
          );
          setPopupTitle("Error");
          setAlertType("error");
          setPopupLoginRedirect(false);
          setBdOpen(false);
        } else if (data === 401) {
          setBdOpen(false);
          setGotEvents(true);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
        }
        else if (data === 404) {
          setBdOpen(false);
          setGotEvents(true);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
        }
      });
    });

    if (GotID === true && GotEvents === true) {
      setFlag(true);
      setBdOpen(false);
    }
    // if(GotEvents === true){
    //   setRecommendationEvent(true);
    // }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function goToConfirm(
    percent,
    time,
    shield_price,
    selected_percent,
    banner_title
  ) {

    var protectionPeriod = 0;

    if (time === "2 Weeks") {
      protectionPeriod = 14;
    } else if (time === "1 Month") {
      protectionPeriod = 30;
    }
    // Setting ShieldExpiration
    new_date.setDate(new_date.getDate() + protectionPeriod);
    new_date = new_date.toISOString().split("T")[0].toString();

    var selectedData = JSON.stringify({
      session_id: Token,
      portfolio_value: parseFloat(selectedPortfolioTotalValue),
      shielded_value: parseFloat((percent / 100) * selectedPortfolioTotalValue),
      shield_price: parseFloat(shield_price),
      shieldexpirationdate: new_date,
      protection_period: time,
      protection_level: percent
    });
    console.log("selectedData", selectedData)
    protection_period_int = protectionPeriod;
    protection_level = percent;
    protection_period_str = time;
    setBdOpen(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'Shield Recommendation Selected',
        userType: sessionStorage.getItem("selectedAcType"),
        user_id: sessionStorage.getItem("clientId"),
        protection_period: protection_period_str,
        protection_level: protection_level,
        banner_title: banner_title
      },
    });
    SheidSetupScreen();
  }

  const banner_data = [
    {
      id:"recommendation1",
      title: "PROTECTION FOR 1 Month",
      time: "1 Month",
      protection_period: "month",
      protection: 85,
      percent_protection: "85%",
      img_src: "Assets/shield-purple.png",
      description:
        "A cost effective plan to protect your investments from a severe downturn",
      diy_cost: diyPrice1,
      saved: costSaved1,
      shield_price: shieldPrice1,
      selected_data: selectedData1,
      selected_percent: selectedPercentage1,
    },
    {
      id:"recommendation2",
      title: "PROTECTION FOR 2 WEEKS",
      time: "2 Weeks",
      protection_period: "week_2",
      protection: 95,
      percent_protection: "95%",
      img_src: "Assets/shield-pink.png",
      description:
        "A high protection plan to protect your investments in the near term",
      diy_cost: diyPrice2,
      saved: costSaved2,
      shield_price: shieldPrice2,
      selected_data: selectedData2,
      selected_percent: selectedPercentage2,
    },
  ];

  return (
    <div class="recommendation_wrap">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Shield Recommendation"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <div className="recommendation_page">
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={spacing}>
              {banner_data.map((card, i) => (
                <Grid key={card.title} item>
                  <a
                    id = {card.id}
                    onClick={() =>
                      goToConfirm(
                        card.protection,
                        card.time,
                        card.shield_price,
                        card.selected_data,
                        card.selected_percent,
                        card.protection_period,
                        card.title
                      )
                    }
                  >
                    <div className="recommendation-banner-lg">
                      <div
                        className={`recommendation-card recommendation-card-${i + 1
                          }`}
                      >
                        <div className="position-new">
                          <div className={`recommendation-title`}>
                            <text className="header-text">
                              RECOMMENDATION {i + 1}
                            </text>
                          </div>
                          <div class="message-panel">
                            <div class="message">
                              <span className={`msg-percent`}>
                                {card.percent_protection}
                              </span>
                              <span className="msg-plan">PROTECTION FOR</span>
                              <span className="msg-week">{card.time}</span>
                            </div>
                            <div class={rendering["money-image"]}>
                              <img
                                src={card.img_src}
                                alt="COINS"
                                class={rendering["coin-image"]}
                              ></img>
                            </div>
                          </div>
                          <div className={`risk-message-text`}>
                            {card.description}
                          </div>
                          <div className="cost-saving">
                            DIY Cost:{" "}
                            <span className="cost-saved">
                              {Number(
                                addZeroes(Number(parseFloat(card.diy_cost).toFixed(2)))
                              ).toLocaleString("en-US", currObj)}
                            </span>
                            <br />
                            <span className={`you-save-${i + 1}`}>
                              You Save:{" "}
                              {Number(
                                addZeroes(Number(parseFloat(card.saved).toFixed(2)))
                              ).toLocaleString("en-US", currObj)}
                            </span>
                          </div>
                        </div>
                        <div className="recommendation-total">
                          {Number(
                            addZeroes(Number(parseFloat(card.shield_price).toFixed(2)))
                          ).toLocaleString("en-US", currObj)}
                        </div>
                      </div>
                    </div>
                  </a>
                </Grid>
              ))}
              {Flag === true && EventsData.length > 0 ? (
                <div className="loader">
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                EventsData.map((card, i) => (
                  <Grid key={card.title} item>
                    <a
                      onClick={() =>
                        goToConfirm(
                          card.protection,
                          card.time,
                          card.shield_price,
                          card.selected_data,
                          card.selected_percent,
                          card.title
                        )
                      }
                    >
                      <div className="recommendation-banner-lg">
                        <div
                          className={`recommendation-card recommendation-card-3`}
                        >
                          <div className="position-new">
                            <div className={`recommendation-title`}>
                              <text className="header-text">{card.title}</text>
                            </div>
                            <div class="message-panel">
                              <div class="message">
                                <span className={`msg-percent`}>
                                  {card.percent_protection}
                                </span>
                                <span className="msg-plan">PROTECTION FOR</span>
                                <span className="msg-week">{card.time}</span>
                              </div>
                              <div class={rendering["money-image"]}>
                                <img
                                  src={card.img_src}
                                  alt="COINS"
                                  class={rendering["coin-image"]}
                                ></img>
                              </div>
                            </div>
                            <div className={`risk-message-text`}>
                              {card.description}
                            </div>
                            <div className="cost-saving">
                              DIY Cost:{" "}
                              <span className="cost-saved">
                                {Number(
                                  addZeroes(Number(parseFloat(card.diy_cost).toFixed(2)))
                                ).toLocaleString("en-US", currObj)}
                              </span>
                              <br />
                              <span className={`you-save-3`}>
                                You Save:{" "}
                                {Number(
                                  addZeroes(Number(parseFloat(card.saved).toFixed(2)))
                                ).toLocaleString("en-US", currObj)}
                              </span>
                            </div>
                          </div>
                          <div className="recommendation-total">
                            {Number(
                              addZeroes(Number(parseFloat(card.shield_price).toFixed(2)))
                            ).toLocaleString("en-US", currObj)}
                          </div>
                        </div>
                      </div>
                    </a>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withWidth()(RecommendationBanner);
