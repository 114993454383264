import React, {useState , Component } from "react";
import { Link } from "react-router-dom";
import AiInput from "../../Components/AiInput/ai-input";
import AiFormError from "../../Components/AiFormError/ai-form-error";
import MainSlider from "../../Components/MainSlider/MainSlider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Container, Row, Col } from "react-bootstrap";
import {
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import AdvisorPool from "../../AdvisorPool";
import { CheckCustomerAccess } from "../../Apis/CheckCustomerAccess";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import PageLoader from "../../Components/PageLoader/PageLoader";
import { parsePhoneNumberFromString } from 'libphonenumber-js'

function Signup(){
  const accountTypes = [
    {name: "INVESTOR", icon:"../Assets/investor.svg"}, 
    {name:"ADVISOR", icon:"../Assets/advisor.svg"}
  ];
  const [selectedAcType,setSelectedAcType] = React.useState("");
  const [bdopen, setBdOpen] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [isAccnVisible, setIsAccnVisible] = React.useState(true);
  const [isLoginVisible, setIsLoginVisible] = React.useState(false);

  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmpassword, setConfirmPassword] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [companyname, setCompanyName] = React.useState("")
  const [inputerrors,setInputErrors] = React.useState({});
  const history = useHistory();

  const OTPConfirmation = () => history.push({
    pathname: '/OTPConfirmationScreen',
    state: {
      userEmail: email,
      userName:email
    }
  });

  React.useEffect(() => {
    window.sessionStorage.setItem("selectedAcType", selectedAcType);
  }, [selectedAcType]);

  const handleAccnVisible = () => {
    setIsAccnVisible(!isAccnVisible);
  };
  const handleLoginVisible = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  function formatPhoneNumber(mobile) {
    try {
      const phoneNumber = parsePhoneNumberFromString(mobile);
      if (phoneNumber) {
        return phoneNumber.formatInternational();
      }
    } catch (error) {
      console.error(error.message);
    }
    return `+1${mobile.trim()}`;
  }

  const pushInfo = () => {
    let attributeList = [];
    let dataEmail = {
      Name: "email",
      Value: email.trim().toLowerCase(),
    };

    let dataLname = {
      Name: "family_name",
      Value: lastname.trim(),
    };

    let dataFname = {
      Name: "name",
      Value: firstname.trim(),
    };
    let attributeEmail = new CognitoUserAttribute(dataEmail);
    let attributeFirstName = new CognitoUserAttribute(dataFname);
    let attributeLastName = new CognitoUserAttribute(dataLname);
    attributeList.push(attributeEmail);
    attributeList.push(attributeFirstName);
    attributeList.push(attributeLastName);

    return attributeList;
  };

  const  pushAdvisorInfo = () => {
    let attributeList = [];
    let dataEmail = {
      Name: "email",
      Value: email.trim().toLowerCase(),
    };

    let dataLname = {
      Name: "family_name",
      Value: lastname.trim(),
    };

    let dataFname = {
      Name: "name",
      Value: firstname.trim(),
    };

    let dataMobileno = {
      Name: "phone_number",
      Value: formatPhoneNumber(mobile),
    };

    let attributeEmail = new CognitoUserAttribute(dataEmail);
    let attributeFirstName = new CognitoUserAttribute(dataFname);
    let attributeLastName = new CognitoUserAttribute(dataLname);
    let attributeMobileNo = new CognitoUserAttribute(dataMobileno);
    attributeList.push(attributeEmail);
    attributeList.push(attributeFirstName);
    attributeList.push(attributeLastName);
    attributeList.push(attributeMobileNo);

    return attributeList;
  };

  const validateSignupForm = () => {
    let errors = {};
    let validFormFlag = true;
    
    if (!firstname) {
      validFormFlag = false;
      errors["firstname"] = "Please enter your first name";
    }

    if (!lastname) {
      validFormFlag = false;
      errors["lastname"] = "Please enter your last name";
    }

    if (!email) {
      validFormFlag = false;
      errors["email"] = "Please enter your email";
    }

    if (!password) {
      validFormFlag = false;
      errors["password"] = "Please enter a password";
    }

    if (password !== confirmpassword && confirmpassword !== "") {
      validFormFlag = false;
      errors["confirmpassword"] =
        "Your password and confirmation password do not match.";
    }

    if (!confirmpassword) {
      validFormFlag = false;
      errors["confirmpassword"] = "Please confirm your password";
    }

    if (firstname && !(/^[A-Za-z][A-Za-z ,._'-]+$/.test(firstname))) {
      validFormFlag = false;
      errors["firstname"] = "Please enter your valid first name.\nValid characters are: A-Z,a-b,space, comma, apostrophe, hyphen, underscore, dot";
    }

    if (lastname && !(/^[A-Za-z][A-Za-z ,._'-]+$/.test(lastname))) {
      validFormFlag = false;
      errors["lastname"] = "Please enter your valid last name.\nValid characters are: A-Z,a-b,space, comma, apostrophe, hyphen, underscore, dot";
    }
    setInputErrors(errors);
    return validFormFlag;
  }
  const validateAdvisorSignupForm = () => {
    let errors = {};
    let validFormFlag = true;
  
    if (!firstname) {
      validFormFlag = false;
      errors["firstname"] = "Please enter your first name";
    }
  
    if (!lastname) {
      validFormFlag = false;
      errors["lastname"] = "Please enter your last name";
    }
  
    if (!email) {
      validFormFlag = false;
      errors["email"] = "Please enter your email";
    }
  
    if (!mobile) {
      validFormFlag = false;
      errors["mobile"] = "Please enter your mobile number";
    } else if (!(/^[0-9]+$/.test(mobile))) {
      validFormFlag = false;
      errors["mobile"] = "Please enter a valid mobile number with digits only";
    }
  
    if (!companyname) {
      validFormFlag = false;
      errors["companyname"] = "Please enter your company name";
    }
  
    if (!password) {
      validFormFlag = false;
      errors["password"] = "Please enter a password";
    }
  
    if (password !== confirmpassword && confirmpassword !== "") {
      validFormFlag = false;
      errors["confirmpassword"] = "Your password and confirmation password do not match.";
    }
  
    if (!confirmpassword) {
      validFormFlag = false;
      errors["confirmpassword"] = "Please confirm your password";
    }
  
    if (firstname && !(/^[A-Za-z][A-Za-z ,.'-]+$/.test(firstname))) {
      validFormFlag = false;
      errors["firstname"] = "Please enter a valid first name. Valid characters are: A-Z, a-z, space, comma, apostrophe, hyphen, dot";
    }
  
    if (lastname && !(/^[A-Za-z][A-Za-z ,.'-]+$/.test(lastname))) {
      validFormFlag = false;
      errors["lastname"] = "Please enter a valid last name. Valid characters are: A-Z, a-z, space, comma, apostrophe, hyphen, dot";
    }
  
    setInputErrors(errors);
    return validFormFlag;
  }
  
  const changeAccountType = (accountType) => {
    setSelectedAcType(accountType);
    setInputErrors({});
  }

  const returnClassname = (accountType) => {
    if (accountType === selectedAcType) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  }

  const handleButtonClick = () => {
    setPopupState(false)
  }

  const submitSignupForm = (e) => {
    e.preventDefault();
    if (selectedAcType === "INVESTOR") {
      if (validateSignupForm()) {
        setBdOpen(true);
        CheckCustomerAccess(email,firstname,lastname,email,selectedAcType).then(
          (customer_access) => {
            if (customer_access === "False") {
              setBdOpen(false);
              setPopupState(true);
              return;
            } else {
              let attributeList = pushInfo();
              UserPool.signUp(
                email.trim().toLowerCase(),
                password.trim(),
                attributeList,
                null,
                (err, data) => {
                  if (err) {
                    let errors = {};
                    setBdOpen(false);
                    if (err.message === "User already exists") {
                      errors["email"] =
                        "This email id is already taken, try another one";
                    }
                    if (err.message === "Invalid email address format.") {
                      errors["email"] = "Please enter a valid email";
                    }
                    if (
                      err.message ===
                      "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
                    ) {
                      errors["email"] = "Please enter a valid username";
                    }
                    if (err.code === "InvalidPasswordException") {
                      errors["password"] =
                        "Please make sure that your password is atleast 8 characters, a number, a uppercase letter and a special character";
                    }
                    setInputErrors(errors);
                  } else {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'User Signup',
                        userType:selectedAcType
                      },
                    });
                    OTPConfirmation();
                  }
                }
              );
            }
          }
        );
      }
    } else if (selectedAcType === "ADVISOR") {
      if (validateAdvisorSignupForm()) {
        setBdOpen(true);
        CheckCustomerAccess(email,firstname,lastname,email,selectedAcType,mobile,companyname).then(
          (customer_access) => {
            if (customer_access === "False") {
              setBdOpen(false);
              setPopupState(true);
              return;
            } else {
              let attributeList = pushAdvisorInfo();
              AdvisorPool.signUp(
                email.trim().toLowerCase(),
                password.trim(),
                attributeList,
                null,
                (err, data) => {
                  if (err) {
                    let errors = {};
                    setBdOpen(false);
                    console.log(" error", err)
                    if (err.message === "User already exists") {
                      errors["email"] =
                        "This email id is already taken, try another one";
                    }
                    if (err.message === "Invalid email address format.") {
                      errors["email"] = "Please enter a valid email";
                    }
                    if (
                      err.message ===
                      "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
                    ) {
                      errors["email"] = "Please enter a valid username";
                    }
                    if (err.code === "InvalidPasswordException") {
                      errors["password"] =
                        "Please make sure that your password is atleast 8 characters, a number, a uppercase letter and a special character";
                    }
                    if (err.message === "Invalid phone number format."){
                      errors["mobile"] = "Invalid phone number format. Please enter mobile number in 10 digits format"
                    }
                    setInputErrors(errors);
                  } else {
                    sessionStorage.setItem(
                      "selectedAcType",
                      selectedAcType
                    );
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'User Signup',
                        userType:selectedAcType
                      },
                    });
                    OTPConfirmation();
                  }
                }
              );
            }
          }
        );
      }
    }
  }
  return (
    <section className="ai-container ai-bording-page">
      <PageLoader bdopen={bdopen}/>
      {popupState && (
        <div>
          <div className="popup">
            <div className="popup-object">
              <div className="confirm-heading">Sign Up</div>
              <div className="body-message">
                <p>
                  {" "}
                  We are currently out of capacity for new users. You have
                  been added to our waitlist.
                </p>
              </div>
              <button
                className="ai-btn primary solid"
                onClick={() => handleButtonClick()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      <Container fluid>
        <Row className="ai-full-height ai-login-form">
          <Col className="ai-slide-wrapper" lg={4}>
            <header>
              <div className="ai-logo">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/ai-logo.png"}
                    alt="Adaptive Investment Logo"
                  />
                </Link>
              </div>
            </header>
            <section className="ai-slide-content">
              <MainSlider />
            </section>
          </Col>
          <Col className="ai-login-wrapper" xs={12} sm={12} md={12} lg={8}>
            <Row className="flex1">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="ai-login-container">
                  <div className="ai-signup-wrapper"> 
                    <div className="ai-login-form">
                      <div className="ai-login-section">
                        {isAccnVisible && (
                          <div className="ai-field-group">
                            <h2>Sign Up</h2>
                            <nav className="ai-tabs nav-tabs">
                              {accountTypes.map((accountType) => (
                                <a
                                  onClick={() => {changeAccountType(accountType.name); handleAccnVisible(); handleLoginVisible();}}
                                  className={returnClassname(accountType.name)}
                                >
                                  <img src={accountType.icon}></img>
                                  {accountType.name}
                                </a>
                              ))}
                            </nav>
                            <div class="calendlysaprator"><span>OR</span></div>
                            <div className="ai-field-group">
                              <div className="ai-row v-middle">
                                <div className="ai-column">
                                  <div className="ai-signin middle-text">
                                    <p>Already have an account?</p>
                                    <Link to="/" className="ai-link">
                                      Sign In
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isLoginVisible && (
                          <>
                            <h2><a className="backtouser" onClick={() => {changeAccountType(""); handleAccnVisible(); handleLoginVisible();}}><img src="../Assets/arrowright.svg"></img></a>Sign Up as {selectedAcType === "INVESTOR" ? (
                              "Investor"
                            ) : (
                              "Advisor"
                            )}</h2>
                            {selectedAcType === "INVESTOR" && (
                              <form
                                method="post"
                                name="signupForm"
                                onSubmit={submitSignupForm}
                              >
                                <div className="ai-field-group">
                                  <div className="ai-row ai-two-column">
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="firstname"
                                        placeholder="First Name"
                                        onChange={(event) => setFirstName(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.firstname
                                        }
                                      />
                                    </div>
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="lastname"
                                        placeholder="Last Name"
                                        onChange={(event) => setLastName(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.lastname
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        onChange={(event) => setEmail(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.email
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="password"
                                        name="password"
                                        placeholder="Create Password"
                                        onChange={(event) => setPassword(event.target.value)}
                                        autoComplete="new-password"
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.password
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="password"
                                        name="confirmpassword"
                                        placeholder="Confirm Password"
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        autoComplete="new-password"
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.confirmpassword
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row" />
                                </div>
                                <div className="ai-field-group">
                                  <div className="ai-row ai-two-column">
                                    <div className="ai-column">
                                      <div className="ai-signin">
                                        <p>Already have an account?</p>
                                        <Link to="/" className="ai-link">
                                          Sign In
                                        </Link>
                                      </div>
                                    </div>
                                    <button className="ai-btn primary round">
                                      <ArrowForwardIcon />
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                            {selectedAcType === "ADVISOR" && (
                              
                              <form
                                method="post"
                                name="signupForm"
                                onSubmit={submitSignupForm}
                              >
                                <div className="ai-field-group">
                                  <div className="ai-row ai-two-column">
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="firstname"
                                        placeholder="First Name"
                                        onChange={(event) => setFirstName(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.firstname
                                        }
                                      />
                                    </div>
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="lastname"
                                        placeholder="Last Name"
                                        onChange={(event) => setLastName(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.lastname
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="email"
                                        placeholder="Email"
                                        onChange={(event) => setEmail(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.email
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="mobile"
                                        placeholder="Mobile"
                                        onChange={(event) => setMobile(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.mobile
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="text"
                                        name="companyname"
                                        placeholder="Company Name"
                                        onChange={(event) => setCompanyName(event.target.value)}
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.companyname
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="password"
                                        name="password"
                                        placeholder="Create Password"
                                        onChange={(event) => setPassword(event.target.value)}
                                        autoComplete='new-password'
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.password
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row">
                                    <div className="ai-column">
                                      <AiInput
                                        type="password"
                                        name="confirmpassword"
                                        placeholder="Confirm Password"
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        autoComplete="new-password"
                                      />
                                      <AiFormError
                                        errormessage={
                                          inputerrors.confirmpassword
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ai-row" />
                                </div>
                                <div className="ai-field-group">
                                  <div className="ai-row ai-two-column">
                                    <div className="ai-column">
                                      <div className="ai-signin">
                                        <p>Already have an account?</p>
                                        <Link to="/" className="ai-link">
                                          Sign In
                                        </Link>
                                      </div>
                                    </div>
                                    <button className="ai-btn primary round">
                                      <ArrowForwardIcon />
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <footer>
                  <div className="ia-footer">
                    <p>
                      Adaptive funds are not held in a brokerage account and
                      are not protected by SIPC. Brokerage assets are held by
                      Adaptive Investments Services, a division of Adaptive
                      Investments LLC.
                    </p>
                    <ul className="ia-footer-links">
                      <li>
                        <Link to="/" className="ia-link">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="ia-link">
                          Terms &#38; Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </footer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Signup;