import React from "react";
import PortfolioSolutionsSummary from "../../Components/PortfolioSolutionSummary/PortfolioSolutionSummary";
import Navbar from "../../Components/Navbar/Navbar_official"
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioSolutionsNavbar from "../../Components/PortfolioSolutionsNavbar/PortfolioSolutionsNavbar"
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";
import PortfolioShield from "../../Components/PortfolioShield/PortfolioShield";
import MarketShield from "../../Components/MarketShield/MarketShield";

function PortfolioSolutionsScreen(props) {
    const [linkClick, setLinkClick] = React.useState("Summary")

    function handleLinkClick(val){
        setLinkClick(val)
    }
    return (
        <div className="ai-content-part">
            <Navbar></Navbar>
            <div className="floathead">
                <PageHeader heading="Shield Comparison" backButton={true} addButton={false} routeTo='./PortfolioSummaryScreen' />
                <PortfolioDetails/>
            </div>
            <PortfolioSolutionsNavbar onChange={handleLinkClick}/>
            {/* {linkClick === "Summary" && (
                <PortfolioSolutionsSummary/>
            )} */}
        </div>
    )
}

export default (PortfolioSolutionsScreen);