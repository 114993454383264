import React from "react";
import "./BackTestScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import BackTestV2 from "../../Components/BackTestV2/BackTestV2";

export default function BackTestScreen() {
  return (
    <div className="ai-content-part backtest">
        <Navbar></Navbar>
        <div className="floathead alon">
          <PageHeader heading="Back Test" backButton={true}/>
        </div>
        <BackTestV2/>
    </div>
  );
}
