import AdvisorCsvLineItemChangesTable from "../../Components/AdvisorCsvLineItemChangesTable/AdvisorCsvLineItemChangesTable";
import React from "react";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Navbar from "../../Components/Navbar/Navbar_official";
import ImportStatusNav from "../../Components/ImportStatusNav/ImportStatusNav";
import { Container, Row, Col } from "react-bootstrap";
import AdvisorCsvLineItemChanges from "../../Components/AdvisorCsvLineItemChanges/AdvisorCsvLineItemChanges";

export default function AdvisorLineItemChangesScreen(props) {
  return (
    <div className="ai-content-part advisor">
      <Navbar />
      <section className="portfolio-table-wrapper">
          <ImportStatusNav />
          <AdvisorCsvLineItemChanges />
      </section>
    </div>
  );
}
