import React from "react";
// import "./MarketShield.scss";
import Navbar from "../../Components/Navbar/Navbar_official"
import PageHeader from "../../Components/PageHeader/PageHeader";
import TransactionDetails from "../../Components/TransactionDetails/TransactionDetails";

function TransactionDetailsScreen(props) {
    return (
        <div className="ai-content-part">
            <Navbar></Navbar>
            <div className="floathead alon">
                <PageHeader heading="Transactions" backButton={true}/>
            </div>
            <section className="portfolio-table-wrapper">
                <TransactionDetails />
            </section>
        </div>
    )
}

export default (TransactionDetailsScreen);