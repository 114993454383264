import React from "react";

import "./PortfolioForm.css";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { useHistory, useLocation } from "react-router-dom";
import { AddPortfolio } from "../../Apis/AddPortfolio";
import { AddPortfolioLineItems } from "../../Apis/AddPortfolioLineItems";
import { FetchPortfolioDetails } from "../../Apis/FetchPortfolioDetails";
import { UpdatePortfolioDetails } from "../../Apis/UpdatePortfolioDetails";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "../Popup/Popup";
import { UpdatePortfolioRisk } from "../../Apis/UpdatePortfolioRisk";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import close from "../../images/close_w.svg";
import save from "../../images/save_w.svg";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";
import { usePortfolio } from '../PortfolioContext/PortfolioContext';

function PortfolioForm(props) {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const { portfolioNames } = usePortfolio()
  const history = useHistory();
  const location = useLocation();
  const [PortfolioName, setPortfolioName] = React.useState("");
  const [PortfolioDescription, setPortfolioDescription] = React.useState(" ");
  const [invalidName, setInvalidName] = React.useState("");
  const [invalidDescription, setInvalidDescription] = React.useState("");
  const PlaidAccountID = sessionStorage.getItem("PlaidAccountID");
  const PlaidBrokerName = sessionStorage.getItem("PlaidBrokerName");
  const [bdopen, setBdOpen] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [showImportPopup, setShowImportPopup] = React.useState(false);
  const [fetchPortfolioFlag, setFetchPortfolioFlag] = React.useState(true);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [originalPortfolioName, setOriginalPortfolioName] = React.useState("");

  React.useEffect(() => {
    sessionStorage.setItem("PortfolioName", PortfolioName);
  }, [PortfolioName]);

  const formSubmit = () => history.push("/PortfolioSummaryScreen");
  React.useEffect(() => {
  }, [location]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return {
        main: "main-lg",
        "portfolio-form": "portfolio-form-lg",
        "form-label": "form-label-lg",
        "submit-button": "submit-button-lg",
        "input-type-text": "input-type-text-lg",
        "input-type-text-area": "input-type-text-area-lg",
        "br-label": "br-label-lg",
        "br-input": "br-input-lg",
      };
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return {
        main: "main-lg",
        "portfolio-form": "portfolio-form-lg",
        "form-label": "form-label-lg",
        "submit-button": "submit-button-lg",
        "input-type-text": "input-type-text-lg",
        "input-type-text-area": "input-type-text-area-lg",
        "br-label": "br-label-lg",
        "br-input": "br-input-lg",
      };
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return {
        main: "main-sm",
        "portfolio-form": "portfolio-form-lg",
        "form-label": "form-label-lg",
        "submit-button": "submit-button-lg",
        "input-type-text": "input-type-text-lg",
        "input-type-text-area": "input-type-text-area-md",
        "br-label": "br-label-lg",
        "br-input": "br-input-lg",
      };
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return {
        main: "main-sm",
        "portfolio-form": "portfolio-form-lg",
        "form-label": "form-label-lg",
        "submit-button": "submit-button-lg",
        "input-type-text": "input-type-text-lg",
        "input-type-text-area": "input-type-text-area-sm",
        "br-label": "br-label-lg",
        "br-input": "br-input-lg",
      };
    }
  }

  const rendering = getRendering(props.width);

  if (props.editFlag === true && fetchPortfolioFlag === true) {
    FetchPortfolioDetails().then((data) => {
      setBdOpen(true);
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        if (data["portfolioname"] !== undefined && data["portfolioname"] !== "") {
          setPortfolioName(data["portfolioname"]);
          setOriginalPortfolioName(data["portfolioname"]);
          if (data['portfoliodesc'] !== undefined && data['portfoliodesc'] !== "") {
            setPortfolioDescription(data['portfoliodesc'])
          }
        }
        setBdOpen(false);
        setFetchPortfolioFlag(false);
      } else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "We are having server issues please try again later."
        );
        setPopupTitle("Server Issues");
        setBdOpen(false);
        setPopupLoginRedirect(false);
      } else if (data === false) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
        );
        setPopupTitle("Error on add");
        setBdOpen(false);
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }
    });
  }

  console.log(portfolioNames)
  const validateForm = () => {
    const isValidName = validateName(PortfolioName);
    const isValidDescription = validateDescription(PortfolioDescription);

    return isValidName && isValidDescription;
};
  
const validateName = (name) => {
  const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  
  if (name === "") {
      setInvalidName("Please enter your portfolio name");
      return false;
  }
  if (format.test(name)) {
      setInvalidName("Please ensure your portfolio name does not contain special characters");
      return false;
  }
  if (portfolioNames.includes(name) && (!props.editFlag || (props.editFlag && originalPortfolioName !== name))) {
      setInvalidName("This portfolio name already exists!");
      return false;
  }
  setInvalidName("");
  return true;
};

const validateDescription = (description) => {
  const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  
  if (description && format.test(description)) {
      setInvalidDescription("Please ensure your portfolio description does not contain special characters");
      return false;
  }
  setInvalidDescription("");
  return true;
};

  const handlePortfolioNameChange = (event) => {
    const name = event.target.value;
    setPortfolioName(name);
    validateName(name);
};

const handlePortfolioDescriptionChange = (event) => {
    const description = event.target.value || " ";
    setPortfolioDescription(description);
    validateDescription(description);
};


  const addPortfolio = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setBdOpen(true);
      if (props.importType === "plaid") {
        AddPortfolio(
          PortfolioName,
          PortfolioDescription,
          PlaidAccountID,
          PlaidBrokerName
        ).then((data) => {
          if (data !== false && data !== 0 && data !== 401 && data !== 404) {
            AddPortfolioLineItems(data).then((message) => {
              if (data !== false && data !== 0) {
                UpdatePortfolioRisk().then((update_data) => {
                  if (update_data === true) {
                    setBdOpen(false);
                    setShowImportPopup(true);
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'Add Portfolio',
                        userType: sessionStorage.getItem("selectedAcType"),
                        user_id: sessionStorage.getItem("clientId"),
                        creation_method: 'Brokerage Account'
                      },
                    });
                  }
                });
              } else {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                  "We are having server issues please try again later."
                );
                setPopupTitle("Server Issues");
                setPopupLoginRedirect(false);
              }
            });
          } else if (data === 0) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "We are having server issues please try again later."
            );
            setPopupTitle("Server Issues");
            setPopupLoginRedirect(false);
            setBdOpen(false);
          } else if (data === false) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
            );
            setPopupTitle("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
            setBdOpen(false);
            setPopupLoginRedirect(false);
          }
          else if (data === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            return;
          } else if (data === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            return;
          }
        });
      } else if (props.importType === "csv") {

        AddPortfolio(PortfolioName, PortfolioDescription).then((data) => {
          console.log(" dataa ", data);
          if (data !== false && data !== 0 && data !== 401 && data !== 404) {
            AddPortfolioLineItems(data).then((message) => {
              if (data !== false && data !== 0) {
                UpdatePortfolioRisk().then((update_data) => {
                  if (update_data === true) {
                    setBdOpen(false);
                    setShowImportPopup(true);
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'Add Portfolio',
                        userType: sessionStorage.getItem("selectedAcType"),
                        user_id: sessionStorage.getItem("clientId"),
                        creation_method: 'CSV Import'
                      },
                    });
                  }
                });
              } else {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                  "We are having server issues please try again later."
                );
                setPopupTitle("Server Issues");
                setPopupLoginRedirect(false);
              }
            });
          } else if (data === 0) {
            setPopupState(true);
            setPopupMessage(
              "We are having server issues please try again later."
            );
            setPopupTitle("Server Issues");
            setPopupLoginRedirect(false);
            setBdOpen(false);
          } else if (data === false) {
            setPopupState(true);
            setPopupMessage(
              "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
            );
            setPopupTitle("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
            setPopupLoginRedirect(false);
            setBdOpen(false);
          }
          else if (data === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            return;
          } else if (data === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
            return;
          }
        });
      } else {
        AddPortfolio(PortfolioName, PortfolioDescription).then((message) => {
          if (message !== false && message !== 0 && message !== 401 && message !== 404) {
            console.log(message);
            sleep(200).then(() => {
              setBdOpen(false);
              setShowImportPopup(true);
              TagManager.dataLayer({
                dataLayer: {
                  event: 'Add Portfolio',
                  userType: sessionStorage.getItem("selectedAcType"),
                  user_id: sessionStorage.getItem("clientId"),
                  creation_method: 'Manual'
                },
              });
            });
          } else if (message === 0) {
            setPopupState(true);
            setPopupMessage(
              "We are having server issues please try again later."
            );
            setPopupTitle("Server Issues");
            setPopupLoginRedirect(false);
            setBdOpen(false);
          } else if (message === false) {
            setPopupState(true);
            setPopupMessage(
              "Unable to retrieve data. Please try again or contact support@adaptive-investments.com."
            );
            setPopupTitle("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
            setPopupLoginRedirect(false);
            setBdOpen(false);
          } else if (message === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Server issue. Please try again or contact support@adaptive-investments.com."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
          } else if (message === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
          }
        });
      }
    }
  };

  const editPortfolio = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setBdOpen(true);
      UpdatePortfolioDetails(PortfolioName, PortfolioDescription, location.state.portfolioid).then((message) => {
        setBdOpen(true);
        if (message !== false && message !== 0 && message !== 401 && message !== 404) {
          sleep(200).then(() => {
            setShowImportPopup(true);
            setBdOpen(false);
            TagManager.dataLayer({
              dataLayer: {
                event: 'Update Portfolio Details',
                userType: sessionStorage.getItem("selectedAcType"),
                user_id: sessionStorage.getItem("clientId")
              },
            });
          });
        } else if (message === 0) {
          setPopupState(true);
          setPopupMessage(
            "We are having server issues please try again later."
          );
          setPopupLoginRedirect(false);
          setPopupTitle("Server Issues");
          setBdOpen(false);
        } else if (message === false) {
          setPopupState(true);
          setPopupMessage(
            "There was an issue updating your portfolio please try again later."
          );
          setPopupTitle("Error while updating your portfolio");
          setPopupLoginRedirect(false);
          setBdOpen(false);
        } else if (message === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Server issue. Please try again or contact support@adaptive-investments.com."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        } else if (message === 404) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          setPopupTitle("Session Expired");
          return;
        }
      });
    }
  }

  const cancelPortfolio = (e) => {
    e.preventDefault();
    formSubmit();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!PortfolioDescription.trim()) {
      setPortfolioDescription(" ");
    }
    const isValid = validateForm();
    if (isValid) {
      if (props.editFlag) {
        editPortfolio();
      } else {
        addPortfolio();
      }
      setShowImportPopup(true);
    }
  };

  const handleNewPortfolio = () => {
    formSubmit();
    setShowImportPopup(false);
  };

  function return_button(value) {
    if (value) {
      return (
        <button id="submitportfolio" type="Submit" className="ai-btn primary solid" onClick={editPortfolio}>
          <img src={save} />Save
        </button>
      );
    } else if (!value) {
      return (
        <button id="submitportfolio" type="Submit" className="ai-btn primary solid" onClick={addPortfolio}>
          <img src={save} />Save
        </button>
      );
    }
  }

  return (
    <div className={rendering["main"]}>
      <PageLoader bdopen={bdopen} />
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      ></Popup>
      <form className="ai-form-group portfolioform" onSubmit={handleSubmit}>
        <div className="form-container">
          <label className={rendering["form-label"]} htmlFor="PortfolioName">
            PORTFOLIO NAME <span className="required" />
          </label>
          <input
            className="ai-form-value"
            type="text"
            name="PortfolioName"
            value={PortfolioName}
            placeholder="Enter Portfolio Name"
            onChange={handlePortfolioNameChange}
          />
          <p className="invalidInputMessage">{invalidName}</p>
        </div>
        <div className="form-container">
          <label className={rendering["form-label"]} htmlFor="PortfolioDesc">
            DESCRIPTION
          </label>
          <textarea
            className="ai-form-value"
            name="PortfolioDesc"
            value={PortfolioDescription}
            placeholder="Comments"
            onChange={handlePortfolioDescriptionChange}
          ></textarea>
          <p className="invalidInputMessage">{invalidDescription}</p>
        </div>
        <div className="button-div">
          <button
            id="cancelbutton"
            type="cancel"
            onClick={cancelPortfolio}
            className="ai-btn secondary solid "
          >
            <img src={close}></img>
            Cancel
          </button>
          {return_button(props.editFlag)}
        </div>
        {showImportPopup && (
          <div>
            <div className="popup">
              <div className="popup-object">
                <div className="confirm-heading">
                  <CheckCircleOutlineRoundedIcon className="icon" />
                  Success
                </div>
                <div className="body-message">
                  {props.editFlag !== true ? (
                    <p>{props.importType !== 'csv' || props.importType !== 'plaid' ? "Portfolio successfully added" : "Portfolio Successfully imported and linked!!"}</p>
                  ) : (<p>Portfolio Updated Successfully</p>)}
                </div>
                <button id="portfoliosuccess"
                  className="ai-btn primary solid "
                  onClick={handleNewPortfolio}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default withWidth()(PortfolioForm);
