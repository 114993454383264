import React from "react";
import "./StocksSummary.css";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import withWidth from "@material-ui/core/withWidth";
import Popup from "../Popup/Popup";
import { GetPlaidHoldings } from "../../Apis/GetPlaidHoldings";
import { Tab, Tabs } from "react-bootstrap";
import { addZeroes } from "../Utilities/AddZeros";
import PageLoader from "../PageLoader/PageLoader";

function StocksSummary({ data_source }) {
  const useStyles = makeStyles((theme) => ({
    checkbox_button: {
      color: "#60034c",
      width: "13px",
      height: "13px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const location = useLocation();

  React.useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  const classes = useStyles();
  const [Flag, setFlag] = React.useState(false);
  const [GotID, setGotID] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [popupState, setPopupState] = React.useState(false);
  const [DataRecognised, setDataRecognised] = React.useState([]);
  const [DataUnRecognised, setDataUnRecognised] = React.useState([]);
  const PlaidAccountID = sessionStorage.getItem("PlaidAccountID");
  const PlaidBrokerName = sessionStorage.getItem("PlaidBrokerName");
  const [countUnRecognised, setCountUnRecognised] = React.useState(0);
  const [countRecognised, setCountRecognised] = React.useState(0);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const plaid_headers = [
    "DESCRIPTION",
    "SYMBOL",
    "ASSET TYPE",
    "QUANTITY",
    "PRICE",
    "MARKET VALUE",
  ];
  const csv_headers = ["SYMBOL", "QUANTITY", "PRICE", "MARKET VALUE"];

  if (Flag === false) {
    if (data_source !== "csv") {
      GetPlaidHoldings(PlaidAccountID).then((data) => {
        if (data !== false && data !== 0 && data !== 401) {
          console.log(" data", data);
          setGotID(true);
          var lyst = [];
          var no_plaid_lyst = [];
          var i = 0;
          var j = 0;
          if (data !== null) {
            for (let [key, value] of Object.entries(data["Attributes"]["info"]["recognised"])) {
              lyst.push([
                value.name,
                value.ticker_symbol,
                value.type,
                value.quantity,
                Number(
                  addZeroes(Number(parseFloat(value.close_price).toFixed(2)))
                ).toLocaleString("en-US", currObj),
                Number(
                  addZeroes(Number(parseFloat(value.total_value).toFixed(2)))
                ).toLocaleString("en-US", currObj),
              ]);
              i = i + 1;
              setDataRecognised([...lyst]);
              setCountRecognised(i);
            }
            for (let [key, value] of Object.entries(data["Attributes"]["info"]["un_recognised"])) {
              no_plaid_lyst.push([
                value.name,
                value.ticker_symbol,
                value.type,
                value.quantity,
                Number(
                  addZeroes(Number(parseFloat(value.close_price).toFixed(2)))
                ).toLocaleString("en-US", currObj),
                Number(
                  addZeroes(Number(parseFloat(value.total_value).toFixed(2)))
                ).toLocaleString("en-US", currObj),
              ]);
              j = j + 1;
              setDataUnRecognised([...no_plaid_lyst]);
              setCountUnRecognised(j);
            }
            setBdOpen(false);
          } else if (data === 0) {
            setBdOpen(false);
            setGotID(true)
            setPopupState(true)
            setPopupMessage('We are having issues connecting to our servers, please try again later')
            setAlertType('error')
            setPopupLoginRedirect(false);
          }
          else if (data === false) {
            setBdOpen(false);
            setGotID(true)
            setPopupState(true)
            setPopupMessage('We are having issues fetching data for this account, please try again later')
            setAlertType('error')
            setPopupLoginRedirect(false);
          }
        }
        else if (data === 0) {
          setBdOpen(false);
          setGotID(true)
          setPopupState(true)
          setPopupMessage('We are having issues connecting to our servers, please try again later')
          setAlertType('error')
          setPopupLoginRedirect(false);
        }
        else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        }
        else if (data === 404) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        }
      });
    }
    else {
      if (location.state.CsvStockData !== undefined) {
        setGotID(true);
        var cust_lyst = [];
        var cust_lyst2 = [];
        var no_count = 0;
        var csv_count = 0;
        for (let [key, value] of Object.entries(location.state.CsvStockData["Attributes"]["info"]["recognised"])) {
          cust_lyst.push([
            value.symbol,
            value.quantity,
            Number(
              addZeroes(Number(parseFloat(value.buyprice).toFixed(2)))
            ).toLocaleString("en-US", currObj),
            Number(
              addZeroes(Number(parseFloat(value.total_value).toFixed(2)))
            ).toLocaleString("en-US", currObj),
          ]);
          csv_count = csv_count + 1;
          setDataRecognised([...cust_lyst]);
          setCountRecognised(csv_count);
        }
        for (let [key, value] of Object.entries(location.state.CsvStockData["Attributes"]["info"]["un_recognised"])) {
          cust_lyst2.push([
            value.symbol,
            value.quantity,
            Number(
              addZeroes(Number(parseFloat(0).toFixed(2)))
            ).toLocaleString("en-US", currObj),
            Number(
              addZeroes(Number(parseFloat(0).toFixed(2)))
            ).toLocaleString("en-US", currObj),
          ]);
          no_count = no_count + 1;
          setDataUnRecognised([...cust_lyst2]);
          setCountUnRecognised(no_count);
        }
      } else {
        setBdOpen(false);
        setGotID(true)
        setPopupState(true)
        setPopupMessage("We are having issues reading from the csv file, please try again later")
        setAlertType('error')
      }
    }
    if (GotID === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  return (
    <div className="sheild-summary-wrapper">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Confirm Shield Purchase"
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      />
      {data_source !== "csv" ? (
        <div className="plaid-info">
          <text>
            List of assets imported from your broker {PlaidBrokerName}
          </text>
          <br />
          <br />
          <div>
            <p>
              <text>IMPORT SUMMARY: &nbsp;</text>
              {countRecognised} assets recongnised and {countUnRecognised} not
              recognized from {PlaidBrokerName}
            </p>
          </div>
          <br />
        </div>
      ) : (
        <div className="plaid-info">
          <br />
          <br />
          <div>
            <p>
              <text>IMPORT SUMMARY: &nbsp;</text>
              {countRecognised} assets recongnised and {countUnRecognised} not
              recognized
            </p>
          </div>
          <br />
        </div>
      )}

      <Tabs
        defaultActiveKey="recognize"
        id="plaid-stocks"
        className="plaid-tabs nav-tabs"
      >
        <Tab eventKey="recognize" title="Assets Hedge-able ">
          <div className="ai-accountsummary">
            <table>
              <tbody>
                {data_source !== "csv" ? (
                  <tr>
                    {plaid_headers.map((header) => (
                      <th>
                        <div>{header}</div>
                      </th>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    {csv_headers.map((header) => (
                      <th>
                        <div>{header}</div>
                      </th>
                    ))}
                  </tr>
                )}
                {/* <tr>
                  <th>Description</th>
                  <th>Symbol</th>
                  <th>Asset Type</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Market Value</th>
                </tr> */}
                {DataRecognised.map((row) => (
                  <tr>
                    {row.map((cell) => (
                      <td>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab>
        <Tab eventKey="profile" title="Assets Unhedge-able">
          <div className="ai-accountsummary">
            <table>
              <tbody>
                {data_source !== "csv" ? (
                  <tr>
                    {plaid_headers.map((header) => (
                      <th>
                        <div>{header}</div>
                      </th>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    {csv_headers.map((header) => (
                      <th>
                        <div>{header}</div>
                      </th>
                    ))}
                  </tr>
                )}
                {DataUnRecognised.map((row) => (
                  <tr>
                    {row.map((cell) => (
                      <td>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default withWidth()(StocksSummary);
