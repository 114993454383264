import React from "react";
import "./TransactionDetails.scss";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "@material-ui/core/Checkbox";
import Popup from "../Popup/Popup";
import { FetchTransactionDetails } from "../../Apis/FetchTransactions";
import { useHistory } from "react-router-dom";
import { addZeroes } from "../Utilities/AddZeros";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import searchicon from "../../images/search.svg";
import PageLoader from "../PageLoader/PageLoader";
function TransactionDetails(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  const [Flag, setFlag] = React.useState(false);
  const [GotID, setGotID] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [TransactionData, setTransactionData] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState("ASC");
  const [searchKey, setSearchKey] = React.useState("");
  const [sortIcon, setSortIcon] = React.useState("Assets/sort_icon.png");
  const [sortCol, setSortCol] = React.useState("");
  const [filterCol, setFilterCol] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterMinValue, setfilterMinValue] = React.useState(null);
  const [filterMaxValue, setfilterMaxValue] = React.useState(null);
  const [filterStartDate, setfilterStartDate] = React.useState(new Date());
  const [filterEndDate, setfilterEndDate] = React.useState(new Date());
  const [filterFormErrors, setFilterFormErrors] = React.useState({});
  const [unfilteredTransactionData, setunfilteredTransactionData] = React.useState([]);
  const [filterList, setFilterList] = React.useState([]);
  const [transactionStatus, setTransactionStatus] = React.useState({
    completed: false,
    pending: false,
    failed:false
  });
  
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [TransactionApiCount, setTransactionApiCount] = React.useState(0);
  const [PortfolioName,setPortfolioName] = React.useState("");
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);

  const history = useHistory();
  const PortfolioTable = () => history.push({
    pathname: '/PortfolioTable',
    state: { portfolioid: selectedPortfolioId}
  });

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  var selectedPortfolioId = 0;
  const GoToPortfolioScreen = (row_val) => {
    if (row_val["portfolioid"] !== "") {
        setBdOpen(true);
        sleep(200).then(() => {
          selectedPortfolioId = row_val["portfolioid"]
          setPortfolioName(row_val["portfolioname"])
          PortfolioTable();
        });
      } else {
        setPopupState(true);
        setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
      }
  };

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioName", PortfolioName);
  }, [PortfolioName]);

  function transactionStatusColor(status) {
    if (status === "completed") {
      return "#38bb55";
    } else if (status === "pending") {
      return "#ffb800";
    } else if (status === "failed") {
      return "#ef3838";
    }
  }

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  if (Flag === false && TransactionApiCount === 0) {
    setTransactionApiCount(TransactionApiCount + 1);
    FetchTransactionDetails().then((data) => {
      if (data !== false && data !== 0 && data !== null && data !== 401 && data !== 404 ) {
        setGotID(true);
        var lyst = [];
        for (let [key, value] of Object.entries(data)) {
          lyst.push({
            portfolioid: value.portfolioid,
            portfolioname: value.portfolioname,
            status: value.status,
            transaction_date_time: value.transaction_date_time,
            transaction_id: value.transaction_id,
            transaction_type: value.transaction_type,
            amount: parseFloat(value.amount).toFixed(2)
          });
        }
        setTransactionData([...lyst]);
        setunfilteredTransactionData([...lyst]);
        sleep(100).then(() => {
            if (lyst.length === 0) {
                setPopupState(true);
                setPopupMessage(
                    "No transactions found. Please deposit funds to get started"
                );
                setAlertType("warning");
            }
        });
        setBdOpen(false);
      } else if (data === false) {
        setPopupMessage("Unable to fetch data. Please try again later.");
        setPopupState(true);
        setPopupLoginRedirect(false);
        setPopupTitle("Unable to fetch data");
        setBdOpen(false);
      } else if (data === 0) {
        setPopupMessage("We are facing server issues, please try again later.");
        setPopupState(true);
        setPopupLoginRedirect(false);
        setPopupTitle("Server Error");
        setBdOpen(false);
      }
      else if (data === null) {
        setPopupState(true);
        setPopupMessage(
          "No client wallet found. Please create a payment instruction to transfer funds or contact support@adaptive-investments.com."
        );
        setPopupLoginRedirect(false);
        setAlertType("warning");
        setPopupTitle("No transactions found");
        setBdOpen(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(false);
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
      else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Terminated");
      }
    });
    if (GotID === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  const requestSort = (col) => {
    let sortCol = col;
    if (sortOrder === "ASC") {
      const sortedTransactionData = [...TransactionData];
      if (
        [
          "portfolioname",
          "status",
          "transaction_date_time",
        ].indexOf(sortCol) >= 0
      ) {
        sortedTransactionData.sort((a, b) =>
          a[sortCol].toLowerCase() > b[sortCol].toLowerCase() ? 1 : -1
        );
      } else {
        sortedTransactionData.sort((a, b) =>
          Number(parseFloat(a[sortCol]).toFixed(2)) >
            Number(parseFloat(b[sortCol]).toFixed(2))
            ? 1
            : -1
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_asc_icon.png");
      setTransactionData(sortedTransactionData);
      setSortOrder("DESC");
    }
    if (sortOrder === "DESC") {
      const sortedTransactionData = [...TransactionData];
      if (
        [
          "portfolioname",
          "status",
          "transaction_date_time",
        ].indexOf(sortCol) >= 0
      ) {
        sortedTransactionData.sort((a, b) =>
          a[sortCol].toLowerCase() < b[sortCol].toLowerCase() ? 1 : -1
        );
      } else {
        sortedTransactionData.sort((a, b) =>
          Number(parseFloat(a[sortCol]).toFixed(2)) <
            Number(parseFloat(b[sortCol]).toFixed(2))
            ? 1
            : -1
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_desc_icon.png");
      setTransactionData(sortedTransactionData);
      setSortOrder("ASC");
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event, col) => {
    setAnchorEl(event.currentTarget);
    setFilterCol(col);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterCol(null);
    setfilterMinValue(null);
    setfilterMaxValue(null);
    setfilterStartDate(new Date());
    setfilterEndDate(new Date());
    setFilterFormErrors({});
    setTransactionStatus({ completed: false, pending: false, failed:false });
  };

  const validateValueFilter = () => {
    let validFilterFlag = true;
    let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
    let error = {};
    if (!filterMinValue) {
      validFilterFlag = false;
      error.minfilter = "Please enter min value";
    }
    if (!filterMaxValue) {
      validFilterFlag = false;
      error.maxfilter = "Please enter max value";
    }
    if (
      Number(parseFloat(filterMaxValue).toFixed(2)) <
      Number(parseFloat(filterMinValue).toFixed(2))
    ) {
      error.maxfilter = "Please enter max value greater than min value";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMinValue)) {
      error.minfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMaxValue)) {
      error.maxfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    setFilterFormErrors(error);
    return validFilterFlag;
  };

  const validateDateFilter = () => {
    let validFilterFlag = true;
    let sdate = filterStartDate.toISOString().split("T")[0].toString();
    let edate = filterEndDate.toISOString().split("T")[0].toString();
    let error = {};
    if (!sdate) {
      validFilterFlag = false;
      error.filterStartDate = "Please enter a start date to apply filter";
    }
    if (!edate) {
      validFilterFlag = false;
      error.filterStartDate = "Please enter a end date to appy filter";
    }
    if (sdate > edate) {
      validFilterFlag = false;
      error.filterStartDate =
        "Start date can not be greater than end date to apply filter";
    }
    setFilterFormErrors(error);
    return validFilterFlag;
  };

  const validateStatusFilter = () => {
    let selectedFlag = true;
    let error = {};
    if (!(transactionStatus.completed || transactionStatus.pending || transactionStatus.failed)) {
      error.filterStatusCheckbox = "Please select atleast one shield status";
      selectedFlag = false;
    }
    setFilterFormErrors(error);
    return selectedFlag;
  };

  const requestFilter = (e) => {
    e.preventDefault();
    if (filterCol === "amount" && validateValueFilter()) {
      const filteredTransactionData = TransactionData.filter(
        (data) =>
          Number(parseFloat(data[filterCol]).toFixed(2)) >=
            Number(parseFloat(filterMinValue).toFixed(2)) &&
          Number(parseFloat(data[filterCol]).toFixed(2)) <=
          Number(parseFloat(filterMaxValue).toFixed(2))
      );
      setTransactionData(filteredTransactionData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
        enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
        status: transactionStatus,
      });
      handleClose();
    } else if (filterCol === "status" && validateStatusFilter()) {
      let statuslyst = [];
      for (const [key, value] of Object.entries(transactionStatus)) {
        if (value === true) {
          statuslyst.push(key);
        }
      }
      const filteredTransactionData = TransactionData.filter((data) =>
        statuslyst.includes(data[filterCol])
      );
      setTransactionData(filteredTransactionData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: filterStartDate.toISOString().split("T")[0].toString()+" 00:00:00",
        enddate: filterEndDate.toISOString().split("T")[0].toString()+" 23:59:59",
        status: transactionStatus,
      });
      handleClose();
    } else if (filterCol === "transaction_date_time" && validateDateFilter()) {
      let fsdate = filterStartDate.toISOString().split("T")[0].toString();
      let fedate = filterEndDate.toISOString().split("T")[0].toString();

      const filteredTransactionData = TransactionData.filter(
        (data) => data[filterCol].split(" ")[0].toString() >= fsdate && data[filterCol].split(" ")[0].toString() <= fedate
      );

      setTransactionData(filteredTransactionData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
        startdate: fsdate+" 00:00:00",
        enddate: fedate+" 23:59:59",
        status: transactionStatus,
      });
      handleClose();
    }
  };

  const removeFilter = (data) => {
    let lyst = [...unfilteredTransactionData];
    var removefilterIndex = filterList
      .map(function (item) {
        return item.filterno;
      })
      .indexOf(data.filterno);
    filterList.splice(removefilterIndex, 1);
    setFilterList([...filterList]);
    if (filterList.length !== 0) {
      for (let [key, value] of Object.entries(filterList)) {
        if (value.column === "amount") {
          lyst = lyst.filter(
            (data) =>
              Number(parseFloat(data[value.column]).toFixed(2)) >=
              Number(parseFloat(value.minvalue).toFixed(2)) &&
              Number(parseFloat(data[value.column]).toFixed(2)) <=
              Number(parseFloat(value.maxvalue).toFixed(2))
          );
          setTransactionData([...lyst]);
        } else if (value.column === "transaction_date_time") {
          lyst = lyst.filter(
            (data) =>
              data[value.column] >= value.startdate.split(" ") &&
              data[value.column] <= value.enddate.split(" ")
          );
          setTransactionData([...lyst]);
        } else if (value.column === "status") {
          let statuslyst = [];
          for (const [k, v] of Object.entries(value.status)) {
            if (v === true) {
                statuslyst.push(k);
            }
          }
          lyst = lyst.filter((data) => statuslyst.includes(data[value.column]));
          setTransactionData([...lyst]);
        }
      }
    } else {
        setTransactionData([...unfilteredTransactionData]);
    }
  };

  const handleTransactionStausChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setTransactionStatus({ ...transactionStatus, [e.target.name]: value });
  };

  const headers = [
    {
      tableHeader: "PORTFOLIO",
      sort_icon: (
        <IconButton onClick={() => requestSort("portfolioname")}>
          <img
            src={sortCol === "portfolioname" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
    },
    {
      tableHeader: "TRANSACTION ID",
      sort_icon: "",
      filter_icon: "",
    },
    {
      tableHeader: "TRANSACTION AMOUNT",
      sort_icon: (
        <IconButton onClick={() => requestSort("amount")}>
          <img
            src={
              sortCol === "amount"
                ? sortIcon
                : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "amount")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
    },
    {
      tableHeader: "DATE/TIME",
      sort_icon: (
        <IconButton onClick={() => requestSort("transaction_date_time")}>
          <img
            src={
              sortCol === "transaction_date_time"
                ? sortIcon
                : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "transaction_date_time")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
    },
    {
      tableHeader: "STATUS",
      sort_icon: (
        <IconButton onClick={() => requestSort("status")}>
          <img
            src={sortCol === "status" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "status")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
    },
    {
        tableHeader: "TRANSACTION TYPE",
        sort_icon: "",
        filter_icon: "",
    }
  ];

  function chip_data(data) {
    if (data.column === "amount") {
      let label = "Value $" + data.minvalue + " - $" + data.maxvalue;
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    } else if (data.column === "transaction_date_time") {
      let label = "Date Between : " + data.startdate + " - " + data.enddate;
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    } else if (data.column === "status") {
      let label = "Status ";
      for (const [k, v] of Object.entries(data.status)) {
        if (v === true) {
          label = label + " - " + k;
        }
      }
      return (
        <Chip
          className="chip"
          label={label}
          variant="outlined"
          sx={{ color: "#E58B76" }}
          deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
          onDelete={() => removeFilter(data)}
        />
      );
    }
  }

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      ></Popup>
      <div className="search_bar">
      <div className="searchinput">
        <img src={searchicon}></img>
        <input
          className="ai-form-value"
          type="text"
          placeholder="Search"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
        />
      </div>
      </div>
      <Paper className="filter-paper">
        {filterList.map((data) => chip_data(data))}
      </Paper>
      <div className="table_holder">
        <TableContainer className="table_height">
          <Table  stickyHeader aria-label="sticky table">
          {
            <TableHead>
                  <TableRow>
                {headers.map((header) => (
                  <TableCell>
                    <div className="stck_table_heading">
                      {header.tableHeader}
                      {header.sort_icon}
                      {header.filter_icon}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {TransactionData.filter((val) => {
              if (searchKey === "") {
                return val;
              } else if (
                val.portfolioname.toLowerCase().includes(searchKey.toLowerCase())
              ) {
                return val;
              }
            }).map((row) => (
              <TableRow>
                <TableCell>
                  <a onClick={() => GoToPortfolioScreen(row)}>
                    {row.portfolioname}
                  </a>
                </TableCell>
                <TableCell>{row.transaction_id}</TableCell>
                <TableCell className="mono">
                  ${row.amount.toLocaleString("en-US", currObj)}
                </TableCell>
                <TableCell>{row.transaction_date_time}</TableCell>
                <TableCell className="risk-transform">
                  <a style={{ color: transactionStatusColor(row.status) }}>
                    {row.status}
                 </a>
                </TableCell>
                <TableCell>{row.transaction_type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </TableContainer>
        {filterCol === "transaction_date_time" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group p16">
              <DatePicker
                className="ai-form-value"
                placeholder="Start Date"
                selected={filterStartDate}
                onChange={(date) => setfilterStartDate(date)}
                dateFormat="yyyy-MM-dd"
              />
              <p className="error-message">
                {filterFormErrors.filterStartDate}
              </p>
              <p className="filter-label">To</p>
              <DatePicker
                className="ai-form-value"
                placeholder="End Date"
                selected={filterEndDate}
                onChange={(date) => setfilterEndDate(date)}
                dateFormat="yyyy-MM-dd"
              />
              <p className="error-message">{filterFormErrors.filterEndDate}</p>
              <div className="button-div">
                <button
                  type="Submit"
                  className="ai-btn primary small solid"
                  onClick={requestFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </Popover>
        )}
        {filterCol === "amount" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group p16">
              <input
                type="text"
                className="ai-form-value"
                placeholder="Min Value"
                onChange={(event) => setfilterMinValue(event.target.value)}
              />
              <p className="error-message">{filterFormErrors.minfilter}</p>
              <p className="filter-label">To</p>
              <input
                type="text"
                className="filter-inputs-max"
                placeholder="Max Value"
                onChange={(event) => setfilterMaxValue(event.target.value)}
              />
              <br />
              <p className="error-message">{filterFormErrors.maxfilter}</p>
              <button
                type="Submit"
                className="ai-btn primary small solid"
                onClick={requestFilter}
              >
                Apply
              </button>
            </div>
          </Popover>
        )}
        {filterCol === "status" && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <div className="ai-form-group">
              <label className="check-box-labels low">
                <Checkbox
                  className="check-box"
                  checked={transactionStatus.completed}
                  onChange={handleTransactionStausChange}
                  name="completed"
                />{" "}
                Completed
              </label>
              <label className="check-box-labels medium">
                <Checkbox
                  type="checkbox"
                  className="check-box"
                  checked={transactionStatus.pending}
                  onChange={handleTransactionStausChange}
                  name="pending"
                />{" "}
                Pending
              </label>
              <label className="check-box-labels high">
                <Checkbox
                  type="checkbox"
                  className="check-box"
                  checked={transactionStatus.failed}
                  onChange={handleTransactionStausChange}
                  name="failed"
                />{" "}
                Failed
              </label>
              <p className="error-message">
                {filterFormErrors.filterStatusCheckbox}
              </p>
              <button
                type="Submit"
                className="ai-btn primary small solid"
                onClick={requestFilter}
              >
                Apply
              </button>
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
}

export default withWidth()(TransactionDetails);