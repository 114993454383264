import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
} from "amazon-cognito-identity-js";

const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_ADVISOR_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_ADVISOR_CLIENT_ID,
};

export default new CognitoUserPool(poolData);
