import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import Popup from "../Popup/Popup";
import Tooltip from "react-tooltip-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageLoader from "../PageLoader/PageLoader";


function CustomerCsvLineItemChanges(props) {
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();

    const location = useLocation();
    React.useEffect(() => {
    }, [location]);

    const [Flag, setFlag] = React.useState(false);
    const [HedgeableData, sethedgeabledata] = React.useState([]);
    const [UnhedgeableData, setunhedgeabledata] = React.useState([]);
    const [bdopen, setBdOpen] = React.useState(true);
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("warning");
    const [APICount, setAPICount] = React.useState(0);
    const [APICalled, setAPICalled] = React.useState(false);
    const CSVData = location.state.CsvData ?? [];

    const FetchCsvUpdates = () => {
        setBdOpen(true);
        try {
            console.log("csv data", location.state.CsvData)
            var lyst = [];
            var lyst1 = [];
            var i = 1;
            for (let [key, value] of Object.entries(CSVData['recognised'])) {
                lyst.push([
                    value.symbol,
                    value.quantity_new,
                    value.quantity_old,
                    value.change.toUpperCase(),
                ]);
                i = i + 1;
                sethedgeabledata([...lyst]);
            }
            for (let [key, value] of Object.entries(CSVData['un_recognised'])) {
                lyst1.push([
                    value.symbol,
                    value.quantity_new,
                    value.quantity_old,
                    value.change.toUpperCase(),
                ]);
                i = i + 1;
                setunhedgeabledata([...lyst1]);
            }
            setBdOpen(false);
        } catch (error) {
            setBdOpen(false);
            setAPICalled(true);
            setPopupState(true);
            setPopupMessage(
                "We are facing issues connecting our servers, please try again later"
            );
            setAlertType("error");
            return;
        }
    }

    if (Flag === false) {
        if (APICalled === false && APICount === 0) {
            setAPICount(1);
            setAPICalled(true);
            FetchCsvUpdates();
        }
        if (APICalled === true) {
            setFlag(true);
            setBdOpen(false);
        }
    }

    const headers = [
        { title: "SYMBOL" },
        {
            title: "NEW QUANTITY",
        },
        {
            title: "OLD QUANTITY",
        },
        { title: "" },
    ];

    return (
        <div className="portfolio-table">
           <PageLoader bdopen={bdopen}/>
            <Popup
                trigger={popupState}
                setTrigger={setPopupState}
                title={"Import Status"}
                content={popupMessage}
                alertType={alertType}
            ></Popup>
            <div className="table_holder portfoliotable stocklist">
                {props.hedgeable && (
                    <div className="table_holder">
                        <TableContainer className="table_height">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableCell>
                                                <Tooltip content={header.tooltip}>
                                                    <div className="stck_table_heading">{header.title}</div>
                                                </Tooltip>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {HedgeableData.length > 0 ? (
                                        HedgeableData.map((row) => (
                                            <TableRow>
                                                {row.map((cell) => (
                                                    <TableCell>{cell}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <div style={{ textAlign: "center" }}>
                                            <p>No Changes found.</p>
                                        </div>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
                {!props.hedgeable && (
                    <div className="table_holder">
                        <TableContainer className="table_height">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableCell>
                                                <Tooltip content={header.tooltip}>
                                                    <div className="stck_table_heading">{header.title}</div>
                                                </Tooltip>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {UnhedgeableData.length > 0 ? (
                                        UnhedgeableData.map((row) => (
                                            <TableRow>
                                                {row.map((cell) => (
                                                    <TableCell>{cell}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <div style={{ textAlign: "center" }}>
                                            <p>No Changes found.</p>
                                        </div>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </div>
        </div>

    );
}

export default withWidth()(CustomerCsvLineItemChanges);
