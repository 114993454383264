import React from "react";
import "./PortfolioUpload.css";
import PortfolioUploadTable from "../../Components/PortfolioUpload/PortfolioUploadTable";
import PageHeader from "../../Components/PageHeader/PageHeader";
import NavBar from "../../Components/Navbar/Navbar_official";

export default function PortfolioUpload() {
  return (
    <div className="ai-content-part">
      <NavBar />
      <PageHeader
        heading="My Portfolios"
        backButton={false}
        addButton={false}
      />
      <div className="upload_area">
        <div className="message_upload">
          <h2>Create or import a portfolio</h2>
        </div>
        <div className="upload_options">
          <PortfolioUploadTable />
        </div>
        <div className="didYouKnow">
          <h3>DID YOU KNOW?</h3>
          <p className="dyk_message">
            Adaptive Shield is protecting 1,206 Portfolios worth $758 million
            right now.
          </p>
        </div>
      </div>
    </div>
  );
}
