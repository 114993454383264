import React from "react";
import { useState } from "react";
import "./PortfolioSummary.css";
import Popup from "../Popup/Popup";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth from "@material-ui/core/withWidth";
import { GetUserPortfolios } from "../../Apis/GetUserPortfolios";
import { addZeroes } from "../Utilities/AddZeros";
import Tooltip from "react-tooltip-lite";
import { newConvertDate } from "../Utilities/ConvertDate";
import { GetStocks } from "../../Apis/GetStocks";
import PageLoader from "../PageLoader/PageLoader";
import { usePortfolio } from '../PortfolioContext/PortfolioContext';

function PortfolioSummary(props) {
  const [Flag, setFlag] = useState(false);
  const [PortfolioID, setPortfolioID] = useState("");
  const [PortfolioName, SetPortfolioName] = useState("");
  const [PortfolioTotalStocks, setPortfolioTotalStocks] = useState(0);
  const [PortfolioTotalValue, setPortfolioTotalValue] = useState(0);
  const [PortfolioShieldStatus, setPortfolioShieldStatus] = useState();
  const [PortfolioSummaryData, SetPortfolioSummaryData] = useState([]);
  const [bdopen, setBdOpen] = useState(true);
  const [portfolioIDlist, setPortfolioIDList] = React.useState([]);
  const [stocksData, setStocksData] = React.useState([]);
  const { setPortfolioNames } = usePortfolio();

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioName", PortfolioName);
  }, [PortfolioName]);

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioTotalStocks", PortfolioTotalStocks);
  }, [PortfolioTotalStocks]);

  React.useEffect(() => {
    window.sessionStorage.setItem("PortfolioTotalValue", PortfolioTotalValue);
  }, [PortfolioTotalValue]);

  React.useEffect(() => {
    window.sessionStorage.setItem(
      "PortfolioShieldStatus",
      PortfolioShieldStatus
    );
  }, [PortfolioShieldStatus]);

  const history = useHistory();
  const PortfolioTable = () => history.push({
    pathname: '/PortfolioTable',
    state: {
      portfolioid: selectedPortfolioId,
      stocksData: stocksData,

    }
  });
  const PortfolioUpload = () => history.push("./PortfolioUpload");

  const ShieldRecommendation = (portfolio_id, name) => {
    setBdOpen(true);
    setPortfolioID(portfolio_id);
    SetPortfolioName(name);
    GetStocks(portfolio_id).then((data) => {
      console.log("res", data );
      if (data !== null && data !== false && data !== undefined && data !== 0 && Object.keys(data).length !== 0 && data !== 401) {
        if (Object.keys(data["hedgeable"]).length !== 0){
        setStocksData(data);
        setPortfolioTotalStocks(data["number_of_lineitems"]);
        setPortfolioTotalValue(data["total_portfolio_value"]);
        history.push({
          pathname: "./PortfolioSolutions",
          state: {
            portfolioid: portfolio_id,
            stocksData: data
          }
        }
        );
        } else {
          setPopupState(true);
          setPopupMessage(
            "Please add atleast one recognized stock to your portfolio to proceed."
          );
          setAlertType("error");
          setBdOpen(false);
        }
      } else {
        setPopupState(true);
        setPopupMessage(
          "Please add atleast one stock to your portfolio to proceed."
        );
        setAlertType("error");
        setBdOpen(false);
      }
    });
  };


  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  var currObj = {
    style: "currency",
    currency: "USD",
  };


  function GetRiskBarColor(risk_value) {
    if (risk_value <= 15) {
      return "#38bb55";
    } else if (risk_value <= 20 && risk_value > 15) {
      return "#ffa654";
    } else if (risk_value <= 30 && risk_value > 20) {
      return "#ed6663";
    } else if (risk_value > 30) {
      return "#aa4a48";
    }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  var selectedPortfolioId = 0;

  const PortfolioSelected = (selectedValues) => {
    if (!isButtonHovered) {
      setPortfolioID(selectedValues.id);
      SetPortfolioName(selectedValues.name);
      setPortfolioTotalStocks(selectedValues.no_stocks);
      setPortfolioTotalValue(parseFloat(selectedValues.port_value).toFixed(2));
      setPortfolioShieldStatus(selectedValues.shieldstatus);

      if (selectedValues.id !== "") {
        setBdOpen(true);
        sleep(200).then(() => {
          selectedPortfolioId = selectedValues.id;
          PortfolioTable();
        });
      } else {
        setPopupState(true);
        setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
      }
    }
  };

  React.useEffect(() => {
    setBdOpen(true);
      try {
        GetUserPortfolios().then((data) => {
          console.log(" data ", data);
          var lyst = [];
          let namesList = [];
          if (data !== false && data !== 0 && data !== undefined && data !== 401 && data !== 404 ) {
            setPortfolioIDList(Object.keys(data));
            for (let [key, value] of Object.entries(data)) {
              setPortfolioID(key);
              lyst.push({
                id: key,
                name: value.portfolioname,
                shieldstatus: value.shieldstatus,
                no_stocks: value.nooflineitems,
                port_value: parseFloat(value.totalportfoliovalue).toFixed(2),
                shieldexpirationdate: newConvertDate(value.shieldexpirationdate),
                risk_wo_shield: value.risk_wo_shield,
                market_risk: value.market_risk,
                risk_w_shield: value.risk_w_shield,
                protection_level: value.protection_level * 100,
              });
              namesList.push(value.portfolioname);
            }
            SetPortfolioSummaryData([...lyst]);
            setPortfolioNames(namesList);
            sleep(100).then(() => {
              if (lyst.length === 0) {
                PortfolioUpload();
                console.log("Empty");
              }
            });
            setBdOpen(false);
            setFlag(true);
          } else if (data === false) {
            setBdOpen(false);
            // setPopupState(true);
            // setPopupMessage(
            //   "No Portfolios are available for this user, redirecting you to portfolio upload page."
            // );
            // setAlertType("error");
            PortfolioUpload();
          } else if (data === 0 || data === undefined) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "We are facing issues connecting our servers, please try again later"
            );
            setAlertType("error");
            setPopupLoginRedirect(false);
            return;
          } else if (data === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
          }
          else if (data === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for  security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
          }
        });
      } catch (error) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "We are facing issues connecting our servers, please try again later"
        );
        setPopupLoginRedirect(false);
        setAlertType("error");
        return;
      }
  }, []);



  const handleButtonMouseEnter = () => {
    setIsButtonHovered(true);
  };

  const handleButtonMouseLeave = () => {
    setIsButtonHovered(false);
  };


  return (
    <div className="portfolio_page">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Portfolio Summary"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <table className="portfoliolist">
        <thead>
          <tr>
            <th>Portfolio Name</th>
            <th>Current market value</th>
            <th>Protection Level</th>
            <th>Shield Expires</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody id="portfoliolist">
          {PortfolioSummaryData.map((value, i) => (
            <tr
              className="portfolio-card-header"
              onClick={() => PortfolioSelected(value)}
            >
              <td id="portfolioname" className="portfolioname">
                {value.shieldstatus === "inactive" && (
                  <img src="Assets/shieldredl.png" />
                )}
                {value.shieldstatus === "active" && (
                  <img src="Assets/shieldgreenl.png" />
                )}
                {value.shieldstatus === "partial" && (
                  <img src="Assets/shieldorangel.png" />
                )}
                {value.name}
              </td>
              <td>
                {Number(
                  addZeroes(Number(parseFloat(value.port_value).toFixed(2)))
                ).toLocaleString("en-US", currObj)}
              </td>
              <td className="pLevel">
                {value.shieldstatus === "inactive" ? (
                  <div>
                    <button className="CompareShieldsButton"
                      onMouseEnter={handleButtonMouseEnter}
                      onMouseLeave={handleButtonMouseLeave}
                      onClick={() => ShieldRecommendation(value.id, value.name)}>Compare Shield</button>
                  </div>
                ) : (
                  <div className="ShieldValue">
                    {value.protection_level}%
                  </div>
                )}

              </td>
              <td>
                {value.shieldstatus === "active" ||
                  value.shieldstatus === "partial" ? (
                  <div className="shield-expiry">
                    <p> Shield Expires {value.shieldexpirationdate}</p>
                  </div>
                ) : (
                  <>
                  </>
                  // <button
                  //   className="ai-btn ex-small line primary"
                  //   onClick={() => PortfolioSelected(value)}
                  // >
                  //   <img src="Assets/activateshieldbutton.png" />
                  //   Buy Shield
                  // </button>
                )}
              </td>
              <td>
                {Flag === true ? (
                  <div className="portfolio-bars">
                    <div className="portfolio-risk-bar">
                      <div className="market-risk-progress">
                        <div
                          className="market-risk-bar"
                          style={{
                            opacity: 1,
                            background:
                              value.market_risk !== undefined
                                ? GetRiskBarColor(value.market_risk * 100)
                                : 0,
                            width:
                              value.market_risk !== undefined
                                ? `${value.market_risk * 200}%`
                                : 0,
                          }}
                        ></div>
                      </div>
                      <p>
                        Market risk
                        <Tooltip
                          hoverDelay="1000"
                          content="VIX (CBOE S&P500 Implied Volatility Index). Low
                          = 0% - 15% Medium = 15% - 20% High = 20% - 30%
                          Very High = 30% +"
                        >
                          <img src="Assets/risk-info.png"></img>
                        </Tooltip>
                      </p>
                    </div>
                    <div className="portfolio-risk-bar">
                      <div className="market-risk-progress">
                        <div
                          className="progress-done-2"
                          style={{
                            opacity: 1,
                            background:
                              value.risk_wo_shield !== undefined
                                ? GetRiskBarColor(value.risk_wo_shield * 100)
                                : 0,
                            width:
                              value.risk_wo_shield !== undefined
                                ? `${value.risk_wo_shield * 200}%`
                                : 0,
                          }}
                        ></div>
                      </div>
                      <p>
                        Portfolio risk
                        <Tooltip
                          hoverDelay="1000"
                          content="Analyzed risk level of portfolio: semi variance measure (annualized standard deviation of downside risk or negative returns)
                          Low = 0% - 15%
                          Medium = 15% - 20%
                          High = 20% - 30%
                          Very High = 30% +"
                        >
                          <img src="Assets/risk-info.png"></img>
                        </Tooltip>
                      </p>
                    </div>
                    <div className="portfolio-risk-bar">
                      {value.shieldstatus === "active" ||
                        value.shieldstatus === "partial" ? (
                        <div className="portfolio-risk-bar">
                          <div className="market-risk-progress">
                            <div
                              className="progress-done-3"
                              style={{
                                opacity: 1,
                                background:
                                  value.risk_w_shield !== undefined
                                    ? GetRiskBarColor(value.risk_w_shield * 100)
                                    : 0,
                                width:
                                  value.risk_w_shield !== undefined
                                    ? `${value.risk_w_shield * 200}%`
                                    : 0,
                              }}
                            ></div>
                          </div>
                          <p>
                            Shielded risk
                            <Tooltip
                              hoverDelay="1000"
                              content="Analyzed risk level of portfolio, accounting for any Shield in place
                              Low = 0% - 15%
                              Medium = 15% - 20%
                              High = 20% - 30%
                              Very High = 30% +"
                            >
                              <img src="Assets/risk-info.png"></img>
                            </Tooltip>
                          </p>
                        </div>
                      ) : (
                        <div className="portfolio-risk-bar">
                          <div className="market-risk-progress inactive">
                            <div
                              className="progress-done-inactive"
                              style={{ opacity: 1 }}
                            ></div>
                          </div>
                          {/* <p>
                            Turn on shield to reduce risk{" "}
                            <Tooltip
                              hoverDelay="1000"
                              content="Analyzed risk level of portfolio, accounting for any Shield in place
                              Low = 0% - 15%
                              Medium = 15% - 20%
                              High = 20% - 30%
                              Very High = 30% +"
                            >
                              <img src="Assets/risk-info.png"></img>
                            </Tooltip>
                          </p> */}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ margin: "auto" }}>
                    <CircularProgress color="inherit" />
                  </div>
                )}
              </td>
              <td className="portfolio-summary-risk">
                <Tooltip content="Portfolio Shield will reduce your portfolio's downside risk.">
                  <img src="Assets/card-icons.png" />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default withWidth()(PortfolioSummary);
