import React from "react";
import "./PortfolioUpload.css";
import PageLoader from "../PageLoader/PageLoader";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import { GetPlaidPublicToken } from "../../Apis/GetPlaidPublicToken";
import { SendPlaidAccessToken } from "../../Apis/SendPlaidAccessToken";
import { UploadCustomerFile } from "../../Apis/UploadCustomerFile";
// import UserPool from "../../UserPool";
import Popup from "../Popup/Popup";

export default function PortfolioUploadTable() {
  const history = useHistory();

  const uploadRoute = (route) => history.push(route);
  const StocksSummary = () => history.push({
    pathname: '/StocksSummary',
    state: {
      CsvStockData: CsvStockData,
    }
  });

  const [Flag, setFlag] = React.useState(false);
  const [GotData, setGotData] = React.useState(false);
  const [Link, setLink] = React.useState("");
  const [PlaidBrokerName, setPlaidBrokerName] = React.useState("");
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const hiddenFileInput = React.useRef(null);
  var CsvStockData = ""
  const [bdopen, setBdOpen] = React.useState(false);

  const selectedAcType = sessionStorage.getItem("selectedAcType");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

  React.useEffect(() => {
    window.sessionStorage.setItem("PlaidBrokerName", PlaidBrokerName);
  }, [PlaidBrokerName]);

  if (Flag === false) {
    GetPlaidPublicToken().then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        setGotData(true);
        setLink(data);
      }
      else if (data === false) {
        setPopupState(true);
        setPopupMessage("Unable to retrieve public token. Please try again or contact support@adaptive-investments.com.");
        setAlertType("error");
        setPopupTitle("Error");
        setPopupLoginRedirect(false);
        setGotData(true)
      }
      else if (data === 0) {
        setPopupState(true)
        setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.")
        setAlertType("error")
        setPopupTitle("Error")
        setPopupLoginRedirect(false);
        setGotData(true)
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }

    });
    if (GotData === true) {
      setFlag(true);
    }
  }

  const onSuccess = useCallback((token, metadata) => {
    SendPlaidAccessToken(token).then((access_token) => {
      if (access_token !== false && access_token !== 0 && access_token !== 401 && access_token !== 404) {
        setBdOpen(false);
        setPlaidBrokerName(metadata["institution"]["name"]);
        uploadRoute("/PlaidAccounts");
      } else if (access_token === false) {
        setBdOpen(false);
        setPopupState(true)
        setPopupMessage('Unable to retrieve Plaid token. Please try again or contact support@adaptive-investments.com.')
        setPopupTitle("Unable to fetch aceess token")
        setAlertType("error")
        setPopupLoginRedirect(false);
      }
      else if (access_token === 0) {
        setBdOpen(false);
        setPopupState(true)
        setPopupMessage("We are unable to connect to our servers at this point, please try again later")
        setPopupTitle("Unable to connect to servers")
        setAlertType("error")
        setPopupLoginRedirect(false);
      } else if (access_token === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Server issue. Please try again or contact support@adaptive-investments.com."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      } else if (access_token === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
    });
    console.log("public", token);
    console.log(" broker name ", metadata["institution"]["name"]);
  }, []);

  const config = {
    token: Link,
    onSuccess,
  };

  const { open, ready, error } = usePlaidLink(config);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleUpload = (event) => {
    setBdOpen(true);
    event.persist();
    const [file] = event.target.files;
    var fileExt = file.name.split('.').pop();
    if (fileExt === "csv") {
      const reader = new FileReader();
      reader.onloadend = (onLoadEndEvent) => {
        UploadCustomerFile(onLoadEndEvent.target.result.split(",")[1]).then((data) => {
          if (data !== false && data !== 0 && data !== 401 && data !== 404) {
            setGotData(true);
            CsvStockData = data;
            setBdOpen(false);
            StocksSummary();
          } else if (data === false) {
            setBdOpen(false);
            setGotData(true);
            setPopupState(true);
            setPopupMessage(
              "We are unable to proccess the csv, please try again later."
            );
            setPopupTitle("Unable to process CSV");
            setPopupLoginRedirect(false);
          } else if (data === 0) {
            setBdOpen(false);
            setGotData(true);
            setPopupState(true);
            setPopupMessage(
              "We are facing server issues, please try again later."
            );
            setPopupTitle("Server Error");
            setPopupLoginRedirect(false);
          }
          else if (data === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
          }
          else if (data === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            setPopupTitle("Session Expired");
          }
        });
      }
      reader.readAsDataURL(file);
    } else {
      setBdOpen(false);
      setPopupState(true);
      setPopupMessage("Unable to process CSV. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
    }
  };

  const initiateAuth = () => {
    const state = Math.random().toString(36).substring(2, 15);
    localStorage.setItem('oauth_state', state);
    const clientId = 'test-adaptive';
    const currentRedirectUri = 'https://d2gkko3cve1nl4.cloudfront.net/nitrogen_clients';
    window.location.href = `https://pro.riskalyze.com/oauthconnect?response_type=code&state=${state}&client_id=${clientId}&redirect_uri=${encodeURIComponent(currentRedirectUri)}`;
  }

  const csvFileBase64 = "U3ltYm9sLFF1YW50aXR5CkdPT0csOTAKTkZMWCwxMzAKTUVUQSwxMDAKQU1aTiwxMApUU0xBLDUwCkFBUEwsMTAw";

  function downloadCSVFile(e) {
      e.preventDefault();
      const element = document.createElement("a");
      const file = new Blob([atob(csvFileBase64)], { type: "text/csv;charset=utf-8" });
      element.href = URL.createObjectURL(file);
      element.download = "SampleTemplate.csv";
      document.body.appendChild(element);
      element.click();
  }

  function addPortfolioOptions() {
    if (selectedAcType === "INVESTOR") {
      return (
        <div className="port-options">
          <a onClick={() => open()} disabled={!ready}>
            <p className="rowText">Import from Brokerage Account</p>
            <div className="button_arrow">
              <img src="Assets/arrowright.png" />
            </div>
          </a>
          <label htmlFor="fileUpload">
            <input
              type="file"
              id="fileUpload"
              accept=".csv"
              ref={hiddenFileInput}
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <p className="rowText">Import from CSV <a href="#" onClick={downloadCSVFile}>(example CSV)</a></p>
            <div className="button_arrow">
              <img src="Assets/arrowright.png" />
            </div>
          </label>
          <a onClick={() => uploadRoute("/AddPortfolioScreen")}>
            <p className="rowText">Enter Manually</p>
            <div className="button_arrow">
              <img src="Assets/arrowright.png" />
            </div>
          </a>
        </div>
      )
    } else {
      return (
        <div className="port-options">
          <label htmlFor="fileUpload">
            <input
              type="file"
              id="fileUpload"
              accept=".csv"
              ref={hiddenFileInput}
              onChange={handleUpload}
              style={{ display: "none" }}
            />
            <p className="rowText">Import from CSV <a href="#" onClick={downloadCSVFile}>(example CSV)</a></p>
            <div className="button_arrow">
              <img src="Assets/arrowright.png" />
            </div>
          </label>
          <a onClick={() => uploadRoute("/AddPortfolioScreen")}>
            <p className="rowText">Enter Manually</p>
            <div className="button_arrow">
              <img src="Assets/arrowright.png" />
            </div>
          </a>
        </div>
      )
    }
  }

  return (
    <>
      <PageLoader bdopen={bdopen} />
      <div className="Holder">
        <h3>CHOOSE</h3>
        <Popup
          trigger={popupState}
          setTrigger={setPopupState}
          title="Portfolio Summary"
          content={popupMessage}
          alertType={alertType}
          loginRedirect={popupLoginRedierct}
        />
        {addPortfolioOptions()}
      </div>
    </>
  );
}
