import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./LoginWindow.scss";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import AdvisorPool from "../../AdvisorPool";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Establish_user_session } from "../../Apis/establish_user_session";
import { Establish_advisor_session } from "../../Apis/establish_advisor_session";
import { AddInvestorSessionID } from "../../Apis/AddInvestorSessionID";
import { AddAdvisorSessionID } from "../../Apis/AddAdvisorSessionID";
import IconButton from "@material-ui/core/IconButton";
import AgreementContent from "../AgreementContent/AgreementContent";
import close from "../../images/close.svg";
import CustomPopup from "../Popup/Popup";
import Popup from "reactjs-popup";
import PageLoader from "../PageLoader/PageLoader";
import TagManager from "react-gtm-module";

function LoginWindow(props) {
  const history = useHistory();
  const LoggedIn = () => history.push("/PortfolioSummaryScreen");
  const AdvisorLoggedIn = () => history.push("/AdvisorDashboard");
  const [screenRedirect, setScreenRedirect] = React.useState(null);

  const popupRedirect = () => {
    if (screenRedirect !== null) {
      var screen = screenRedirect
      setScreenRedirect(null);
      history.push({
        pathname: screen,
        state: {
          userName: UserName,
          userEmail: "",
        }
      })
    } else {
      setPopupState(false);
    }
  };

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const [selectedAcType, ChangeSelectedAcType] = React.useState("");
  const [UserName, setUserName] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [WrongUsername, setWrongUsername] = React.useState("");
  const [WrongPassword, setWrongPassword] = React.useState("");
  const [bdopen, setBdOpen] = React.useState(false);
  const [Token, setToken] = React.useState("");
  const [loggedInUser, setLoggedInUser] = React.useState("");
  const [sessionID, setSessionID] = React.useState("");
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [showAgreementPopup, setShowAgreementPopup] = React.useState(false);
  const [AgreementFlag, setAgreementFlag] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [AgreementText, setAgreementText] = React.useState("");
  var verifyInvestorCount = 0;
  var verifyAdvisorCount = 0;

  React.useEffect(() => {
    window.sessionStorage.setItem("Token", Token);
  }, [Token]);

  React.useEffect(() => {
    window.sessionStorage.setItem("clientId", clientId);
  }, [clientId]);

  React.useEffect(() => {
    window.sessionStorage.setItem("selectedAcType", selectedAcType);
  }, [selectedAcType]);

  React.useEffect(() => {
    window.sessionStorage.setItem("sessionID", sessionID);
  }, [sessionID]);

  const handleBdClose = () => {
    setBdOpen(false);
  };
  const handleBdToggle = () => {
    setBdOpen(!bdopen);
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const classes = useStyles();

  const changeAccountType = (type) => {
    ChangeSelectedAcType(type);
    setWrongUsername("")
    setWrongPassword("");
  };
  const accountTypes = [{ name: "INVESTOR", icon: "../Assets/investor.svg", id:"investor" }, { name: "ADVISOR", icon: "../Assets/advisor.svg", id:"advisor" }];

  const returnClassname = (accountType) => {
    if (accountType === selectedAcType) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  };
  const [isAccnVisible, setIsAccnVisible] = useState(true);
  const toggleaccount = () => {
    setIsAccnVisible(!isAccnVisible);
  };
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const togglelogin = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  const verifyInvestorOTP = (user) => {
    if (verifyInvestorCount === 1) {
      verifyInvestorCount = 0;
      setWrongUsername("")
      setWrongPassword("");
      user.resendConfirmationCode(function (err, result) {
        if (err) {
          console.log(err.message || JSON.stringify(err))
        }
        setPopupState(true);
        setPopupMessage(
          "Please verifiy your account with the Verification code sent to your registered email address. Please check your inbox."
        );
        setScreenRedirect("/OTPConfirmationScreen");
        setAlertType("warning");
        setPopupLoginRedirect(false);
      })
    }
  }

  const verifyAdvisorOTP = (user) => {
    if (verifyAdvisorCount === 1) {
      verifyAdvisorCount = 0;
      setWrongUsername("")
      setWrongPassword("");
      user.resendConfirmationCode(function (err, result) {
        if (err) {
          console.log(err.message || JSON.stringify(err))
        }
        setPopupState(true);
        setScreenRedirect("/OTPConfirmationScreen");
        setPopupMessage(
          "Please verifiy your account with the Verification code sent to your registered email address. Please check your inbox."
        );
        setAlertType("warning");
        setPopupLoginRedirect(false);
      })
    }
  }

  const LogIn = (event) => {
    handleBdToggle();
    event.preventDefault();
    const user = new CognitoUser({
      Username: UserName,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: UserName,
      Password: Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        setWrongUsername("");
        setWrongPassword("");
        Establish_user_session(data, selectedAcType);
        const user = UserPool.getCurrentUser();
        if (user === null) {
          console.log("Not Logged In");
        } else {
          var user_name = user.getUsername();
          setLoggedInUser(user_name);
          user.getSession(function (error, session) {
            if (error) {
              console.log(error);
            }
            if (session) {
              // var session_id = createUserSessionID();
              // setSessionID(session_id);
              setToken(session.idToken.jwtToken);
              user.getUserAttributes(function (err, result) {
                if (err) {
                  console.log(err);
                }
                AddInvestorSessionID(user_name).then((data) => {
                  console.log("data logg", data);
                  setClientId(data['customer_id']);
                  setAgreementText(data['agreement_body']);
                  sessionStorage.setItem('AgreementText', JSON.stringify(data['agreement_body']));
                  if (data['agreement_flag'] === 'False') {
                    setBdOpen(false);
                    setAgreementFlag(data['agreement_flag']);
                  } else if (data['agreement_flag'] === 'True') {
                    sleep(100).then(() => {
                      LoggedIn();
                      setAgreementText(data['agreement_body']);
                    });
                  } else if (data === 0) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    setPopupLoginRedirect(false);
                    return;
                  }
                  else if (data === false) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    setPopupLoginRedirect(false);
                    return;
                  }
                  else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                      "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                  }
                  TagManager.dataLayer({
                    dataLayer: {
                      'event': 'UserLogin',
                      'userType': selectedAcType,
                      'user_id': data['customer_id'],
                    },
                  });
                });
              });
            }
          });
        }
      },

      onFailure: (err) => {
        handleBdClose();
        for (const [key, value] of Object.entries(err)) {
          if (value === 'UserNotFoundException') {
            setWrongUsername("Username/Email Id does not exist.")
            setWrongPassword("");
          }
          else if (value === 'NotAuthorizedException') {
            setWrongPassword("Incorrect Password.")
            setWrongUsername("");
          }
          else if (value === 'UserNotConfirmedException') {
            if (verifyInvestorCount === 0) {
              verifyInvestorCount = 1;
              sleep(10).then(() => {
                verifyInvestorOTP(user)
              });

            }
          }
        }
      },

      newPasswordRequired: (data) => {
        handleBdClose();
        console.log("changes password required", data);
      },
    });
  };

  const AdvisorLogIn = (event) => {
    handleBdToggle();
    event.preventDefault();

    const user = new CognitoUser({
      Username: UserName,
      Pool: AdvisorPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: UserName,
      Password: Password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        setWrongUsername("");
        setWrongPassword("");
        Establish_advisor_session(data);
        const user = AdvisorPool.getCurrentUser();
        if (user === null) {
          console.log("Advisor Not Logged In");
        } else {
          var user_name = user.getUsername();
          setLoggedInUser(user_name);
          user.getSession(function (error, session) {
            if (error) {
              console.log(error);
            }
            if (session) {
              // var session_id = createUserSessionID();
              // setSessionID(session_id);
              setToken(session.idToken.jwtToken);
              user.getUserAttributes(function (err, result) {
                if (err) {
                  console.log(err);
                }
                AddAdvisorSessionID(user_name).then((data) => {
                  console.log(" ad log", data)
                  setClientId(data['customer_id']);
                  setAgreementText(data['agreement_body']);
                  sessionStorage.setItem('AgreementText', JSON.stringify(data['agreement_body']));
                  if (data['agreement_flag'] === 'False') {
                    // setShowAgreementPopup(true);
                    setBdOpen(false);
                    // setAgreementText(data['agreement_body']);
                    setAgreementFlag(data['agreement_flag']);
                  } else if (data['agreement_flag'] === 'True') {
                    sleep(100).then(() => {
                      AdvisorLoggedIn();
                    })
                  } else if (data === 0) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    setPopupLoginRedirect(false);
                    return;
                  }
                  else if (data === false) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    setPopupLoginRedirect(false);
                    return;
                  }
                  else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                      "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                  }
                  TagManager.dataLayer({
                    dataLayer: {
                      'event': 'UserLogin',
                      'userType': selectedAcType,
                      'user_id': data['customer_id'],
                    },
                  });
                });
              });
            }
          });
        }
      },

      onFailure: (err) => {
        handleBdClose();
        for (const [key, value] of Object.entries(err)) {
          if (value === 'UserNotFoundException') {
            setWrongUsername("Username does not exist.")
            setWrongPassword("");
          }
          else if (value === 'NotAuthorizedException') {
            setWrongPassword("Incorrect Password.")
            setWrongUsername("");
          }
          else if (value === 'UserNotConfirmedException') {
            if (verifyAdvisorCount === 0) {
              verifyAdvisorCount = 1;
              sleep(10).then(() => {
                verifyAdvisorOTP(user)
              });
            }
          }
        }
      },

      newPasswordRequired: (data) => {
        handleBdClose();
        console.log("changes password required", data);
      },
    });
  };


  React.useEffect(() => {
    if (clientId && AgreementText && AgreementFlag) {
      setShowAgreementPopup(true);
    }
  }, [clientId, AgreementText, AgreementFlag]);

  const handlePopup = () => {
    setPopupState(false);
  }


  return (
    <div className="ai-login-form">
      <PageLoader bdopen={bdopen} />
      <CustomPopup
        trigger={popupState}
        setTrigger={handlePopup && popupRedirect}
        title="Sign In"
        alertType={alertType}
        content={popupMessage}
        loginRedirect={popupLoginRedierct}
      />


      <div className="ai-login-section">
        {isAccnVisible && (
          <div className="ai-field-group">
            <h2>Sign In as</h2>
            <nav className="ai-tabs nav-tabs">
              {accountTypes.map((accountType) => (
                <a
                  onClick={() => { changeAccountType(accountType.name); togglelogin(); toggleaccount(); }}
                  className={returnClassname(accountType.name)}
                  id={accountType.id}
                >
                  <img src={accountType.icon}></img>
                  {accountType.name}
                </a>
              ))}
            </nav>
            <div class="calendlysaprator"><span>OR</span></div>
            <div className="ai-field-group">
              <div className="ai-row v-middle">
                <div className="ai-column">
                  <div className="ai-signin middle-text">
                    <p>Don’t have an account?</p>
                    <Link to="/SignUp" className="ai-link">
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoginVisible && (
          <>
            <h2>
              <a className="backtouser" onClick={() => { changeAccountType(""); togglelogin(); toggleaccount(); }}><img src="../Assets/arrowright.svg"></img></a>Sign In as {selectedAcType === "INVESTOR" ? (
                "Investor"
              ) : (
                "Advisor"
              )}</h2>
            <div className="ai-field-group">
              <div className="ai-row">
                <div className="ai-column">
                  <div className="ai-form-group">
                    <input
                      id="username"
                      className="ai-form-value"
                      type="text"
                      placeholder="Enter Username/Email Id"
                      onChange={(event) => setUserName(event.target.value)}
                    />
                    <div className="ai-error-message">
                      <p>{WrongUsername}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ai-row">
                <div className="ai-column">
                  <div className="ai-form-group">
                    <input
                      id="password"
                      className="ai-form-value ai-password"
                      type="password"
                      placeholder="Password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <div className="ai-error-message">
                      <p>{WrongPassword}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ai-row">
                <div className="ai-column ai-align-right">
                  <Link className="ai-forgot-password" to="/ForgotPassword">Forgot Password?</Link>
                </div>
              </div>
            </div>
            <div className="ai-field-group">
              <div className="ai-row v-middle">
                <div className="ai-column">
                  <div className="ai-signin">
                    <p>Don’t have an account?</p>
                    <Link id='SignUp' to="/SignUp" className="ai-link">
                      Sign Up
                    </Link>
                  </div>
                </div>
                {selectedAcType === "INVESTOR" ? (
                  <button onClick={LogIn} className="ai-btn primary round">
                    <ArrowForwardIcon />
                  </button>
                ) : (
                  <button onClick={AdvisorLogIn} className="ai-btn primary round">
                    <ArrowForwardIcon />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {showAgreementPopup && AgreementText && (
        <Popup id="LoginAgreement" open={true} modal>
          <div className="add-portfolio-popup">
            <div className="popup-head">
              <img src="Assets/adaptive-name.png" ></img>
              <IconButton
                onClick={() => setShowAgreementPopup(false)}
                className="closepopup"
              >
                <img src={close}></img>
              </IconButton>
            </div>
            <AgreementContent AgreementText={AgreementText} AgreementFlag={AgreementFlag} selectedAcType={selectedAcType} clientId={clientId} viewOnly={true} />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default LoginWindow;
