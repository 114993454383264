import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "../Popup/Popup";
import PageLoader from "../PageLoader/PageLoader";
import { GetNitrogenClient } from "../../Apis/GetNitrogenClient";

function NitrogenClients(props) {

    const [bdopen, setBdOpen] = useState(true);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupState, setPopupState] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
    const [authCode, setAuthCode] = React.useState(null);
    const currentRedirectUri = 'https://d2gkko3cve1nl4.cloudfront.net/nitrogen_clients';

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const returnedCode = urlParams.get('code');
        const returnedState = urlParams.get('state');

        console.log(" return code", returnedCode, returnedState)

        if (returnedCode && returnedState === localStorage.getItem('oauth_state')) {
            setAuthCode(returnedCode);
            GetNitrogenClient(localStorage.getItem('oauth_state'), currentRedirectUri).then((data) => {
                console.log(" clients info", data)
                setBdOpen(false);
            })
        }
    }, []);

    return (
        <div className="account-table">
            <PageLoader bdopen={bdopen} />
            <Popup
                trigger={popupState}
                setTrigger={setPopupState}
                title="Import Plaid"
                alertType={alertType}
                content={popupMessage}
                loginRedirect={popupLoginRedierct}
            />
            <div className="ai-accountsummary">
                <table>
                    {/* <tbody>
                        <tr>
                            <th>Account Name</th>
                            <th>Account Type</th>
                            <th>Overall Value</th>
                            <th>Currency</th>
                            <th></th>
                        </tr>
                        {PlaidSummaryData.map((value, i) => (
                            <tr>
                                <td>{value.account_name}</td>
                                <td>{value.account_type}</td>
                                <td>{value.account_balance}</td>
                                <td>{value.currency}</td>
                                <td>
                                    <div className="plaid-select">{return_button(value)}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody> */}
                </table>
            </div>
        </div>
    );
}

export default NitrogenClients;
