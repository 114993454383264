import React from "react";
import "./PageHeader.css";
import Popup from "reactjs-popup";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import PortfolioUploadTable from "../../Components/PortfolioUpload/PortfolioUploadTable.js";
import AddNewPaymentAccount from "../AddNewPaymentAccount/AddNewPaymentAccount";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import close from "../../images/close.svg";

export default function PageHeader({
  heading,
  bubble,
  backButton,
  addButton,
  routeTo,
  buttonText,
  exportButton,
  importType,
  addPaymentAccount
}) {
  const history = useHistory();
  const BackClick = () => history.push(routeTo);
  const AddNewPortfolio = () => history.push("/AddPortfolioScreen");

  const with_backButton = (
    <div className="page-header-with-back-button">
      <a id="goback" onClick={() => history.goBack()}>
        <ChevronLeftOutlinedIcon className="back_button" />
      </a>
      <h1 className="page-header">{heading}<span className="bubble">{bubble}</span></h1>
    </div>
  );
  const normal = <h1 className="page-header">{heading}</h1>;

  const [showPopup, setShowPopup] = React.useState(false);
  const [ShowNewAccountPopup, setShowNewAccountPopup] = React.useState(false);
  const [stockImport, setStockImport] = React.useState("");
  React.useEffect(() => {
    window.sessionStorage.setItem("stockImport", stockImport);
  }, [stockImport]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const addPortfolio = () => {
    setShowPopup(true);
  };

  const addNewPaymentPopup = () => {
    setShowNewAccountPopup(true);
  };

  const handleAddAccountClose = () => {
    setShowNewAccountPopup(false);
    // fetchExternaAccounts();
  };

  const handleNewPortfolio = () => {
    setStockImport(importType);
    sleep(100).then(() => {
      AddNewPortfolio();
    });
  };

  const add_button = (
    <button id="addportfoliobutton" className="ai-btn primary solid" onClick={() => addPortfolio()}>
      <img className="ai-img" src="Assets/buyshield.png" />
      {buttonText}
    </button>
  );

  const add_new_payment_account = (
    <button className="ai-btn primary solid" onClick={() => addNewPaymentPopup()}>
      Add new account
    </button>
  );

  const import_button = (
    <button className="ai-btn primary solid" onClick={handleNewPortfolio}>
      <FileDownloadOutlinedIcon /> Import
    </button>
  );

  const return_div = () => {
    if (backButton === true) {
      return with_backButton;
    } else {
      return normal;
    }
  };

  const return_add = () => {
    if (addButton === true) {
      return add_button;
    } else {
      return null;
    }
  };

  const return_import = () => {
    if (exportButton === true) {
      return import_button;
    } else {
      return null;
    }
  };

  const return_add_new_payment = () => {
    if (addPaymentAccount === true) {
      return add_new_payment_account;
    } else {
      return null;
    }
  };

  return (
    <div className="main-header">
      <div className="page-header-wrapper">
        {return_div()}
        {addButton && <div>{return_add()}</div>}
        {exportButton && <div>{return_import()}</div>}
        {addPaymentAccount && <div>{return_add_new_payment()}</div>}
      </div>
      <div>
        {showPopup && (
          <Popup id="PortfolioPopUp" open={true} modal>
            <div className="add-portfolio-popup">
              <IconButton onClick={() => setShowPopup(false)} className="closepopup">
                <img src={close}></img>
              </IconButton>
              <PortfolioUploadTable />
            </div>
          </Popup>
        )}
        {ShowNewAccountPopup && (
          <Popup open={true} modal>
            <div className="add-portfolio-popup">
              <IconButton
                onClick={() => setShowNewAccountPopup(false)}
                className="closepopup"
              >
                <img src={close}></img>
              </IconButton>
              <AddNewPaymentAccount handleClose={() => setShowNewAccountPopup(false)} handleAddAccountClose={handleAddAccountClose}/>
            </div>
          </Popup>
        )
        }
      </div>
    </div>
  );
}
