import React from "react";
import "./AdvisorClientListTab.css";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { GetAdvisorDashboardStats } from "../../Apis/GetAdvisorDashboardStats";
import Popup from "../Popup/Popup";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { UploadAdvisorCsvFile } from "../../Apis/UploadAdvisorCsvFile";
import { UpdateAdvisorSessionID } from "../../Apis/UpdateAdvisorSessionID";
import { addZeroes } from "../Utilities/AddZeros";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import searchicon from "../../images/search.svg";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";
import deleticon from "../../images/delete.svg"
import { InitiateDeleteClient } from "../../Apis/InititiateDeleteClient";
import ConfirmPopup from "../ConfirmationPopup/confirmPopup";
import { ConfirmDeleteClient } from "../../Apis/ConfirmDeleteClient";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

function AdvisorClientListTab(props) {
  const [clientData, setClientData] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("ASC");
  const [searchKey, setSearchKey] = React.useState("");
  const [sortIcon, setSortIcon] = React.useState("Assets/sort_icon.png");
  const [sortCol, setSortCol] = React.useState("");
  const [bdopen, setBdOpen] = React.useState(false);
  const [filterCol, setFilterCol] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterMinValue, setfilterMinValue] = React.useState(null);
  const [filterMaxValue, setfilterMaxValue] = React.useState(null);
  const [filterFormErrors, setFilterFormErrors] = React.useState({});
  const [unfilteredClients, setunfilteredClients] = React.useState("");
  const [filterList, setFilterList] = React.useState([]);
  const [GotData, setGotData] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [AdvisorCsvData, setAdvisorCsvData] = React.useState(JSON.parse(sessionStorage.getItem("AdvisorCsvData")));
  const hiddenFileInput = React.useRef(null);
  const [AdvisorCsvFileName, setAdvisorCsvFileName] = React.useState("");
  const AdvisorClientChanges = () => history.push("./AdvisorClientChanges");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [confirmPopupMessage, setConfirmPopupMessage] = React.useState("");
  const [confirmPopup, setConfirmPopup] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  React.useEffect(() => {
    window.sessionStorage.setItem("AdvisorCsvFileName", AdvisorCsvFileName);
  }, [AdvisorCsvFileName]);

  React.useEffect(() => {
    window.sessionStorage.setItem("AdvisorCsvData", JSON.stringify(AdvisorCsvData));
  }, [AdvisorCsvData]);

  const GetClientPortfolios = (clientname, customername) => {
    setBdOpen(true);
    UpdateAdvisorSessionID(clientname).then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        sleep(100).then(() => {
          PortfolioSummary();
        });
      } else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === false) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
        setAlertType("warning");
        setPopupLoginRedirect(false);
        return;
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      }
    });
  };

  const history = useHistory();
  const PortfolioSummary = () => history.push("./PortfolioSummaryScreen");
  const AddClients = () => history.push("./AddNewClient");

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  React.useEffect(() => {
    setBdOpen(true);
    GetAdvisorDashboardStats().then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        var lyst = [];
        for (let [key, value] of Object.entries(data.client_details)) {
          lyst.push({
            clientid: key,
            externalclientid: !value.externalid ? "-" : value.externalid,
            clientname: value.name,
            noofportfolio: Number(value.total_portfolio),
            aum: Number(
              addZeroes(
                Number(parseFloat(value.total_portfolio_value).toFixed(2))
              )
            ),
          });
        }
        setBdOpen(false);
        setClientData([...lyst]);
        setunfilteredClients([...lyst]);
        setGotData(true);
      } else if (data === false) {
        setBdOpen(false);
        setGotData(true);
        setPopupState(true);
        setPopupMessage(
          "Unable to retrieve client data. Please try again or contact support@adaptive-investments.com."
        );
        setPopupTitle("Unable to fetch Dashboard Data");
        setPopupLoginRedirect(false);
      } else if (data === 0) {
        setBdOpen(false);
        setGotData(true);
        setPopupState(true);
        setPopupMessage(
          "We are facing server issues, please try again later."
        );
        setPopupTitle("Server Error");
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setPopupTitle('Session Expired')
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      }
    });
  }, []);

  const handleFileClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUpload = (event) => {
    setBdOpen(true);
    event.persist();
    const [file] = event.target.files;
    var fileExt = file.name.split(".").pop();
    if (fileExt === "csv") {
      const reader = new FileReader();
      reader.onloadend = (onLoadEndEvent) => {
        setAdvisorCsvFileName(file.name);
        console.log(onLoadEndEvent.target.result.split(",")[1]);
        UploadAdvisorCsvFile(onLoadEndEvent.target.result.split(",")[1]).then((data) => {
          if (data !== false && data !== 0 && data !== "WRONG_FORMAT" && data !== 401) {
            setGotData(true);
            console.log(data);
            if (Object.entries(data['changes']).length > 0) {
              if (Object.entries(data["changes"]["customers"]).length === 0 && Object.entries(data["changes"]["portfolios"]).length === 0 && Object.entries(data["changes"]["portfolio_line_items"]).length === 0) {
                data["changes"] = {};
              }
            }
            setAdvisorCsvData(data);
            if (Object.keys(data.errors).length !== 0) {
              history.push("/AdvisorErrorsScreen");
            } else {
              AdvisorClientChanges();
            }
            TagManager.dataLayer({
              dataLayer: {
                event: 'Advisor CSV Imported',
                userType: sessionStorage.getItem("selectedAcType"),
                user_id: sessionStorage.getItem("clientId")
              },
            });
          } else {
            sessionStorage.removeItem("AdvisorCsvData");
            if (data === false) {
              setBdOpen(false);
              setGotData(true);
              setPopupState(true);
              setPopupMessage(
                "Unable to process CSV. Please try again or contact support@adaptive-investments.com."
              );
              setPopupTitle("Unable to process CSV");
              setPopupLoginRedirect(false);
            } else if (data === 0) {
              setBdOpen(false);
              setGotData(true);
              setPopupState(true);
              setPopupMessage(
                "We are facing server issues, please try again later."
              );
              setPopupTitle("Server Error");
              setPopupLoginRedirect(false);
            } else if (data === "WRONG_FORMAT") {
              setBdOpen(false);
              setGotData(true);
              setPopupState(true);
              setPopupMessage(
                "You have selected a wrong csv format. Please try again or contact support@adaptive-investments.com."
              );
              setPopupTitle("Server Error");
              setAlertType("error");
              // history.push("/AdvisorErrorsScreen");
              setPopupLoginRedirect(false);
            }
            else if (data === 401) {
              setBdOpen(false);
              setPopupState(true);
              setPopupMessage(
                "Your session timed out for security reasons. Please sign-in again."
              );
              setAlertType("error");
              setPopupLoginRedirect(true);
              setPopupTitle("Session Expired");
              return;
            } else if (data === 404) {
              setBdOpen(false);
              setGotData(true);
              setPopupState(true);
              setPopupMessage(
                "Your session was terminated for security reasons. Please sign-in again"
              );
              setPopupTitle("Session Expired");
              setPopupLoginRedirect(true);
            }
          }
        });
      };
      reader.readAsDataURL(file);
    } else {
      setPopupState(true);
      setPopupMessage("Unable to process CSV. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      setBdOpen(false);
      setPopupLoginRedirect(false);
    }
  };

  function DeleteClient(clientId) {
    setBdOpen(true);
    InitiateDeleteClient(clientId).then((data) => {
      console.log(data);
      if (data === "ALLOW_DELETE") {
        setBdOpen(false);
        setConfirmPopup(true);
        setConfirmPopupMessage("Are you sure you want to delete this client. This action is permanent and cannot be undone");
        setSelectedClientId(clientId);
      } else if (data === "SHIELD_ACTIVE") {
        setBdOpen(false);
        setPopupState(true);
        setAlertType("warning");
        setPopupMessage("Cannot delete the user as one or more portfolios have an active shield.Contact support@adaptive-investments.com for more information.");
        setPopupTitle("Alert");
        setPopupLoginRedirect(false);
      } else if (data === "WALLET_NOT_EMPTY") {
        setBdOpen(false);
        setPopupState(true);
        setAlertType("warning");
        setPopupMessage("The Selected Client has Funds in His Wallet. Withdraw all funds to continue or contact support@adaptive-investments.com.");
        setPopupTitle("Alert");
        setPopupLoginRedirect(false);
      }
      else if (data === 0) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("We are facing server issues, please try again later");
        setPopupTitle("Server Error");
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(false);
        setPopupTitle("Session Expired");
      }
    });
    TagManager.dataLayer({
      dataLayer: {
        'event': 'Delete Ticker',
        'userType': sessionStorage.getItem("selectedAcType"),
        'user_id': sessionStorage.getItem("clientId")
      },
    });
  }
  const confirmDelete = () => {
    setConfirmPopup(false);
    setBdOpen(true);
    if (selectedClientId !== null) {
      ConfirmDeleteClient(selectedClientId).then((data) => {
        if (data === false) {
          setPopupState(true);
          setPopupMessage("Unable to delete client. Please try again or contact support@adaptive-investments.com.");
          setAlertType("error");
          setPopupTitle("Error");
          setBdOpen(false);
        } else if (data === 0) {
          setPopupState(true);
          setPopupMessage("We are facing problems connecting to our servers, please try again later");
          setAlertType("error");
          setPopupTitle("Server Error")
          setBdOpen(false);
        } else if (data === "DELETE_PROHIBITED") {
          setPopupState(true);
          setPopupMessage("You cannot delete this client. Please try again or contact support@adaptive-investments.com.");
          setPopupTitle("Delete Prohibited");
          setAlertType("error");
          setBdOpen(false);
        }
        else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        } else if (data === 404) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        }
        else {
          setBdOpen(false);
          setShowDeleteConfirm(true);
          TagManager.dataLayer({
            dataLayer: {
              event: 'Client Deleted',
              userType: sessionStorage.getItem("selectedAcType"),
              user_id: sessionStorage.getItem("clientId")
            },
          });
        }
      })
    } else {
      setPopupState(true);
      setPopupMessage("Unable to delete client. Please try again or contact support@adaptive-investments.com.");
      setAlertType("error");
      setBdOpen(false);
    };
  };
  const open = Boolean(anchorEl);
  const handleClick = (event, col) => {
    setAnchorEl(event.currentTarget);
    setFilterCol(col);
  };

  const validateFilter = (event) => {
    let validFilterFlag = true;
    let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
    let error = {};
    if (!filterMinValue) {
      validFilterFlag = false;
      error.minfilter = "Please enter min value";
    }
    if (!filterMaxValue) {
      validFilterFlag = false;
      error.maxfilter = "Please enter max value";
    }
    if (
      Number(parseFloat(filterMaxValue).toFixed(2)) <
      Number(parseFloat(filterMinValue).toFixed(2))
    ) {
      error.maxfilter = "Please enter max value greater than min value";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMinValue)) {
      error.minfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMaxValue)) {
      error.maxfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    setFilterFormErrors(error);
    return validFilterFlag;
  };

  const requestFilter = (e) => {
    e.preventDefault();
    if (validateFilter()) {
      const filteredData = clientData.filter(
        (data) =>
          Number(parseFloat(data[filterCol]).toFixed(2)) >=
          Number(parseFloat(filterMinValue).toFixed(2)) &&
          Number(parseFloat(data[filterCol]).toFixed(2)) <=
          Number(parseFloat(filterMaxValue).toFixed(2))
      );
      setClientData(filteredData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setfilterMinValue(null);
    setfilterMaxValue(null);
    setFilterCol(null);
    setFilterFormErrors({});
  };

  const headers = [
    {
      tableHeader: "CLIENT ID",
      sort_icon: (
        <IconButton onClick={() => requestSort("clientid")}>
          <img
            src={sortCol === "clientid" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
    },
    {
      tableHeader: "EXTERNAL CLIENT ID",
      sort_icon: (
        <IconButton onClick={() => requestSort("externalclientid")}>
          <img
            src={
              sortCol === "externalclientid" ? sortIcon : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
    },
    {
      tableHeader: "CLIENT NAME",
      sort_icon: (
        <IconButton onClick={() => requestSort("clientname")}>
          <img
            src={sortCol === "clientname" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
    },
    {
      tableHeader: "NO OF PORTFOLIOS",
      sort_icon: (
        <IconButton onClick={() => requestSort("noofportfolio")}>
          <img
            src={
              sortCol === "noofportfolio" ? sortIcon : "Assets/sort_icon.png"
            }
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
    },
    {
      tableHeader: "AUM",
      sort_icon: (
        <IconButton onClick={() => requestSort("aum")}>
          <img
            src={sortCol === "aum" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "aum")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
    },
  ];

  const requestSort = (col) => {
    let sortCol = col;
    if (sortOrder === "ASC") {
      const sortedData = [...clientData];
      if (["clientname"].indexOf(sortCol) >= 0) {
        sortedData.sort((a, b) =>
          a[sortCol].toLowerCase() > b[sortCol].toLowerCase() ? 1 : -1
        );
      } else if (["noofportfolio", "aum"].indexOf(sortCol) >= 0) {
        sortedData.sort((a, b) =>
          Number(a[sortCol]) > Number(b[sortCol]) ? 1 : -1
        );
      } else if (["clientid", "externalclientid"].indexOf(sortCol) >= 0) {
        sortedData.sort((a, b) =>
          a[sortCol].toString().localeCompare(b[sortCol].toString(), "en", {
            numeric: true,
            sensitivity: "base",
          })
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_asc_icon.png");
      setClientData(sortedData);
      setSortOrder("DESC");
    }
    if (sortOrder === "DESC") {
      const sortedData = [...clientData];
      if (["clientname"].indexOf(sortCol) >= 0) {
        sortedData.sort((a, b) =>
          a[sortCol].toLowerCase() < b[sortCol].toLowerCase() ? 1 : -1
        );
      } else if (["noofportfolio", "aum"].indexOf(sortCol) >= 0) {
        sortedData.sort((a, b) =>
          Number(a[sortCol]) < Number(b[sortCol]) ? 1 : -1
        );
      } else if (["clientid", "externalclientid"].indexOf(sortCol) >= 0) {
        sortedData.sort((a, b) =>
          b[sortCol].toString().localeCompare(a[sortCol].toString(), "en", {
            numeric: true,
            sensitivity: "base",
          })
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_desc_icon.png");
      setClientData(sortedData);
      setSortOrder("ASC");
    }
  };

  const removeFilter = (data) => {
    let lyst = [...unfilteredClients];
    var removefilterIndex = filterList
      .map(function (item) {
        return item.filterno;
      })
      .indexOf(data.filterno);
    filterList.splice(removefilterIndex, 1);
    setFilterList([...filterList]);
    if (filterList.length !== 0) {
      for (let [key, value] of Object.entries(filterList)) {
        lyst = lyst.filter(
          (data) =>
            Number(parseFloat(data[value.column]).toFixed(2)) >=
            Number(parseFloat(value.minvalue).toFixed(2)) &&
            Number(parseFloat(data[value.column]).toFixed(2)) <=
            Number(parseFloat(value.maxvalue).toFixed(2))
        );
        setClientData([...lyst]);
      }
    } else {
      setClientData([...unfilteredClients]);
    }
  };
  return (
    <div>
        <div>
          <PageLoader bdopen={bdopen} />
          <Popup
            trigger={popupState}
            setTrigger={setPopupState}
            title={popupTitle}
            content={popupMessage}
            alertType={alertType}
          ></Popup>
          <ConfirmPopup
            trigger={confirmPopup}
            setTriggerYes={() => confirmDelete()}
            setTriggerNo={setConfirmPopup}
            popupMessage={confirmPopupMessage}
          />
          <div className="search_bar">
            <div className="searchinput">
              <img src={searchicon}></img>
              <input
                className="ai-form-value"
                type="text"
                placeholder="Search"
                onChange={(event) => {
                  setSearchKey(event.target.value);
                }}
              />
            </div>
            {showDeleteConfirm && (
              <div>
                <div className="popup">
                  <div className="popup-object">
                    <div className="confirm-heading">
                      <CheckCircleOutlineRoundedIcon className="icon" />
                      Success
                    </div>
                    <div className="body-message">
                      <p>The client information was deleted successfully </p>
                    </div>
                    <button
                      className="ai-btn primary solid "
                      onClick={() => {
                        setShowDeleteConfirm(false);
                        setBdOpen(true);
                        window.location.reload();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            )}
            <button className="ai-btn primary solid" onClick={() => handleFileClick()} >
              <FileDownloadOutlinedIcon />
              <input
                type="file"
                id="fileUpload"
                accept=".csv"
                ref={hiddenFileInput}
                onChange={handleUpload}
                style={{ display: "none" }}
              />Import from CSV</button>
            <button className="ai-btn primary solid" onClick={() => AddClients()}>Add Client</button>
          </div>
          <Paper className="filter-paper">
            {filterList.map((data) => (
              <Chip
                className="chip"
                label={"AUM $" + data.minvalue + " - $" + data.maxvalue}
                variant="outlined"
                sx={{ color: "#E58B76" }}
                deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
                onDelete={() => removeFilter(data)}
              />
            ))}
          </Paper>
          <div className="table_holder">
            <TableContainer className="table_height">
              <Table stickyHeader aria-label="sticky table">
                {
                  <TableHead>
                    <TableRow>
                      {headers.map((header, i) => (
                        <TableCell>
                          <div className="stck_table_heading">
                            {header.tableHeader}
                            {header.sort_icon}
                            {header.filter_icon}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                }
                <TableBody>
                {typeof clientData === "object" && (clientData !== null && clientData !== {}) && (GotData === true) &&
                  clientData
                    .filter((val) => {
                      if (searchKey === "") {
                        return val;
                      } else if (
                        val.clientname
                          .toLowerCase()
                          .includes(searchKey.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((row) => (
                      <TableRow>
                        <TableCell>{row.clientid}</TableCell>
                        <TableCell>{row.externalclientid}</TableCell>
                        <TableCell className="link">
                          <a
                            onClick={() =>
                              GetClientPortfolios(row.clientid, row.clientname)
                            }
                          >
                            {row.clientname}
                          </a>
                        </TableCell>
                        <TableCell>{row.noofportfolio}</TableCell>
                        <TableCell>{row.aum.toLocaleString("en-US", currObj)}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => DeleteClient(row.clientid)}>
                            <img src={deleticon} alt="" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <div className="ai-form-group p16">
                <input
                  type="text"
                  className="ai-form-value"
                  placeholder="Min Value"
                  onChange={(event) => setfilterMinValue(event.target.value)}
                />
                <p className="error-message">{filterFormErrors.minfilter}</p>
                <p className="filter-label">To</p>
                <input
                  type="text"
                  className="ai-form-value"
                  placeholder="Max Value"
                  onChange={(event) => setfilterMaxValue(event.target.value)}
                />
                <br />
                <p className="error-message">{filterFormErrors.maxfilter}</p>
                <div className="button-div">
                  <button
                    type="Submit"
                    className="ai-btn primary small solid"
                    onClick={requestFilter}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </Popover>
          </div>
        </div>
    </div>
  );
}
export default withWidth()(AdvisorClientListTab);
