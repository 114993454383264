import React, { Component } from "react";
import AiInput from "../../Components/AiInput/ai-input";
import AiFormError from "../../Components/AiFormError/ai-form-error";
import MainSlider from "../../Components/MainSlider/MainSlider";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Container, Row, Col } from "react-bootstrap";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import AdvisorPool from "../../AdvisorPool";
import { withRouter } from "react-router";
import Popup from "../../Components/Popup/Popup";
import "./ForgotPassword.css";
import { useHistory } from "react-router-dom";
import PageLoader from "../../Components/PageLoader/PageLoader";

function ForgotPassword(){

  const [stage ,setStage] = React.useState(1);
  const [popupState,setPopupState] = React.useState(false);
  const [inputerrors ,setInputErrors] = React.useState({});
  const [newpassworderrors, setNewPasswordErrors] = React.useState({});
  const [username,setUserName] = React.useState("");
  const [code,setCode] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [bdopen, setBdOpen] = React.useState(false);

  const history = useHistory();
  const loginPage = () => history.push("/");

  const validateForgotForm = () => {
    let errors = {};
    let validFormFlag = true;
    if (!username) {
      validFormFlag = false;
      errors["username"] = "Please enter your Username / Email Id.";
    }
    setInputErrors(errors);
    return validFormFlag;
  }

  const validateNewPasswordForm = () => {
    let errors = {};
    let validNewPasswordFormFlag = true;
    if (newPassword !== confirmPassword) {
      validNewPasswordFormFlag = false;
      errors["confirmPassword"] = "Password and Confirm Password doesnot match";
    }
    if (!newPassword) {
      validNewPasswordFormFlag = false;
      errors["newPassword"] = "Password should not be empty";
    }
    if (!confirmPassword) {
      validNewPasswordFormFlag = false;
      errors["confirmPassword"] = "Confirm Password should not be empty";
    }
    if (!code) {
      validNewPasswordFormFlag = false;
      errors["code"] = "Verification code should not be empty";
    }
    setNewPasswordErrors(errors);
    return validNewPasswordFormFlag;
  }

  const getUser= () => {
    if(sessionStorage.getItem("selectedAcType") === "INVESTOR"){
      return new CognitoUser({
        Username: username,
        Pool: UserPool,
      });
    }else{
      return new CognitoUser({
        Username: username,
        Pool: AdvisorPool,
      });
    }
  }

  const sendCode = () => {
    setBdOpen(true);
    let errors = {};
    getUser().forgotPassword({
      onSuccess: (data) => {
      },
      onFailure: (err) => {
        setBdOpen(false);
        errors["username"] = "Username/Email Id not found. Please enter correct Username / Email id";
        setInputErrors(errors);
      },
      inputVerificationCode: (data) => {
        setStage(2);
        setBdOpen(false);
      },
    });
  }

  const resetPassword = (event) => {
    let errors = {};
    setBdOpen(true);
    getUser().confirmPassword(
      code,
      newPassword,
      {
        onSuccess:() => {
          setBdOpen(false);
          setPopupState(true);
        },
        onFailure: (err) => {
          setBdOpen(false);
          if(err["message"] === "Invalid verification code provided, please try again."){
            errors["code"] = "Invalid verification code provided, please try again";
          }else{
            errors["confirmPassword"] = err["message"]
          }
          setNewPasswordErrors(errors);
        },
      }
    );
  }
  const handlePopup = () => {
    setPopupState(false);
  }

  const submitForgotPasswordForm = (e) => {
    e.preventDefault();
    if (validateForgotForm()) {
      sendCode();
    }
  }

  const submitNewPasswordForm = (e) =>{
    e.preventDefault();
    if (validateNewPasswordForm()) {
      resetPassword();
    }
  }

  return (
    <section className="ai-container ai-bording-page">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={handlePopup && loginPage}
        title="Forgot Password"
        content="Password changed successfully. Try login"
        alertType="success"
      />

      <Container fluid>
        <Row className="ai-full-height">
          <Col className="ai-slide-wrapper" lg={4}>
            <header>
              <div className="ai-logo">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/ai-logo.png"}
                    alt="Adaptive Investment Logo"
                  />
                </Link>
              </div>
            </header>
            <section className="ai-slide-content">
              <MainSlider />
            </section>
          </Col>
          <Col className="ai-login-wrapper" xs={12} sm={12} md={12} lg={8}>
            <Row className="flex1">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="ai-login-container">
                  <div className="ai-signup-wrapper">
                    <div className="ai-login-form">
                      <div className="ai-login-section">
                        <div className="ai-field-group">
                          <h2>Forgot Password</h2>
                        </div>
                        {stage === 1 && (
                          <form
                            name="submitForgotPasswordForm"
                            onSubmit={submitForgotPasswordForm}
                          >
                            <div className="ai-field-group">
                              <div className="ai-row">
                                <div className="ai-column">
                                  <AiInput
                                    type="text"
                                    name="username"
                                    placeholder="Enter Username / Email Id"
                                    onChange={(event) => setUserName(event.target.value)}
                                  />
                                  <AiFormError
                                    errormessage={
                                      inputerrors.username
                                    }
                                  />
                                </div>
                              </div>
                              <div className="ai-row" />
                            </div>
                            <div className="ai-field-group">
                              <div className="ai-row ai-two-column">
                                <div className="ai-column">
                                  <div className="ai-signin">
                                      Back to{" "}
                                      <Link to="/" className="ai-link">
                                        Sign In
                                      </Link>
                                  </div>
                                </div>
                                <div className="ai-column ai-align-right">
                                  <button className="ai-btn primary round">
                                    <ArrowForwardIcon />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}

                        {stage === 2 && (
                          <form
                            name="submitNewPasswordForm"
                            onSubmit={submitNewPasswordForm}
                          >
                            <div className="ai-field-group">
                              <div className="ai-row">
                                <div className="ai-column">
                                    Verification code has been sent to your
                                    registered email address. Please check
                                    your inbox.
                                </div>
                              </div>
                              <div className="ai-row">
                                <div className="ai-column">
                                  <div className="ai-form-group">
                                      <input 
                                          type="text"
                                          placeholder="Enter Verification Code"
                                          name="code"
                                          className="ai-form-value" 
                                          onChange={(event) => setCode(event.target.value)}
                                          autoComplete="new-password" />
                                  </div>
                                  <AiFormError
                                    errormessage={
                                      newpassworderrors.code
                                    }
                                  />
                                </div>
                              </div>
                              <div className="ai-row">
                                <div className="ai-column">
                                  <div className="ai-form-group">
                                      <input 
                                          type="password"
                                          placeholder="Enter New Password"
                                          name="newPassword"
                                          className="ai-form-value" 
                                          onChange={(event) => setNewPassword(event.target.value)}
                                          autoComplete="new-password" />
                                  </div>
                                  <AiFormError
                                    errormessage={
                                      newpassworderrors.newPassword
                                    }
                                  />
                                </div>
                              </div>
                              <div className="ai-row">
                                <div className="ai-column">
                                  <div className="ai-form-group">
                                      <input 
                                          type="password"
                                          placeholder="Enter Confirm Password"
                                          name="confirmPassword"
                                          className="ai-form-value" 
                                          onChange={(event) => setConfirmPassword(event.target.value)}
                                          autoComplete="new-password" />
                                  </div>
                                  <AiFormError
                                    errormessage={
                                      newpassworderrors.confirmPassword
                                    }
                                  />
                                </div>
                              </div>
                              <div className="ai-row" />
                            </div>
                            <div className="ai-field-group">
                              <div className="ai-row ai-two-column">
                                <div className="ai-column">
                                  <div className="ai-signin">
                                      Back to{" "}
                                      <Link to="/" className="ai-link">
                                        Sign In
                                      </Link>
                                  </div>
                                </div>
                                <div className="ai-column ai-align-right">
                                    <button className="ai-btn primary round">
                                      <ArrowForwardIcon />
                                    </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <footer>
                  <div className="ia-footer">
                    <p>
                      Adaptive funds are not held in a brokerage account and
                      are not protected by SIPC. Brokerage assets are held by
                      Adaptive Investments Services, a division of Adaptive
                      Investments LLC.
                    </p>
                    <ul className="ia-footer-links">
                      <li>
                        <Link to="/" className="ia-link">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="ia-link">
                          Terms &#38; Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </footer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );

}
export default withRouter(ForgotPassword);
