import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function AddExpectedDeposits(payment_method,deposit_value) {

    const Token = sessionStorage.getItem("Token");

    const header = { Authorization: Token };

    var raw = JSON.stringify({
        session_id: Token,
        payment_method:payment_method,
        deposit_value:deposit_value
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: header,
    };
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_ADD_EXPECTED_DEPOSITS_API,
                requestOptions
            );
            if (!response.ok) {
                return false;
            } else {
                const message = await response.json();
                if (message["result"] !== "") {
                    return message["result"];
                } else if (message["error"] === "Token Verification Failed") {
                    return 404;
                }
                else {
                    console.log(message["error"]);
                    console.log("There was an error fetching your data");
                    return false;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                window.location.reload();
            }
            else {
                return 0
            }
        }
    }
    else{
        return 401
    }
}
