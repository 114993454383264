import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function UpdateQuantity(Stock, Quantity, portfolioid) {

  if (Quantity === "") {
    // alert("Enter Valid Quantity");
    return null;
  }

  Quantity = Number(Quantity);
  if (isNaN(Quantity)||(Quantity<0)) {
    // alert("Enter Valid Quantity");
    return;
  }
  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token,
    symbol: Stock,
    quantity: Quantity,
    portfolio_id:portfolioid
  });

  var requestOptions = {
    method: "PATCH",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_UPDATE_PORTFOLIO_LINE_ITEMS_QUANTITY,
        requestOptions
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      } else {
        //if the call is successful, store the data in json format
        const message = await response.json();
        console.log(" mess", message)
        if (message["result"] !== "") {
          return message["result"];
        } else if (message["error"]==="Token Verification Failed"){
          return 404
        }
        else {
          console.log(message["error"]);
          console.log("There was an error fetching your data");
          return false;
        }
      }
    }catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else{
        return 0
      }
    }
  }
  else{
    return 401
  }
}
