import React from "react";
import "./PortfolioSolutionSummary.scss";
import Backdrop from "@material-ui/core/Backdrop";
import por_1 from "../../images/portfolio_shield-1.svg";
import mar1 from "../../images/market_shield-1.svg";
import Popup from "reactjs-popup";
import CustomPopup from "../Popup/Popup";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MarketShieldSummary from "../MarketShieldSummary/MarketShieldSummary";
import close from "../../images/close.svg";
import exlm from "../../images/exlm.svg";
import { addZeroes } from "../Utilities/AddZeros";
import { selectedPeriodBackend } from "../Utilities/GetProtectionPeriodValue";
import TagManager from "react-gtm-module";
import { newConvertDate } from "../Utilities/ConvertDate";
import PageLoader from "../PageLoader/PageLoader";
import Tooltip from "react-tooltip-lite";

function PortfolioSolutionsSummary(props) {

    const useStyles = makeStyles((theme) => ({
        button: {
            color: "white",
            marginTop: "2px",
        },
        checkbox_button: {
            color: "#60034c",
            width: "14px",
            height: "14px",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();

    var currObj = {
        style: "currency",
        currency: "USD",
    };


    const [bdopen, setBdOpen] = React.useState(false);
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [popupTitle, setPopupTitle] = React.useState("");
    const [shieldType, setShieldType] = React.useState("");
    const [showRefresh, setShowRefresh] = React.useState(false);
    const [showPopup, setShowPopup] = React.useState(false);
    const [PurchaseShield, setPurchaseShield] = React.useState(false);
    const [checkoutData, setCheckoutData] = React.useState([]);
    const [marketOpenFlag, setMarketOpenFlag] = React.useState(false);
    const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
    const [MarketPlusPopup, setMarketPlusPopup] = React.useState(false);
    const [MarketConcPopup, setMarketConcPopup] = React.useState(false);
    const [MarketPluscheckoutData, setMarketPlusCheckoutData] = React.useState([]);
    const selectedPortfolioTotalValue = sessionStorage.getItem("PortfolioTotalValue");
    const { period_str } = selectedPeriodBackend(props.period_value);
    
    function TurnShieldOn(shield_type) {
        setBdOpen(false);
        setPurchaseShield(true);
        setShieldType(shield_type);
        if(shield_type === 'ms'){
            setShowPopup(true);
        }else if (shield_type === 'msp'){
            setMarketPlusPopup(true);
        }
        else if(shield_type === 'msc'){
            setMarketConcPopup(true);
        }
    }

    const handleClose = (mesg) => {
        setShieldType("");
        setShowPopup(false);
        setPopupState(true);
        setPopupMessage(mesg);
        setPopupTitle("Purchase Shield");
        setAlertType("error");
        setBdOpen(false);
    };

    const closeMarketSummaryPopup = () => {
        setShowPopup(false);
        setMarketPlusPopup(false);
        setMarketConcPopup(false);
        setBdOpen(false);
      };

    return (
        <div className="ShieldProtect-holder">
            <PageLoader bdopen={bdopen}/>
            <CustomPopup
                trigger={popupState}
                setTrigger={setPopupState}
                title={popupTitle}
                content={popupMessage}
                alertType={alertType}
                loginRedirect={popupLoginRedierct}
            />
            <div className="shieldcompare_page">
                <div id="shieldcompare" className="shildcompare">
                    <div className="shildhead">
                        <div className="left">Protection type</div>
                        <div>Tracking Error</div>
                        <div>duration</div>
                        <div>Protection Level
                        </div>
                        <div>Protection Price</div>
                    </div>
                    <div className="shieldcard">
                        <div className="left">Portfolio Shield</div>
                        <div className="graph">
                            <img src={por_1}></img>
                            <p>Tracking Error</p>
                            <div>0%</div>
                        </div>
                        <div>
                            {props.period_value}
                            <br></br>
                            {props.shieldExpires}
                        </div>
                        <div>{props.level_value}%</div>
                        <div>
                            {Number(
                                addZeroes(Number(parseFloat(props.shieldPrice).toFixed(2)))
                            ).toLocaleString("en-US", currObj)}
                            <p>{Number(
                                addZeroes(Number(parseFloat((props.shieldPrice / selectedPortfolioTotalValue) * 100).toFixed(2)))
                            )}%
                            </p>
                        </div>
                        <div className="btncard">
                            <a className="ai-btn small secondary line">Coming in 2023</a>
                            <p>{props.PeriodLackMsg}</p>
                        </div>
                    </div>
                    {props.MarketShieldData &&
                    <div className="shieldcard">
                        <div className="left">Market Shield</div>
                        <div className="graph">
                            <img src={mar1}></img>
                            <p>Tracking Error</p>
                            <div>{Number(
                                addZeroes(
                                    Number(parseFloat(props.MarketShieldData["tracking_error"] * 100).toFixed(2))
                                )
                            ).toLocaleString()}%</div>
                        </div>
                        <div>
                        {props.MarketShieldSelectedData["ExpDtInDays"]} DAYS
                            <br></br>
                            {newConvertDate(props.MarketShieldSelectedData["ExpirationDate"])}
                        </div>
                        <div>
                                {props.level_value}% <Tooltip content="The shield price is adjusted as per the new protection level"><img src="Assets/risk-info.png"></img></Tooltip>    
                        </div>
                        {props.MarketShieldSelectedData["Cost"] < 0 ? (
                            <div>
                                -
                            </div>
                        ) :
                            <div>
                                {Number(
                                    addZeroes(Number(parseFloat(props.MarketShieldSelectedData["Cost"]).toFixed(2)))
                                ).toLocaleString("en-US", currObj)}
                                <p>{Number(
                                    addZeroes(Number(parseFloat((props.MarketShieldSelectedData["Cost"] / selectedPortfolioTotalValue) * 100).toFixed(2)))
                                )}%
                                </p>
                                {!props.MarketShieldSelectedData["Purchaseable"] &&
                                    <span className="notice"><img src={exlm}></img> Indicative price only. Market prices not available</span>}
                            </div>
                        }
                        {props.showRefresh === true ? (
                            <div className="btncard">
                                <a className="ai-btn ai-disabled small primary solid">TURN SHIELD ON</a>
                            </div>
                        ) : (
                            <div className="btncard">
                                <a id="ms" className="ai-btn small primary solid" onClick={() => TurnShieldOn("ms")}
                                    disabled={!props.MarketShieldSelectedData}>TURN SHIELD ON</a>
                            </div>
                        )}
                    </div>
                    }
                    {props.MSPlusAvailFlag && props.MSPlusData &&
                        <div className="shieldcard">
                            <div className="left">Market Shield Plus</div>
                            <div className="graph">
                                <img src={mar1}></img>
                                <p>Tracking Error </p>
                                <div>{Number(
                                    addZeroes(
                                        Number(parseFloat(props.MSPlusData["tracking_error"] * 100).toFixed(2))
                                    )
                                ).toLocaleString()}%</div>
                            </div>
                            <div>
                                {props.MSPlusSelectedData["ExpDtInDays"]} DAYS
                                <br></br>
                                {newConvertDate(props.MSPlusSelectedData["ExpirationDate"])}
                            </div>
                            <div>{props.level_value}% <Tooltip content="The shield price is adjusted as per the new protection level"><img src="Assets/risk-info.png"></img></Tooltip></div>
                            {props.MSPlusSelectedData["Cost"] < 0 ? (
                                <div>
                                    -
                                </div>
                            ) :
                                <div>
                                    {Number(
                                        addZeroes(Number(parseFloat(props.MSPlusSelectedData["Cost"]).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}
                                    <p>{Number(
                                        addZeroes(Number(parseFloat((props.MSPlusSelectedData["Cost"] / selectedPortfolioTotalValue) * 100).toFixed(2)))
                                    )}%
                                    </p>
                                    {!props.MSPlusSelectedData["Purchaseable"] &&
                                        <span className="notice"><img src={exlm}></img> Indicative price only. Market prices not available</span>}
                                </div>
                            }
                            {props.showRefresh === true ? (
                                <div className="btncard">
                                    <a className="ai-btn ai-disabled small primary solid">TURN SHIELD ON</a>
                                    <p>{props.MSPlusAvailableContract}</p>
                                </div>
                            ) : (
                                <div className="btncard">
                                    <a id="msp" className="ai-btn small primary solid" onClick={() => TurnShieldOn("msp")} 
                                        disabled={!props.MSPlusSelectedData}>TURN SHIELD ON</a>
                                    <p>{props.MSPlusAvailableContract}</p>
                                </div>
                            )}
                        </div>
                    }
                    {/*Market Shield Concentrated */}
                    {props.MultiDataAvailable && props.MultiShieldSelectedData &&
                        <div className="shieldcard">
                            <div className="left">Market Shield Concentrated</div>
                            <div className="graph">
                                <img src={mar1}></img>
                                <p>Tracking Error </p>
                                <div>{Number(
                                    addZeroes(
                                        Number(parseFloat(props.MSConcentratedData["tracking_error"] * 100).toFixed(2))
                                    )
                                ).toLocaleString()}%</div>
                            </div>
                            <div>
                                {props.MultiShieldSelectedData["ExpDtInDays"]} DAYS
                                <br></br>
                                {newConvertDate(props.MultiShieldSelectedData["ExpirationDate"])}
                            </div>
                            <div>{props.level_value}% <Tooltip content="The shield price is adjusted as per the new protection level"><img src="Assets/risk-info.png"></img></Tooltip></div>
                            {props.MultiShieldSelectedData["Cost"] < 0 ? (
                                <div>
                                    -
                                </div>
                            ) :
                                <div>
                                    {Number(
                                        addZeroes(Number(parseFloat(props.MultiShieldSelectedData["Cost"]).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}
                                    <p>{Number(
                                        addZeroes(Number(parseFloat((props.MultiShieldSelectedData["Cost"] / selectedPortfolioTotalValue) * 100).toFixed(2)))
                                    )}%
                                    </p>
                                    {!props.MultiShieldSelectedData["Purchaseable"] &&
                                        <span className="notice"><img src={exlm}></img> Indicative price only. Market prices not available</span>}
                                </div>
                            }
                            {props.showRefresh === true ? (
                                <div className="btncard">
                                    <a className="ai-btn ai-disabled small primary solid">TURN SHIELD ON</a>
                                    <p>{props.MSPlusAvailableContract}</p>
                                </div>
                            ) : (
                                <div className="btncard">
                                    <a id="msc" className="ai-btn small primary solid"
                                        onClick={() => TurnShieldOn("msc")}
                                        disabled={!props.MultiShieldSelectedData}>
                                        TURN SHIELD ON
                                    </a>
                                    <p>{props.MSPlusAvailableContract}</p>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
            {shieldType === "ms" && (
                <Popup id="PortfolioPopUp" open={showPopup} modal>
                    <div className="add-portfolio-popup">
                        <IconButton
                            onClick={() => setShowPopup(false)}
                            className="closepopup"
                        >
                            <img src={close}></img>
                        </IconButton>
                        <MarketShieldSummary checkoutdata={props.MarketShieldSelectedData}
                            ShieldData={props.MarketShieldData} buyShield={PurchaseShield} marketOpenFlag={marketOpenFlag}
                            marketShield={true} agreementContractData={props.agreementContractData}
                            showPurchaseShield={true} AgreementID={props.AgreementID}
                            marketShieldButtonFlag={props.marketShieldButtonFlag}
                            selectedPeriod={props.period_value} contractData={props.contractData}
                            handleClose={handleClose} LivePriceFlag={props.LivePriceFlag}
                            PopupTitle="Market Shield Purchase Agreement Summary"
                            portfolioData={props.protectedPortfolioData}
                            chosenIndex={props.ms_chosenIndex}
                            customerInfo={props.customerInfo} 
                            shield_type={shieldType}
                            period_str={period_str}
                            closeMarketSummaryPopup={closeMarketSummaryPopup}/>
                    </div>
                </Popup>
            )}
            {shieldType === "msp" && (
                <Popup id="PortfolioPopUp" open={MarketPlusPopup} modal>
                    <div className="add-portfolio-popup">
                        <IconButton
                            onClick={() => setMarketPlusPopup(false)}
                            className="closepopup"
                        >
                            <img src={close}></img>
                        </IconButton>
                        <MarketShieldSummary checkoutdata={props.MSPlusSelectedData} buyShield={PurchaseShield} marketOpenFlag={marketOpenFlag}
                            ShieldData={props.MSPlusData}
                            marketShield={true} agreementContractData={props.mspagreementContractData}
                            showPurchaseShield={true} AgreementID={props.MSPAgreementID}
                            marketShieldButtonFlag={props.marketShieldPlusButtonFlag}
                            selectedPeriod={props.period_value} contractData={props.mspagreementContractData}
                            handleClose={handleClose} LivePriceFlag={props.LivePriceFlag}
                            PopupTitle="Market Shield Plus Purchase Agreement Summary"
                            portfolioData={props.protectedPortfolioData}
                            chosenIndex={props.msp_chosenIndex}
                            customerInfo={props.customerInfo}
                            shield_type={shieldType}
                            period_str={period_str}
                            closeMarketSummaryPopup={closeMarketSummaryPopup} />
                    </div>
                </Popup>
            )}
            {shieldType === "msc" && (
                <Popup id="PortfolioPopUp" open={MarketConcPopup} modal>
                    <div className="add-portfolio-popup">
                        <IconButton
                            onClick={() => setMarketConcPopup(false)}
                            className="closepopup"
                        >
                            <img src={close}></img>
                        </IconButton>
                        <MarketShieldSummary checkoutdata={props.MultiShieldSelectedData} buyShield={PurchaseShield} marketOpenFlag={marketOpenFlag}
                            ShieldData={props.MSConcentratedData}
                            marketShield={true} agreementContractData={props.mspagreementContractData}
                            showPurchaseShield={true} AgreementID={props.MSPAgreementID}
                            marketShieldButtonFlag={props.marketShieldPlusButtonFlag}
                            selectedPeriod={props.period_value} contractData={props.mspagreementContractData}
                            handleClose={handleClose} LivePriceFlag={props.LivePriceFlag}
                            PopupTitle="Market Shield Concentrated Purchase Agreement Summary"
                            portfolioData={props.protectedPortfolioData}
                            chosenIndex={props.msp_chosenIndex}
                            customerInfo={props.customerInfo}
                            shield_type={shieldType}
                            period_str={period_str}
                            closeMarketSummaryPopup={closeMarketSummaryPopup} />
                    </div>
                </Popup>
            )}
        </div>
    );
}

export default PortfolioSolutionsSummary;
