import React from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Bar, Cell, ReferenceLine, Legend } from 'recharts';


const getMaxRange = data => {
  let max = -Infinity;
  let min = Infinity;
  data.forEach(item => {
    if (item.range[1] > max) max = item.range[1];
    if (item.range[0] < min) min = item.range[0];
  });
  return [min - 0.1, max + 0.1];
};

const generateTicks = (min, max, numberOfTicks) => {
  const delta = (max - min) / (numberOfTicks - 1);
  const ticks = Array.from({ length: numberOfTicks }, (_, i) => +(min + i * delta).toFixed(2));
  return ticks;
};

const CustomYAxisTick = ({ x, y, payload, orientation }) => {
  const isLeft = orientation === 'left';
  return (
    <text x={x} y={y} dx={isLeft ? -10 : 10} dy={5} textAnchor={isLeft ? "end" : "start"} fill="#666">{payload.value}</text>
  );
};

const CustomLegend = () => (
  <div className='legend'>
    <div className='legendlist'><svg width={20} height={20} style={{ verticalAlign: 'middle', marginRight: '20px' }}>
      <polygon points="10,0 0,20 20,20" fill="#FFA654" />
    </svg>
    <span style={{ verticalAlign: 'middle', marginRight: '20px' }}>Implied Downside Volatility of Portfolio without shield</span>
    </div>
    <div className='legendlist'>
    <svg width={20} height={20} style={{ verticalAlign: 'middle', marginRight: '20px' }}>
      <circle cx={10} cy={10} r={10} fill="#38BB55" />
    </svg>
    <span style={{ verticalAlign: 'middle' }}>Implied Downside Volatility of Portfolio with shield</span>
    </div>
  </div>
);

const BarGraph = (props) => {
  console.log("props", props);
  const data = [
    {
      name: 'Without Adaptive Shield',
      range: props.data['without_shield'],
      without_implied_volatility: props.data['portfolio_implied_volatility']
    },
    {
      name: 'With Adaptive Shield',
      range: props.data['with_shield'],
      with_implied_volatility: props.data['shielded_portfolio_volatility']
    },
  ];

  console.log("data99", data);

  const renderCustomBar = ({ x, y, width, height, payload }) => {
    const [negative, positive] = payload.range;
    const positiveHeight = (positive / (positive - negative)) * height;
    const negativeHeight = height - positiveHeight;
    const offset = (width - 50) / 2;

    return (
      <g>
        <rect x={x + offset} y={y} width={50} height={positiveHeight} fill="#E58B76" />
        <rect x={x + offset} y={y + positiveHeight} width={50} height={negativeHeight} fill="#60034C" />
        {payload.without_implied_volatility &&
          <polygon
            points={`${x + offset + 25},${y + positiveHeight +
              payload.without_implied_volatility * height} ${x + offset + 20},${y + positiveHeight + payload.without_implied_volatility * height + 10} ${x + offset + 30},${y + positiveHeight + payload.without_implied_volatility * height + 10}`}
            fill="#FFA654"
          />
        }
        {payload.with_implied_volatility &&
          <circle
            cx={x + offset + 25}
            cy={y + positiveHeight + payload.with_implied_volatility * height}
            r="5"
            fill="#38BB55"
          />
        }
      </g>
    );
  };

  const domain = getMaxRange(data);
  const ticks = generateTicks(domain[0], domain[1], 10);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const range = data.range;
      return (
        <div>
          <p>Range: {range[0].toFixed(3)} - {range[1].toFixed(3)}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <BarChart
      width={900}
      height={400}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <XAxis dataKey="name" />
      <YAxis domain={domain} ticks={ticks} tick={props => <CustomYAxisTick {...props} orientation="left" />} />
      <YAxis yAxisId="right" orientation="right" domain={domain} ticks={ticks} tick={props => <CustomYAxisTick {...props} orientation="right" />} axisLine={true} tickLine={true} />
      <Tooltip content={<CustomTooltip />} />
      <ReferenceLine y={0} stroke="#000" strokeWidth={0.3} />
      <Bar dataKey="range" fill="green" shape={renderCustomBar}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} />
        ))}
      </Bar>
      <Legend content={<CustomLegend />} />
    </BarChart>
  );
};

export default BarGraph;