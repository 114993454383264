import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function UpdateShieldStatus(protection_period, protection_level) {

  const Token = sessionStorage.getItem("Token");

  const header = { Authorization: Token };
  
  // const session_id = sessionStorage.getItem("sessionID");
  
  var raw = JSON.stringify({
    session_id: Token,
    protection_period: protection_period['period_str'],
    protection_level: protection_level
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_PRUCHASE_QUOTE_ENGINE,
        requestOptions
      );
      if (!response.ok) {
        // alert("Could not turn shield on");
        return false;
      } else {
        return true;
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else{
    return 401
  }
}
