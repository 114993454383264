import React from "react";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import "./AdvisorClientListTabScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import AdvisorClientListTab from "../../Components/AdvisorClientListTab/AdvisorClientListTab";
import { Container, Row, Col } from 'react-bootstrap';

function AdvisorClientListTabScreen(props) {
  return (
    <div className="ai-content-part advisor">
        <Navbar />
        <section className="portfolio-table-wrapper">
              <AdvisorClientListTab/>
        </section>
    </div>
  );
}

export default AdvisorClientListTabScreen;