import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetMarketRiskData() {
    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };
  
    var requestOptions = {
      method: "GET",
      headers: header,
      redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
      try {
        const response = await fetchWithTimeout(
          process.env.REACT_APP_ADVISOR_MARKET_RISK,
          requestOptions
        );
        if (response.message === "Internal server error") {
          //alert("We are facing server issues");
        } else if (response.message === "The incoming token has expired") {
          //alert("Your session has expired please log-in again");
        } else {
          const data = await response.json();
          if (data["result"] !== "") {
            return data["result"];
          } else {
            return false;
          }
        }
      } catch (error) {
        if (error.name === "AbortError") {
          //alert("The request timed out, Please try again");
          window.location.reload();
        }
        else{
          return 0
        }
      }
    }
    else{
      return 401
    }
  }
  