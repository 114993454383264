import React from "react";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import SelfAssemblyWindow from "../../Components/SelfAssemblyWindow/SelfAssembly";

export default function SelfAssembly() {
  return (
    <div className="ai-content-part">
      <Navbar />
      <PageHeader heading="Self Assembly" backButton={true} routeTo="/AdaptiveGenius"/>
      <SelfAssemblyWindow />
    </div>
  );
}
