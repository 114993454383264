import React from "react";

import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    Dot,
} from "recharts";


function RiskContributionGraph(props) {
    const data = props.data
    var risk_contribution_max = Math.round(Math.max(...data.map(o => o.risk_contribution))+5)
    var position_risk_max = Math.round(Math.max(...data.map(o => o.position_risk))+5)
    const RenderDot = ({ cx, cy }) => {
        return (
            <Dot cx={cx} cy={cy} fill="#E58B76" r={8} />
        )
    }
      
    return (
        <div id="riskcontributiongraph" style={{ width: "100%", height: "450px" }}>
            <ResponsiveContainer
            width='100%'
            height={400}>
                <ComposedChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 0,
                        left: 10,
                        bottom: 0
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <XAxis
                        dataKey="symbol"
                        tickLine={true}
                        axisLine={{ stroke: "#f5f5f5" }}
                    />
                    <Tooltip />
                    {/* <Legend verticalAlign="top" align="left" height={36} /> */}
                    <Bar
                        radius={[10, 10, 0, 0]}
                        dataKey="risk_contribution"
                        barSize={50}
                        fill="#60034C"
                        yAxisId="left"
                        legendType="rect"
                        name="Risk Contribution"
                    />
                    <Line
                        dot={false}
                        strokeWidth={2}
                        strokeLinecap="round"
                        type="monotone"
                        dataKey="vix_value"
                        stroke="#54C1FF"
                        yAxisId="right"
                        legendType="rect"
                        name="VIX"
                    />
                    <Scatter dataKey="position_risk" fill="#E58B76" yAxisId="right" radius={20} name="Position Risk" shape={<RenderDot/>}/>
                    <YAxis
                        tickLine={true}
                        yAxisId="left"
                        axisLine={{ stroke: "#f5f5f5" }}
                        unit="%"
                        domain={[ 0, risk_contribution_max]} 
                        tickCount={5}
                        scale="auto"
                    />
                    <YAxis
                        tickLine={false}
                        yAxisId="right"
                        orientation="right"
                        axisLine={{ stroke: "#f5f5f5" }}
                        unit="%"
                        domain={[0, position_risk_max]}
                        tickCount={5}
                        scale="auto"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>

    );
}


export default RiskContributionGraph;
