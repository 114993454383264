import React from "react";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Navbar from "../../Components/Navbar/Navbar_official";
import TerminalReturn from "../../Components/TerminalReturn/TerminalReturn";

export default function TerminalReturnScreen() {
    return (
        <div className="ai-content-part">
            <Navbar />
            <div className="floathead alon big">
                <PageHeader heading="Terminal Return Distribution" backButton={true} routeTo="./PortfolioSummaryScreen" />
            </div>
            <TerminalReturn />
        </div>
    );
}