import React from "react";
import withWidth from "@material-ui/core/withWidth";
import "./ShieldRecommendationScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";
import RecommendationBanner from "../../Components/RecommendationBanner/RecommendationBanner";
import { useLocation } from "react-router-dom";

function ShieldRecommendationScreen() {
  const location = useLocation();
  return (
    <div className="ai-content-part">
      <Navbar />
      <div className="floathead">
        <PageHeader
          heading="Shield Recommendations"
          backButton={true}
          routeTo="./AnalyzePortfolio"
        ></PageHeader>
        <PortfolioDetails
          routeTo="./ShieldProtectionScreen"
          forward_route={true}
          imgSrc="Assets/buyshield.png"
          routeText="Make your Own Shield"
          stocksData = {location.state.stocksData}
        />
      </div>
      <RecommendationBanner />
    </div>
  );
}

export default withWidth()(ShieldRecommendationScreen);
