import React from "react";
import "./ActiveShieldPDF.css";
import ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";
import Html from "react-pdf-html";
import MediumFont from "../../fonts/Inter-Medium.woff";
import SemiBoldFont from "../../fonts/Inter-SemiBold.woff";
import BoldFont from "../../fonts/Inter-Bold.woff";
import RegularFont from "../../fonts/Inter-V.ttf";
import { addZeroes } from "../Utilities/AddZeros";
import { newConvertDate, convertDate } from "../Utilities/ConvertDate";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  pdf,
  Font,
} from "@react-pdf/renderer";

import TagManager from "react-gtm-module";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

function getInitialsFromName(fullname) {
  if (!fullname || typeof fullname !== 'string') {
      return "N.A";
  } 

  let nameParts = fullname.split(" ").filter(part => part);
  let initials = nameParts.map(part => part.charAt(0)).join(" ");

  return initials || "N.A";
}

function ActiveShieldPDF(props) {

  console.log("props data", props)

  Font.register({ family: "Inter-Bold", src: BoldFont });
  Font.register({ family: "Inter-V", src: RegularFont });
  Font.register({ family: "Inter-SemiBold", src: SemiBoldFont });
  Font.register({ family: "Inter-Medium", src: MediumFont });

  var currObj = {
    style: "currency",
    currency: "USD",
  };


  const headers = [
    {
      title: "Tracking Instrument",
      tooltip: "",
    },
    {
      title: "Quantity",
      tooltip: "",
    },
    {
      title: "Current Index Level",
      tooltip: "",
    },
    {
      title: "NOTIONAL VALUE",
      tooltip: "",
    },
    {
      title: "Protected Value",
      tooltip: "",
    },
  ];

  const styles = StyleSheet.create({
    body: {
      paddingTop: 16,
      paddingBottom: 32,
      paddingHorizontal: 16,
    },
    image: {
      marginVertical: 15,
      textAlign: "left",
      height: 40,
      width: 150,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    footer: {
      position: "absolute",
      fontSize: 8,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "left",
      color: "#3E4968",
      paddingLeft: 30,
      paddingRight: 30,
    },
    summaryHeader: {
      color: "#3E4968",
      fontSize: 18,
      textAlign: "left",
      fontWeight: "bold",
      marginVertical: 15,
      fontFamily: "Inter-Bold",
    },
    tableheader: {
      backgroundColor: "#fbece6",
      borderRadius: 5,
      color: "#60034c",
      fontSize: 8,
      padding: 10,
      fontFamily: "Inter-V",
    },
    tablebody: {
      fontSize: 10,
      padding: 8,
      textAlign: "right",
      fontFamily: "Inter-V",
    },
  });


  const DownloadPdf = async () => {
    await new Promise(resolve => {
      const intervalId = setInterval(() => {
        if (props !== undefined) {
          clearInterval(intervalId);
          resolve();
        }
      }, 1000);  
    });

    const element = (
      <html>
        <body>
          <style>
            {`
        .payment-header {
          margin: 12px 0;
          font-size: 16px;
          color: #3e4968;
          font-family: Inter-Bold;
          font-weight: bold;
        }    
        .payment-header-p {
          font-size: 10px;
          color: black;
          font-family: Inter-V;
        }
        .payment-stocksummary {
          border: 1px solid #999;
          margin-top: 32px;
          border-radius: 5px;
        }  
        .payment-stocksummary table thead {
          display: block;
          font-size: 15px;
          border-radius: 8px 8px 0px 0px;
        }
        .payment-TC {
          padding: 8px;
          border: 1px solid #999;
          border-radius: 5px;
        }
        .payment-TC p {
          margin: 0;
          line-height: 1.6;
        }
        .user-checkout-details {
          display: flex;
          text-align: right;
          align-items: flex-start;
        }
        .user-checkout-details-label {
          font-family: Inter-V;
          color: black;
          font-size: 12px;
          display:flex;
          width:150px;
        }
        .user-checkout-details .pay-value {
          font-size: 12px;
          font-family: Inter-Medium;
          font-weight: bold;
          color: black;        
        }      
        .payment-details-table {
          display: flex;
          font-size: 10px;
        }
        .payment-details-table  table tbody tr td:nth-child(even) {
          text-align: left;
          font-family: Inter-Medium;
          color: #333333;
          font-weight: 800;
        } 
        .payment-details-table  table tbody tr td:nth-child(odd) {
          text-align: right;
          font-family: Inter-V;
          color: #666666;
          display: inline-block;
        } 
        .payment-stocksummary table tbody tr:nth-child(even) {
          background: #fafafa;
        }
        .payment-stocksummary table tbody tr td:nth-child(1) {
          text-align: left;
        }
        .portfolioinfotable, .informationtable{
          font-size:9px;
          font-family: Inter-Medium;
          text-align: left;
          margin:0;
        }
        .portfolioinfotable td, .informationtable td{
          padding: 4px 0;
        }
        .informationtable td:first-child{
          max-width:75px;
          width:75px;
        }
        .portfolioinfotable td:first-child{
          padding-right:20px;
        }    
        
        .tdlist{
          display:flex;
          flex-direction:row;
        }
        .tdlist .saprator{
          display:flex;
          padding:0 4px;
          color:#E58B76;
          font-weight: 800;
        }
        .tdlist .lable{
          display:flex;
          flex:1;
        }
        .tdlist .value{
          text-align:left;
          display:flex;
          width:120px;
        }
        .tdlist .value1{
          text-align:left;
          display:flex;
          width:65px;
        }
        .marketagree{
          font-size:9px;
          line-height:1.5;
          font-family: Inter-V;
          text-align: left;
          margin:0;
          color:#666;
        }
        .marketagree h5{
          font-family: Inter-Medium;
          font-size:11px;
          margin-top:8px;
          margin-bottom:4px;
          color:#000;
        }
        .marketagree b,.marketagree u{
          font-family: Inter-Medium;
          color:#000;
        }
        .marketagree u{
          text-decoration:underline;
        }
        .marketagree td{
          padding-top:6px;
          padding-bottom:8px;
        }
        .marketagree td.number{
          max-width:15px;
          width:15px;
          font-size:10px;
          text-align:right;
          font-family: Inter-Medium;
          padding-right:5px;
        }
        .rightalign{
          text-align:right;
        }
        center{
          text-align:center;
        }
        .rightalign .signaturetbl{
          width:200px;
          text-align:left;
          display:inline-block;
          padding-right:30px;
        }
        .rightalign .signaturetbl td{
          padding:2px;
        }
        .center{
          text-align:center;
        }
        
        `}
          </style>
          <div id="pdf-content" className="pdf-content">
            <div className="payment-TC">
              <table className="informationtable">
                <tr>
                  <td>Name : </td>
                  <td>{props.customerInfo?.client_name ?? ""}</td>
                </tr>
                {/* <tr>
                  <td>Address :{" "}</td>
                  <td>{props.customerInfo.client_address}</td>
                </tr> */}
                <tr>
                  <td>Agreement ID :{" "}</td>
                  <td>{props.AgreementID}</td>
                </tr>
              </table>
            </div>
            <br />
            <div style={styles.summaryHeader}>Shield Summary</div>
            <div className="payment-TC">
              {props.detail === "marketshield" ? (
                <table className="portfolioinfotable">
                  <tbody>
                    <tr>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Market Portfolio Notional Value </div>
                          <div className="saprator"> : </div>
                          <div className="value1">
                            $
                            {Number(
                              Number(
                                props.checkoutdata["TotalNotionalVal"]
                              ).toFixed(2)
                            ).toLocaleString("en-US")}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Market Shield Fee </div>
                          <div className="saprator"> : </div>
                          <div className="value">
                            $
                            {Number(
                              Number(
                                props.checkoutdata["Cost"]
                              ).toFixed(2)
                            ).toLocaleString("en-US")}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Market Portfolio Protected Value </div>
                          <div className="saprator"> : </div>
                          <div className="value1">
                            $
                            {Number(
                              Number(
                                props.checkoutdata["TotalProtectedVal"]
                              ).toFixed(2)
                            ).toLocaleString("en-US")}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Market Shield Agreement ID </div>
                          <div className="saprator"> : </div>
                          <div className="value">{props.AgreementID}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Market Portfolio Protected Level </div>
                          <div className="saprator"> : </div>
                          <div className="value1">
                            {props.checkoutdata["ProtLevel"]}%
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Market Portfolio Details </div>
                          <div className="saprator"> : </div>
                          <div className="value">
                            $
                            {Number(
                              Number(props.checkoutdata["TotalProtectedVal"]).toFixed(2)
                            ).toLocaleString("en-US")}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tdlist">
                          <div className="lable">For a Market Portfolio Loss of no more than </div>
                          <div className="saprator"> : </div>
                          <div className="value1">
                            {100 -
                              props.checkoutdata["ProtLevel"]}
                            %
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="tdlist">
                          <div className="lable">For a period of </div>
                          <div className="saprator"> : </div>
                          <div className="value">
                            {props.period}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Starting Date </div>
                          <div className="saprator"> : </div>
                          <div className="value1">
                            {props.previousPath === 'activeshield' ? newConvertDate(props.checkoutdata.shield_purchase_datetime[0]) : convertDate(new Date())}</div>
                        </div>
                      </td>
                      <td>
                        <div className="tdlist">
                          <div className="lable">Expiration Date </div>
                          <div className="saprator"> : </div>
                          <div className="value">
                            {newConvertDate(
                              props.checkoutdata["ExpirationDate"]
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className="portfolioinfotable">
                  <tbody>
                    <tr>
                      <td>Initial Portfolio Value :</td>
                      <td>

                      </td>
                      <td>Protected Value :</td>
                      <td>

                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Protection Level :</td>
                      <td>{ }%</td>
                      <td>Maximum loss :</td>
                      <td>{ }%</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>For a period of :</td>
                      <td>{ }</td>
                      <td>Starting Date :</td>
                      <td>{ }</td>
                      <td>Expiration Date :</td>
                      <td>{ }</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="payment-stocksummary">
              {/* {props.detail === "marketshield" ? (
                <table>
                  <thead>
                    <tr style={styles.tableheader}>
                      <th style={{ textAlign: "left" }}>SHIELDED STOCK DETAIL</th>
                      <th style={{ textAlign: "right" }}>INITIAL QTY</th>
                      <th style={{ textAlign: "right" }}>INITIAL PRICE</th>
                      <th style={{ textAlign: "right" }}>INITIAL VALUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.portfolioData.map((row) => (
                      <tr style={styles.tablebody} key={row.key}>
                        <td>{row.symbol}</td>
                        <td>{row.quantity}</td>
                        <td>${row.close_price.toFixed(2).toLocaleString("en-US")}</td>
                        <td>${row.market_value.toFixed(2).toLocaleString("en-US")}</td>
                      </tr>
                    ))}
                    <tr style={styles.tableheader}>
                      <td />
                      <td style={{ textAlign: "right" }}>
                        INITIAL PORTFOLIO VALUE
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          color: "black",
                          fontFamily: "Inter-Medium",
                          fontSize: 10,
                        }}
                      >
                        $
                        {Number(
                          Number(PortfolioTotalValue).toFixed(2)
                        ).toLocaleString("en-US")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : ( */}
              <table>
                <thead>
                  <th style={{ textAlign: "left" }}>
                    Tracking Instrument Details
                  </th>
                  <tr style={styles.tableheader}>
                    {headers.map((header) => (
                      <th style={{ textAlign: "right" }}>
                        <div>
                          {header.title}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {props.checkoutdata && props.checkoutdata.ClosePrice
                    && props.checkoutdata.ClosePrice.length > 0
                    && props.checkoutdata.ClosePrice.map((_, i) => (
                      <tr style={styles.tablebody} key={i}>
                        <td>{props.checkoutdata.SecDesc[i]}</td>
                        <td>{props.checkoutdata.ConQty[i]}</td>
                        <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.ClosePrice[i]).toFixed(2)))
                        ).toLocaleString()}</td>
                        <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.NotionalVal[i]).toFixed(2)))
                        ).toLocaleString()}</td>
                        <td>${Number(addZeroes(Number(parseFloat(
                          props.checkoutdata.ProtectedVal[i]).toFixed(2)))).toLocaleString()}</td>
                      </tr>
                    ))}
                  <tr style={styles.tableheader}>
                    <td />
                    <td />
                    <td style={{ textAlign: "right" }}>
                      Total
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        color: "black",
                        fontFamily: "Inter-Medium",
                        fontSize: 7,
                      }}
                    >
                      ${Number(
                        addZeroes(
                          Number(
                            parseFloat(
                              props.checkoutdata["TotalNotionalVal"]
                            ).toFixed(2)
                          )
                        )
                      ).toLocaleString()}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        color: "black",
                        fontFamily: "Inter-Medium",
                        fontSize: 7,
                      }}
                    >
                      ${Number(
                        addZeroes(
                          Number(
                            parseFloat(
                              props.checkoutdata["TotalProtectedVal"]
                            ).toFixed(2)
                          )
                        )
                      ).toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* )} */}
            </div>
            <br />
            {props.detail === "quoteshield" ? (
              <div style={styles.summaryHeader}>Shield Terms & Conditions</div>
            ) : (
              <div style={styles.summaryHeader}>
                Market Shield Purchase Agreement
              </div>
            )}
            {props.detail === "marketshield" ? (
              <>
                <table className="marketagree">
                  <tr>
                    <td colSpan={2}>
                      This Market Shield Purchase Agreement (the “<b><u>Agreement</u></b>”), effective as of <b>{props.previousPath === 'activeshield' ? newConvertDate(props.checkoutdata.shield_purchase_datetime[0]) : convertDate(new Date())}</b>, is made by and between Adaptive Capital Management, LLC, a Delaware limited liability company (the “<b><u>Company</u></b>”), and the purchaser party and/or such purchaser party’s authorized agent hereto designated on the signature page hereof (collectively, the “<b><u>Purchaser</u></b>”).
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <h5>RECITALS</h5>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>WHEREAS, the Company offers to purchasers a contractual obligation to pay such purchaser an amount based on certain losses that a hypothetical market portfolio may suffer over a fixed time period and within the parameters established by this Agreement (the “<b><u>Market Shield</u></b>”); and</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>WHEREAS, Purchaser has reviewed all materials with respect to the Market Shield and this Agreement and has completed the information necessary to calculate the protection and Market Shield price parameters set forth on <u>Exhibit A</u> hereto;</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>NOW, THEREFORE, in consideration of the mutual agreements contained herein, and for other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Purchaser and Company hereby agree as follows:</td>
                  </tr>
                  <tr>
                    <td className="number">
                      I.
                    </td>
                    <td><b>Description of Market Shield. </b>The Market Shield is designed to provide certain downside risk protection for the Purchaser based upon a hypothetical market portfolio assessed to be a reasonably close facsimile of a Purchaser’s designated securities portfolio. Because the Market Shield is an approximation of the Purchaser’s designated securities portfolio, it may be subject to varying divergences either narrower or wider, depending in part but not exclusively on the diversification of the Purchaser’s designated securities portfolio. Pursuant to a Market Shield purchased pursuant to this Agreement, the Company will pay to the Purchaser the amounts set forth on <u>Exhibit A</u> hereto if the provisions of this Agreement (including the parameters set forth on <u>Exhibit A</u>) are met. The Company does not use the Purchaser’s assets or portfolio in any way with respect to the Market Shield, but rather makes its own investments to hedge its obligations to the Purchaser with respect to the Market Shield. The Market Portfolio established in connection with this Agreement is used by the Company to provide a measurement tool for the value of the Market Shield downside protection being purchased and does not involve the sale or purchase of any securities for the Purchaser’s account.</td>
                  </tr>
                  <tr>
                    <td className="number">
                      II.
                    </td>
                    <td><b>The Fee.</b> The fee to be paid by Purchaser hereunder for purchase of the Market Shield is set forth on <u>Exhibit A</u> hereto and shall be paid contemporaneously with the purchase of the Market Shield and execution of this Agreement. Note that a portion of the fee to be paid by Purchaser to the Company will be paid to the Company’s affiliate Adaptive Advisers, LLC (“<b><u>Adaptive Advisers</u></b>”), an SEC registered investment adviser, for advisory services rendered to the Company with respect to the Market Shield (as described in the Form ADV filed by Adaptive Advisers).</td>
                  </tr>
                  <tr>
                    <td className="number">
                      III.
                    </td>
                    <td><b>Downside Protection.</b> Depending on the parameters set forth on <u>Exhibit A</u> with respect to the Market Shield being purchased hereby, the Company will, within (<b>2</b>) business days from the Expiration Date of the Market Shield (as set forth on <u>Exhibit A</u>), pay to the Purchaser the “<b><u>Protection Payout</u></b>” calculated as set forth on <u>Exhibit A</u>, if any. <b>For the avoidance of doubt, the Market Shield does not provide protection for the Purchaser’s designated securities portfolio. Rather, based on the Purchaser’s inputs with respect to such Purchaser’s designated securities portfolio, the Company has created a Market Portfolio as designed in <u>Exhibit A</u>, based on such Purchaser's designated securities portfolio (the "Designated Portfolio"). The Market Portfolio is designed to rise and fall with approximately the same magnitude of the Purchaser’s designated portfolio. The downside protection provided by this Agreement is therefore tied to changes in the Market Portfolio and will not provide the exact same protection as such downside protection would for the Purchaser's Designated Portfolio. The details of the Market Portfolio and the protection provided by this Agreement are set forth on <u>Exhibit A</u> attached hereto.</b></td>
                  </tr>
                  <tr>
                    <td className="number">
                      IV.
                    </td>
                    <td><b>Term.</b>{" "}The term of this Agreement (“<b><u>Term</u></b>”) shall commence on the date of this Agreement and terminate (i) if no Protection Payout is due to the Purchaser at the Expiration Date of the Market Shield, on the Expiration Date, or (ii) if a Protection Payout is due to the Purchaser, the date such Protection Payout is made to the Purchaser.</td>
                  </tr>
                  <tr>
                    <td className="number">
                      V.
                    </td>
                    <td>
                      <b>Assignment & Transfer</b><br></br>
                      <table>
                        <tr>
                          <td className="number">
                            a.
                          </td>
                          <td><u>Transfer or Assignment by Purchaser.</u> This Agreement is intended for and shall inure to the benefit of the Purchaser, and each and every reference herein to the Purchaser shall also include and refer to each and every successor or assignee of the Purchaser to the rights under this Agreement. This Agreement shall be transferable by the Purchaser with written notice to the Company.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            b.
                          </td>
                          <td><u>Transfer or Assignment by Company.</u> It is hereby agreed that Company may not, without the express prior written consent of Purchaser, transfer or assign any of its rights, powers, duties or obligations hereunder.</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table className="marketagree">
                  <tr>
                    <td className="number">
                      VI.
                    </td>
                    <td>
                      <b>Miscellaneous</b><br></br>
                      <table>
                        <tr>
                          <td className="number">
                            a.
                          </td>
                          <td><u>Waiver.</u> No failure to exercise, and no delay in exercising, on the part of the Purchaser, any right hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other right. The rights of Purchaser hereunder shall be in addition to all other rights provided by law. No modification or waiver of any provision of this Agreement, nor consent to departure therefrom, shall be effective unless in writing and no such consent or waiver shall extend beyond the particular case and purpose involved. No notice or demand given in any case shall constitute a waiver of the right to take other action in the same, similar or other instances without such notice or demand.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            b.
                          </td>
                          <td>
                            <u>Notices.</u> It is agreed that:<br></br>
                            <table>
                              <tr>
                                <td className="number">
                                  i.
                                </td>
                                <td>
                                  Any notice or demand to the Company hereunder or in connection herewith may be given and shall conclusively be deemed and considered to have been given and received (i) upon the receipt of an email to the Company addressed to marketshield@adaptivecapitalmgmt.com or (ii) upon the third day after the deposit thereof, in writing, in the United States mail, duly stamped and addressed to the Company at the address of the Company shown below (whether actually received or not), but actual notice, however given or received, shall always be effective.
                                  Adaptive Capital Management, LLC
                                  6 Liberty Square, No. 2352
                                  Boston, Massachusetts 02109
                                  The last preceding sentence shall not be construed in anywise to affect or impair any waiver of notice or demand herein provided or to require giving of notice or demand to or upon the Company in any situation or for any reason.
                                </td>
                              </tr>
                              <tr>
                                <td className="number">
                                  ii.
                                </td>
                                <td>Any notice which Company may or shall give to the Purchaser shall be delivered via electronic mail or personally delivered or mailed by prepaid certified or registered mail return receipt requested, delivery restricted to addressee only at the address of the Purchaser as shown below.</td>
                              </tr>
                              <tr>
                                <td className="number">
                                  iii.
                                </td>
                                <td>In the event that Purchaser or Company changes its e-mail or mailing address, notice shall be given to the other party in the manner described in this Section.</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="number">
                            c.
                          </td>
                          <td><u>Invalid Provisions.</u> If any provision of this Agreement is held to be illegal, invalid or unenforceable under present or future laws effective during the term of this Agreement, such provision shall be fully severable and this Agreement shall be construed and enforced as if such illegal, invalid or unenforceable provision had never comprised a part of this Agreement, and the remaining provisions of this Agreement shall, to the extent permitted by law, remain in full force and effect and shall not be affected by the illegal, invalid or unenforceable provision.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            d.
                          </td>
                          <td><u>No Conditions.</u> The Company acknowledges, represents and warrants to the Purchaser that (a) no promises or inducements have been made to the Company to execute and deliver this Agreement to the Purchaser, other than those promises and inducements (if any) which are expressly set forth in this Agreement and (b) either there are no conditions or all conditions (if any) which the Company may require to be satisfied as a condition to the execution and delivery of this Agreement by the Company to the Purchaser have been satisfied and (c) the Company is not relying upon or requiring the guaranty of any other person or entity for all or any part of the Market Shield in executing and delivering this Agreement to the Purchaser.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            e.
                          </td>
                          <td><u>GOVERNING LAW; JURISDICTION.</u> THE SUBSTANTIVE LAWS OF THE COMMONWEALTH OF MASSACHUSETTS SHALL GOVERN THE VALIDITY, CONSTRUCTION, ENFORCEMENT AND INTERPRETATION OF THIS AGREEMENT. FOR PURPOSES OF THIS AGREEMENT AND THE RESOLUTION OF DISPUTES HEREUNDER, COMPANY HEREBY IRREVOCABLY SUBMITS AND CONSENTS TO, AND WAIVES ANY OBJECTION TO, THE NON-EXCLUSIVE JURISDICTION OF THE STATE AND FEDERAL COURTS LOCATED IN THE COMMONWEALTH OF MASSACHUSETTS.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            f.
                          </td>
                          <td><u>Headings.</u> Section headings are for convenience of reference only and shall in no way affect the interpretation of this Agreement</td>
                        </tr>
                        <tr>
                          <td className="number">
                            g.
                          </td>
                          <td><u>Rights and Remedies.</u> The exercise by Purchaser of any right or remedy hereunder or under any other instrument, or at law or in equity, shall not preclude the concurrent or subsequent exercise of any other right or remedy.</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table className="marketagree">
                  <tr>
                    <td className="number">
                    </td>
                    <td>
                      <table>
                        <tr>
                          <td className="number">
                            h.
                          </td>
                          <td><u>Multiple Counterparts.</u> To facilitate execution, this Agreement may be executed in any number of counterparts as may be convenient or necessary, and it shall not be necessary that the signatures of all parties hereto be contained on any one counterpart hereof. Additionally, the parties hereto hereby agree that (a) the signature pages taken from separate individually executed counterparts of this Agreement may be combined to form multiple fully executed counterparts and (b) a facsimile transmission shall be deemed to be an original signature. All executed counterparts of this Agreement shall be deemed to be originals, but all such counterparts taken together or collectively, as the case may be, shall constitute one and the same agreement.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            i.
                          </td>
                          <td><u>WAIVER OF JURY TRIAL.</u> COMPANY AND THE PURCHASER HEREBY KNOWINGLY, VOLUNTARILY, IRREVOCABLY AND INTENTIONALLY WAIVE, TO THE MAXIMUM EXTENT PERMITTED BY LAW, ALL RIGHT TO TRIAL BY JURY IN ANY ACTION, PROCEEDING OR CLAIM ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE NOTE, THE GUARANTEED DEBT OR THE TRANSACTIONS CONTEMPLATED THEREBY.</td>
                        </tr>
                        <tr>
                          <td className="number">
                            j.
                          </td>
                          <td><u>NO OTHER AGREEMENTS.</u> THIS WRITTEN AGREEMENT REPRESENTS THE FINAL AGREEMENT BETWEEN THE PURCHASER AND THE COMPANY AND MAY NOT BE CONTRADICTED BY EVIDENCE OF PRIOR, CONTEMPORANEOUS, OR SUBSEQUENT ORAL AGREEMENTS OF THE PURCHASER AND THE COMPANY. THERE ARE NO UNWRITTEN ORAL AGREEMENTS BETWEEN THE PURCHASER AND THE COMPANY.</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table className="marketagree">
                  <tr>
                    <td>
                      <h5 className="center">[SIGNATURE PAGE FOLLOWS]</h5>
                    </td>
                  </tr>
                  <tr>
                    <td className="center">
                      IN WITNESS WHEREOF, the parties hereto have caused this Agreement to be executed as of the date first written above by their duly authorized representatives.
                    </td>
                  </tr>
                  <tr>
                    <td className="rightalign">
                      <br></br>
                      <table className="signaturetbl">
                        <tr><td>By P S{" "}{props.previousPath === 'activeshield' ? newConvertDate(props.checkoutdata.shield_purchase_datetime[0]) : convertDate(new Date())}</td></tr>
                        <tr><td>Adaptive Capital Management, LLC</td></tr>
                        <tr><td>Philip Sun</td></tr>
                      </table>
                      <br></br>
                      {props.customerInfo ? (
                        <table className="signaturetbl">
                          <tr><td>By{" "}{getInitialsFromName(props.customerInfo.client_name ?? "")}{" "}{props.previousPath === 'activeshield' ? newConvertDate(props.checkoutdata.shield_purchase_datetime[0]) : convertDate(new Date())}</td></tr>
                          <tr><td>Purchaser </td></tr>
                          <tr><td>{props.customerInfo.client_name ?? ""}</td></tr>
                          <tr><td>{props.customerInfo.client_address ?? ""}</td></tr>
                          <tr><td>{props.customerInfo.client_email ?? ""}</td></tr>
                        </table>
                      ) : (
                        <table className="signaturetbl">
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                        </table>
                      )}
                      <br></br>
                      {props.customerInfo?.advisor_status ? (
                        <table className="signaturetbl">
                          <tr><td>and, if applicable:</td></tr>
                          <tr><td>By{" "}{getInitialsFromName(props.customerInfo.advisor_firstname ?? "" + " " + props.customerInfo.advisor_lastname ?? "")}{" "}{props.previousPath === 'activeshield' ? newConvertDate(props.checkoutdata.shield_purchase_datetime[0]) : convertDate(new Date())}</td></tr>
                          <tr><td>Purchaser’s Authorized Agent</td></tr>
                          <tr><td>{props.customerInfo.advisor_firstname ?? "" + " " + props.customerInfo.advisor_lastname ?? ""}</td></tr>
                          <tr><td>{props.customerInfo.advisor_address ?? ""}</td></tr>
                          <tr><td>{props.customerInfo.advisor_email ?? ""}</td></tr>
                        </table>
                      ) : (
                        <table className="signaturetbl">
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                          <tr><td></td></tr>
                        </table>
                      )}
                    </td>
                  </tr>
                </table>
                <br></br><br></br>
                <br></br><br></br>
                <br></br><br></br>
                <br></br><br></br>
                <table className="marketagree">
                  <tr><td className="center"><h5 className="center"><u>EXHIBIT A</u></h5></td></tr>
                  <tr><td className="center"><u>Market Shield Information</u></td></tr>
                  <tr></tr>
                  <tr></tr>

                  <tr><td>Market Portfolio Notional Value: ${" "}{Number(Number(props.checkoutdata["TotalNotionalVal"]).toFixed(2)).toLocaleString("en-US")}</td></tr>
                  <tr><td>Market Portfolio Protected Level: {props.checkoutdata["ProtLevel"]}%</td></tr>
                  <tr><td>Market Portfolio Protected Value: ${" "}{Number(Number(props.checkoutdata["TotalProtectedVal"]).toFixed(2)).toLocaleString("en-US")}</td></tr>
                  <tr><td>For a Market Portfolio Loss of no more than: {100 - props.checkoutdata["ProtLevel"]}%</td></tr>
                  <tr><td>Protection Period:{" "}{props.period}</td></tr>
                  <tr><td>Start Date:{" "}{props.previousPath === 'activeshield' ? newConvertDate(props.checkoutdata.shield_purchase_datetime[0]) : convertDate(new Date())}</td></tr>
                  <tr><td>Expiration Date:{" "}{newConvertDate(props.checkoutdata["ExpirationDate"])}</td></tr>
                  <tr><td>Market Shield Fee:${" "}{Number(Number(props.checkoutdata["Cost"]).toFixed(2)).toLocaleString("en-US")}</td></tr>

                  <tr><td>Market Shield Agreement ID:{" "}{props.AgreementID}</td></tr>
                  <tr><td>Tracking Instrument Index:{" "}{props.checkoutdata?.IndexName ?? ""}</td></tr>
                  <tr></tr>
                  <h3>Tracking Instruments Details</h3>
                  <div className="payment-stocksummary">
                    <table stickyHeader aria-label="sticky table">
                      <thead>
                        <th style={{ textAlign: "left" }}>
                          Tracking Instrument Details
                        </th>
                        <tr style={styles.tableheader}>
                          {headers.map((header) => (
                            <th style={{ textAlign: "right" }}>
                              <div>
                                {header.title}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {props.checkoutdata && props.checkoutdata.ClosePrice
                          && props.checkoutdata.ClosePrice.length > 0
                          && props.checkoutdata.ClosePrice.map((_, i) => (
                            <tr style={styles.tablebody} key={i}>
                              <td>{props.checkoutdata.SecDesc[i]}</td>
                              <td>{props.checkoutdata.ConQty[i]}</td>
                              <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.ClosePrice[i]).toFixed(2)))
                              ).toLocaleString()}</td>
                              <td>${Number(addZeroes(Number(parseFloat(props.checkoutdata.NotionalVal[i]).toFixed(2)))
                              ).toLocaleString()}</td>
                              <td>${Number(addZeroes(Number(parseFloat(
                                props.checkoutdata.ProtectedVal[i]).toFixed(2)))).toLocaleString()}</td>
                            </tr>
                          ))}
                        <tr style={styles.tableheader}>
                          <td />
                          <td />
                          <td style={{ textAlign: "right" }}>
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color: "black",
                              fontFamily: "Inter-Medium",
                              fontSize: 7,
                            }}
                          >
                            ${Number(
                              addZeroes(
                                Number(
                                  parseFloat(
                                    props.checkoutdata["TotalNotionalVal"]
                                  ).toFixed(2)
                                )
                              )
                            ).toLocaleString()}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color: "black",
                              fontFamily: "Inter-Medium",
                              fontSize: 7,
                            }}
                          >
                            ${Number(
                              addZeroes(
                                Number(
                                  parseFloat(
                                    props.checkoutdata["TotalProtectedVal"]
                                  ).toFixed(2)
                                )
                              )
                            ).toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <tr><td>The Market Shield <b>“<u>Protection Period</u>”</b> above specified the duration of the downside protection starting on the date the Market Shield is purchased (the <b>“<u>Start Date</u>”</b>) and ending on the <b>“<u>Expiration Date</u>.”</b></td></tr>
                  <tr><td>The Market Shield <b>“<u>Protected Level</u>”</b> specifies the protected value as a percentage of the current value of the Market Portfolio.</td></tr>
                  <tr><td>The <b>“<u>Market Portfolio</u>”</b> is set forth above. The Market Portfolio <b>“<u>Actual Value</u>”</b> means the value of the securities in the Market Portfolio as of the date of measurement.</td></tr>
                  <tr><td>The Market Shield <b>“<u>Protection Payout</u>”</b>, if any, is calculated and settled at the last exchange closing on or before the Expiration Date of the Market Shield (which is usually the exchange closing on the Expiration Date). The Market Shield Protection Payout, if any, shall equal the amount by which the Market Portfolio’s Actual Value as of the Expiration Date is lower than the Market Portfolio Protected Value. For the purposes of clarity, if the Market Portfolio Actual Value on the Expiration Date is higher than the Market Portfolio Protected Value there will be no Market Shield Protection Payout. For example, if a Market Portfolio with a $100,000 Notional Value and a Protected Level of 90% (resulting in a Protected Value of $90,000) experiences a market crash similar to the 1987 “Black Monday” magnitude (22.6% down) resulting in an Actual Value of the Market Shield on the Expiration Date of $77,400, the resulting Market Shield Protection Payout would equal $12,600.</td></tr>
                  <tr><td><b>Calculation of Market Shield Protection Payout</b>: All calculations of the payout are conducted in the basic unit of Mini-SPX Index Put Options on the S&P 500 Market Index (the “Mini SPX Index”), and will pay out in accordance with the payouts in such Mini SPX Index contracts, based on the Tracking Instrument quantity, as identized above, in the Market Shield. <b>For the avoidance of doubt, While the Market Shield Protection Payout is calculated based on the Mini SPX Index, purchase of the Market Shield does not reflect purchase of any units of the Mini SPX Index on behalf of the Purchaser, and merely relies on the calculation formula set forth therein.</b> The Mini-SPX Index is based on 1/10th the value of the Standard & Poor’s 500 Index (“SPX”). The Standard & Poor’s 500 Index is a capitali ation weighted index of 500 stocks from a broad range of industries. The component stocks are weighted according to the total market value of their outstanding “free foat” shares.</td></tr>
                  <tr><td>The exercise settlement amount is equal to the difference in the exercise-settlement value and the exercise price of the option, multiplied by *100. For more information on the calculation of Mini-SPX Index Put Options, please refer to the <a target="_blank" href="https://www.cboe.com/tradable_products/sp_500/mini_spx_options/specifications/">XSP Options Product Specification</a>.</td></tr>
                  <tr><td>While such basic unit of payout calculation is in the Mini-SPX Index Put Options, the Purchaser does not own the Mini-SPX Index Options. The Purchaser is entitled only to any payout set forth in this Market Shield Agreement.</td></tr>
                  <tr></tr>
                  <tr><td className="center">--</td></tr>
                </table>
              </>
            ) : (
              <p className="payment-header-p">
                Shield Protection Period expires on the selected date. Shield
                Protection Level specifies the level of selected protection.
                Shield Protection Payout is settled at closing on the expiration
                date of the Shield Protection Period. Calculation of Protection
                Payouts: The Shield Protection Payout equals the difference
                between (1) the aggregate market value of the holdings (see table
                above), inclusive of all dividends and other distributions during
                the Shield Protection Period, and (2) the Shield Protection Level.
                For example, if a $100,000 Portfolio experiences a crash and, at
                the expiration of the Shield Protection Period,is down by the 1987
                Black Monday magnitude (22.6%)—and the Shield Protection Level is
                set at 10% you will be paid the difference of 12.6% equal to
                $12,600. Contact Adaptive for Shield Redemption, prior to the end
                of the Shield Protection Period.
                <br />
                <br />
                Shield Protection Period expires on the selected date. Shield
                Protection Level specifies the level of selected protection.
                Shield Protection Payout is settled at closing on the expiration
                date of the Shield Protection Period.
                <br />
                <br />
                Calculation of Protection Payouts: The Shield Protection Payout
                equals the difference between (1) the aggregate market value of
                the holdings (see table above), inclusive of all dividends and
                other distributions during the Shield Protection Period, and (2)
                the Shield Protection Level. For example, if a $100,000 Portfolio
                experiences a crash and, at the expiration of the Shield
                Protection Period,is down by the 1987 Black Monday magnitude
                (22.6%)—and the Shield Protection Level is set at 10% you will be
                paid the difference of 12.6% equal to $12,600.
              </p>
            )}
          </div>
        </body>
      </html>
    );


    const html = ReactDOMServer.renderToStaticMarkup(element);

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.body}>
          <Image style={styles.image} src="Assets/adaptive-name.png" fixed />
          <Html resetStyles>{html}</Html>
        </Page>
      </Document>
    );

    const blob = await pdf(<MyDocument />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'AdaptiveShield_Contract.pdf';
    link.click();
  };

  return DownloadPdf;
}

//   return (
//     <PDFDownloadLink
//       document={<MyDocument />}
//       fileName="AdaptiveShield_Contract"
//     >
// {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download Pdf')}
//     </PDFDownloadLink>
//   )

// }

export default ActiveShieldPDF;