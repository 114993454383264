import React from 'react';
import "./FactorAnalysisScreen.scss";
import NavBar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import FactorAnalysis from "../../Components/FactorAnalysis/FactorAnalysis";


function FactorAnalysisScreen(props) {
  return (
    <div className="ai-content-part">
      <NavBar />
      <div className="floathead">
        <PageHeader
          heading="Portfolio Factor Analysis"
          backButton={true}
          addButton={false}
          routeTo="./PortfolioSummaryScreen"
        />
      </div>
      <FactorAnalysis/>
    </div>
  );
}

export default FactorAnalysisScreen;
