import React from "react";
import withWidth from "@material-ui/core/withWidth";
import "./ComingSoon.css";
import Navbar from "../../Components/Navbar/Navbar_official"

import { Container, Row, Col } from "react-bootstrap";
function ComingSoon(props) {
    return (
        <div>
            <div>
                <Navbar></Navbar>
            </div>
            <div className="cs-heading">
                <h1 className="cs-title">Coming Soon!!</h1>
                <p className="cs-subtitle">We are working hard on this section to have something ready soon.</p>
            </div>
            <Container>
                <Row>
                    <Col><p className="emptyfill"/></Col>
                    <Col>
                        <p className="emptyfill1"/>
                        <p className="emptyfill2"/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withWidth()(ComingSoon)