import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function GetQuoteEngineData(pid) {

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };
  // const session_id = sessionStorage.getItem("sessionID");

  var raw = JSON.stringify({
    session_id: Token,
    portfolio_id: pid,
  });



  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)) {
    try {

      const response = await fetch(
        process.env.REACT_APP_QUOTE_ENGINE,
        requestOptions
      );
      const data = await response.json();
      if (data["result"] !== "") {
        return data["result"];
      } else if (data["error"] !== "") {
        console.log(" err ", data["error"])
        if (data["error"] === "Token Verification Failed") {
          return 404
        } else {
          return 0
        }
      }
      else {
        return false;
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }
}
