export function convertDate(date) {
    // use when time is generated by frontend
    date =
        date.toLocaleString("default", { month: "short" }) +
        " " +
        date.getDate() +
        ", " +
        date.getFullYear();
    return date;
}

export function newConvertDate(date) {
    // use when time is received as string from backend
    var new_date = new Date(date)
    var userTimezoneOffset = new_date.getTimezoneOffset() * 60000;
    new_date = new Date(new_date.getTime() + userTimezoneOffset);
    //console.log(new_date)
    new_date =
        new_date.toLocaleString("default", { month: "short" }) +
        " " +
        new_date.getDate() +
        ", " +
        new_date.getFullYear();
    return new_date;
}