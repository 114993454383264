import React from "react";
import MediumFont from "../../fonts/Inter-Medium.woff";
import SemiBoldFont from "../../fonts/Inter-SemiBold.woff";
import BoldFont from "../../fonts/Inter-Bold.woff";
import RegularFont from "../../fonts/Inter-V.ttf";
import {
    Page,
    Text,
    Document,
    StyleSheet,
    PDFDownloadLink,
    Image,
    pdf,
    Font,
} from "@react-pdf/renderer";
import Popup from "../Popup/Popup";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AcceptAgreement } from "../../Apis/AcceptAgreement";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    image: {
        marginVertical: 15,
        textAlign: "left",
        height: 30,
        width: 120,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
    },
    footer: {
        position: "absolute",
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "left",
        color: "#3E4968",
        paddingLeft: 30,
        paddingRight: 30,
    },
    summaryHeader: {
        color: "#3E4968",
        fontSize: 18,
        textAlign: "left",
        fontWeight: "bold",
        marginVertical: 15,
        fontFamily: "Inter-Bold",
    },
    content: {
        color: "black",
        fontSize: 10,
    },
});

const AgreementDocument = (props) => (
    <Document>
        <Page size="A4" style={styles.body}>
            <Image style={styles.image} src="Assets/adaptive-name.png" fixed />
            {props.AgreementText && Object.entries(props.AgreementText)
                .map(([key, value]) =>
                    <div>
                        <Text style={styles.summaryHeader}>{key}</Text>
                        <Text style={styles.content}>{value}</Text>
                    </div>
                )
            }
            <Text style={styles.footer} fixed>
                Adaptive funds are not held in a brokerage account and are not
                protected by SIPC. Brokerage assets are held by Adaptive Investment
                Services, a division of Adaptive Investments LLC.
            </Text>
        </Page>
    </Document>
);

function AgreementContent(props) {
    const history = useHistory();
    const LoggedIn = () => history.push("./PortfolioSummaryScreen");
    const AdvisorLoggedIn = () => history.push("./AdvisorDashboard");

    const LoginUser = () => {
        if (props.selectedAcType === "INVESTOR") {
            LoggedIn();
        } else if (props.selectedAcType === "ADVISOR") {
            AdvisorLoggedIn();
        }
    }
    const useStyles = makeStyles((theme) => ({
        button: {
            color: "white",
            marginTop: "2px",
        },
        checkbox_button: {
            color: "#60034c",
            width: "14px",
            height: "14px",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));
    const classes = useStyles();

    Font.register({ family: "Inter-Bold", src: BoldFont });
    Font.register({ family: "Inter-V", src: RegularFont });
    Font.register({ family: "Inter-SemiBold", src: SemiBoldFont });
    Font.register({ family: "Inter-Medium", src: MediumFont });


    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("error");
    const [bdopen, setBdOpen] = React.useState(false);
    const [showDownload, setShowDownload] = React.useState(true);
    const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
    const downloadLinkRef = React.useRef(null);

    React.useEffect(() => {
        if (!props.viewOnly && props.download) {
            pdf(<AgreementDocument AgreementText={props.AgreementText} />).toBlob().then(blob => {
                const url = URL.createObjectURL(blob);
                downloadLinkRef.current.href = url;
                downloadLinkRef.current.download = 'Adaptive_Agreement.pdf';
                downloadLinkRef.current.click();
                props.handleAfterDownloadAgreement()
            });
        }
    }, [props.viewOnly, props.AgreementText,props.download]);

    const Accept = () => {
        setBdOpen(true);
        AcceptAgreement(props.selectedAcType).then((data) => {
            console.log(" data ", data)
            if (data === 'SUCCESS') {
                setBdOpen(false);
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Agreement Accepted',
                        userType: sessionStorage.getItem("selectedAcType"),
                        user_id: props.clientId
                    },
                });
                LoginUser();
            } else if (data === 0) {
                console.log(" Facing some error", data);
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                setAlertType("warning");
                setPopupLoginRedirect(false);
                return;
            }
            else if (data === false) {
                console.log(" Facing some error", data);
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                setAlertType("warning");
                setPopupLoginRedirect(false);
                return;
            }
            else if (data === 401) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                    "Your session timed out for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
                return;
            } else if (data === 404) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                    "Your session was terminated for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
                return;
            }
        });
    };

    if (props.viewOnly === true) {
        return (
            <div className="flexfull">
                <PageLoader bdopen={bdopen}/>
                <Popup
                    trigger={popupState}
                    setTrigger={setPopupState}
                    title="Login"
                    alertType={alertType}
                    content={popupMessage}
                    loginRedirect={popupLoginRedierct}
                />
                <div className="agreement-holder">
                    <div className="summary-header">Please accept Adaptive General Data and Privacy Policy</div>
                    <div className="popupcontentpart" style={{ whiteSpace: "pre-wrap" }}>
                        {props.AgreementText &&
                            Object.entries(props.AgreementText)
                                .map(([key, value]) =>
                                    <div className="popupcontent">
                                        <h4>{key}</h4>
                                        <p>{value}</p>
                                    </div>
                                )
                        }
                    </div>
                    <div className="summarybtn">
                        <button
                            className="ai-btn primary solid"
                            onClick={() => Accept()}
                        >
                            Accept
                        </button>
                        {props.AgreementText && showDownload && (<div>
                            <PDFDownloadLink
                                document={<AgreementDocument AgreementText={props.AgreementText} />}
                                fileName="Adaptive_Agreement"
                                className="ai-btn secondary solid"
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: 'Agreement Downloaded',
                                            userType: sessionStorage.getItem("selectedAcType"),
                                            user_id: props.clientId
                                        },
                                    });
                                    setShowDownload(false);
                                }}
                            > Download
                            </PDFDownloadLink>
                        </div>)}
                    </div>
                </div >
            </div >
        );
    } else {
        return (
            <a ref={downloadLinkRef} style={{ display: 'none' }} />
        );
    }
}

export default AgreementContent;
