import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetPlaidHoldings(account_id) {

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };
  // const session_id = sessionStorage.getItem("sessionID");

  var raw = JSON.stringify({ account_id: account_id, session_id: Token });

  var requestOptions = {
    method: "POST",
    redirect: "follow",
    body: raw,
    headers: header,
    timeout: 18000,
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_GET_PLAID_USER_HOLDINGS,
        requestOptions
      );

      if (!response.ok) {
        console.log(" in response ", response)
        return false;
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          console.log("holding output", message["result"]);
          return message["result"];
        } else if (message["error"] === "Token Verification Failed") {
          return 404;
        } else {
          console.log("error", message["error"]);
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }
}
