import React from "react";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import "./AdvisorDashboardStockTabScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import AdvisorDashboardStockTab from "../../Components/AdvisorDashboardStockTab/AdvisorDashboardStockTab";
import { Container, Row, Col } from 'react-bootstrap';

function AdvisorDashboardStockTabScreen(props) {
  return (
    <div className="ai-content-part advisor">
        <Navbar />
        <section className="portfolio-table-wrapper">
              <AdvisorDashboardStockTab/>
        </section>
    </div>
  );
}

export default withWidth()(AdvisorDashboardStockTabScreen);
