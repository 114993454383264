import React from "react";
import "./AdvisorAddClientScreen.css";
import AddNewClient from "../../Components/AddNewClient/AddNewClient";
import PageHeader from "../../Components/PageHeader/PageHeader";
import Navbar from "../../Components/Navbar/Navbar_official";

export default function AdvisorAddClientScreen() {
  return (
    <div className="ai-content-part advisor">
      <Navbar/>
      <AddNewClient/>
    </div>
  );
}
