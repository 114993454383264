import React from "react";
import { useState } from "react";
import "./PaymentInstructions.scss";
import Popup from "reactjs-popup";
import CustomPopup from "../Popup/Popup";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth from "@material-ui/core/withWidth";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import deleteicon from "../../images/delete_red.svg";
import { DeleteExternalAccount } from "../../Apis/DeleteExternalAccount";
import { InitiateExternalAcVerification } from "../../Apis/InitiateExternalAcVerification";
import { FetchExternalAccountDetails } from "../../Apis/FetchExternalAccountDetails";
import VerifyPaymentAccount from "../../Components/VerifyPaymentAccount/VerifyPaymentAccount";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";

function PaymentInstructions() {
    const [Flag, setFlag] = useState(false);
    const [ExternalAccountData, SetExternalAccountData] = useState([]);
    const [bdopen, setBdOpen] = useState(true);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [ExternalAccountAPICalled, setExternalAccountAPICalled] = React.useState(false);
    const [ExternalAccountAPICount, setExternalAccountAPICount] = React.useState(0);
    const [DeletePaymentAcPopup, setDeletePaymentAcPopup] = React.useState(false);
    const [ExternalAccountId, setExternalAccountId] = React.useState("");
    const [VerificationStatus, setVerificationStatus] = React.useState("");
    const [showVerifyPopup, setShowVerifyPopup] = React.useState(false);
    const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const fetchExternaAccounts = () => {
        setBdOpen(true);
        try {
            FetchExternalAccountDetails().then((data) => {
                setExternalAccountAPICalled(true);
                var lyst = [];
                if (data !== false && data !== 0 && data !== undefined && data !== 401 && data !== 404) {
                    for (let [key, value] of Object.entries(data)) {
                        lyst.push({
                            account_name: value.name,
                            verification_status: value.verification_status,
                            account_number: "******" + (data[key]["account_details"][0].account_number.slice(-3)),
                            account_id: value.id
                        });
                    }
                    SetExternalAccountData([...lyst]);
                    sleep(100).then(() => {
                        if (lyst.length === 0) {
                            setPopupState(true);
                            setPopupMessage(
                                "No client wallet found. Please create a payment instruction to transfer funds or contact support@adaptive-investments.com."
                            );
                            setAlertType("error");
                            setFlag(true);
                            setBdOpen(false);
                        }
                    });
                    setBdOpen(false);
                } else if (data === false) {
                    setBdOpen(false);
                    setExternalAccountAPICalled(true);
                    setPopupState(true);
                    setPopupMessage(
                        "No client wallet found. Please create a payment instruction to transfer funds or contact support@adaptive-investments.com."
                    );
                    setPopupLoginRedirect(false);
                    setAlertType("error");
                } else if (data === 0 || data === undefined) {
                    setBdOpen(false);
                    setExternalAccountAPICalled(true);
                    setPopupState(true);
                    setPopupMessage(
                        "We are facing issues connecting our servers, please try again later"
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    return;
                }
                else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                } else if (data === 404) {
                    setBdOpen(false);
                    setPopupState(true);
                    setExternalAccountAPICalled(true);
                    setPopupMessage(
                        "Your session was terminated for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                }
            });
        } catch (error) {
            setBdOpen(false);
            setExternalAccountAPICalled(true);
            setPopupState(true);
            setPopupMessage(
                "We are facing issues connecting our servers, please try again later"
            );
            setAlertType("error");
            setPopupLoginRedirect(false);
            return;
        }
    }

    if (Flag === false) {
        if (ExternalAccountAPICalled === false && ExternalAccountAPICount < 1) {
            setExternalAccountAPICount(1);
            fetchExternaAccounts();
        }
        if (ExternalAccountAPICalled === true) {
            setFlag(true);
            setBdOpen(false);
        }
    }

    function DeleteSelectedAccount(account_id) {
        setBdOpen(true);
        DeleteExternalAccount(account_id).then((data) => {
            if (data !== false && data !== 0 && data !== undefined && data !== "resource_not_found" && data !== 401) {
                setBdOpen(false);
                setDeletePaymentAcPopup(true);
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Payment Account Deleted',
                        userType: sessionStorage.getItem("selectedAcType"),
                        user_id: sessionStorage.getItem("clientId")
                    },
                });
            } else if (data === false || data === "resource_not_found") {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                    "Unable to delete account. Please try again or contact support@adaptive-investments.com."
                );
                setAlertType("error");
                setPopupLoginRedirect(false);
            } else if (data === 0 || data === undefined) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                    "We are facing issues connecting our servers, please try again later"
                );
                setAlertType("error");
                setPopupLoginRedirect(false);
                return;
            }
            else if (data === 401) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                    "Your session timed out for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
                return;
            }
        })
    }

    function VerifySelectedAccount(account_id, verification_status) {
        setBdOpen(true);
        setExternalAccountId(account_id);
        if (verification_status === "pending_verification") {
            setBdOpen(false);
            setShowVerifyPopup(true);
        } else {
            InitiateExternalAcVerification(account_id).then((data) => {
                if (data !== false && data !== 0 && data !== undefined && data !== "resource_not_found" && data !== 401) {
                    setBdOpen(false);
                    setShowVerifyPopup(true);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'Payment Account Verification Initiated',
                            userType: sessionStorage.getItem("selectedAcType"),
                            user_id: sessionStorage.getItem("clientId")
                        },
                    });
                } else if (data === false || data === "resource_not_found") {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "Unable to verify account. Please try again or contact support@adaptive-investments.com."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                } else if (data === 0 || data === undefined) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "We are facing issues connecting our servers, Please try again later"
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    return;
                } else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                }
            })
        };
    }

    const handleClose = () => {
        setShowVerifyPopup(false);
        fetchExternaAccounts();
    };

    const handleDeletePaymentAccount = () => {
        setDeletePaymentAcPopup(false);
        fetchExternaAccounts();
    };

    return (
        <div className="portfolio_page">
            <PageLoader bdopen={bdopen}/>
            <CustomPopup
                trigger={popupState}
                setTrigger={setPopupState}
                title="Account Details"
                content={popupMessage}
                alertType={alertType}
                loginRedirect={popupLoginRedierct}
            />
            <div className="bankinstructions">
                {ExternalAccountData.map((value, i) => (
                    <div className="paymentcard">
                        <div className="portfolioname">
                            {value.account_name}
                        </div>
                        <div className="accountnum">
                            {value.account_number}
                        </div>
                        <div className="action">
                            {/* {value.verification_status === "unverified" ? (
                                <div>
                                    <button className="ai-btn small solid primary" onClick={() => VerifySelectedAccount(value.account_id, value.verification_status)}>Verify</button>
                                </div>
                            ) : (
                                <div></div>
                            )} */}
                            {value.verification_status === "pending_verification" || value.verification_status === "unverified" ? (
                                < div >
                                    <button className="ai-btn small solid primary" onClick={() => VerifySelectedAccount(value.account_id, value.verification_status)}>Pending Verification</button>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            <button className="ai-btn plain" onClick={() => DeleteSelectedAccount(value.account_id)}><img src={deleteicon} /></button>
                        </div>
                    </div>
                ))}
            </div>
            {
                DeletePaymentAcPopup && (
                    <div>
                        <div className="popup">
                            <div className="popup-object">
                                <div className="confirm-heading">
                                    <CheckCircleOutlineRoundedIcon className="icon" />
                                    Account Details
                                </div>
                                <div className="body-message">
                                    <p>Account has been Deleted Successfully.</p>
                                </div>
                                <button
                                    className="ai-btn secondary solid"
                                    onClick={handleDeletePaymentAccount}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                showVerifyPopup && (
                    <Popup open={true} modal>
                        <div className="add-portfolio-popup">
                            <VerifyPaymentAccount account_id={ExternalAccountId} handleClose={handleClose} verification_status={VerificationStatus} />
                        </div>
                    </Popup>
                )
            }
        </div >
    );
}

export default withWidth()(PaymentInstructions);
