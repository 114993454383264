import React from "react";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import "./AdvisorCsvAccountStatusScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import ImportStatusNav from "../../Components/ImportStatusNav/ImportStatusNav";
import AdvisorCsvAccountChange from "../../Components/AdvisorCsvAccountChange/AdvisorCsvAccountChange";
import { Container, Row, Col } from 'react-bootstrap';

function AdvisorCsvStatusScreen(props) {
    return (
        <div className="ai-content-part advisor">
            <Navbar />
            <section className="portfolio-table-wrapper">                
                <ImportStatusNav/>
                <AdvisorCsvAccountChange />
            </section>
        </div>
    );
}

export default withWidth()(AdvisorCsvStatusScreen);
