import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function UpdatePortfolioDetails(
  portfolioname,
  portfoliodesc,
  portfolioid) {

  const Token = sessionStorage.getItem("Token");
  // const session_id = sessionStorage.getItem("sessionID");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token,
    toupdate: {
      portfolioname:portfolioname,
      portfoliodesc:portfoliodesc,
    },
    portfolio_id:portfolioid
  });

  var requestOptions = {
    method: "PATCH",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_PORTFOLIO_API,
        requestOptions
      );
      if (!response.ok) {
        //alert("Could not update portfolio details");
        return false
      } else {
        return true;
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  } else {
    return 401
  }
  
}
