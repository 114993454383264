import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetUserPlaidAccounts() {

  const Token = sessionStorage.getItem("Token");
  // const session_id = sessionStorage.getItem("sessionID");

  const header = { Authorization: Token };

  var raw = JSON.stringify({ session_id: Token });

  var requestOptions = {
    method: "POST",
    redirect: "follow",
    body: raw,
    headers: header,
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_GET_PLAID_USER_ACCOUNTS,
        requestOptions
      );
      if (!response.ok) {
        //alert("Could not get account details");
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          console.log("token", message["result"]);
          return message["result"];
        } else {
          console.log(message["error"]);
          return false;
        }
      }
    }catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else{
        return 0
      }
    }
  }else{
    return 401
  }
}
