import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetProjectionConeData(portfolio_id) {

  // const id = window.sessionStorage.getItem("sessionID");

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
    timeout: 15000
  };
  if (!CheckSessionTimeout(Token)) {
    try {
      const response = await fetch(
        process.env.REACT_APP_PROJECTION_CONE,
        requestOptions
      );
      const data = await response.json();
      if (data['error'] === "") {
        return (data['result']);
      } else if (data["error"] !== "") {
        console.log("errr ", data["error"]);
        if (data["error"] === "Token Verification Failed") {
          return 404
        } else {
          return 0
        }
      }
      else {
        return false;
      }

    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }
}
