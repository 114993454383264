import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdaptiveGenius from "./Screens/AdaptiveGenius/AdaptiveGenius";
import MarketShield from "./Screens/MarketShield/MarketShield";
import AnalyzePortfolio from "./Screens/AnalyzePortfolio/AnalyzePortfolio";
import LoginScreen from "./Screens/LoginScreen/LoginScreen";
import PortfolioSummaryScreen from "./Screens/PortfolioSummaryScreen/PortfolioSummaryScreen";
import PortfolioTable from "./Screens/PortfolioTable/PortfolioTable";
import PortfolioUpload from "./Screens/PortfolioUpload/PortfolioUpload";
import ShieldProtectionScreen from "./Screens/ShieldProtectionScreen/ShieldProtectionScreen";
import ShieldRecommendationScreen from "./Screens/ShieldRecommendationScreen/ShieldRecommendationScreen";
import ServicesScreen from "./Screens/ServicesScreen/ServicesScreen";
import AddPortfolioScreen from "./Screens/AddPortfolioScreen/AddPortfolioScreen";
import ShieldConfirmationScreen from "./Screens/ShieldConfirmationScreen/ShieldConfirmationScreen";
import ShieldOrderComplition from "./Screens/ShieldOrderComplition/ShieldOrderComplition";
import Signup from "./Screens/SignupScreen/Signup";
import ForgotPassword from "./Screens/ForgotPasswordScreen/ForgotPassword";
import BackTestScreen from "./Screens/BackTestScreen/BackTestScreen";
import OTPConfirmationScreen from "./Screens/OTPConfirmationScreen/OTPConfirmation";
import PaymentSuccessScreen from "./Screens/PaymentSuccessScreen/PaymentSuccessScreen";
import Graph from "./Components/Graph/Graph";
import SelfAssemblyScreen from "./Screens/SelfAssemblyScreen/SelfAssembly";
import PlaidAccountScreen from "./Screens/PlaidAccountScreen/PlaidAccountScreen";
import PlaidStocksScreen from "./Screens/PlaidStocksSummaryScreen/PlaidStocksSummary";
import StocksSummary from "./Screens/CsvStockSummaryScreen/CsvStockSummaryScreen"; 
import AdvisorDashboardScreen from "./Screens/AdvisorDashboardScreen/AdvisorDashboardScreen"; 
import AdvisorDashboardStockTabScreen from "./Screens/AdvisorDashboardStockTabScreen/AdvisorDashboardStockTabScreen"; 
import ADClientPortfolioTabScreen from "./Screens/ADClientPortfolioTabScreen/ADClientPortfolioTabScreen"; 
import * as Sentry from '@sentry/react';
import AdvisorClientListTabScreen from "./Screens/AdvisorClientListTabScreen/AdvisorClientListTabScreen";
import AdvisorAddClientScreen from "./Screens/AdvisorAddClientScreen/AdvisorAddClientScreen";
import ComingSoon from "./Screens/ComingSoon/ComingSoon";
import AdvisorCsvClientStatus from "./Screens/AdvisorCsvClientStatusScreen/AdvisorCsvClientStatusScreen";
import AdvisorCsvAccountStatus from "./Screens/AdvisorCsvAccountStatusScreen/AdvisorCsvAccountStatusScreen";
import AdvisorCsvLineItemChanges from "./Screens/AdvisorLineItemChangesScreen/AdvisorLineItemChangesScreen";
import AdvisorErrorsScreen from "./Screens/AdvisorErrorsScreen/AdvisorErrorsScreen";
import EditPortfolioScreen from "./Screens/EditPortfolioScreen/EditPortfolioScreen";
import PaymentInstructionsScreen from "./Screens/PaymentInstructionsScreen/PaymentInstructionsScreen";
import PaymentDepositAndWithdrawScreen from "./Screens/PaymentDepositWithdrawScreen/PaymentDepositWithdrawScreen";
import TransactionDetailsScreen from "./Screens/TransactionDetailsScreen/TransactionDetailsScreen";
import ActiveShieldsScreen from "./Screens/ActiveShieldsScreen/ActiveShieldsScreen";
import PortfolioSolutionsSummaryScreen from "./Screens/PortfolioSolutionsSummaryScreeen/PortfolioSolutionsSummaryScreen";
import MarketShieldScreen from "./Screens/MarketShieldScreen/MarketShieldScreen";
import PortfolioShieldScreen from "./Screens/PortfolioShieldScreen/PortfolioShieldScreen";
import ForwardTestScreen from "./Screens/ForwardTestScreen/ForwardTestScreen";
import RiskContributionScreen from "./Screens/RiskContributionScreen/RiskContributionScreen";
import FactorAnalysisScreen from "./Screens/FactorAnalysisScreen/FactorAnalysisScreen";
import CustomerCsvChangeScreen from "./Screens/CustomerCsvChangeScreen/CustomerCsvChangeScreen"; 
import TerminalReturnScreen from "./Screens/TerminalReturnScreen/TerminalReturnScreen"; 
import { PortfolioProvider } from './Components/PortfolioContext/PortfolioContext';
import { ClientProvider } from './Components/ClientContext/ClientContext';
import ShieldRiskEquivalenceScreen from './Screens/ShieldRiskEquivalenceScreen/ShieldRiskEquivalenceScreen';
import NitrogenClientScreen from "./Screens/NitrogenClientScreen/NitrogenClientScreen";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "PRODUCTION") {
  TagManager.initialize(tagManagerArgs);
}
function App() {
  return (
    <div>
      <ClientProvider>
      <PortfolioProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <Route exact path="/Signup" component={Signup} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route
            exact
            path="/OTPConfirmationScreen"
            component={OTPConfirmationScreen}
          />

          <Route exact path="/ProductScreen" component={ServicesScreen} />
          <Route exact path="/PortfolioUpload" component={PortfolioUpload} />
          <Route
            exact
            path="/PortfolioSummaryScreen"
            component={PortfolioSummaryScreen}
          />
          <Route
            exact
            path="/TransactionDetailsScreen"
            component={TransactionDetailsScreen}
          />
          <Route
            exact
            path="/ActiveShieldsScreen"
            component={ActiveShieldsScreen}
          />
          <Route
            exact
            path="/RiskContributionScreen"
            component={RiskContributionScreen}
          />          
          {/* <Route
            exact
            path="/RiskContributionpdfScreen"
            component={RiskContributionpdfScreen}
          /> */}
          
          <Route
            exact
            path="/FactorAnalysisScreen"
            component={FactorAnalysisScreen}
          />
          <Route exact path="/MarketShield" component={MarketShield} />
          <Route
            exact
            path="/ShieldProtectionScreen"
            component={ShieldProtectionScreen}
          />
          <Route exact path="/AnalyzePortfolio" component={AnalyzePortfolio} />
          <Route
            exact
            path="/ShieldRecommendationScreen"
            component={ShieldRecommendationScreen}
          />
          <Route
            exact
            path="/AddPortfolioScreen"
            component={AddPortfolioScreen}
          />
          <Route exact path="/PortfolioTable" component={PortfolioTable} />
          <Route exact path="/AdaptiveGenius" component={AdaptiveGenius} />
          <Route
            exact
            path="/ShieldConfirmationScreen"
            component={ShieldConfirmationScreen}
          />
          <Route exact path="/BackTest" component={BackTestScreen} />
          <Route
            exact
            path="/PaymentSuccessScreen"
            component={PaymentSuccessScreen}
          />
          <Route
            exact
            path="/ShieldOrderComplition"
            component={ShieldOrderComplition}
          />
          <Route exact path="/Graph" component={Graph} />
          <Route
            exact
            path="/SelfAssemblyScreen"
            component={SelfAssemblyScreen}
          />
          <Route
            exact
            path="/PlaidAccounts"
            component={PlaidAccountScreen}
          />
            <Route
            exact
            path="/PlaidStocks"
            component={PlaidStocksScreen}
          />
            <Route
            exact
            path="/StocksSummary"
            component={StocksSummary}
          />
          <Route
            exact
            path="/AdvisorDashboard"
            component={AdvisorDashboardScreen}
          />
          <Route
            exact
            path="/AdvisorDashboardStockTab"
            component={AdvisorDashboardStockTabScreen}
          />
          <Route
            exact
            path="/ClientPortfolio"
            component={ADClientPortfolioTabScreen}
          />
          <Route
            exact
            path="/ClientList"
            component={AdvisorClientListTabScreen}
          />
          <Route
            exact
            path="/AddNewClient"
            component={AdvisorAddClientScreen}
          />
          <Route
            exact
            path="/ComingSoon"
            component={ComingSoon}
          />
          <Route
            exact
            path="/AdvisorClientChanges"
            component={AdvisorCsvClientStatus}
          />
          <Route
            exact
            path="/AdvisorAccountChanges"
            component={AdvisorCsvAccountStatus}
          />
          <Route
            exact
            path="/AdvisorLineItemChanges"
            component={AdvisorCsvLineItemChanges}
          />
          <Route
            exact
            path="/AdvisorErrorsScreen"
            component={AdvisorErrorsScreen}
          />
          <Route
            exact
            path="/EditPortfolioScreen"
            component={EditPortfolioScreen}
          />
          <Route
            exact
            path="/PaymentInstructions"
            component={PaymentInstructionsScreen}
          />
          <Route
            exact
            path="/DepositOrWithdraw"
            component={PaymentDepositAndWithdrawScreen}
          />
          <Route
            exact
            path="/PortfolioSolutions"
            component={PortfolioSolutionsSummaryScreen}
          />
          <Route
            exact
            path="/PortfolioSolutions/MarketShield"
            component={MarketShieldScreen}
          />
          <Route
            exact
            path="/PortfolioSolutions/PortfolioShield"
            component={PortfolioShieldScreen}
          />
          <Route
            exact
            path="/ForwardTestScreen"
            component={ForwardTestScreen}
          />
          <Route
            exact
            path="/CustomerLineItemChanges"
            component={CustomerCsvChangeScreen}
          />
          <Route
            exact
            path="/TerminalReturn"
            component={TerminalReturnScreen}
          />
          <Route
            exact 
            path="/ShieldRiskEquivalence"
            component={ShieldRiskEquivalenceScreen}
            />
          <Route
            exact
            path="/nitrogen_clients"
            component={NitrogenClientScreen}
          />
        </Switch>
      </Router>
      </PortfolioProvider>
      </ClientProvider>
    </div>
  );
}
export default Sentry.withProfiler(App)