import React from "react";
import PageHeader from "../../Components/PageHeader/PageHeader";
import NavBar from "../../Components/Navbar/Navbar_official";
import CustomerCsvLineItemChanges from "../../Components/CustomerCsvLineItemChanges/CustomerCsvLineItemChanges";
import PortfolioDetails from "../../Components/PortfolioDetails/PortfolioDetails";
import Popup from "../../Components/Popup/Popup";
import { TabContainer, TabPane, TabContent } from 'react-bootstrap';

function CustomerCsvChangeScreen() {

    const [popupState, setPopupState] = React.useState(false);
    const [tabvalue, settabvalue] = React.useState('hedgeable');
    
    function handleTabChange(val){
        settabvalue(val)
    }

    return (
        <div className="ai-content-part">
            <NavBar />
            <div className="floathead">
                <PageHeader heading="Portfolio Detail" backButton={true} routeTo='./PortfolioTable' />
                <Popup trigger={popupState} setTrigger={setPopupState}
                    title="Add Stocks"
                    content="Please add at least one stock to your portfolio to proceed"
                    alertType="error" />
                <PortfolioDetails
                    passing_id={true}
                    confirm_csv_route={true}
                    imgSrc="Assets/proceed.png"
                    routeText="Proceed to Analysis"
                    tabvalue={tabvalue}
                    onChange={handleTabChange}
                    showTabs={true}
                />
            </div>
            <TabContainer defaultActiveKey={tabvalue}>
                <TabContent>
                    <TabPane eventKey="hedgeable" active={tabvalue === 'hedgeable'} >
                        <CustomerCsvLineItemChanges
                            hedgeable={true}
                        />
                    </TabPane>
                    <TabPane eventKey="unhedgeable" active={tabvalue === 'unhedgeable'}>
                        <CustomerCsvLineItemChanges
                            hedgeable={false}
                        />
                    </TabPane>
                </TabContent>
            </TabContainer>
        </div>
    );
}

export default CustomerCsvChangeScreen;
