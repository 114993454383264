import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function FetchTerminalReturn(portfolio_id, protection_period, protection_level) {

    const Token = sessionStorage.getItem("Token");

    const header = { Authorization: Token };

    var raw = JSON.stringify({
        portfolio_id: portfolio_id,
        protection_period: protection_period,
        protection_level: protection_level
    });

    var requestOptions = {
        method: "POST",
        headers: header,
        body: raw,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_FETCH_TERMINAL_RETURN_API,
                requestOptions
            );
            if (response.message === "Internal server error") {
                //alert("We are facing server issues");
                return false;
            } else if (response.message === "The incoming token has expired") {
                //alert("Your session has expired please log-in again");
            } else {
                const data = await response.json();
                if (data !== "") {
                    return data;
                } else {
                    return false;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("The request timed out, Please try again");
            }
            else {
                return 0
            }
        }
    } else {
        return 401
    }
}
