import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function GetPlaidPublicToken() {

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: header,
  };
  if (!CheckSessionTimeout(Token)) {
    try {

      const response = await fetchWithTimeout(
        process.env.REACT_APP_GET_PLAID_PUBLIC_TOKEN,
        requestOptions
      );
      if (!response.ok) {
        //alert("Unable to retrieve public token. Please try again or contact support@adaptive-investments.com.");
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          console.log("token", message["result"]);
          return message["result"];
        } else if (message["error"] !== "") {
          if (message["error"] === "Token Verification Failed") {
            return 404
          } else {
            return 0
          }
        }
        else {
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else {
    return 401
  }
}
