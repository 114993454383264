import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function PurchaseShield(selectedData) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    var requestOptions = {
        method: "POST",
        body: selectedData,
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetch(
                process.env.REACT_APP_PRUCHASE_SHIELD,
                requestOptions
            );

            if (!response.ok) {
                return false
            } else {
                const message = await response.json();
                console.log("response", message);
                if (message["error"] === "Insufficient Funds") {
                    return "INSUFFICIENT";
                }
                else if (message["error"] === "IB FUNDS AVAILABILITY - FALSE") {
                    return "IB FUNDS AVAILABILITY - FALSE";
                }
                else if (message["error"] === "Wallet doesn't exist") {
                    return "Wallet doesn't exist";
                }
                else if (message["error"] === "SHIELD PRICE - EXPIRED") {
                    return "Shield Price Expired";
                }
                else if (message["error"] === "Insufficient balance.") {
                    return "Insufficient balance.";
                }
                else if (message["error"] !== "") {
                    console.log(message["error"]);
                    if (message["error"] === "Token Verification Failed") {
                        return 404
                    } else {
                        return false;
                    }
                } else {
                    console.log(message["result"]);
                    return true;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("The request timed out, Please try again");
                window.location.reload();
                return 0;
            }
            else {
                return 0
            }
        }
    } else {
        return 401
    }

}
