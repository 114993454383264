import React, { Component } from "react";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class MainSlider extends Component {
  render() {
    const slider = {
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 15000,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Slider {...slider} className="ai-slider">
        <div className="ai-slide">
          <p style={{ paddingBottom: 50 }}>
            Protect your nest with the trusted Portfolio analytics engine -
            Adaptive Shield
          </p>
          <div className="ai-slide-image">
            <img
              src={process.env.PUBLIC_URL + "/login-img1.png"}
              alt="Adaptive Shield"
            />
          </div>
        </div>
        <div className="ai-slide">
          <p>
            Adaptive Shield can help you stay invested for long term growth.
          </p>
          <div className="ai-slide-image">
            <img
              src={process.env.PUBLIC_URL + "/login-img2.png"}
              alt="Adaptive Shield"
            />
          </div>
        </div>
        <div className="ai-slide">
          <p>
            Limit your risk without having to sell. Adaptive Shield is
            cost-effective, easy-to-use downside protection
          </p>
          <div className="ai-slide-image">
            <img
              src={process.env.PUBLIC_URL + "/login-img2.png"}
              alt="Adaptive Shield"
            />
          </div>
        </div>
      </Slider>
    );
  }
}

export default MainSlider;
