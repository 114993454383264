import React from "react";
import { Link } from "react-router-dom";
import MainSlider from "../../Components/MainSlider/MainSlider";
import { Container, Row, Col } from "react-bootstrap";
import LoginWindow from "../../Components/LoginWindow/LoginWindow";
import "./LoginScreen.css";

function LoginScreen() {
  return (
    <section className="ai-container ai-bording-page">
      <Container fluid>
        <Row className="ai-full-height">
          <Col className="ai-slide-wrapper" lg={4}>
            <header>
              <div className="ai-logo">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/ai-logo.png"}
                    alt="Adaptive Investment Logo"
                  />
                </Link>
              </div>
            </header>
            <section className="ai-slide-content">
              <MainSlider />
            </section>
          </Col>
          <Col className="ai-login-wrapper" xs={12} sm={12} md={12} lg={8}>
            <Row className="flex1">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="ai-login-container">
                  <div className="ai-signup-wrapper">
                    <LoginWindow />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <footer>
                  <div className="ia-footer">
                    <p>
                      Adaptive funds are not held in a brokerage account and are
                      not protected by SIPC. Brokerage assets are held by
                      Adaptive Investments Services, a division of Adaptive
                      Investments LLC.
                    </p>
                    <ul className="ia-footer-links">
                      <li>
                        <Link to="/" className="ai-link">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="ai-link">
                          Terms &#38; Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </footer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LoginScreen;
