import React from "react";
import { useState } from "react";
import "./PaymentDepositWithdraw.scss";
import CustomPopup from "../Popup/Popup";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth from "@material-ui/core/withWidth";
import { GetWalletDetails } from "../../Apis/GetWalletDetails";
import { FetchExternalAccountDetails } from "../../Apis/FetchExternalAccountDetails";
import { AddExpectedDeposits } from "../../Apis/AddExpectedDeposits";
import { WithDrawFunds } from "../../Apis/WithDrawFunds";
import TagManager from "react-gtm-module";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PageLoader from "../PageLoader/PageLoader";

function PaymentDepositWithdraw() {

    const useStyles = makeStyles((theme) => ({
        button: {
            color: "white",
            marginTop: "2px",
        },
        checkbox_button: {
            color: "#60034c",
            width: "14px",
            height: "14px",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const classes = useStyles();

    const [Flag, setFlag] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const [RoutingNo, setRoutingNo] = useState("N/A");
    const [VirtualAcNo, setVirtualAcNo] = useState("N/A");
    const [bdopen, setBdOpen] = useState(true);
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [popupTitle, setPopupTitle] = React.useState("");
    const [popupRedirect, setPopupRedirect] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [fetchbalanceapicalled, setfetchbalanceapicalled] = React.useState(false);
    const [fetchbalanceapiCount, setfetchbalanceapiCount] = React.useState(0);
    const [walletState, setWalletState] = React.useState(false);
    const [ExternalAccountData, SetExternalAccountData] = useState([]);
    const [ExternalAccountAPICalled, setExternalAccountAPICalled] = React.useState(false);
    const [ExternalAccountAPICount, setExternalAccountAPICount] = React.useState(0);
    const [ExternalAccountId, setExternalAccountId] = React.useState("");
    const [ExpectedDepositsErrors, setExpectedDepositsErrors] = React.useState({ "depositValue": "", "paymentMethod": "", "externalAccountDeposit": "" });
    const [WithdrawErrors, setWithdrawErrors] = React.useState({ "withdraw": "", "payAccount": "" });
    const [hideVirtualAccountNumberFlag, setHideVirtualAccountNumberFlag] = React.useState(true);
    const [DepositAmount, setDepositAmount] = React.useState(0);
    const [WithDrawAmount, setWithDrawAmount] = React.useState(0);
    const [isRoutingCopied, setRoutingCopied] = useState(false);
    const [isVirtualCopied, setVirtualCopied] = useState(false);
    const [enableWithdraw, setEnableWithdraw] = useState(false);
    const [PaymentMethod, setPaymentMethod] = useState("");
    const [ExternalAccountIdDeposit, setExternalAccountIdDeposit] = useState("");
    const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

    const handleRoutingCopy = (copytext) => {
        setRoutingCopied(true);
        if (isVirtualCopied) {
            setVirtualCopied(false);
        }
        navigator.clipboard.writeText(copytext);
    }
    const handleVirualCopy = (copytext) => {
        setVirtualCopied(true);
        if (isRoutingCopied) {
            setRoutingCopied(false);
        }
        navigator.clipboard.writeText(copytext);
    }

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const fetchWalletDetails = () => {
        setBdOpen(true);
        try {
            GetWalletDetails().then((data) => {
                setfetchbalanceapicalled(true);
                if (data !== false && data !== 0 && data !== undefined && data !== 400 && data !== 404) {
                    setVirtualAcNo(data["virtual_account_no"])
                    setWalletBalance(data["wallet_value"])
                    setWalletState(true)
                    setBdOpen(false);
                } else if (data === false) {
                    setBdOpen(false);
                    setfetchbalanceapicalled(true);
                    setWalletState(false);
                    setPopupState(true);
                    setPopupMessage(
                        "No client wallet found. Please create a payment instruction to transfer funds or contact support@adaptive-investments.com."
                    );
                    setPopupRedirect('/PaymentInstructions');
                    setPopupLoginRedirect(false);
                    setAlertType("error");
                    return;
                } else if (data === 0 || data === undefined) {
                    setBdOpen(false);
                    setfetchbalanceapicalled(true);
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage(
                        "We are facing issues connecting our servers, please try again later"
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    setWalletState(false)
                    return;
                } else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    setPopupTitle("Session Expired");
                    return;
                } else if (data === 404) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupTitle('Session Expired')
                    setPopupMessage(
                        "Your session was terminated for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    return;
                }
            });
        } catch (error) {
            setBdOpen(false);
            setfetchbalanceapicalled(true);
            setPopupState(true);
            setPopupRedirect('');
            setPopupMessage(
                "We are facing issues connecting our servers, please try again later"
            );
            setPopupLoginRedirect(false);
            setAlertType("error");
            setWalletState(false)
        }
    }

    if (Flag === false) {
        try {
            if (fetchbalanceapicalled === false && fetchbalanceapiCount < 1) {
                setfetchbalanceapiCount(1);
                fetchWalletDetails();
            }
            if (ExternalAccountAPICalled === false && ExternalAccountAPICount < 1) {
                setExternalAccountAPICount(1);
                FetchExternalAccountDetails().then((data) => {
                    console.log(data)
                    setExternalAccountAPICalled(true);
                    var lyst = [];
                    if (data !== false && data !== 0 && data !== undefined && data !== 401 && data !== 404) {
                        for (let [key, value] of Object.entries(data)) {
                            if (value.bypass_verification === "True") {
                                lyst.push({
                                    account_name: value.name,
                                    verification_status: value.verification_status,
                                    account_number: "******" + (data[key]["account_details"][0].account_number.slice(-4)),
                                    account_id: value.id
                                });

                            } else {
                                if (value.verification_status === "verified") {
                                    lyst.push({
                                        account_name: value.name,
                                        verification_status: value.verification_status,
                                        account_number: "******" + (data[key]["account_details"][0].account_number.slice(-4)),
                                        account_id: value.id
                                    });
                                }
                            }
                        }
                        SetExternalAccountData([...lyst]);
                        sleep(100).then(() => {
                            if (lyst.length === 0) {
                                setPopupState(true);
                                setPopupMessage(
                                    "Please verify external account information by entering the deposit amounts in any order on the “Payment Instructions” screen to transfer funds."
                                );
                                setAlertType("warning");
                                setPopupRedirect('/PaymentInstructions');
                                setFlag(true);
                                setBdOpen(false);
                                setEnableWithdraw(true);
                                return;
                            }
                        });
                        setBdOpen(false);
                    } else if (data === false) {
                        setPopupState(true);
                        setPopupMessage(
                            "Please verify external account information by entering the deposit amounts in any order on the “Payment Instructions” screen to transfer funds."
                        );
                        setAlertType("warning");
                        setPopupRedirect('/PaymentInstructions');
                        setFlag(true);
                        setBdOpen(false);
                        setEnableWithdraw(true);
                        setPopupLoginRedirect(false);
                        return;
                    }
                    else if (data === 0 || data === undefined) {
                        setBdOpen(false);
                        setExternalAccountAPICalled(true);
                        setPopupState(true);
                        setPopupRedirect('');
                        setPopupMessage(
                            "We are facing issues connecting our servers, please try again later"
                        );
                        setAlertType("error");
                        setPopupLoginRedirect(false);
                        return;
                    }
                    else if (data === 401) {
                        setBdOpen(false);
                        setPopupState(true);
                        setPopupMessage(
                            "Your session timed out for security reasons. Please sign-in again."
                        );
                        setAlertType("error");
                        setPopupLoginRedirect(true);
                        setPopupTitle("Session Expired");
                        return;
                    } else if (data === 404) {
                        setBdOpen(false);
                        setPopupMessage(
                            "Your session was terminated for security reasons. Please sign-in again."
                        );
                        setAlertType("error");
                        setPopupTitle('Session Expired')
                        setPopupRedirect('');
                        setPopupState(true);
                        setPopupLoginRedirect(true);
                        return;
                    }
                });
            }
        } catch (error) {
            setBdOpen(false);
            setfetchbalanceapicalled(true);
            setPopupState(true);
            setPopupRedirect('');
            setPopupMessage(
                "We are facing issues connecting our servers, please try again later"
            );
            setAlertType("error");
            setPopupLoginRedirect(false);
            return;
        }

        if (fetchbalanceapicalled === true && ExternalAccountAPICalled === true) {
            setFlag(true);
            setBdOpen(false);
        }
    }

    const validateExpectedDepositForm = () => {
        let formValid = true;
        let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
        if (DepositAmount === 0) {
            formValid = false;
            setExpectedDepositsErrors(d => ({ ...d, "depositValue": "Please enter amount value to be deposited" }));
        }
        if (PaymentMethod === "") {
            formValid = false;
            setExpectedDepositsErrors(d => ({ ...d, "paymentMethod": "Please select one payment method to deposit" }));
        }
        if (!pattern.test(DepositAmount)) {
            formValid = false;
            setExpectedDepositsErrors(d => ({ ...d, "depositValue": "Please enter valid amount value to be deposited" }));
        }
        if (ExternalAccountIdDeposit === "") {
            formValid = false;
            setExpectedDepositsErrors(d => ({ ...d, "externalAccountDeposit": "Please select one account" }));
        }
        return formValid
    }

    const validateWithdrawForm = () => {
        let formValid = true;
        let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
        if (WithDrawAmount === 0) {
            formValid = false;
            setWithdrawErrors(d => ({ ...d, "withdrawAmt": "Please enter amount value to be withdraw" }));
        }
        if (ExternalAccountId === "") {
            formValid = false;
            setWithdrawErrors(d => ({ ...d, "payAc": "Please select one account" }));
        }
        if (!pattern.test(WithDrawAmount)) {
            formValid = false;
            setWithdrawErrors(d => ({ ...d, "withdrawAmt": "Please enter valid amount value to be withdrawn" }));
        }
        return formValid
    }

    const addExpectedDeposits = (e) => {
        e.preventDefault();
        if (validateExpectedDepositForm()) {
            setBdOpen(true);
            AddExpectedDeposits(PaymentMethod, DepositAmount).then((data) => {
                if (data !== false && data !== 0 && data !== 401) {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("Funds transfer initiated. Expect availability in 24-48 hours.");
                    setAlertType("success");
                    setBdOpen(false);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'User Added Deposit',
                            userType: sessionStorage.getItem("selectedAcType"),
                            user_id: sessionStorage.getItem("clientId")
                        },
                    });
                }
                else if (data === 0) {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("We are having server issues please try again later.");
                    setPopupTitle("Server Error");
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    setBdOpen(false);
                }
                else if (data === false) {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("Unable to transfer funds. Please try again or contact support@adaptive-investments.com.");
                    setPopupTitle("Error while depositing amount to account");
                    setAlertType("error");
                    setPopupLoginRedirect(false);
                    setBdOpen(false);
                }
                else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    setPopupTitle("Session Expired");
                    return;
                } else if (data === 404) {
                    setBdOpen(false);
                    setPopupMessage(
                        "Your session was terminated for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupLoginRedirect(true);
                    setPopupTitle('Session Expired');
                    return;
                }
            });
        }
    }

    const WithDraw = (e) => {
        e.preventDefault();
        if (validateWithdrawForm()) {
            setWithdrawErrors({ "withdraw": "", "payAccount": "" });
            setBdOpen(true);
            
            WithDrawFunds(ExternalAccountId, WithDrawAmount).then((data) => {
                console.log(data)
                if (data === "SUCCESS") {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("Amount $" + Number(Number(WithDrawAmount).toFixed(2)).toLocaleString("en-US") + " withdrawn from your account");
                    setAlertType("success");
                    setPopupTitle("Withdrawl Successfull");
                    setBdOpen(false);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'User Made Withdrawl',
                            userType: sessionStorage.getItem("selectedAcType"),
                            user_id: sessionStorage.getItem("clientId")
                        },
                    });
                    fetchWalletDetails();
                } else if (data === "INSUFFICIENT") {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("Insufficient balance. Please try again or contact support@adaptive-investments.com.");
                    setPopupTitle("Insufficient Funds");
                    setAlertType("error");
                    setBdOpen(false);
                    setPopupLoginRedirect(false);
                }
                else if (data === "UNVERIFIED") {
                    setPopupState(true);
                    setPopupRedirect('/PaymentInstructions');
                    setPopupMessage("Please verify external account information by entering the deposit amounts in any order on the “Payment Instructions” screen to transfer funds.");
                    setPopupTitle("Unverified Account");
                    setAlertType("error");
                    setBdOpen(false);
                    setPopupLoginRedirect(false);
                }
                else if (data === 0) {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("We are having server issues please try again later.");
                    setPopupTitle("Server Error");
                    setAlertType("error");
                    setBdOpen(false);
                    setPopupLoginRedirect(false);
                }
                else if (data === false) {
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupMessage("Unable to transfer funds. Please try again or contact support@adaptive-investments.com.");
                    setPopupTitle("Error while withdraw amount from account");
                    setAlertType("error");
                    setBdOpen(false);
                    setPopupLoginRedirect(false);
                }
                else if (data === 401) {
                    setBdOpen(false);
                    setPopupState(true);
                    setPopupMessage(
                        "Your session timed out for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupLoginRedirect(true);
                    setPopupTitle("Session Expired");
                } else if (data === 404) {
                    setBdOpen(false);
                    setPopupMessage(
                        "Your session was terminated for security reasons. Please sign-in again."
                    );
                    setAlertType("error");
                    setPopupState(true);
                    setPopupRedirect('');
                    setPopupLoginRedirect(true);
                    setPopupTitle('Session Expired');
                    return;
                }
            });
        }
    }

    function hideVirtualAccountNumber(virtualaccountnumber) {
        if (hideVirtualAccountNumberFlag && virtualaccountnumber !== "N/A") {
            return "******" + (virtualaccountnumber.slice(-4))
        }
        else {
            return virtualaccountnumber
        }
    }

    function eyeIcon() {
        if (hideVirtualAccountNumberFlag) {
            return (<img className="show" src="Assets/eye.svg" alt=""></img>)
        } else {
            return (<img className="show" src="Assets/d-eye.svg"></img>)
        }
    }

    function toggleHideVirtualAccountNo(hideVirtualAccountNumberFlag) {
        setHideVirtualAccountNumberFlag(!hideVirtualAccountNumberFlag)
    }

    const changeAccountType = (account) => {
        if (account === "ACH") {
            setRoutingNo("028000024")
            setPaymentMethod("ACH")
        }
        else if (account === "Wire") {
            setRoutingNo("021000021")
            setPaymentMethod("Wire")
        }
    };

    return (
        <div className="withdraw-container">
            <PageLoader bdopen={bdopen}/>
            <CustomPopup
                trigger={popupState}
                setTrigger={setPopupState}
                title={popupTitle}
                content={popupMessage}
                alertType={alertType}
                redirectLink={popupRedirect}
                loginRedirect={popupLoginRedierct}
            />
            <div>
                <div className="balance">
                    <h3>Your current cash wallet balance</h3>
                    <div className="available_balance">${Number(Number(walletBalance).toFixed(2)).toLocaleString("en-US")}</div>
                </div>
                <div className="instructions">
                    <h3>Instructions</h3>
                    <p>To add money to your wallet account: Wire or ACH funds to the following account:</p>
                    <form className="ai-form-group">
                        <div className="form-container">
                            <label class="form-label-light" htmlFor="AddNewPaymentAccount" >
                                Method
                            </label>
                            <Select className="ai-form-value-mui" onChange={(event) => changeAccountType(event.target.value)}>
                                <MenuItem value="0">Select account type</MenuItem>
                                <MenuItem value="ACH">ACH</MenuItem>
                                <MenuItem value="Wire">Wire</MenuItem>
                            </Select>
                            <p className="invalidMessage">{ExpectedDepositsErrors["paymentMethod"]}</p>
                        </div>
                        <div className="form-container">
                            <label className="form-label-light">Routing number</label>
                            <div className="lineinput">
                                <input disabled
                                    className="ai-form-value"
                                    type="text"
                                    placeholder={RoutingNo}
                                />
                                <button type="button" className="ai-btn secondary solid" onClick={() => handleRoutingCopy(RoutingNo)}><img src="Assets/copy.svg"></img><span>{isRoutingCopied ? 'Copied' : ''}</span></button>
                            </div>
                        </div>
                        <div className="form-container">
                            <label className="form-label-light">Your personal virtual account</label>
                            <div className="lineinput">
                                <div className="viewfield">
                                    <input disabled
                                        className="ai-form-value"
                                        type="text"
                                        placeholder={hideVirtualAccountNumber(VirtualAcNo)}
                                    />
                                    <a className="showtext" onClick={() => toggleHideVirtualAccountNo(hideVirtualAccountNumberFlag)}>{eyeIcon()}</a>
                                </div>
                                <button type="button" className="ai-btn secondary solid" onClick={() => handleVirualCopy(VirtualAcNo)}><img src="Assets/copy.svg"></img><span>{isVirtualCopied ? 'Copied' : ''}</span></button>
                            </div>
                        </div>
                    </form>
                    <form className="ai-form-group deposit portfolioform">
                        <div className="form-container">
                            <label class="form-label-light" htmlFor="AddNewPaymentAccount" >
                                Deposit Amount
                            </label>
                            <input
                                className="ai-form-value"
                                type="text"
                                placeholder="Deposit Amount"
                                name="depositamount"
                                onChange={(event) => setDepositAmount(parseFloat(event.target.value).toFixed(2))}
                            />
                            <p className="invalidMessage">{ExpectedDepositsErrors["depositValue"]}</p>
                        </div>
                        <div className="form-container">
                            <label class="form-label-light" htmlFor="AddNewPaymentAccount" >
                                Account to send money from
                            </label>
                            <Select
                                className="ai-form-value-mui"
                                onChange={(event) => setExternalAccountIdDeposit(event.target.value)}
                            >   <MenuItem selected disabled>Select account</MenuItem>
                                {ExternalAccountData.map((value, i) => (
                                    <MenuItem value={value.account_id}>{value.account_name} {value.account_number}</MenuItem>
                                ))}
                            </Select>
                            <p className="invalidMessage">{ExpectedDepositsErrors["externalAccountDeposit"]}</p>
                        </div>
                        <button type="submit" className="ai-btn primary solid" onClick={addExpectedDeposits}>
                            Record Expected Deposit
                        </button>
                    </form>
                </div>
                <div className="instructions">
                    <h3>Withdraw Funds</h3>
                    <p>To withdraw money from your wallet account:</p>
                    <form className="ai-form-group deposit portfolioform" onSubmit={WithDraw}>
                        <div className="form-container">
                            <label class="form-label-light" htmlFor="AddNewPaymentAccount" >
                                WithDraw Amount
                            </label>
                            <input
                                className="ai-form-value"
                                type="text"
                                placeholder="WithDraw Amount"
                                name="withdrawamount"
                                onChange={(event) => setWithDrawAmount(parseFloat(event.target.value).toFixed(2))}
                                disabled={enableWithdraw}
                            />
                            <p className="invalidMessage">{WithdrawErrors["withdrawAmt"]}</p>
                        </div>
                        <div className="form-container">
                            <label class="form-label-light" htmlFor="AddNewPaymentAccount" >
                                Account to send money from
                            </label>
                            <Select className="ai-form-value-mui"
                                onChange={(event) => setExternalAccountId(event.target.value)} >
                                <MenuItem selected disabled>Select account</MenuItem>
                                {ExternalAccountData.map((value, i) => (
                                    <MenuItem value={value.account_id}>{value.account_name} {value.account_number}</MenuItem>
                                ))}
                            </Select>
                            <p className="invalidMessage">{WithdrawErrors["payAc"]}</p>
                        </div>
                        <button type="submit" className="ai-btn primary solid" disabled={enableWithdraw}>
                            Confirm Withdrawal
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );

}

export default withWidth()(PaymentDepositWithdraw);
