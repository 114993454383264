import React from 'react';
import { addZeroes } from "../Utilities/AddZeros";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Tooltip from "react-tooltip-lite";
import { Tab, Tabs, TabContainer, TabPane, TabContent } from "react-bootstrap";

var currObj = {
    style: "currency",
    currency: "USD",
};

const BackTestV2Table = ({
    PortfolioDetailsTable,
    selectedPortfolioTotalValue,
    SimulationDetailsTable,
    PayoutDetailsTable
}) => {

    const portfolioDetailsTableHeaders = [
        { title: "#" },
        { title: "SYMBOL" },
        { title: "POSITION NAME" },
        { title: "QUANTITY", tooltip: "Number of shares held for each stock" },
        { title: "CLOSING PRICE", tooltip: "Price of a security at the EOD previous market close" },
        { title: "MARKET VALUE", tooltip: "Market value of each security based on last price" },
        { title: "WEIGHT" }
    ];

    const SimulationDetailsTableHeaders = [
        { title: "Date" },
        { title: "Shield Cost($)" },
        { title: "Compounded Shield Cost($)" },
        { title: "Portfolio Value" },
        { title: "Shield Cost(%)" },
        { title: "Protection Level" },
        { title: "Portfolio Implied Downside Volatility (without Shield)" },
        { title: "Portfolio Implied Downside Volatility(with Shield)" },
        { title: "Shield Payout" },
        { title: "Compounded Shield Payout" },
    ];

    const ShieldPayoutTableHeaders = [
        { title: "Date" },
        { title: "Payout" },
        { title: "Compounded Shield Payout($)" },
    ];
    const [tabletabvalue, setTableTabValue] = React.useState("Portfolio Details");

    function handleTableTabChange(tabletabvalue) {
        setTableTabValue(tabletabvalue);
    }

    return (
        <div className="backtestv2-holder">
            <div className="shieldstates">
                <div className="contentshield">
                    <ul className="subnavbar-menu navtab">
                        <li>
                            <a id="portfoliodetailstab" className={tabletabvalue === "Portfolio Details" ? "SUBITEM-selected " : "SUBITEM"} eventKey="Portfolio Details" onClick={() => handleTableTabChange('Portfolio Details')} > Portfolio Details <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                        <li>
                            <a id="simulationdetailstab" className={tabletabvalue === "Simulation Details" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Simulation Details" onClick={() => handleTableTabChange('Simulation Details')}>Simulation Details <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                        <li>
                            <a id="shieldpayoutstab" className={tabletabvalue === "Shield Payouts" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Shield Payouts" onClick={() => handleTableTabChange('Shield Payouts')}>Shield Payouts <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                    </ul>
                    <div id="backtesttable" className="table_holder">
                        <TabContainer defaultActiveKey={tabletabvalue}>
                            <TabContent>
                                <TabPane eventKey="Portfolio Details" active={tabletabvalue === 'Portfolio Details'} >
                                    <TableContainer className="table_height">
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {portfolioDetailsTableHeaders.map((header) => (
                                                        <TableCell>
                                                            <div id="portfoliodetailstable" className="stck_table_heading">{header.title}</div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(PortfolioDetailsTable).map(([key, value]) => (
                                                    <TableRow>
                                                        <TableCell>{value.number+1}</TableCell>
                                                        <TableCell>{value.Ticker}</TableCell>
                                                        <TableCell>{value.Name}</TableCell>
                                                        <TableCell>{value.quantity}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value["ClosePrice"]).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value["Market Value"]).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{(parseFloat(value["Weight"]) * 100).toFixed(2)}%</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan="5">Total:</TableCell>
                                                    <TableCell>{Number(
                                                        addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))
                                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                                    <TableCell>100%</TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </TabPane>
                                <TabPane eventKey="Simulation Details" active={tabletabvalue === 'Simulation Details'}>
                                    <TableContainer className="table_height">
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {SimulationDetailsTableHeaders.map((header) => (
                                                        <TableCell>
                                                            <div id="simulationdetailstable" className="stck_table_heading">{header.title}</div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(SimulationDetailsTable).map(([key, value]) => (
                                                    <TableRow>
                                                        <TableCell>{value.date}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.shield_cost).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.compounded_shield_cost).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.portfolio_value).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{parseFloat(value.shield_cost_percent * 100).toFixed(2)}%</TableCell>
                                                        <TableCell>{value.protection_level}</TableCell>
                                                        <TableCell>{(parseFloat(value.portfolio_implied_downside_volatility_without_shield).toFixed(2))}%</TableCell>
                                                        <TableCell>{(parseFloat(value.portfolio_implied_downside_volatility_with_shield).toFixed(2))}%</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.shield_payoff).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.compounded_shield_payoff).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPane>
                                <TabPane eventKey="Shield Payouts" active={tabletabvalue === 'Shield Payouts'}>
                                    <TableContainer className="table_height">
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {ShieldPayoutTableHeaders.map((header) => (
                                                        <TableCell>
                                                            <div id="shieldidpayoutstable" className="stck_table_heading">{header.title}</div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.entries(PayoutDetailsTable).map(([key, value]) => (
                                                    <TableRow>
                                                        <TableCell>{value.payoff_date}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.payoff).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                        <TableCell>{Number(
                                                            addZeroes(Number(parseFloat(value.compounded_shield_payoff).toFixed(2)))
                                                        ).toLocaleString("en-US", currObj)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPane>
                            </TabContent>
                        </TabContainer>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BackTestV2Table;