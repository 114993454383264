import React from "react";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import "./AdvisorDashboardScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import AdvisorStats from "../../Components/AdvisorStats/AdvisorStats";

function AdvisorDashboardScreen(props) {
  return (
    <div className="ai-content-part advisor">
      <Navbar></Navbar>
      <AdvisorStats />
    </div>
  );
}

export default withWidth()(AdvisorDashboardScreen);
