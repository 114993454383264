import React from "react";
import "./ShieldConfirmationScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import ShieldSummary from "../../Components/ShieldSummary/ShieldSummary";

function ShieldConfirmationScreen() {
  return (
    <div className="ai-content-part">
        <Navbar />
        <PageHeader
          heading="Confirm Shield Purchase"
          backButton={true}
          routeTo = './ShieldRecommendationScreen'
        />
        <div className="shield-wrapper">
          <div className="confirmation-holder">
              <ShieldSummary showPurchaseShield={false} />
          </div>
        </div>
    </div>
  );
}
export default ShieldConfirmationScreen;