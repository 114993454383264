import React from "react";
import { useState } from "react";
// import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { GetUserPlaidAccounts } from "../../Apis/GetUserPlaidAccounts";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./PlaidAccounts.css";
import Popup from "../Popup/Popup";
import PageLoader from "../PageLoader/PageLoader";

function PlaidSummary(props) {
  const [Flag, setFlag] = useState(false);
  const [GotID, setGotID] = useState(false);
  const [PlaidAccountID, setPlaidAccountID] = useState("");
  const [PlaidAccountName, setPlaidAccountName] = useState("");
  const [PlaidSummaryData, setPlaidSummaryData] = useState([]);
  const [bdopen, setBdOpen] = useState(true);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupState, setPopupState] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);

  React.useEffect(() => {
    window.sessionStorage.setItem("PlaidAccountID", PlaidAccountID);
  }, [PlaidAccountID]);

  React.useEffect(() => {
    window.sessionStorage.setItem("PlaidAccountName", PlaidAccountName);
  }, [PlaidAccountName]);

  const history = useHistory();
  const PlaidStocks = () => history.push("./PlaidStocks");
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  function addZeroes(value) {
    var new_value = value * 1;
    new_value = new_value + "";

    var pos = new_value.indexOf(".");
    if (pos == -1) new_value = new_value + ".00";
    else {
      var integer = new_value.substring(0, pos);
      var decimals = new_value.substring(pos + 1);
      while (decimals.length <= 1) decimals = decimals + "0";
      new_value = integer + "." + decimals;
    }
    return new_value;
  }
  var currObj = {
    style: "currency",
    currency: "USD",
  };

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return {
        "plaid-card": "plaid-card-lg",
        "plaid-summary": "plaid-summary-lg",
      };
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return {
        "plaid-card": "plaid-card-lg",
        "plaid-summary": "plaid-summary-lg",
      };
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return {
        "plaid-card": "plaid-card-lg",
        "plaid-summary": "plaid-summary-lg",
      };
    }
  }

  const classes = useStyles();
  const rendering = getRendering(props.width);
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const AccountSelected = (selectedValues) => {
    setPlaidAccountID(selectedValues.account_id);
    setPlaidAccountName(selectedValues.name);

    if (PlaidAccountID != "") {
      sleep(100).then(() => {
        PlaidStocks();
      });
    }
  };

  if (Flag === false) {
    if (bdopen === false) {
      setBdOpen(true);
    }
    GetUserPlaidAccounts().then((data) => {
      if (data !== false && data !== 0 && data !== 401) {
        setGotID(true);
        var lyst = [];
        for (let [key, value] of Object.entries(data)) {
          lyst.push({
            account_id: value.account_id,
            account_name: value.name,
            account_type: value.type,
            account_balance: Number(
              addZeroes(Number(parseFloat(value.balance).toFixed(2)))
            ).toLocaleString("en-US", currObj),
            currency: value.currency,
            is_linked: value.linked,
          });
        }
        setBdOpen(false)
        setPlaidSummaryData([...lyst]);
      }
      else if (data === false) {
        setBdOpen(false)
        setGotID(true)
        setPopupState(true);
        setPopupMessage("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
        setPopupTitle("error");
      } else if (data === 0) {
        setBdOpen(false)
        setGotID(true)
        setPopupState(true);
        setPopupMessage("We are facing server issues at this point, please try again later")
        setPopupTitle("error")
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }

    });
    if (GotID === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  function return_button(value) {
    console.log(value);
    if (value.account_type === "investment" && value.is_linked === 0) {
      return (
        <button className="select-btn" onClick={() => AccountSelected(value)}>
          Select
        </button>
      );
    } else if (value.account_type === "investment" && value.is_linked === 1) {
      return <p className="linked-text">LINKED</p>;
    } else {
      return (
        <button className="select-btn disabled" disabled={true}>
          Select
        </button>
      );
    }
  }

  return (
    <div className="account-table">
     <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Import Plaid"
        alertType={alertType}
        content={popupMessage}
        loginRedirect = {popupLoginRedierct}
      />
      <div className="ai-accountsummary">
        <table>
          <tbody>
            <tr>
              <th>Account Name</th>
              <th>Account Type</th>
              <th>Overall Value</th>
              <th>Currency</th>
              <th></th>
            </tr>
            {PlaidSummaryData.map((value, i) => (
              <tr>
                <td>{value.account_name}</td>
                <td>{value.account_type}</td>
                <td>{value.account_balance}</td>
                <td>{value.currency}</td>
                <td>
                  <div className="plaid-select">{return_button(value)}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default withWidth()(PlaidSummary);
