import React from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';

const generateXTicks = (start, end, step) => {
    const ticks = [];
    let tick = start;
    while (tick <= end) {
        ticks.push(tick);
        tick += step;
    }
    return ticks;
};

const BellCurve = (props) => {
    const rawData = {
        "x": props.data["x"],
        "y": props.data["y"]
    };

    const data = rawData.x.map((xVal, i) => ({ x: xVal, y: rawData.y[i] }));

    const threshold1 = props.data["pct_shield_price"];
    const threshold2 = props.data["prot_level"];
    const prot_level = threshold2;
    const pct_shield_price = threshold1;

    const minX = Math.min(...rawData.x);
    const maxX = Math.max(...rawData.x);
    const xTicks = generateXTicks(minX, maxX, (maxX - minX) / 5);

    const leftData = data.filter(({ x }) => x >= Math.log(prot_level) - pct_shield_price && x <= 0);
    const rightData = data.filter(({ x }) => x >= 0);

    return (
        <ComposedChart
            width={900}
            height={400}
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="x" type="number" 
                domain={[minX, maxX]} ticks={xTicks} 
                tickFormatter={(value) => value.toFixed(2)} 
                label={{ value: 'Possible Returns', position: 'insideBottom', offset: -10 }}/>
            <YAxis dataKey="y" type="number" label={{ value: 'Probabilities', angle: -90, position: 'insideLeft' }} />
            {/* <Tooltip /> */}
            <Area type='monotone' data={leftData} dataKey='y' fill='#E58B76' />
            <Area type='monotone' data={rightData} dataKey='y' fill='#60034C' />
            <Line type='monotone' dataKey='y' stroke='black' dot={false} />
            <ReferenceLine x={0} stroke="white" label={{ value: `Protection Level: ${(threshold2 * 100).toFixed(2)}%`, position: 'insideRight' }} />
            <ReferenceLine x={-pct_shield_price} stroke="yellow" label={{ value: `% Shield Cost: ${(threshold1 * 100).toFixed(3)}%`, position: 'insideTopLeft' }} />
        </ComposedChart>
    );
};

export default BellCurve;
