import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "react-bootstrap";
import { GetUserPortfolios } from "../../Apis/GetUserPortfolios";
import withWidth from "@material-ui/core/withWidth";
import { GetBacktestGraphData } from "../../Apis/GetBacktestGraphData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Popup from "../Popup/Popup";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../Components/BackTestSlider/BackTestSlider.css";
import "./BackTestV2.scss";
import TagManager from "react-gtm-module";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { keyEvents } from "../Utilities/BackTestEvents";
import BacktestV2Graph from "./BackTestV2Graph";
import BackTestV2Table from "./BackTestV2Table";
import PageLoader from "../PageLoader/PageLoader";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " ai-slide-next"}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        ></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " ai-slide-prev"}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        ></div>
    );
}

export function BackTestV2() {

    var currObj = {
        style: "currency",
        currency: "USD",
    };
    const events = {
        dots: false,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));
    const classes = useStyles();

    const [Flag, setFlag] = React.useState(false);
    const [bdopen, setBdOpen] = React.useState(true);
    const [GotData, setGotData] = React.useState(false);
    const [portfolioName, setPortfolioName] = React.useState([]);
    const [GotBTData, setGotBTData] = React.useState(false);
    const [BtData, setBtData] = React.useState([]);
    const [BtLevel, setBtLevel] = React.useState(0);
    const [period, setPeriod] = React.useState(0);
    const [BtPortfolioID, setBtPortfolioID] = React.useState(0);
    var start = new Date();
    const [startDate, setStartDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
    const [endDate, setEndDate] = React.useState(new Date(new Date().setDate(new Date().getDate() - 7)));
    const [popupState, setPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [showEvents, setShowEvents] = React.useState(false);
    const [EventBtData, setEventBtData] = React.useState([]);
    const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
    const [tabletabvalue, setTableTabValue] = React.useState("Portfolio Details");
    const [PortfolioDetailsTable, setPortfolioDetailsTable] = React.useState([]);
    const [SimulationDetailsTable, setSimulationDetailsTable] = React.useState([]);
    const [PayoutDetailsTable, setPayoutDetailsTable] = React.useState([]);
    const [EventPortfolioDetailsTable, setEventPortfolioDetailsTable] = React.useState([]);
    const [EventSimulationDetailsTable, setEventSimulationDetailsTable] = React.useState([]);
    const [EventPayoutDetailsTable, setEventPayoutDetailsTable] = React.useState([]);
    const [selectedPortfolioTotalValue, setSelectedPortfolioTotalValue] = React.useState(0);

    const levels = [
        { percent: 0, level: "Select" },
        { percent: 0.8, level: "80%" },
        { percent: 0.85, level: "85%" },
        { percent: 0.9, level: "90%" },
        { percent: 0.95, level: "95%" },
        { percent: 1.0, level: "100%" },
    ];

    const periods = [
        { period_int: 0, period_value: "Select" },
        { period_int: 30, period_value: "1 Month" },
        { period_int: 60, period_value: "2 Months" },
        { period_int: 91, period_value: "3 Months" },
        { period_int: 182, period_value: "6 Months" },
        { period_int: 365, period_value: "Year" },
    ];


    React.useEffect(() => {
        GetUserPortfolios().then((data) => {
            var portfoliolyst = [{ id: 0, name: "Select Portfolio" }];
            if (data !== false && data !== 0 && data !== 401 && data !== 404) {
                for (let [key, value] of Object.entries(data)) {
                    setGotData(true);
                    portfoliolyst.push({
                        id: key,
                        name: value.portfolioname,
                        port_value: value.totalportfoliovalue
                    });
                }
                console.log(portfoliolyst);
                setPortfolioName(portfoliolyst);
                setBdOpen(false)
            }
            else if (data === false) {
                setPopupState(true);
                setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
                setAlertType("error");
                setBdOpen(false);
                setGotData(true);
                setPopupLoginRedirect(false);
            }
            else if (data === 0) {
                setPopupState(true);
                setPopupMessage("We are facing issues connecting our servers, please try again later");
                setAlertType("error");
                setBdOpen(false);
                setGotData(true);
                setPopupLoginRedirect(false);
            }
            else if (data === 401) {
                setBdOpen(false);
                setPopupState(true);
                setPopupMessage(
                    "Your session timed out for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
            } else if (data === 404) {
                setBdOpen(false);
                setPopupState(true);
                setGotData(true);
                setPopupMessage(
                    "Your session was terminated for security reasons. Please sign-in again."
                );
                setAlertType("error");
                setPopupLoginRedirect(true);
            }
        });
    },
        []);

    const changePortfolio = (portfolioid) => {
        if (portfolioid === 0) {
            setPopupState(true);
            setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
            setAlertType("warning");
            return;
        }

        var portfolio = portfolioName.find(data => data.id === portfolioid);
        setBtPortfolioID(portfolio.id);
        setSelectedPortfolioTotalValue(portfolio.port_value)
    };

    const changeLevel = (percent) => {
        setBtLevel(percent);
    };

    const changePeriod = (period_value) => {
        setPeriod(period_value);
    };

    function validateInput(datediff) {
        console.log("date diff", datediff, period)
        if (BtPortfolioID === 0) {
            setPopupState(true);
            setPopupMessage("Please select a valid Portfolio");
            setAlertType("warning");
            setBdOpen(false);
            return false;
        }
        else if (period === 0) {
            setPopupState(true);
            setPopupMessage("Protection period not recognized. Please try again or contact support@adaptive-investments.com.");
            setAlertType("warning");
            setBdOpen(false);
            return false;
        }
        else if (BtLevel === 0) {
            setPopupState(true);
            setPopupMessage("Protection level not recognized. Please try again or contact support@adaptive-investments.com.");
            setAlertType("warning");
            setBdOpen(false);
            return false;
        }
        else if (datediff <= period) {
            setPopupState(true);
            setPopupMessage("Please select a wider date range than the protection period to re-run the Backtest.");
            setAlertType("warning");
            setBdOpen(false);
            return false;
        }
        //if start year < 2017 or end year < 2017 or start year > current year or end year > current year or start year > end year raise error
        else if (startDate.getFullYear() < 2017 || endDate.getFullYear() < 2017 || startDate.getFullYear() > new Date().getFullYear() || endDate.getFullYear() > new Date().getFullYear() || startDate.getFullYear() > endDate.getFullYear()) {
            setPopupState(true);
            setPopupMessage("Cannot run backtest for the selected date range. Please limit your date range from 2017 to current date.");
            setAlertType("warning");
            setBdOpen(false);
            return false;

        }
        else {
            return true;
        }
    }
    function runBackTest(start, end, showEventsGraph) {
        // if (startDate.getTime() < 1577836800000) {
        //     setPopupState(true);
        //     setPopupMessage("Data not yet available earlier than 2020, please try a different date range");
        //     setAlertType("warning");
        //     setBdOpen(false);
        //     return;
        // }

        setBdOpen(true);
        let date1 = startDate.toISOString().split("T")[0].toString();
        let date2 = endDate.toISOString().split("T")[0].toString();
        var datediff = (
            (endDate.getTime() - startDate.getTime()) /
            (1000 * 3600 * 24)
        ).toFixed(0);
        if (showEventsGraph !== false) {
            date1 = start;
            date2 = end;
            console.log(" date1", date1, date2)
            const diffTime = Math.abs(new Date(date2) - new Date(date1));
            datediff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }

        if (validateInput(datediff)) {
            GetBacktestGraphData(BtPortfolioID, date1, date2, BtLevel, period).then((data) => {
                if (data === false) {
                    setGotBTData(true);
                    setBdOpen(false)
                    setPopupState(true);
                    setFlag(false);
                    if (date1 >= date2) {
                        setAlertType("warning");
                        setPopupMessage("Dates not recognized. Please try again or contact support@adaptive-investments.com.");
                        return;
                    } else if (datediff <= 30) {
                        setAlertType("warning");
                        setPopupMessage("Backtest dates require at least one month. Please try again or contact support@adaptive-investments.com.");
                        return;
                    } else {
                        setAlertType("error");
                        setPopupMessage("Unable to run backtest. Please try again or contact support@adaptive-investments.com.");
                        return;
                    }
                }
                else if (data === 0) {
                    setBdOpen(false)
                    setGotBTData(true);
                    setPopupState(true);
                    setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    return;
                } else if (data === "Empty Portfolio") {
                    setBdOpen(false)
                    setGotBTData(true);
                    setPopupState(true);
                    setPopupMessage("Your portfolio does not have any recognized tickers or is empty. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    return;
                } else if (data === "Data not found for the given ticker(s)") {
                    setBdOpen(false)
                    setGotBTData(true);
                    setPopupState(true);
                    setPopupMessage("None of the tickers in your portfolio have data for the selected period. Please try again or contact support@adaptive-investments.com.");
                    setAlertType("warning");
                    return;
                } else {
                    setGotBTData(true);
                    if (showEventsGraph === false) {
                        setBtData(data);
                        setPortfolioDetailsTable(data["portfolio_details"]);
                        setSimulationDetailsTable(data["simulation_details"]);
                        setPayoutDetailsTable(data["payout_details"]);
                        // setEventBtData([]);
                    } else {
                        setEventBtData(data);
                        setEventPortfolioDetailsTable(data["portfolio_details"]);
                        setEventSimulationDetailsTable(data["simulation_details"]);
                        setEventPayoutDetailsTable(data["payout_details"]);
                        // setBtData([]);
                    }
                    setBdOpen(false);
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'Back Test',
                            userType: sessionStorage.getItem("selectedAcType"),
                            user_id: sessionStorage.getItem("clientId"),
                            backtest_type: 'Backtest Calculated for Custom Dates'
                        },
                    });
                }
            });
        }
        // else {
        //     setGotBTData(true);
        //     setBdOpen(false)
        //     setPopupState(true);
        //     setFlag(false);
        //     setAlertType("error");
        //     setPopupMessage("Unable to run backtest. Please try again or contact support@adaptive-investments.com.");
        //     return;
        // }
    }


    const historicalEvents = (start, end) => {
        let showEventsGraph = true;
        runBackTest(start, end, showEventsGraph);
        TagManager.dataLayer({
            dataLayer: {
                event: 'Back Test',
                userType: sessionStorage.getItem("selectedAcType"),
                user_id: sessionStorage.getItem("clientId"),
                backtest_type: 'Backtest Calculated for Historical Events'
            },
        });
    };

    return (
        <div className="testwindow-wrap">
            <PageLoader bdopen={bdopen} />
            <Popup
                trigger={popupState}
                setTrigger={setPopupState}
                title="Run Backtest"
                alertType={alertType}
                content={popupMessage}
                loginRedirect={popupLoginRedierct}
            />
            <div className="details_wrap alon">
                <div className="infocard-wrap">
                    <div id="selectportfolio" className="infocard">
                        <h3>Portfolio</h3>
                        <Select
                            className="ai-form-value-bt"
                            onChange={(event) => changePortfolio(event.target.value)}
                        >
                            {portfolioName.map((value, i) => (
                                <MenuItem value={value.id}>{value.name}</MenuItem>
                            ))}
                        </Select>
                        <span className="ai-backtest-select-arrow"></span>
                    </div>
                    <div class="saprator"></div>
                    <div id="selectprotectionlevel" className="infocard">
                        <h3>Protection Level</h3>
                        <Select
                            className="ai-form-value-bt"
                            onChange={(event) => changeLevel(event.target.value)}
                        >
                            {levels.map((value, i) => (
                                <MenuItem value={value.percent}>{value.level}</MenuItem>
                            ))}
                        </Select>
                        <span className="ai-backtest-select-arrow"></span>
                    </div>
                    <div class="saprator"></div>
                    <div id="selectprotectionperiod" className="infocard">
                        <h3>Protection Period</h3>
                        <Select
                            className="ai-form-value-bt"
                            onChange={(event) => changePeriod(event.target.value)}
                        >
                            {periods.map((value, i) => (
                                <MenuItem value={value.period_int}>{value.period_value}</MenuItem>
                            ))}
                        </Select>
                        <span className="ai-backtest-select-arrow"></span>
                    </div>
                </div>
            </div>
            <Tabs
                defaultActiveKey="daterange"
                id="uncontrolled-tab-example"
                className="ai-tabs ai-backtest-tabs"
            >
                <Tab eventKey="daterange" title="Date Range">
                    <div className="ai-date-selection">
                        <DatePicker
                            className="ai-form-value"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date("01-01-2017")}
                        />
                        <p style={{ color: "#4f0044" }}>To</p>
                        <DatePicker
                            className="ai-form-value"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            maxDate={new Date()}
                        />
                        <button id="runtestbutton"
                            className="ai-btn primary solid small"
                            onClick={() => runBackTest(startDate, endDate, showEvents)}
                        >
                            Run Test
                        </button>
                    </div>
                    {BtData.length !== 0 &&
                        <>
                            <div className="ai-backtest-graph">
                                <BacktestV2Graph rawData={BtData} />
                            </div>
                            <BackTestV2Table
                                PortfolioDetailsTable={PortfolioDetailsTable}
                                selectedPortfolioTotalValue={selectedPortfolioTotalValue}
                                SimulationDetailsTable={SimulationDetailsTable}
                                PayoutDetailsTable={PayoutDetailsTable}
                            />
                        </>
                    }
                </Tab>
                <Tab eventKey="historyevents" title="Historical Events">
                    <Slider {...events} className="ai-backtest-slider-bt">
                        {keyEvents.map((value, i) => (
                            <a
                                className="ai-backtest-event-bt"
                                onClick={() =>
                                    historicalEvents(value.eventStart, value.eventEnd)
                                }
                            >
                                <p>{value.title}</p>
                                <p className="ai-event-timestamp-bt">
                                    {value.eventStart} to {value.eventEnd}
                                </p>
                            </a>
                        ))}
                    </Slider>
                    {EventBtData.length !== 0 &&
                        <>
                            <div className="ai-backtest-graph">
                                <BacktestV2Graph rawData={EventBtData} />
                            </div>
                            <BackTestV2Table
                                PortfolioDetailsTable={EventPortfolioDetailsTable}
                                selectedPortfolioTotalValue={selectedPortfolioTotalValue}
                                SimulationDetailsTable={EventSimulationDetailsTable}
                                PayoutDetailsTable={EventPayoutDetailsTable}
                            />
                        </>
                    }
                </Tab>
            </Tabs>
        </div>
    );
}
export default withWidth()(BackTestV2);
