import React from 'react';
import "./RiskContributionScreen.scss";
import NavBar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import RiskContribution from "../../Components/RiskContribution/RiskContribution";


function RiskContributionScreen(props) {
  return (
    <div className="ai-content-part">
      <NavBar />
      <div className="floathead">
        <PageHeader
          heading="Portfolio Risk Contribution"
          backButton={true}
          bubble="Beta"
          addButton={false}
          routeTo="./PortfolioSummaryScreen"
        />
      </div>
      <RiskContribution/>
    </div>
  );
}

export default RiskContributionScreen;
