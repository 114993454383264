import { useState, useEffect } from 'react';
import "./PageLoader.css";
export default function PageLoader(props) {
  useEffect(() => {

  }, [props.bdopen]);

  return (
    <>
      {props.bdopen &&
          <div className="page-loader">
            <svg className='loadersvg' width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.15508 4.69017C4.92348 1.64368 8.34583 0.0551557 12.5311 0.229241C13.5339 0.272762 14.5802 0.381565 15.5175 0.707975C17.8063 1.49136 18.9617 3.36277 18.9835 6.06109C19.0053 9.49927 18.9835 12.9592 18.9835 16.3974C18.9835 19.0087 19.7682 19.6397 22.3186 19.0522C21.8391 20.8148 20.0516 22.338 18.0025 22.5992C15.3867 22.9256 13.1197 22.4468 12.1388 19.3568C11.681 19.8573 11.3322 20.2055 10.9834 20.5537C9.19597 22.4033 6.99433 23.0126 4.50931 22.5992C2.80903 22.3163 1.63192 21.3806 1.17415 19.705C0.650987 17.8118 1.26134 16.1798 2.56925 14.8306C4.33492 12.981 6.71095 12.1976 9.06518 11.436C9.82812 11.1966 10.6347 11.1096 11.3758 10.8267C11.681 10.7179 12.0298 10.2827 12.0734 9.95624C12.1824 9.23814 12.1388 8.49828 12.0952 7.78018C11.9862 5.21243 10.7873 3.90679 8.21504 3.97207C6.23138 4.01559 4.22593 4.42904 2.15508 4.69017ZM12.0952 11.6101C9.98071 11.6101 7.64828 13.3291 7.16871 15.1353C6.86354 16.3103 6.88534 17.4637 7.82267 18.3776C8.84719 19.3568 10.0897 19.4221 11.3758 18.9651C11.681 18.8563 12.0734 18.4429 12.0734 18.16C12.117 16.0057 12.0952 13.8296 12.0952 11.6101Z" fill="#60034C" />
            </svg>
            <i className="loader"></i>
          </div>
        }
    </>
  );
};