import React from "react";
import AdvisorMultilineChart from "./views/MultilineChart/AdvisorMultilineChart";
import Legend from "./components/Legend";
import "./AdvisorGraph.css";

export default function AdvisorGraph(props) {
  var array1 = [];
  var array2 = [];
  var array3 = [];
  var array4 = [];
  var array5 = [];
  // console.log("props:", props);
  if (props.vix_data !== undefined && props.snp_data !== undefined) {
    console.log("vix_data:", props.vix_data);
    console.log("snp_data:", props.snp_data);
  }

  const defaults = {
    data: {
      tradedate: [],
      vix: [],
      snp: [],
    },
  };
  var arrayOfObject = [];
  if (props.vix_data === undefined && props.snp_data === undefined) {
    array1 = [];
    array2 = [];
    array3 = [];
    array4 = [];
    array5 = [];
    arrayOfObject = [];
  } else if (props.vix_data !== undefined && props.snp_data !== undefined) {
    array1 = props.vix_data.tradedate;
    array2 = props.vix_data.scaled_close;
    array3 = props.snp_data.scaled_close;
    array4 = props.vix_data.closeprice;
    array5 = props.snp_data.closeprice;
  }

  arrayOfObject = array1.map(function (value, index) {
    return {
      date: value,
      vix: array2[index],
      vix_label: array4[index],
      snp: array3[index],
      snp_label: array5[index],
    };
  });

  const vix = {
    name: "VIX",
    color: "#60034C",
    items: arrayOfObject.map((d) => ({
      value: d.vix,
      date: new Date(d.date),
      label: d.vix_label,
    })),
  };
  const snp = {
    name: "S&P500",
    color: "#E58B76",
    items: arrayOfObject.map((d) => ({
      value: d.snp,
      date: new Date(d.date),
      label: d.snp_label,
    })),
  };

  console.log(vix);

  //   // console.log(arrayOfObject);

  //   const [selectedItems, setSelectedItems] = React.useState([
  //     "Hedged",
  //     "Unhedged",
  //   ]);
  const legendData = [vix, snp];
  const chartData = [vix, snp];
  //   console.log(chartData);
  //   const onChangeSelection = (name) => {
  //     const newSelectedItems = selectedItems.includes(name)
  //       ? selectedItems.filter((item) => item !== name)
  //       : [...selectedItems, name];
  //     setSelectedItems(newSelectedItems);
  //   };

  return (
    <div className="App">
      {props.data.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <AdvisorMultilineChart data={chartData} is_backtest={false} />
        </div>
      )}
    </div>
  );
}

// getDefaultProps() {
//   return {
//     note: '',
//     onCancel: this.handleClose.this(bind),
//     onSave: this.handleClose.this(bind),
//     onEditChange: this.handleChange.this(bind)
//   };
// }

AdvisorGraph.defaultProps = {
  data: {
    tradedate: [],
    vix: [],
    snp: [],
  },
};
