import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function AddNewWallet() {

    const Token = sessionStorage.getItem("Token");
    // const session_id = sessionStorage.getItem("sessionID");

    const header = { Authorization: Token };

    var raw = JSON.stringify({
        session_id: Token,
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: header,
    };
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetch(
                process.env.REACT_APP_FETCH_WALLET_API,
                requestOptions
            );
            if (!response.ok) {
                console.log("Could not create virtual account");
                return false;
            } else {
                const message = await response.json();
                if (message["error"] === "") {
                    return message["result"];
                } else {
                    console.log(message["error"]);
                    return false
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                // window.location.reload();
                return 0
            }
            else {
                return 0
            }
        }
    }
    else{
        return 401
    }
}
