import React from "react";
import Navbar from "../../Components/Navbar/Navbar_official"
import PageHeader from "../../Components/PageHeader/PageHeader";
import PaymentDepositWithdraw from "../../Components/PaymentDepositAndWithdraw/PaymentDepositWithdraw";

function PaymentDepositAndWithdrawScreen(props) {
    return (
        <div className="ai-content-part">
            <Navbar></Navbar>
            <PageHeader heading="Deposit/Withdraw Funds" backButton={true} />            
            <PaymentDepositWithdraw />
        </div>
    )
}

export default (PaymentDepositAndWithdrawScreen);