import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from '@mui/material/MenuItem';
import React, {useEffect, useState} from "react";
import Select from '@mui/material/Select';
import TagManager from "react-gtm-module";
import withWidth from "@material-ui/core/withWidth";

import {addZeroes} from "../Utilities/AddZeros";
import Popup from "../Popup/Popup";
import BarGraph from '../../Components/TerminalBarGraph/TerminalBarGraph';
import BellCurve from '../../Components/TerminalBellCurve/TerminalBellCurve';
import {GetUserPortfolios} from "../../Apis/GetUserPortfolios";
import {FetchTerminalReturn} from "../../Apis/FetchTerminalReturn";
import PageLoader from "../PageLoader/PageLoader";

export function TerminalReturn() {
    const [alertType, setAlertType] = useState("");
    const [bdOpen, setBdOpen] = useState(false);
    const [disableRunButton, setDisableRunButton] = useState(true);
    const [droppedTicker, setDroppedTicker] = useState([]);
    const [popupLoginRedierct, setPopupLoginRedirect] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupState, setPopupState] = useState(false);
    const [portfolioId, setPortfolioId] = useState(0);
    const [portfolioLineItemsCount, setPortfolioLineItemsCount] = useState("-");
    const [portfolioList, setPortfolioList] = useState([{id: 0, name: 'Select Portfolio', stock_no: "-", port_val: "-"}]);
    const [portfolioTotalValue, setPortfolioTotalValue] = useState("-");
    const [protectionLevel, setProtectionLevel] = useState(0);
    const [protectionPeriod, setProtectionPeriod] = useState(0);
    const [terminalReturnDistributionData, setTerminalReturnDistributionData] = useState();
    const [unrecognisedTicker, setUnrecognisedTicker] = useState([]);

    const periods = [
        {period_int: 0, period_value: "Select"},
        {period_int: 30, period_value: "1 Month"},
        {period_int: 60, period_value: "2 Months"},
        {period_int: 91, period_value: "3 Months"},
        {period_int: 182, period_value: "6 Months"},
        {period_int: 365, period_value: "Year"},
    ];

    const levels = [
        {percent: 0, level: "Select"},
        {percent: 0.80, level: "80%"},
        {percent: 0.85, level: "85%"},
        {percent: 0.90, level: "90%"},
        {percent: 0.95, level: "95%"},
        {percent: 1.0, level: "100%"},
    ];

    const currentPortfolio = (portfolioid) => {
        if (portfolioid === 0) {
            setDisableRunButton(true);
            setPortfolioLineItemsCount("-");
            setPortfolioTotalValue("-");
            return;
        }
        setPortfolioId(portfolioid);
        let portfolio_data = portfolioList.find(data => data.id === portfolioid);
        setPortfolioLineItemsCount(portfolio_data.stock_no);
        setPortfolioTotalValue(portfolio_data.port_val);
    };

    const HitTerminalReturnAPI = () => {
        setDisableRunButton(true);
        setBdOpen(true);
        FetchTerminalReturn(portfolioId, protectionPeriod, protectionLevel).then((data) => {
            if (data !== false && data !== 0 && data !== 401 && data !== 404) {
                setTerminalReturnDistributionData(data);
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Terminal Return',
                        userType: sessionStorage.getItem('selectedAcType'),
                        user_id: sessionStorage.getItem('clientId')
                    },
                });
                setBdOpen(false);
                setDisableRunButton(false);
            } else if (data === false || data === 0 || data === 401 || data === 404) {
                setPopupState(true);
                if (data === false) {
                    setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
                    setPopupLoginRedirect(false);
                } else if (data === 0) {
                    setPopupMessage("We are facing issues connecting our servers, please try again later");
                    setPopupLoginRedirect(false);
                } else if (data === 401) {
                    setPopupMessage("Your session timed out for security reasons. Please sign-in again.");
                    setPopupLoginRedirect(true);
                } else if (data === 404) {
                    setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
                    setPopupLoginRedirect(true);
                }
                setAlertType("error");
                setDisableRunButton(false);
            }
            setDroppedTicker(data.dropped);
            setUnrecognisedTicker(data.unrecognised);
            setBdOpen(false);
        });
    };

    useEffect(() => {
        GetUserPortfolios().then((data) => {
            setBdOpen(true);
            if (data !== false && data !== 0 && data !== 401 && data !== 404) {
                for (let [key, value] of Object.entries(data)) {
                    portfolioList.push({
                        id: key,
                        name: value.portfolioname,
                        stock_no: value.nooflineitems,
                        port_val: parseFloat(value.totalportfoliovalue).toFixed(2)
                    });
                }
                setPortfolioList(portfolioList);
            } else if (data === false || data === 0 || data === 401 || data === 404) {
                setPopupState(true);
                if (data === false) {
                    setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
                    setPopupLoginRedirect(false);
                } else if (data === 0) {
                    setPopupMessage("We are facing issues connecting our servers, please try again later");
                    setPopupLoginRedirect(false);
                } else if (data === 401) {
                    setPopupMessage("Your session timed out for security reasons. Please sign-in again.");
                    setPopupLoginRedirect(true);
                } else if (data === 404) {
                    setPopupMessage("Your session was terminated for security reasons. Please sign-in again.");
                    setPopupLoginRedirect(true);
                }
                setAlertType("error");
            }
            setBdOpen(false);
        });
        return () => {

        };
    }, []);

    useEffect(() => {
        if (portfolioList) {
            // console.log('portfolioList', portfolioList);
            setBdOpen(false);
        }
    }, [portfolioList]);

    useEffect(() => {
        if (portfolioId) {
            // console.log('portfolioId', portfolioId);
        }

        if (protectionLevel && protectionPeriod && portfolioId && portfolioLineItemsCount > 0 && portfolioTotalValue > 0) {
            setDisableRunButton(false);
        } else {
            setDisableRunButton(true);
        };
    }, [portfolioId]);

    useEffect(() => {
        if (portfolioLineItemsCount) {
            // console.log('portfolioLineItemsCount', portfolioLineItemsCount);
        }
    }, [portfolioLineItemsCount]);

    useEffect(() => {
        if (disableRunButton) {
            // console.log('disableRunButton', disableRunButton);
        }
    }, [disableRunButton]);

    useEffect(() => {
        if (protectionLevel) {
            // console.log('protectionLevel', protectionLevel);
        }

        if (protectionLevel && protectionPeriod && portfolioId && portfolioLineItemsCount > 0 && portfolioTotalValue > 0) {
            setDisableRunButton(false);
        } else {
            setDisableRunButton(true);
        };
    }, [protectionLevel]);

    useEffect(() => {
        if (protectionPeriod) {
            // console.log('protectionPeriod', protectionPeriod);
        }

        if (protectionLevel && protectionPeriod && portfolioId && portfolioLineItemsCount > 0 && portfolioTotalValue > 0) {
            setDisableRunButton(false);
        } else {
            setDisableRunButton(true);
        };
    }, [protectionPeriod]);

    useEffect(() => {
        if (terminalReturnDistributionData) {
            // console.log('terminalReturnDistributionData', terminalReturnDistributionData);
            setBdOpen(false);
        }
    }, [terminalReturnDistributionData]);

    useEffect(() => {
        if (droppedTicker.length > 0) {
            setPopupState(true);
            setPopupMessage("The following tickers have been dropped due to inadequate data.:\n" + droppedTicker.join(", "));
        }
    }, [droppedTicker]);

    useEffect(() => {
        if (droppedTicker.length > 0 || unrecognisedTicker.length > 0) {
            setPopupState(true);
            setPopupMessage("The following tickers have been dropped due to inadequate data:\n" + droppedTicker.join(", ") + unrecognisedTicker.join(", "));
            setDroppedTicker([]);
            setUnrecognisedTicker([]);
        }
    }, [droppedTicker, unrecognisedTicker]);

    return (
        <div className="testwindow-wrap">
            <PageLoader bdopen={bdOpen} />
            <Popup
                trigger={popupState}
                setTrigger={setPopupState}
                title="Run Terminal Distribution"
                alertType={alertType}
                content={popupMessage}
                loginRedirect={popupLoginRedierct}
            />
            <div className="details_wrap alon">
                <div id="selectportfolio" className="infocard">
                    <h3>Portfolio</h3>
                    <Select
                        className="ai-form-value-bt"
                        defaultValue={portfolioId}
                        onChange={(event) => currentPortfolio(event.target.value)}
                    >
                        {portfolioList?.map(item => (
                            <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                    <span className="ai-backtest-select-arrow"></span>
                </div>
                <div className="saprator"></div>
                <div className="infocard">
                    <h3>HOLDINGS</h3>
                    <p id="portfolioholdings">{portfolioLineItemsCount}</p>
                </div>
                <div className="saprator"></div>
                <div className="infocard">
                    <h3>PORTFOLIO VALUE</h3>
                    <p id="portfoliovalue">
                        {(portfolioTotalValue === '-') ? "-" : "$" + Number(addZeroes(Number(parseFloat(portfolioTotalValue).toFixed(2)))).toLocaleString("en-US")}
                    </p>
                </div>
            </div>
            <div className="forwardtest-holder">
                <div className="details_wrap self_assembly">
                    <div className="infocard-wrap">
                        <div id="selectprotectionperiod" className="infocard">
                            <h3>Protection Period</h3>
                            <Select
                                className="ai-form-value-bt"
                                value={protectionPeriod}
                                onChange={(event) => setProtectionPeriod(event.target.value)}
                            >
                                {periods.map((value) => (
                                    <MenuItem value={value.period_int}>{value.period_value}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="saprator"></div>
                        <div id="selectprotectionlevel" className="infocard">
                            <h3>Protection Level</h3>
                            <Select
                                className="ai-form-value-bt"
                                value={protectionLevel}
                                onChange={(event) => setProtectionLevel(event.target.value)}
                            >
                                {levels.map((value, i) => (
                                    <MenuItem value={value.percent}>{value.level}</MenuItem>
                                ))}
                            </Select>
                            <span className="ai-selfassembly-select-arrow"></span>
                        </div>
                    </div>
                    <div className="buttonbuy">
                        <button id="rundistribution" className="ai-btn primary solid"
                            disabled={disableRunButton}
                            onClick={HitTerminalReturnAPI}
                        >
                            Run Distribution
                        </button>
                        {!disableRunButton &&
                            <p className="infomessage"> Press “Run..” to recalculate distribution</p>
                        }
                        {portfolioLineItemsCount === 0 &&
                            <p className="infomessage">Add holdings to the portfolio</p>
                        }
                    </div>
                </div>
                <div className="dataforwardtest">
                    <div className="forwardtest">
                        <div className="forgraphs">
                            <div id ="bargraph" className="forward-column">

                                {terminalReturnDistributionData ? (
                                    <>
                                        <h4>Standard Deviation Returns </h4>
                                        <BarGraph data={terminalReturnDistributionData} />
                                    </>) : (<>
                                    </>)}
                            </div>
                        </div>
                        <div id="bellcurve" className="forwardanalyze-portfolio">
                            {terminalReturnDistributionData ? (
                                <>
                                    <h4>Possible Returns And Their Probabilities </h4>
                                    <BellCurve data={terminalReturnDistributionData} />
                                </>) : (<>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withWidth()(TerminalReturn);