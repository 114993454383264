import React from "react";
import "./Navbar_official.scss";
import PageLoader from "../PageLoader/PageLoader";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import clsx from "clsx";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UserPool from "../../UserPool";
import AdvisorPool from "../../AdvisorPool";
import { UpdateAdvisorSessionID } from "../../Apis/UpdateAdvisorSessionID";
import { FetchSessionDetails } from "../../Apis/FetchSessionDetails";
import Popup from "../Popup/Popup";
import TagManager from "react-gtm-module";
import Iaccount_details from "../../images/account_details.svg"
import Iactive_shields from "../../images/active_shields.svg"
import Itransaction_details from "../../images/transaction_details.svg"
import Ipayment_instructions from "../../images/payment_instructions.svg"
import Ilog_out from "../../images/log_out.svg"
import close from "../../images/close.svg";
import { AddFeedbackMessage } from "../../Apis/AddFeedback";
import AgreementContent from "../../Components/AgreementContent/AgreementContent";
import { usePortfolio } from '../PortfolioContext/PortfolioContext';
import { useSelectedClient } from "../ClientContext/ClientContext";

function Navbar(props) {
  const useStyles = makeStyles((theme) => ({
    button: {
      color: "white",
      marginTop: "2px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    list: {
      width: 300,
      fontColor: "#60034c",
    },
    fullList: {
      width: "auto",
    },
  }));

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return [
        "navbar",
        "navbar-logo",
        "navbar-menu",
        "navbar-noti",
        "navbar-account",
      ];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return [
        "navbar-2",
        "navbar-logo-2",
        "navbar-menu-2",
        "navbar-noti",
        "navbar-account-2",
      ];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return [
        "navbar-3",
        "navbar-logo-3",
        "navbar-menu-3",
        "navbar-noti-3",
        "navbar-account-3",
      ];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return [
        "navbar-3",
        "navbar-logo-3",
        "navbar-menu-3",
        "navbar-noti-3",
        "navbar-account-3",
      ];
    }
  }

  const rendering = getRendering(props.width);
  const history = useHistory();

  const location = useLocation();
  React.useEffect(() => {
  }, [location]);


  const handleLogoClick = () => history.push("/ProductScreen");
  const handleLogoClickAdvisor = () => history.push("/AdvisorDashboard");
  const handlePaymentInstruction = () => history.push("/PaymentInstructions");
  const ActiveShieldsScreen = () => history.push("/ActiveShieldsScreen");
  const Transactiondetails = () => history.push("/TransactionDetailsScreen");
  const DepositOrWithdraw = () => history.push("/DepositOrWithdraw");
  const handleLogOut = () => history.push("/");
  const { clearPortfolioNames } = usePortfolio();
  const [HasPortfolios, setHasPortfolios] = React.useState(
    "/PortfolioSummaryScreen"
  );
  const [bdopen, setBdOpen] = React.useState(false);
  const selectedAcType = sessionStorage.getItem("selectedAcType");
  const [clientSearch, setClientSearch] = React.useState("");
  const [clientData, setClientData] = React.useState(
    JSON.parse(sessionStorage.getItem("clientData"))
  );
  // const [clientData, setClientData] = React.useState();
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [feedbackMessage, setFeedbackMessage] = React.useState("");
  const [sourcePage, setSourcePage] = React.useState(window.location.href);
  const [maxFeedbackLength, setMaxFeedbackLength] = React.useState(500);
  const [currentFeedbackLength, setCurrentFeedbackLength] = React.useState(0);
  const [feedbackError, setFeedbackError] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [download, setDownload] = React.useState(false);
  const [AgreementText, setAgreementText] = React.useState(null);
  const [submitFlag, setSubmitFlag] = React.useState(false);
  const { selectedClientName, setSelectedClientName, clientUserName, setClientUserName, clearSelectedClientName } = useSelectedClient();

  React.useEffect(() => {
    if (currentFeedbackLength === maxFeedbackLength) {
      setFeedbackError("Feedback message has reached maximum length limit of 500 characters,Press submit to send your feedback");
    }
  }, [currentFeedbackLength, maxFeedbackLength]);

  React.useEffect(() => {
    const storedAgreementText = JSON.parse(sessionStorage.getItem('AgreementText'));
    setAgreementText(storedAgreementText);
  }, []);

  const handleFeedbackMessageChange = event => {
    setFeedbackError("");
    setFeedbackMessage(event.target.value.slice(0, maxFeedbackLength));
    setCurrentFeedbackLength(event.target.value.length)
  };

  const validateFeedbackForm = (event) => {
    let validFormFlag = true;
    if (!feedbackMessage) {
      validFormFlag = false;
      setFeedbackError("Please enter a feedback message");
    }
    if (feedbackMessage.length > 500) {
      validFormFlag = false;
      setFeedbackError("Feedback message maximum length limit is 500 characters");
    }
    return validFormFlag;
  };

  function submitFeedbackForm() {
    if (validateFeedbackForm()) {
      setSubmitFlag(true);
      AddFeedbackMessage(feedbackMessage, sourcePage).then((data) => {
        if (data === "success") {
          TagManager.dataLayer({
            dataLayer: {
              event: 'User Feedback',
              userType: sessionStorage.getItem("selectedAcType"),
              user_id: sessionStorage.getItem("clientId")
            },
          });
          datasubmit()
        } else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          return;
        }
      })
    }
  }


  const PortfolioSummary = () => {
    if (window.location.pathname === "/PortfolioSummaryScreen") {
      return history.go("./PortfolioSummaryScreen");
    } else {
      return history.push("./PortfolioSummaryScreen");
    }
  };

  const setClientName = (client, customername) => {
    setBdOpen(true);
    if (customername !== selectedClientName) {
      setClientUserName(client);
      setSelectedClientName(customername);
      UpdateAdvisorSessionID(client).then((data) => {
        if (data !== false && data !== 0 && data !== 401 && data !== 404) {
          PortfolioSummary();
          TagManager.dataLayer({
            dataLayer: {
              'event': 'Advisor Selected a Client',
              'userType': sessionStorage.getItem("selectedAcType"),
              'user_id': sessionStorage.getItem("clientId")
            },
          });
        } else if (data === 0) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
          setAlertType("warning");
          setPopupLoginRedirect(false);
          return;
        }
        else if (data === false) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage("We are not able to fetch your details. Please try again later");
          setAlertType("warning");
          setPopupLoginRedirect(false);
          return;
        }
        else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          return;
        } else if (data === 40) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          return;
        }
      });
    }

  };

  const classes = useStyles();
  const [RightDrawer, setRightDrawer] = React.useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setRightDrawer({ ...RightDrawer, [anchor]: open });
  };

  React.useEffect(() => {
    if (clientData === null && selectedAcType === "ADVISOR") {
      try {
        FetchSessionDetails().then((data) => {
          if (data !== false && data !== 0 && data !== 401 && data !== 404) {
            console.log(" infooo", data)
            if (data["Item"] && data["Item"]["info"]) {
              if (data["Item"]["info"]["client_details"]) {
                var lyst = [];
                for (let [key, value] of Object.entries(data["Item"]["info"]["client_details"])) {
                  lyst.push({
                    clientid: key,
                    externalclientid: value.externalid,
                    clientname: value.name,
                    noofportfolio: Number(value.total_portfolio),
                  });
                }
                setClientData([...lyst]);
                if (data["Item"]["info"]["user_name"] !== undefined && data["Item"]["info"]["user_name"] !== "") {
                  setClientUserName(data["Item"]["info"]["user_name"]);
                  var user_name = data["Item"]["info"]["user_name"]
                  setSelectedClientName(data["Item"]["info"]["client_details"][user_name]["name"]);
                }
              }
            }
          } else if (data === 0) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage("Server issue. Please try again or contact support@adaptive-investments.com.");
            setAlertType("warning");
            setPopupLoginRedirect(false);
            return;
          }
          else if (data === false) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage("We are not able to fetch your details. Please try again later");
            setAlertType("warning");
            setPopupLoginRedirect(false);
            return;
          }
          else if (data === 401) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session timed out for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            return;
          } else if (data === 404) {
            setBdOpen(false);
            setPopupState(true);
            setPopupMessage(
              "Your session was terminated for security reasons. Please sign-in again."
            );
            setAlertType("error");
            setPopupLoginRedirect(true);
            return;
          }
        });
      } catch (error) {
        setBdOpen(false);
        setPopupState(true);
        setPopupLoginRedirect(false);
        setPopupMessage(
          "We are facing issues connecting our servers, please try again later"
        );
        setAlertType("error");
        return;
      }
    }
  }, []);

  const returnMainMenuClassname = (currentPath) => {
    if (history.location.pathname === currentPath) {
      return "ITEM-selected";
    } else {
      return "ITEM";
    }
  };

  const returnSubMenuClassname = (currentPath) => {
    if (history.location.pathname === currentPath) {
      return "SUBITEM-selected";
    } else {
      return "SUBITEM";
    }
  };

  const MenuItems = [
    {
      id: 1,
      title: "Products",
      url: "/ProductScreen",
      img: "Assets/products.png",
    },
    {
      id: 2,
      title: "My Portfolios",
      url: HasPortfolios,
      img: "Assets/porfolios.png",
    },
    {
      id: 3,
      title: "Adaptive Genius",
      url: "/AdaptiveGenius",
      img: "Assets/genius.png",
    },
    {
      id: 4,
      title: "Adaptive University",
      url: "/ComingSoon",
      img: "Assets/university.png",
    },
  ];

  const AdvisorMenuItems = [
    {
      title: "Dashboard",
      url: "/AdvisorDashboard",
    },
    {
      title: "Client Admin",
      url: "/ClientList",
    },
  ];

  const AdvisorSubMenuItems = [
    {
      title: "Market Risk",
      url: "/AdvisorDashboard",
    },
    {
      title: "Stock Risk",
      url: "/AdvisorDashboardStockTab",
    },
    {
      title: "Client Portfolio Risk",
      url: "/ClientPortfolio",
    },
    {
      title: "Import Status",
      url: "/AdvisorClientChanges",
    },
  ];

  const AdvisorCsvStatusItems = [
    {
      title: "Client Changes Detected",
      url: "/AdvisorDashboard",
    },
    {
      title: "Account Changes Detected",
      url: "/AdvisorDashboardStockTab",
    },
    {
      title: "Portfolio Changes Detected",
      url: "/ClientPortfolio",
    },
  ];

  /*  Logout for Investor and Advisor*/
  function logout() {
    if (selectedAcType === "INVESTOR") {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.signOut();
        window.sessionStorage.clear();
        window.localStorage.clear();
      }
    } else {
      const user = AdvisorPool.getCurrentUser();
      if (user) {
        user.signOut();
        window.sessionStorage.clear();
      }
    }
    TagManager.dataLayer({
      dataLayer: {
        'event': 'User LogOut',
        'userType': sessionStorage.getItem("selectedAcType"),
        'user_id': sessionStorage.getItem("clientId")
      },
    });
  }

  const logUserOut = async () => {
    clearPortfolioNames();
    clearSelectedClientName()
    logout();
    handleLogOut();
  }

  // const pdf_data = {
  //   viewOnly: false,
  //   AgreementText: AgreementText
  // };

  const handleDownloadAgreement = () => {
    setDownload(true);
  }

  const handleAfterDownloadAgreement = () => {
    setDownload(false);
  }

  const options = []
  if ((selectedAcType === "INVESTOR") || ((selectedAcType === "ADVISOR") && (clientUserName !== ""))) {
    options.push(
      {
        text: "Account Details",
        icon: Iaccount_details,
        Onclick: "#",
      },
      {
        text: "Active Shields",
        icon: Iactive_shields,
        Onclick: ActiveShieldsScreen,
      },
      {
        text: "Deposit / Withdraw Funds",
        icon: Itransaction_details,
        Onclick: DepositOrWithdraw,
      },
      {
        text: "Transaction Details",
        icon: Itransaction_details,
        Onclick: Transactiondetails,
      },
      {
        text: "Payment Instructions",
        icon: Ipayment_instructions,
        Onclick: handlePaymentInstruction,
        url: "/PaymentInstructions"
      },
      {
        text: "Adaptive Agreement",
        icon: Iactive_shields,
        Onclick: handleDownloadAgreement,
        // url: "/PaymentInstructions"
      },
      {
        text: "Log Out",
        icon: Ilog_out,
        Onclick: logUserOut,
      },
    )
  } else if ((selectedAcType === "ADVISOR") && (clientUserName === "")) {
    options.push(
      {
        text: "Account Details",
        icon: Iaccount_details,
      },
      {
        text: "Adaptive Agreement",
        icon: Iactive_shields,
        Onclick: handleDownloadAgreement,
        // url: "/PaymentInstructions"
      },
      {
        text: "Log Out",
        icon: Ilog_out,
        Onclick: logUserOut,
      },
    )
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {options.map((option, index) => (
          <ListItem button key={option.text} onClick={option.Onclick}>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
  function opennav() {
    var element = document.getElementById("navigation");
    element.classList.toggle("nav-open");
  }

  function clientMenubar() {
    if (
      selectedClientName !== null &&
      selectedClientName !== "" &&
      selectedClientName !== "null"
    ) {
      return (
        <ul>
          {MenuItems.map((item) => {
            return (
              <li key={item.id}>
                <Link
                  key={item.title}
                  className={returnMainMenuClassname(item.url)}
                  to={item.url}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            <a>Please Select a Client to see more options</a>
          </li>
        </ul>
      );
    }
  }

  function clientList() {
    if (clientData !== null) {
      return (
        <ul class="user_list">
          {clientData
            .filter((val) => {
              if (clientSearch === "") {
                return val;
              } else if (
                val.clientname
                  .toLowerCase()
                  .includes(clientSearch.toLowerCase())
              ) {
                return val;
              }
            })
            .map((row) => (
              <li onClick={() => setClientName(row.clientid, row.clientname)}>
                <a>
                  <span className="user_name">{row.clientname}</span>
                  <span className="user_clientid">#{row.clientid}</span>
                </a>
              </li>
            ))}
        </ul>
      );
    }
  }

  function showAdvisorSubMenu() {
    if (
      selectedAcType === "ADVISOR" &&
      [
        "/AdvisorDashboard",
        "/AdvisorDashboardStockTab",
        "/ClientPortfolio",
        "/AdvisorClientChanges",
        "/AdvisorAccountChanges",
        "/AdvisorLineItemChanges",
        "/AdvisorErrorsScreen",
      ].indexOf(window.location.pathname) >= 0 && clientData !== null && typeof (clientData) == "object"
    ) {
      return (
        <nav className="subnavbar" id="navigation">
          <ul className="subnavbar-menu navtab">
            {AdvisorSubMenuItems.map((item) => {
              return (
                <li key={item.id}>
                  <a
                    key={item.title}
                    className={returnSubMenuClassname(item.url)}
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      );
    }
  }

  function showAdvisorCsvStatusMenu() {
    if (
      selectedAcType === "ADVISOR" &&
      ["/AdvisorClientChanges", "/AdvisorAccountChanges"].indexOf(
        window.location.pathname
      ) >= 0
    ) {
      return (
        <nav className="csvnavbar" id="navigation">
          <ul className="csvnavbar-menu">
            {AdvisorCsvStatusItems.map((item) => {
              return (
                <li key={item.id}>
                  <Link
                    key={item.title}
                    className={returnSubMenuClassname(item.url)}
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      );
    }
  }

  function getSelectedClient() {
    if (
      selectedClientName !== null &&
      selectedClientName !== "" &&
      selectedClientName !== "null"
    ) {
      return selectedClientName;
    } else {
      return "Select Client";
    }
  }
  function opennav() {
    var element = document.getElementById("navigation");
    var element2 = document.getElementById("nav-toggle");
    element.classList.toggle("nav-open");
    element2.classList.toggle("nav-open");
  }
  function openfeed() {
    var element = document.getElementById("feedbackcover");
    element.classList.toggle("open");
  }
  function datasubmit() {
    setSubmitFlag(false);
    var element = document.getElementById("feedform");
    var element2 = document.getElementById("submmision");
    element.classList.toggle("closediv");
    element2.classList.toggle("active");
  }
  return (
    <div className="nav-wrap">
      <PageLoader bdopen={bdopen} />
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={alertType}
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <a className="nav-toggle" href="javascript:;" onClick={() => opennav()}>
        <span class=""></span>
        <span></span>
        <span></span>
      </a>
      <nav className={rendering[0]} id="navigation">
        <div className={rendering[1]}>
          <a
            onClick={() =>
              selectedAcType === "ADVISOR"
                ? handleLogoClickAdvisor()
                : handleLogoClick()
            }
          >
            <img className="logo-nav" src="Assets/adaptive_logo.png"></img>
          </a>
        </div>
        {selectedAcType === "INVESTOR" ? (
          <div className="main_navbar">
            <ul className={rendering[2]}>
              {MenuItems.map((item) => {
                return (
                  <li key={item.id}>
                    {/* <img className="symbol" src={item.img} /> */}
                    <Link
                      key={item.title}
                      className={returnMainMenuClassname(item.url)}
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="main_navbar">
            <ul className={rendering[2]}>
              {AdvisorMenuItems.map((item) => {
                return (
                  <li key={item.id}>
                    <Link
                      key={item.title}
                      className={returnMainMenuClassname(item.url)}
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
            {typeof clientData === "object" && clientData !== null ? (
              <div className="user-selector">
                <div className="user">
                  <div className="selecteduser">
                    <img src="Assets/user_profile.svg"></img>
                    <span>{getSelectedClient()}</span>
                    <img src="Assets/arrow_down.svg"></img>
                  </div>
                  <div className="user_dropdone">
                    <div className="search_input">
                      <img src="Assets/search.svg"></img>
                      <input
                        className="user_input"
                        placeholder="Search user"
                        onChange={(event) => {
                          setClientSearch(event.target.value);
                        }}
                      ></input>
                    </div>
                    {clientList()}
                  </div>
                </div>
                {clientMenubar()}
              </div>
            ) : (
              <div className="user-selector">
                <div className="user">
                  <div className="selecteduser">
                    <img src="Assets/user_profile.svg"></img>
                    <span>{getSelectedClient()}</span>
                    <img src="Assets/arrow_down.svg"></img>
                  </div>
                  <div className="user_dropdone">
                    <div className="search_input">
                      <img src="Assets/search.svg"></img>
                      <input
                        className="user_input"
                        placeholder="Search user"
                        onChange={(event) => {
                          setClientSearch(event.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
                {clientMenubar()}
              </div>
            )}
          </div>
        )}
        {selectedAcType === "INVESTOR" ? (
          <div class="userarea">
            <div className={rendering[3]}>
              <a className="noti-icon-wrapper">
                <img src="assets/notification.svg"></img>
                <span className="noti-count">0</span>
              </a>
            </div>
            <div className="usercover">
              <a className="feedback" onClick={() => openfeed()} >
                <img className="account-nav" src="Assets/feedback.svg"></img>
                <span>Feedback</span>
              </a>
              <div className="feedbackcover" id="feedbackcover">
                <div className="header"><h3>Give us some feedback!</h3><a onClick={() => openfeed()}><img src={close}></img></a></div>
                <form className="feedform" id="feedform">
                  <textarea placeholder="Write your feedback here..." maxLength={500} onChange={handleFeedbackMessageChange}></textarea>
                  <div className="error-message">
                    <label>[{currentFeedbackLength}/{maxFeedbackLength}]</label>
                    <p className="invalidMessage">{feedbackError}</p>
                  </div>
                  <a class="ai-btn primary solid" onClick={() => submitFeedbackForm()} disabled={submitFlag}>{submitFlag ? 'Sending Feedback' : 'Submit'}</a>
                </form>
                <div className="submmision" id="submmision">
                  <h3>Thank you for your feedback!</h3>
                  <a class="ai-btn primary solid" onClick={() => openfeed()}>Close</a>
                </div>
              </div>
            </div>
            <div className={rendering[4]}>
              <a>
                <img className="account-nav" src="Assets/avatar.png"></img>
              </a>
              <ul className="userprofile">
                {options.map((option, index) => (
                  <li>
                    <a key={option.text} onClick={option.Onclick}>
                      <img src={option.icon} />
                      <span>{option.text}</span>
                    </a>
                  </li>
                ))}
                {download && <AgreementContent viewOnly={false} AgreementText={AgreementText} download={download} handleAfterDownloadAgreement={handleAfterDownloadAgreement} />}
              </ul>
            </div>
          </div>
        ) : (
          <div class="userarea advisor">
            <div className={rendering[3]}>
              <a className="noti-icon-wrapper">
                <img src="assets/notification.svg"></img>
                <span className="noti-count">0</span>
              </a>
            </div>
            <div className="usercover">
              <a className="feedback" onClick={() => openfeed()} >
                <img className="account-nav" src="Assets/feedback.svg"></img>
                <span>Feedback</span>
              </a>
              <div className="feedbackcover" id="feedbackcover">
                <div className="header"><h3>Give us some feedback!</h3><a onClick={() => openfeed()}><img src={close}></img></a></div>
                <form className="feedform" id="feedform">
                  <textarea placeholder="Write your feedback here..." maxLength={500} onChange={handleFeedbackMessageChange}></textarea>
                  <div className="error-message">
                    <label>[{currentFeedbackLength}/{maxFeedbackLength}]</label>
                    <p className="invalidMessage">{feedbackError}</p>
                  </div>
                  <a class="ai-btn primary solid" onClick={() => submitFeedbackForm()} disabled={submitFlag}>{submitFlag ? 'Sending Feedback' : 'Submit'}</a>
                </form>
                <div className="submmision" id="submmision">
                  <h3>Thank you for your feedback!</h3>
                  <a class="ai-btn primary solid" onClick={() => openfeed()}>Close</a>
                </div>
              </div>
            </div>
            <div className={rendering[4]}>
              <a>
                <img className="account-nav" src="Assets/avatar.png"></img>
              </a>
              <ul className="userprofile">
                {options.map((option, index) => (
                  <li>
                    <a key={option.text} onClick={option.Onclick}>
                      <img src={option.icon} />
                      <span>{option.text}</span>
                    </a>
                  </li>
                ))}
                {download && <AgreementContent viewOnly={false} AgreementText={AgreementText} download={download} handleAfterDownloadAgreement={handleAfterDownloadAgreement} />}
              </ul>
            </div>
          </div>
        )}
      </nav>
      {showAdvisorSubMenu()}
    </div>
  );
}
export default withWidth()(Navbar);
