import React, { createContext, useState, useContext } from 'react';

export const PortfolioContext = createContext();

export const PortfolioProvider = ({ children }) => {
    const [portfolioNames, setPortfolioNames] = useState([]);

    const clearPortfolioNames = () => {
        setPortfolioNames([]);
    };

    return (
        <PortfolioContext.Provider value={{ portfolioNames, setPortfolioNames, clearPortfolioNames }}>
            {children}
        </PortfolioContext.Provider>
    );
};

export const usePortfolio = () => {
    return useContext(PortfolioContext);
};
