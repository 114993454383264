import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function AddExternalAccount(account_type, account_nick_name = "", name_on_account, routing_no, account_no, routing_number_type) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    // const session_id = sessionStorage.getItem("sessionID");

    var raw = JSON.stringify({
        session_id: Token,
        account_type: account_type,
        account_nick_name: account_nick_name,
        account_number: account_no,
        routing_number: routing_no,
        name_on_account: name_on_account,
        routing_number_type: routing_number_type
    });

    console.log(" raw", raw);
    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: header,
    };
    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetch(
                process.env.REACT_APP_ADD_EXTERNAL_ACCOUNT_API,
                requestOptions
            );
            if (!response.ok) {
                console.log("Could not create External Account");
                return false;
            } else {
                const message = await response.json();
                if (message["result"][0] !== "") {
                    return message["result"];
                } else if (message["error"] === "Token Verification Failed") {
                    return 404;
                }
                else {
                    console.log(message["error"]);
                    return false;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                window.location.reload();
            }
            else {
                return 0
            }
        }
    }
    else {
        return 401
    }
}
