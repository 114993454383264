import React from "react";
import Navbar from "../../Components/Navbar/Navbar_official"
import PageHeader from "../../Components/PageHeader/PageHeader";
import PaymentInstructions from "../../Components/PaymentInstructions/PaymentInstructions";

function PaymentInstructionsScreen(props) {
    return (
        <div className="ai-content-part">
            <Navbar></Navbar>
            <PageHeader heading="Payment Instructions" backButton={true} addPaymentAccount={true} buttonText='Add new Portfolio' />
            <div className="portfolio_page-holder">
                <PaymentInstructions />
            </div>
        </div>
    )
}

export default (PaymentInstructionsScreen);