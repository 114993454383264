import * as AWS from "aws-sdk/global";

export async function Establish_user_session(result, accountType) {
  AWS.config.region = "us-east-1";

  const arn =
    "cognito-idp.us-east-1.amazonaws.com/" +
    process.env.REACT_APP_COGNITO_USER_POOL_ID;

    console.log(process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID);

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    Logins: {
      [arn]: result.getIdToken().getJwtToken(),
    },
  });
  AWS.config.credentials.clearCachedId()
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    Logins: {
      [arn]: result.getIdToken().getJwtToken(),
    },
  });
  
  AWS.config.credentials.refresh((error) => {
    if (error) {
      //alert(error);
      console.error(error);
      return false;
    } else {
      console.log("Successfully logged!");
      return true;
    }
  });
}
