import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SelfAssemblyTable from "../SelfAssemblyTable/SelfAssemblyTable";
import Slider from "react-slick";
import "./SelfAssembly.css";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "../Popup/Popup";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetUserPortfolios } from "../../Apis/GetUserPortfolios";
import { GetSelfAssemblyData } from "../../Apis/GetSelfAssemblyData";
import TagManager from "react-gtm-module";
import { findClosest } from "../Utilities/ClosestPercent";
import { addZeroes } from "../Utilities/AddZeros";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PageLoader from "../PageLoader/PageLoader";

export default function SelfAssemblyWindow({ showpopup }) {
  const useStyles = makeStyles((theme) => ({
    del_button: {
      color: "#60034c",
    },
    edit_button: {
      color: "#60034c",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [DataFlag, setDataFlag] = React.useState(true);
  const [TableFlag, setTableFlag] = React.useState(false);
  const [portfolioName, setPortfolioName] = React.useState([]);
  const [Level, setLevel] = React.useState("");
  const [periodSelected, setPeriodSelected] = React.useState("");
  const [PortfolioID, setPortfolioID] = React.useState(0);
  const levels = [
    { percent: 0, level: "Select" },
    { percent: 80, level: "80%" },
    { percent: 85, level: "85%" },
    { percent: 90, level: "90%" },
    { percent: 95, level: "95%" },
    { percent: 100, level: "100%" },
  ];

  const durations = [
    { period: 0, duration: "Select" },
    { period: 7, duration: "Weekly" },
    { period: 15, duration: "2 Weeks" },
    { period: 21, duration: "3 Weeks" },
    { period: 30, duration: "1 Month" },
    { period: 60, duration: "2 Months" },
    { period: 91, duration: "3 Months" },
    { period: 182, duration: "6 Months" },
    { period: 365, duration: "Year" },
  ];

  const [rows, setRows] = React.useState([]);
  const [rows2, setRows2] = React.useState([]);
  const [gauranteeAssemblyTotal, setGauranteeAssemblyTotal] = React.useState(0);
  const [approximateAssemblyTotal, setApproximateAssemblyTotal] =
    React.useState(0);
  const [optionDesc, setOptionDesc] = React.useState([]);
  const [askPrice, setAskPrice] = React.useState([]);
  const [quantity, setQuantity] = React.useState([]);
  const [inlineTotal, setInlineTotal] = React.useState([]);
  const [closestLevel, setClosestLevel] = React.useState(0);
  const [PortfolioTotalStocks, setPortfolioTotalStocks] = React.useState(0);
  const [PortfolioTotalValue, setPortfolioTotalValue] = React.useState(0.0);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

  if (Flag === false) {
    if (bdopen === false) {
      setBdOpen(true);
    }
    GetUserPortfolios().then((data) => {
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        var portfoliolyst = [{ id: 0, name: "Select" }];
        for (let [key, value] of Object.entries(data)) {
          setGotData(true);
          portfoliolyst.push({
            id: key,
            name: value.portfolioname,
            no_stocks: value.nooflineitems,
            port_value: parseFloat(value.totalportfoliovalue).toFixed(2),
          });
        }
        console.log("lyst", portfoliolyst.name);
        setPortfolioName(portfoliolyst);
      }
      else if (data === false) {
        setGotData(true)
        setPopupState(true)
        setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
        setPopupLoginRedirect(false);
        setAlertType("error")
        setBdOpen(false);
      }
      else if (data === 0) {
        setBdOpen(false);
        setGotData(true)
        setPopupState(true)
        setPopupMessage("Unable to connect to our servers at this point, please try again later")
        setPopupLoginRedirect(false);
        setAlertType("error")
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setFlag(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }


    });
    if (GotData === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  var new_array = [];
  var val = 0;
  var totalQty = 0;
  var totalValue = 0;
  var totalAprxQty = 0;
  var totalAprxValue = 0;
  const fisrt_inline_lyst = [];
  const second_inline_lyst = [];

  //function to get the required price from the data.
  async function fetch_price(period, percent) {
    if (Flag === true) {
      setBdOpen(true);
      GetSelfAssemblyData(PortfolioID).then((data) => {
        if (data !== false && data !== 0 && data !== 401) {
          setTableFlag(true);
          if (period === "7") {
            var tp = data["week"];
          } else if (period === "15") {
            tp = data["week_2"];
          } else if (period === "21") {
            tp = data["week_3"];
          } else if (period === "30") {
            tp = data["month"];
          } else if (period === "60") {
            tp = data["month_2"];
          } else if (period === "91") {
            tp = data["quarter"];
          } else if (period === "182") {
            tp = data["half_year"];
          } else if (period === "365") {
            tp = data["year"];
          }

          percent = findClosest(tp, percent);
          setClosestLevel(percent);
          percent = addZeroes(Number((100 - percent).toFixed(2).toString()));
          // Gauranteed Table
          try {
            var first_lyst = [];
            setInlineTotal([]);
            for (var i = 0; i < tp[percent]["optiondesc"].length; i++) {
              if (tp[percent]["oqty_diy"][i] > 0) {
                first_lyst.push([
                  i + 1,
                  tp[percent]["optiondesc"][i],
                  tp[percent]["oqty_diy"][i].toFixed(2),
                  Number(
                    addZeroes(Number(parseFloat(tp[percent]["ask"][i]).toFixed(2)))
                  ).toLocaleString("en-US", currObj),
                  Number(
                    addZeroes(Number(parseFloat(
                      tp[percent]["ask"][i] *
                      tp[percent]["oqty_diy"][i] *
                      100
                    ).toFixed(2)))
                  ).toLocaleString("en-US", currObj),
                ]);
                totalValue =
                  totalValue +
                  tp[percent]["ask"][i] * tp[percent]["oqty_diy"][i] * 100;
                totalQty = totalQty + tp[percent]["oqty_diy"][i].toFixed(2);
              }
              fisrt_inline_lyst.push(
                (
                  tp[percent]["ask"][i] *
                  tp[percent]["oqty_diy"][i] *
                  100
                ).toFixed(2)
              );
            }
          } catch (e) {
            console.log(e.message);
          }
          setRows([...first_lyst]);
          setInlineTotal(fisrt_inline_lyst);
          setGauranteeAssemblyTotal(totalValue);
          setOptionDesc(tp[percent]["optiondesc"]);
          setAskPrice(tp[percent]["ask"]);
          setQuantity(tp[percent]["oqty_diy"]);
          console.log(" ROWS", rows);
          // Approximate Table
          try {
            var second_lyst = [];
            var j = 0;
            for (var i = 0; i < tp[percent]["optiondesc"].length; i++) {
              if (tp[percent]["optiondesc"][i].startsWith("XSP")) {
                second_lyst.push([
                  j + 1,
                  tp[percent]["optiondesc"][i],
                  tp[percent]["oqty_s"][i].toFixed(2),
                  Number(
                    addZeroes(Number(parseFloat(tp[percent]["ask"][i]).toFixed(2)))
                  ).toLocaleString("en-US", currObj),
                  Number(
                    addZeroes(Number(parseFloat(
                      tp[percent]["ask"][i] *
                      tp[percent]["oqty_s"][i] *
                      100
                    ).toFixed(2)))).toLocaleString("en-US", currObj),
                ]);
                totalAprxValue =
                  totalAprxValue +
                  tp[percent]["ask"][i] * tp[percent]["oqty_s"][i] * 100;
                totalAprxQty = totalAprxQty + tp[percent]["oqty_s"][i].toFixed(2);
                console.log(" oqty_s ", tp[percent]["oqty_s"][i]);
              }
              second_inline_lyst.push(
                (tp[percent]["ask"][i] * tp[percent]["oqty_s"][i] * 100).toFixed(
                  2
                )
              );
            }
            setRows2([...second_lyst]);
            setInlineTotal(second_inline_lyst);
            setApproximateAssemblyTotal(totalAprxValue);
            setOptionDesc(tp[percent]["optiondesc"]);
            setAskPrice(tp[percent]["ask"]);
            setQuantity(tp[percent]["oqty_s"]);
          } catch (e) {
            console.log(e.message);
          }
          setBdOpen(false);
        }
        else if (data === false) {
          setBdOpen(false);
          setTableFlag(true)
          setPopupState(true);
          setPopupMessage("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
          setPopupLoginRedirect(false);
          setAlertType("error")
        }
        else if (data === 0) {
          setBdOpen(false);
          setTableFlag(true)
          setPopupState(true);
          setPopupMessage("Unable to connect to our servers, please try again later.");
          setPopupLoginRedirect(false);
          setAlertType("error")
        }
        else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        }
      });
    }
  }

  const changePortfolio = (portfolio) => {
    console.log("portfolio", portfolio);
    if (portfolio === 0 || portfolio === "0") {
      // alert('Please select valid portfolio');
      setPopupState(true);
      setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      setPortfolioID("");
      setDataFlag(true);
      return;
    }
    for (let i in portfolioName) {
      if (portfolio === portfolioName[i]["id"]) {
        console.log("TRUE", portfolioName[i]);
        setPortfolioTotalStocks(portfolioName[i]["no_stocks"]);
        setPortfolioTotalValue(portfolioName[i]["port_value"]);
      }
    }
    setPortfolioID(portfolio);
  };

  const changeLevel = (percent) => {
    if (percent === 0 || percent === "0") {
      // alert('Please select valid percent');
      setPopupState(true);
      setPopupMessage("Protection level not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      setLevel("");
      setDataFlag(true);
      return;
    }
    setLevel(percent);
  };

  const changeDuration = (period) => {
    if (period === 0 || period === "0") {
      // alert('Please select valid duration');
      setPopupState(true);
      setPopupMessage("Protection period not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      setPeriodSelected("");
      setDataFlag(true);
      return;
    }
    setPeriodSelected(period);
  };

  if (DataFlag === true) {
    console.log("flag ", periodSelected, Level, PortfolioID);
    if (Level !== "" && periodSelected !== "" && PortfolioID !== "") {
      setDataFlag(false);
    }
  }
  const calculate = () => {
    console.log("calculate", periodSelected, Level, PortfolioID);
    fetch_price(periodSelected, Level);
    setPopupState(false);
    setButtonText("Re-Calculate");
    setToggleClass("ai-btn secondary solid");
    TagManager.dataLayer({
      dataLayer: {
        event: 'Self Assembly',
        userType: sessionStorage.getItem("selectedAcType"),
        user_id: sessionStorage.getItem("clientId")
      },
    });

  };

  const [ToggleClass, setToggleClass] = React.useState("ai-btn primary solid");
  const [ButtonText, setButtonText] = React.useState("Calculate");

  return (
    <div className="selfassembly-page">
      <PageLoader bdopen={bdopen} />
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Shield Protection Calculator"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <div className="details_wrap self_assembly">
        <div className="infocard-wrap">
          <div className="infocard">
            <h3>Select Portfolio</h3>
            <select id="selectportfolio"
              className="ai-form-value-bt"
              onChange={(event) => changePortfolio(event.target.value)}
            >
              {portfolioName.map((value, i) => (
                <option value={value.id}>{value.name}</option>
              ))}
            </select>
            <span className="ai-selfassembly-select-arrow"></span>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>Duration</h3>
            <select id="selectprotectionperiod"
              className="ai-form-value-bt"
              onChange={(event) => changeDuration(event.target.value)}
            >
              {durations.map((value, i) => (
                <option value={value.period}>{value.duration}</option>
              ))}
            </select>
            <span className="ai-selfassembly-select-arrow"></span>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>Protection</h3>
            <select id="selectprotectionlevel"
              className="ai-form-value-bt"
              onChange={(event) => changeLevel(event.target.value)}
            >
              {levels.map((value, i) => (
                <option value={value.percent}>{value.level}</option>
              ))}
            </select>
            <span className="ai-selfassembly-select-arrow"></span>
          </div>
          <div class="saprator"></div>
          {/* <Col xs={12} sm={12} md={12} lg={3}> */}

          <div className="infocard">
            <h3>Stocks</h3>
            <p>{PortfolioTotalStocks}</p>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>Value</h3>
            <p>
              USD {Number(PortfolioTotalValue).toLocaleString("en-US")}
            </p>
          </div>
          {/* </Col> */}
          {/* <div className = "vl"></div> */}
        </div>
        <button id="calculate"
          className={ToggleClass}
          onClick={calculate}
          disabled={DataFlag}
        >
          {ButtonText}
        </button>
      </div>
      <div className="ai-selfassembly-container">
        <div className="ai-guaranteed-results">
          {!TableFlag && (
            <div className="ai-static-content">
              <p className="ai-header">
                Portfolio Protection
              </p>
              <p className="ai-helpText">
                Please select portfolio and protection criteria and press
                calculate.
              </p>
            </div>
          )}
          {TableFlag && (
            <div id="guaranteed-table" className="ai-guaranteed-table">
              <div className="ai-card-header">
                <h4>Portfolio Protection</h4>
                <p>Put contracts for individual portfolio holdings, <br></br>with approximated strike price & expiration. <a className="ai-link-secondary" href="#faqsec" >Learn more.</a></p>
              </div>
              <SelfAssemblyTable
                data={rows}
                total={Number(gauranteeAssemblyTotal).toLocaleString("en-US")}
                showPopup={popupState}
              />
            </div>
          )}
        </div>
        <div className="ai-affordable-results">
          {!TableFlag && (
            <div className="ai-static-content">
              <p className="ai-header">
                Market Protection
              </p>
              <p className="ai-helpText">
                Please select portfolio and protection criteria and press
                calculate.
              </p>
            </div>
          )}
          {TableFlag && (
            <div id="affordable-table" className="ai-affordable-table">
              <div className="ai-card-header">
                <h4>Market Protection</h4>
                <p><a className="ai-link-secondary" target="_blank" href="https://www.cboe.com/tradable_products/sp_500/mini_spx_options">Mini-SPX Put Options</a> on the S&P 500 market index, <br></br>with approximated strike price & expiration. <a className="ai-link-secondary" href="#faqsec" >Learn more.</a></p>
              </div>
              <SelfAssemblyTable
                data={rows2}
                total={Number(approximateAssemblyTotal).toLocaleString("en-US")}
                showPopup={popupState}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
