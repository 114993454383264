import React, { useState } from 'react';
import { AgreementTextContext } from '../../context';

export const AgreementTextProvider = ({ children }) => {
    const [AgreementText, setAgreementText] = useState(null);

    return (
        <AgreementTextContext.Provider value={{ AgreementText, setAgreementText }}>
            {children}
        </AgreementTextContext.Provider>
    );
};
