import {
  CognitoUser,
} from "amazon-cognito-identity-js";
import "./OTPConfirmationScreen.css";
import UserPool from "../../UserPool";
import React from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AiFormError from "../../Components/AiFormError/ai-form-error";
import MainSlider from "../../Components/MainSlider/MainSlider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import AdvisorPool from "../../AdvisorPool";
import PageLoader from "../../Components/PageLoader/PageLoader";
import Popup from "../../Components/Popup/Popup";

function OTPConfirmation() {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const [bdopen, setBdOpen] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const goToSignIn = () => history.push("/");
  const [invalidOTP, setInvalidOTP] = React.useState("");
  const [otp, setOtp] = React.useState(new Array(6).fill(""));
  const userName = location.state.userName;
  const userEmail = location.state.userEmail;
  const [popupState,setPopupState] = React.useState(false);

  const classes = useStyles();
  const accountType = sessionStorage.getItem("selectedAcType");

  const handlePopup = () => {
    setPopupState(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (accountType === "INVESTOR") {
      var userData = {
        Username: userName,
        Pool: UserPool,
      };
      var cognitoUser = new CognitoUser(userData);
      var otpValue = otp.join("");
      if (otpValue === "") {
        setInvalidOTP("Please enter your OTP");
      } else {
        setBdOpen(true);
        cognitoUser.confirmRegistration(otpValue, true, function (err, result) {
          if (err) {
            setInvalidOTP(err.message || JSON.stringify(err));
            setBdOpen(false);
            return;
          }
          setPopupState(true);
        });
      }
    } else if (accountType === "ADVISOR") {
      var userData = {
        Username: userName,
        Pool: AdvisorPool,
      };
            var cognitoUser = new CognitoUser(userData);
            var otpValue = otp.join("");
      if (otpValue === "") {
        setInvalidOTP("Please enter your OTP");
      } else {
        setBdOpen(true);
        cognitoUser.confirmRegistration(otpValue, true, function (err, result) {
          if (err) {
            setInvalidOTP(err.message || JSON.stringify(err));
            setBdOpen(false);
            return;
          }
          setPopupState(true);
        });
      }
    }
  };

  const handleOTPChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <section className="ai-container ai-bording-page">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={handlePopup && goToSignIn}
        title="OTP Verified Successfully"
        content="OTP verified successfully. Try login"
        alertType="success"
      />
      <Container fluid>
        <Row className="ai-full-height">
          <Col className="ai-slide-wrapper" lg={4}>
            <header>
              <div className="ai-logo">
                <Link to="/">
                  <img
                    src={process.env.PUBLIC_URL + "/ai-logo.png"}
                    alt="Adaptive Investment Logo"
                  />
                </Link>
              </div>
            </header>
            <section className="ai-slide-content">
              <MainSlider />
            </section>
          </Col>
          <Col className="ai-login-wrapper ai-otp-group" xs={12} sm={12} md={12} lg={8}>
            <Row className="flex1">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="ai-login-container">
                  <div className="ai-signup-wrapper">
                    <div className="ai-login-form">
                      <div className="ai-login-section">
                        <div className="ai-field-group">
                          <h2>Sign Up</h2>
                        </div>
                        <form name="submitOTPForm" onSubmit={onSubmit}>
                          <div className="ai-field-group">
                            <div className="ai-row">
                              <div className="ai-column">
                                <div className="ai-otp-screen">
                                  {location.state.userEmail !== "" ? (
                                    <p>
                                      Please enter a OTP we sent on {userEmail}{" "}
                                    </p>
                                  ): (
                                    <p>
                                      Please enter a OTP we sent on your registered email id {location.state.userEmail}{" "}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="ai-row ai-align-center">
                              <div className="ai-column">
                                <div className="otp-field">
                                  {otp.map((data, index) => {
                                    return (
                                      <input
                                        className="otp-form-value ai-form-value"
                                        type="text"
                                        name="otp"
                                        maxLength="1"
                                        key={index}
                                        value={data}
                                        onChange={(e) =>
                                          handleOTPChange(e.target, index)
                                        }
                                        onFocus={(e) => e.target.select()}
                                      />
                                    );
                                  })}
                                </div>
                                <AiFormError errormessage={invalidOTP} />
                              </div>
                            </div>
                            <div className="ai-row" />
                          </div>
                          <div className="ai-field-group">
                            <div className="ai-row v-middle">
                              <div className="ai-column">
                                <div className="ai-signin">
                                  <p>
                                    Back to{" "}
                                    <Link to="/" className="ai-link">
                                      Sign In
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <button className="ai-btn primary round">
                                <ArrowForwardIcon />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <footer>
                  <div className="ia-footer">
                    <p>
                      Adaptive funds are not held in a brokerage account and are
                      not protected by SIPC. Brokerage assets are held by
                      Adaptive Investments Services, a division of Adaptive
                      Investments LLC.
                    </p>
                    <ul className="ia-footer-links">
                      <li>
                        <Link to="/" className="ia-link">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="ia-link">
                          Terms &#38; Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </footer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default OTPConfirmation;
