import React from "react";
import "./ForwardTest.scss";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetUserPortfolios } from "../../Apis/GetUserPortfolios";
import withWidth from "@material-ui/core/withWidth";
import MonteCarloSimulation from "../Graph/MonteCarloGraph";
import "react-datepicker/dist/react-datepicker.css";
import Popup from "../Popup/Popup";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { addZeroes } from "../Utilities/AddZeros";
import { TabContainer, TabPane, TabContent } from 'react-bootstrap';
import { FetchForwardSimulation } from "../../Apis/FetchForwardSimulation";
import PortfolioReturnGraph from "../Graph/PortfolioReturnGraph";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Tooltip from "react-tooltip-lite";
import TagManager from "react-gtm-module";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PageLoader from "../PageLoader/PageLoader";

export function ForwardTest() {

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const levels = [
    { percent: 0, level: "Select" },
    { percent: 0.80, level: "80%" },
    { percent: 0.85, level: "85%" },
    { percent: 0.90, level: "90%" },
    { percent: 0.95, level: "95%" },
    { percent: 1.0, level: "100%" },
  ];

  const periods = [
    { period_int: 0, period_value: "Select" },
    { period_int: 30, period_value: "1 Month" },
    { period_int: 60, period_value: "2 Months" },
    { period_int: 91, period_value: "3 Months" },
    { period_int: 182, period_value: "6 Months" },
    { period_int: 365, period_value: "Year" },
  ];

  const simulationPeriod = [
    { simulation_period: 0, simulation_value: "Select" },
    { simulation_period: 0.09, simulation_value: "1 Month" },
    { simulation_period: 0.17, simulation_value: "2 Months" },
    { simulation_period: 0.25, simulation_value: "3 Months" },
    { simulation_period: 0.5, simulation_value: "6 Months" },
    { simulation_period: 1, simulation_value: "1 Year" },
    { simulation_period: 2, simulation_value: "2 Years" },
    { simulation_period: 3, simulation_value: "3 Years" },
    { simulation_period: 4, simulation_value: "4 Years" },
    { simulation_period: 5, simulation_value: "5 Years" },
    { simulation_period: 6, simulation_value: "6 Years" },
    { simulation_period: 7, simulation_value: "7 Years" },
    { simulation_period: 8, simulation_value: "8 Years" },
    { simulation_period: 9, simulation_value: "9 Years" },
    { simulation_period: 10, simulation_value: "10 Years" },
  ];

  const NumberOfPaths = [
    { path_no: 0, path_value: "Select" },
    { path_no: 100, path_value: "100" },
    { path_no: 500, path_value: "500" },
    { path_no: 1000, path_value: "1,000" },
    { path_no: 2000, path_value: "2,000" },
    { path_no: 5000, path_value: "5,000" },
    { path_no: 10000, path_value: "10,000" }
  ];

  const portfolioDetailsTableHeaders = [
    { title: "#" },
    { title: "SYMBOL" },
    { title: "POSITION NAME" },
    { title: "QUANTITY", tooltip: "Number of shares held for each stock" },
    { title: "CLOSING PRICE", tooltip: "Price of a security at the EOD previous market close" },
    { title: "MARKET VALUE", tooltip: "Market value of each security based on last price" },
    { title: "WEIGHT" }
  ];

  const SimulationDetailsTableHeaders = [
    { title: "PATH", tooltip: "" },
    { title: "ENDING PORTFOLIO VALUE WITHOUT SHIELD", tooltip: "Ending portfolio value without Shield" },
    { title: "ENDING PORTFOLIO VALUE WITH SHIELD", tooltip: "Ending portfolio value net of Shield cost" },
    { title: "TOTAL SHIELD COST", tooltip: "Aggregate Shield Cost " },
    { title: "TOTAL SHIELD PAYOFF", tooltip: "Aggregate Shield payoffs" },
    { title: "AVERAGE SHIELD COST", tooltip: "Average Shield cost for the entire path" },
    { title: "AVERAGE SHIELD PAYOFF", tooltip: "Average Shield payoff for the entire path" },
    { title: "AVERAGE SHIELD COST(%)", tooltip: "Shield cost as a percentage of total portfolio value" },
    { title: "AVERAGE SHIELD PAYOFF(%)", tooltip: "Shield payoff as a percentage of total portfolio value" },
    // { title: "PORTFOLIO IMPLIED DOWNSIDE VOLATILITY (WITHOUT SHIELD)" },
    // { title: "PORTFOLIO IMPLIED DOWNSIDE VOLATILITY (WITH SHIELD)" },
    // { title: "REALIZED PORTFOLIO DOWNSIDE VOLATILITY WITHOUT SHIELD" },
    // { title: "REALIZED PORTFOLIO DOWNSIDE VOLATILITY WITH SHIELD" }
  ];
  const [level, setLevel] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [Simulation, setSimulation] = React.useState(0);
  const [Path, setPath] = React.useState(0);
  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [portfolioName, setPortfolioName] = React.useState([]);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [FtPortfolioID, setFtPortfolioID] = React.useState(0);
  const [selectedPortfolioTotalStocks, setSelectedPortfolioTotalStocks] = React.useState(0);
  const [selectedPortfolioTotalValue, setSelectedPortfolioTotalValue] = React.useState(0);
  const [tabletabvalue, setTableTabValue] = React.useState("Portfolio Details");
  const [tabvalue, setTabValue] = React.useState("Portfolio Return");
  const [PortfolioDetailsTable, setPortfolioDetailsTable] = React.useState([]);
  const [SimulationDetailsTable, setSimulationDetailsTable] = React.useState([]);
  const [HistogramShieldedData, setHistogramShieldedData] = React.useState([]);
  const [HistogramUnShieldedData, setHistogramUnShieldedData] = React.useState([]);
  const [HistogramRelativeData, setHistogramRelativeData] = React.useState([]);
  const [ShieldedGraphData, setShieldedGraphData] = React.useState([]);
  const [UnshieldedGraphData, setUnshieldedGraphData] = React.useState([]);
  const [DisableRunButton, setDisableRunButton] = React.useState(true);
  const [ShieldedMarketGraph, setShieldedMarketGraph] = React.useState([]);
  const [UnShieldedMarketGraph, setUnShieldedMarketGraph] = React.useState([]);
  const [GotForwardTData, setGotForwardTData] = React.useState(false);
  const [ApiCount, setApiCount] = React.useState(0);
  const [HistXMax, setHistXMax] = React.useState();
  const [HistYMax, setHistYMax] = React.useState();
  const [HistXMin, setHistXMin] = React.useState();
  const [LineYMin, setLineYMin] = React.useState();
  const [LineYMax, setLineYMax] = React.useState();


  const changePeriod = (event) => {
    if (period === "") {
      setPopupState(true);
      setPopupMessage("Protection period not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setPeriod(event.target.value);
  };

  const changeLevel = (event) => {
    if (level === "") {
      setPopupState(true);
      setPopupMessage("Protection level not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setLevel(event.target.value);
  };

  const changeSimulationPeriod = (event) => {
    if (Simulation === "") {
      setPopupState(true);
      setPopupMessage("Please select valid simulation length");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setSimulation(event.target.value);
  };

  const changeNumberOfPath = (event) => {
    if (Path === "") {
      setPopupState(true);
      setPopupMessage("Please select valid number of path");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setPath(event.target.value);
  };

  if (DisableRunButton === true) {
    if (FtPortfolioID !== 0 && Simulation !== 0 && period !== 0 && level !== 0 && Path !== 0 && ApiCount === 0) {
      setDisableRunButton(false);
    }
  }

  const calculateForwardTest = () => {
    console.log("calculate", Simulation, Path, level, period, FtPortfolioID);
    if (period === 0) {
      setPopupState(true);
      setPopupMessage("Protection period not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    fetchForwardSimulation(FtPortfolioID, Simulation, period, level, Path);
  };

  React.useEffect(() => {
    GetUserPortfolios().then((data) => {
      var portfoliolyst = [{ id: 0, name: "Select Portfolio" }];
      if (data !== false && data !== 0 && data !== 401 && data !== 404) {
        for (let [key, value] of Object.entries(data)) {
          setGotData(true);
          portfoliolyst.push({
            id: key,
            name: value.portfolioname,
            no_stocks: value.nooflineitems,
            port_value: parseFloat(value.totalportfoliovalue).toFixed(2)
          });
        }
        setPortfolioName(portfoliolyst);
        setBdOpen(false)
      }
      else if (data === false) {
        setPopupState(true);
        setPopupMessage("No portfolios found. Please create a portfolio or contact support@adaptive-investments.com.");
        setAlertType("error");
        setBdOpen(false);
        setGotData(true);
        setPopupLoginRedirect(false);
      }
      else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are facing issues connecting our servers, please try again later");
        setAlertType("error");
        setBdOpen(false);
        setGotData(true);
        setPopupLoginRedirect(false);
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setFlag(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }
    });
  }, []);

  function GenerateHistData(data) {
    let rawData = Object.entries(data).map(([interval, { count , cumulative_expected_loss}]) => ({
      interval,
      count,
      cumulative_expected_loss,
    }));

    let intervalData = rawData.reduce((acc, { interval, count ,cumulative_expected_loss}) => {
      const intervals = JSON.stringify(interval).replace('(','').replace(']','').split(',')
      const key = Math.floor(parseInt(interval.match(/-?\d+/), 10) / 5) * 5;
      const prev = acc.find((d) => d.interval === `${key}-${key + 5}`);
      if (prev) {
        prev.count += count;
      } else {
        acc.push({ interval: `${key + 5}`,
                   count,
                   "Return_percent": [Number(parseFloat(intervals[0].replace('"','')).toFixed(2)),Number(parseFloat(intervals[1].replace('"','')).toFixed(2))],
                   "Probability_of_occuring": Number(parseFloat(count *100)).toFixed(2),
                   "Return_value": [Number(parseFloat(Number(parseFloat(intervals[0].replace('"','')/100)) * selectedPortfolioTotalValue)).toFixed(2), Number(parseFloat(Number(parseFloat(intervals[1].replace('"','')/100)) * selectedPortfolioTotalValue)).toFixed(2)],
                   "Cumulative_expected_loss": Number((parseFloat(cumulative_expected_loss/100) * selectedPortfolioTotalValue)).toFixed(2)
                  });
      }
      return acc;
    }, []);
    intervalData.sort((a, b) => parseInt(a.interval) - parseInt(b.interval));
    return intervalData;
  }

  function FillMissingIntervals(data1, data2) {
    const intervals1 = Object.keys(data1);
    const intervals2 = Object.keys(data2);
    const uniqueIntervals = new Set([...intervals1, ...intervals2]);

    const newData1 = {};
    const newData2 = {};
    for (const interval of uniqueIntervals) {
      const count1 = data1[interval]?.count || 0;
      const cm_loss1 = data1[interval]?.cumulative_expected_loss||0;
      const count2 = data2[interval]?.count || 0;
      const cm_loss2 = data2[interval]?.cumulative_expected_loss||0;
      newData1[interval] = { count: count1 , cumulative_expected_loss : cm_loss1};
      newData2[interval] = { count: count2 , cumulative_expected_loss: cm_loss2};
    }

    let output_1 = GenerateHistData(newData1);
    let output_2 = GenerateHistData(newData2);
    return [output_1, output_2];
  }

  const fetchForwardSimulation = (portfolio_id, simulation_period, protection_period, protection_level, number_of_paths) => {
    if (selectedPortfolioTotalStocks !== 0) {
      setApiCount(1);
      setDisableRunButton(true);
      setBdOpen(true);
      FetchForwardSimulation(portfolio_id, simulation_period, protection_period, protection_level, number_of_paths).then((data) => {
        console.log("FS", data)
        if (data !== false && data !== 0 && data !== undefined && data !== 401) {
          setGotForwardTData(true);
          const [ShieldedData, UnShieldedData] = FillMissingIntervals(data["historgram_shielded_height"], data["historgram_unshielded_height"]);
          setHistogramShieldedData(ShieldedData);
          setHistogramUnShieldedData(UnShieldedData);
          setHistogramRelativeData(GenerateHistData(data["historgram_relative_height"]));
          setSimulationDetailsTable(data["simulation_details"]);
          setShieldedGraphData(data["shielded_portfolio_value"]);
          setUnshieldedGraphData(data['unshielded_portfolio_value']);
          setPortfolioDetailsTable(data["portfolio_details"]);
          setSimulationDetailsTable(data["simulation_details"]);
          const xMax = Math.max(
            ...ShieldedData.map((item) => item.interval),
            ...UnShieldedData.map((item) => item.interval)
          );
          const xMin = Math.min(
            ...ShieldedData.map((item) => item.interval),
            ...UnShieldedData.map((item) => item.interval)
          );
          setHistXMax(xMax);
          setHistXMin(xMin);

          const yMax = Math.max(
            ...ShieldedData.map((item) => item.count),
            ...UnShieldedData.map((item) => item.count)
          );
          setHistYMax(Math.ceil(yMax * 100) / 100);

          const lines1 = Object.keys(data["shielded_portfolio_value"][0]).filter(key => key !== 'index')
          const lines2 = Object.keys(data["unshielded_portfolio_value"][0]).filter(key => key !== 'index')
          setShieldedMarketGraph(lines1);
          setUnShieldedMarketGraph(lines2);

          const yMax1 = Math.max(
            ...data["shielded_portfolio_value"].map(row => Math.max(...lines1.map(key => row[key]))),
            ...data["unshielded_portfolio_value"].map(row => Math.max(...lines2.map(key => row[key])))
          );

          const yMin1 = Math.min(
            ...data["shielded_portfolio_value"].map(row => Math.max(...lines1.map(key => row[key]))),
            ...data["unshielded_portfolio_value"].map(row => Math.max(...lines2.map(key => row[key])))
          );

          setLineYMin(yMin1)
          setLineYMax(yMax1)

          TagManager.dataLayer({
            dataLayer: {
              event: 'Forward Simulation',
              userType: sessionStorage.getItem("selectedAcType"),
              user_id: sessionStorage.getItem("clientId")
            },
          });
          setBdOpen(false);
        } else if (data === false) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "We are facing some issues while loading the data. Please try again after sometime."
          );
          setAlertType("error");
        } else if (data === 0 || data === undefined) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Please retry by reducing the number of paths or simulation period."
          );
          setAlertType("error");
          setPopupLoginRedirect(false);
          return;
        } else if (data === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session timed out for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
        } else if (data === "INSUFFICIENT DATA") {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "The tickers in your portfolio dont have sufficient data for simulation to run."
          );
          setAlertType("error");
          setPopupLoginRedirect(false);
          return;
        }
      });
    }else{
      setBdOpen(false);
      setPopupState(true);
      setPopupMessage(
        "No stocks found for simulation to run. Please add atleast one stock."
      );
      setAlertType("error");
      setPopupLoginRedirect(false);
      return;
    }
  }

  const changePortfolio = (portfolioid) => {
    if (portfolioid === 0) {
      setPopupState(true);
      setPopupMessage("Portfolio not recognized. Please try again or contact support@adaptive-investments.com.");
      setAlertType("warning");
      setDisableRunButton(true);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    var portfolio = portfolioName.find(data => data.id === portfolioid);
    setFtPortfolioID(portfolio.id);
    setSelectedPortfolioTotalStocks(portfolio.no_stocks)
    setSelectedPortfolioTotalValue(portfolio.port_value)
  };

  function handleTableTabChange(tabletabvalue) {
    setTableTabValue(tabletabvalue);
  }
  function handleTabChange(tabvalue) {
    setTabValue(tabvalue);
  }


  return (
    <div className="testwindow-wrap">
      <PageLoader bdopen={bdopen} />
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Run Forward Test"
        alertType={alertType}
        content={popupMessage}
        loginRedirect={popupLoginRedierct}
      />
      <div className="details_wrap alon">
        <div className="infocard-wrap">
          <div id="selectportfolio" className="infocard">
            <h3>Portfolio</h3>
            <Select
              className="ai-form-value-bt"
              onChange={(event) => changePortfolio(event.target.value)}
            >
              {portfolioName.map((value, i) => (
                <MenuItem value={value.id}>{value.name}</MenuItem>
              ))}
            </Select>
            <span className="ai-backtest-select-arrow"></span>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>HOLDINGS</h3>
            <p id="portfolioholdings">{selectedPortfolioTotalStocks}</p>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>PORTFOLIO VALUE</h3>
            <p id="portfoliovalue">
              ${Number(addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))).toLocaleString("en-US")}
            </p>
          </div>
        </div>
      </div>
      <div className="forwardtest-holder">
        <div className="details_wrap self_assembly">
          <div className="infocard-wrap">
            <div id="selectsimulationlength" className="infocard">
              <h3>Simulation Length</h3>
              <Select
                className="ai-form-value-bt"
                onChange={changeSimulationPeriod}
              >
                {simulationPeriod.map((value, i) => (
                  <MenuItem value={value.simulation_period}>{value.simulation_value}</MenuItem>
                ))}
              </Select>
              <span className="ai-selfassembly-select-arrow"></span>
            </div>
            <div class="saprator"></div>
            <div  id="selectprotectionperiod"  className="infocard">
              <h3>Protection Period</h3>
              <Select
                className="ai-form-value-bt"
                value={period}
                onChange={changePeriod}
              >
                {periods.map((value, i) => (
                  Simulation * 365 >= value.period_int &&
                  <MenuItem value={value.period_int}>{(Simulation !== 0) ? value.period_value : 'Please select valid simulation'}</MenuItem>
                ))}
              </Select>
              <span className="ai-selfassembly-select-arrow"></span>
            </div>
            <div class="saprator"></div>
            <div id="selectprotectionlevel" className="infocard">
              <h3>Protection Level</h3>
              <Select
                className="ai-form-value-bt"
                value={level}
                onChange={changeLevel}
              >
                {levels.map((value, i) => (
                  <MenuItem value={value.percent}>{value.level}</MenuItem>
                ))}
              </Select>
              <span className="ai-selfassembly-select-arrow"></span>
            </div>
            <div class="saprator"></div>
            <div id="selectnumberofpaths" className="infocard">
              <h3>Number of Paths</h3>
              <Select className="ai-form-value-bt"
                onChange={changeNumberOfPath}>
                {NumberOfPaths.map((value, i) => (
                  <MenuItem value={value.path_no}>{value.path_value}</MenuItem>
                ))}
              </Select>
              <span className="ai-selfassembly-select-arrow"></span>
            </div>
          </div>
          <div className="buttonbuy">
            <button  id="runforwardsimulation" className="ai-btn primary solid"
              disabled={DisableRunButton}
              onClick={calculateForwardTest}>
              Run Monte Carlo Simulation
            </button>
            {!DisableRunButton &&
              <p className="infomessage"> Press “Run..” to recalculate the simulation </p>
            }
          </div>
        </div>
        <div className="dataforwardtest">
          {GotForwardTData &&
            <>
              <div className="forwardtest">
                <div className="forgraphs">
                  <div id="portfoliowithshield" className="forward-column">
                    <h3><img className="ai-img" src="Assets/shield-green.png" />Portfolio with Shield <Tooltip content="Simulated outcomes for the selected portfolio, when using the Portfolio Shield."><img src="Assets/risk-info.png"></img></Tooltip></h3>
                    {ShieldedGraphData.length === 0 ? (
                      <div style={{ textAlign: "center" }}>
                      </div>
                    ) : (<MonteCarloSimulation data={ShieldedGraphData} lines={ShieldedMarketGraph}
                      yMin={LineYMin} yMax={LineYMax} portfolioTotalValue={selectedPortfolioTotalValue} />)}
                  </div>
                  <div id="portfoliowithoutshield" className="forward-column">
                    <h3><img className="ai-img" src="Assets/shield-red.png" />Portfolio without Shield <Tooltip content="Simulated outcomes for the selected portfolio, unprotected i.e. NOT using the Portfolio Shield."><img src="Assets/risk-info.png"></img></Tooltip></h3>
                    {UnshieldedGraphData.length === 0 ? (
                      <div style={{ textAlign: "center" }}>
                      </div>
                    ) : (<MonteCarloSimulation data={UnshieldedGraphData} lines={UnShieldedMarketGraph}
                      yMin={LineYMin} yMax={LineYMax} portfolioTotalValue={selectedPortfolioTotalValue} />)}
                  </div>
                </div>
                <div className="forwardanalyze-portfolio">
                  <div className="shieldstates">
                    <div className="contentshield">
                      <ul className="subnavbar-menu navtab">
                        <li>
                          <a id="portfolioreturntab" className={tabvalue === "Portfolio Return" ? "SUBITEM-selected " : "SUBITEM"} eventKey="Portfolio Return" onClick={() => handleTabChange('Portfolio Return')} > Portfolio Return <Tooltip content="The portfolio with Portfolio Shield will be protected from severe market downturns because protection is in place.The portfolio with Portfolio Shield will still benefit from stock market gains (less the cost of the Shield)."><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                        <li>
                          <a id="relativeperformancetab" className={tabvalue === "Relative Performance" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Relative Performance" onClick={() => handleTabChange('Relative Performance')}>Relative Performance <Tooltip content="The portfolio with Portfolio Shield will be protected from severe market downturns because protection is in place.The portfolio with Portfolio Shield will still benefit from stock market gains (less the cost of the Shield)."><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <TabContainer defaultActiveKey={tabvalue}>
                    <TabContent>
                      <TabPane key="portfolio-return-tab" eventKey="Portfolio Return" active={tabvalue === 'Portfolio Return'} >
                        <div id="portfolioreturnbarchart" className="barchart">
                          <PortfolioReturnGraph data={HistogramShieldedData} gradientId="colorUv1" xMin={HistXMin} xMax={HistXMax} yMax={HistYMax} />
                          <PortfolioReturnGraph data={HistogramUnShieldedData} gradientId="colorUv2" xMin={HistXMin} xMax={HistXMax} yMax={HistYMax} />
                        </div>
                      </TabPane>
                      <TabPane key="relative-performance-tab" eventKey="Relative Performance" active={tabvalue === 'Relative Performance'}>
                        <div id="relativeperformancebarchart" className="barchart">
                          <PortfolioReturnGraph data={HistogramRelativeData} gradientId="colorUv3" />
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabContainer>
                </div>
              </div>
              <div className="shieldstates">
                <div className="contentshield">
                  <ul className="subnavbar-menu navtab">
                    <li>
                      <a id="portfoliodetailstab" className={tabletabvalue === "Portfolio Details" ? "SUBITEM-selected " : "SUBITEM"} eventKey="Portfolio Details" onClick={() => handleTableTabChange('Portfolio Details')} > Portfolio Details</a>
                    </li>
                    <li>
                      <a id="simulationdetailstab" className={tabletabvalue === "Simulation Details" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Simulation Details" onClick={() => handleTableTabChange('Simulation Details')}>Simulation Details</a>
                    </li>
                  </ul>
                  <div className="table_holder">
                    <TabContainer defaultActiveKey={tabletabvalue}>
                      <TabContent>
                        <TabPane eventKey="Portfolio Details" active={tabletabvalue === 'Portfolio Details'} >
                          <TableContainer id="portfoliodetailstable" className="table_height">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {portfolioDetailsTableHeaders.map((header) => (
                                    <TableCell>
                                      <div className="stck_table_heading">{header.title}</div>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.entries(PortfolioDetailsTable).map(([key, value]) => (
                                  <TableRow>
                                    <TableCell>{value.number + 1}</TableCell>
                                    <TableCell>{value.Ticker}</TableCell>
                                    <TableCell>{value.Name}</TableCell>
                                    <TableCell>{value.quantity}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value["ClosePrice"]).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value["Market Value"]).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{(parseFloat(value["Weight"]) * 100).toFixed(2)}%</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TableCell colSpan="5">Total:</TableCell>
                                  <TableCell>{Number(
                                    addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))
                                  ).toLocaleString("en-US", currObj)}</TableCell>
                                  <TableCell>100%</TableCell>
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </TabPane>
                        <TabPane eventKey="Simulation Details" active={tabletabvalue === 'Simulation Details'}>
                          <TableContainer id="simulationdetailstable" className="table_height">
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {SimulationDetailsTableHeaders.map((header) => (
                                    <TableCell>
                                      <div className="stck_table_heading">{header.title} <Tooltip content={header.tooltip}><img src="Assets/risk-info.png"></img></Tooltip></div>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.entries(SimulationDetailsTable).map(([key, value]) => (
                                  <TableRow>
                                    <TableCell>{value.path_number +1}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value.portfolio_value).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value.shielded_portfolio_value).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value.total_shield_cost).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value.total_shield_payoff).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value.average_shield_cost).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{Number(
                                      addZeroes(Number(parseFloat(value.average_shield_payoff).toFixed(2)))
                                    ).toLocaleString("en-US", currObj)}</TableCell>
                                    <TableCell>{parseFloat(value.percentage_shield_cost).toFixed(2)}%</TableCell>
                                    <TableCell>{parseFloat(value.percentage_shield_payoff).toFixed(2)}%</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TabPane>
                      </TabContent>
                    </TabContainer>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>

    </div>
  )
}
export default withWidth()(ForwardTest);