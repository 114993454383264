import React from "react";
import "./ShieldOrderComplition.css";
import { useHistory } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";

function ShieldOrderComplition() {
  
  const history = useHistory();
  const goToPortfolioSummary = () => history.push("/PortfolioSummaryScreen");

  return (
    <div className="ai-content-part">
        <Navbar />
        <PageHeader
          heading="Confirm Shield Purchase"
          backButton={true}
          routeTo = './ShieldRecommendationScreen'
        />
        <div className="shield-wrapper">
          <div className="confirmation-holder-2">
            <img src="Assets/warning-big.svg" alt="" />
            <div>
              <p>There might be an issue with your shield purchase. <br></br>Please check your Shield List screen to confirm your purchase</p>
              <a className="ai-link" onClick={goToPortfolioSummary}>Back to Portfolio Listing</a>
            </div>
          </div>
        </div>
    </div>
  );
}
export default ShieldOrderComplition;