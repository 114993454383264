import React, { createContext, useState, useContext } from 'react';
export const ClientContext = createContext();
export const ClientProvider = ({ children }) => {
    const [selectedClientName, setSelectedClientName] = useState("");
    const [clientUserName, setClientUserName] = React.useState("");
    
    const clearSelectedClientName = () => {
        setSelectedClientName("");
        setClientUserName("");
    };
    return (
        <ClientContext.Provider value={{ selectedClientName, setSelectedClientName,clientUserName,setClientUserName, clearSelectedClientName }}>
            {children}
        </ClientContext.Provider>
    );
};
export const useSelectedClient = () => {
    return useContext(ClientContext);
};
