import React from "react";
import "./AdaptiveGeniusProducts.css";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";

function AdaptiveGeniusProducts(props) {
  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["product_card genius_product_card"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["product_card genius_product_card"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["product_card genius_product_card"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["product_card genius_product_card"];
    }
  }
  const rendering = getRendering(props.width);

  return (
    <>
      <Link to={props.routeTo} className="tooltip_con tool-top">
        <div
          // content={props.tooltip}
          className={rendering[0] + "-" + props.color}
        >
          <div className="card_title">
            <p className="title_text">{props.title}</p>
            <img className="product_card_img" src={props.icon}></img>
          </div>
        </div>
      </Link>
    </>
  );
}
export default withWidth()(AdaptiveGeniusProducts);
