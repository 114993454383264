import React from "react";
import "./ShieldSummary.css";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Popup from "../Popup/Popup";
import StockTable from "../../Components/StockTable/StockTable";
import { PlaceOrder } from "../../Apis/PlaceOrder";
import { UpdateShieldStatus } from "../../Apis/UpdatePortfolio";
import { selectedPeriodBackend } from "../Utilities/GetProtectionPeriodValue";
import { convertDate, newConvertDate } from "../Utilities/ConvertDate";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";

function ShieldSummary(props) {
  const useStyles = makeStyles((theme) => ({
    checkbox_button: {
      color: "#60034c",
      width: "13px",
      height: "13px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [PortfolioValues, setValue] = React.useState([]);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  function handleValueChange(newValue) {
    setValue(newValue);
  }
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const history = useHistory();

  const checkOutClick = () => history.push({
    pathname: '/PaymentSuccessScreen',
    state: {
      detail: 'quoteshield',
      selectedData: props.selectedData,
      portfolioid: props.selectedData['portfolio_id']
    }
  });

  function shieldSetupPage() {
    window.location.reload();
  }

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar",
        "summary-header",
        "summary-TC",
        "TC-text",
      ];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar",
        "summary-header",
        "summary-TC-sm",
        "TC-text-sm",
      ];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar-md",
        "summary-header",
        "summary-TC-sm",
        "TC-text-sm",
      ];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return [
        "summary-details-table",
        "summary-buttons_ar-md",
        "summary-header-sm",
        "summary-TC-sm",
        "TC-text-sm",
      ];
    }
  }

  const rendering = getRendering(props.width);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const PortfolioShieldStatus = sessionStorage.getItem("PortfolioShieldStatus");

  function PurchaseShield() {
    UpdateShieldStatus(
      selectedPeriodBackend(props.selectedData["protection_period"]),
      props.selectedData["closest_percent"],
    ).then((data) => {
      if (data === true) {
        checkOutClick();
        TagManager.dataLayer({
          dataLayer: {
            event: 'Shield Purchased',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId"),
            protection_level: props.selectedData["protection_level"],
            protection_period: props.selectedData["protection_period"],
            shield_type: "Guaranteed Shield"
          },
        });
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        return;
      }
      else {
        setPopupState(true);
        setBdOpen(false);
        setPopupLoginRedirect(false);
        setPopupMessage("Unable to purchase Shield. Please try again or contact support@adaptive-investments.com.")
        return;
      }
    });
  }

  const TurnShieldOn = () => {
    if (checked === false) {
      setPopupState(true);
      setBdOpen(false);
      setPopupMessage(
        "Please read and agree to our terms and conditions to proceed."
      );
      setAlertType("warning");
      return;
    }
    setBdOpen(true);
    if (props.marketShield === true) {
      PlaceOrder().then((place_order_status) => {
        if (place_order_status === "SUCCESS") {
          PurchaseShield();
        } else if (place_order_status === "FAILED") {
          setBdOpen(false);
          setPopupMessage(
            "Unable to purchase Shield. Please try again or contact support@adaptive-investments.com."
          );
          setPopupLoginRedirect(false);
          setPopupState(true);
          return;
        } else if (place_order_status === 0 || place_order_status === false || place_order_status === undefined) {
          setBdOpen(false);
          setPopupMessage(
            "We are facing server issues. Please try again later"
          );
          setPopupState(true);
          setPopupLoginRedirect(false);
          return;
        }
        else if (place_order_status === 401) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Server issue. Please try again or contact support@adaptive-investments.com."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          return;
        } else if (place_order_status === 404) {
          setBdOpen(false);
          setPopupState(true);
          setPopupMessage(
            "Your session was terminated for security reasons. Please sign-in again."
          );
          setAlertType("error");
          setPopupLoginRedirect(true);
          return;
        }
      });
    } else {
      PurchaseShield();
    }
  };

  return (
    <div className="sheild-summary-wrapper">
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Confirm Shield Purchase"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />

      <div className="summary-header">Shield Summary</div>
      <div className="popupcontentpart">
        <div>
          <div className={rendering[3]}>
            <div className={rendering[0]}>
              <div className="checkout-details">
                <label>Portfolio Value</label>
                <span className="col-sap">:</span>
                <span className="value">
                  ${" "}
                  {Number(
                    Number(props.selectedData["portfolio_value"]).toFixed(2)
                  ).toLocaleString("en-US")}
                </span>
              </div>
              <div className="checkout-details">
                <label>For a loss no less than</label>
                <span className="col-sap">:</span>
                <span className="value">
                  {parseFloat(100 - props.selectedData["protection_level"])}%
                </span>
              </div>
              <div className="checkout-details">
                <label>Protected Value</label>
                <span className="col-sap">:</span>
                <span className="value">
                  ${" "}
                  {Number(
                    Number(props.selectedData["shielded_value"]).toFixed(2)
                  ).toLocaleString("en-US")}
                </span>
              </div>
              <div className="checkout-details">
                <label>For a period of</label>
                <span className="col-sap">:</span>
                <span className="value">{props.selectedData["protection_period"]}</span>
              </div>
              <div className="checkout-details">
                <label>Portfolio value Protected</label>
                <span className="col-sap">:</span>
                <span className="value">{props.selectedData["protection_level"]}%</span>
              </div>
              <div className="checkout-details">
                <label>Starting Date</label>
                <span className="col-sap">:</span>
                <span className="value">{convertDate(new Date())}</span>
              </div>
              <div className="checkout-details">
                <label>Shield Price</label>
                <span className="col-sap">:</span>
                <span className="value">
                  ${" "}
                  {Number(Number(props.selectedData["shield_price"]).toFixed(2)).toLocaleString(
                    "en-US"
                  )}
                </span>
              </div>
              <div className="checkout-details">
                <label>Ending Date</label>
                <span className="col-sap">:</span>
                <span className="value">{newConvertDate(props.selectedData["shieldexpirationdate"])}</span>
              </div>
            </div>
          </div>
          <div className="ai-shieldsummary">
            <h3> Stock Details</h3>
            <StockTable
              PortfolioValues={PortfolioValues}
              onChange={handleValueChange}
              editable={false}
              hedgeable={true}
            />
          </div>
        </div>
        <div>
          <h4>Terms and Conditions</h4>
          <p className={rendering[4]}>
            Shield Protection Period specifies the duration of protection
            starting at the current day, and ending on the expiration date.
          </p>
          <p className={rendering[4]}>
            Shield Protection Level specifies the protected value as a
            percentage of the current portfolio value.
          </p>
          <p className={rendering[4]}>
            Shield Protection Payout is settled at the latest exchange closing,
            on or before the expiration date of the Shield. Usually this is the
            exchange closing on the expiration date of the Shield.
          </p>
          <p className={rendering[4]}>
            Calculation of Protection Payouts: The Shield Protection Payout
            equals the difference between (1) the aggregate market value of the
            holdings (see table above), inclusive of all dividends and other
            distributions during the Shield Protection Period, and (2) the
            Shield Protected Value. For example, if a $100,000 Portfolio
            experiences a crash and, at the expiration of the Shield Protection
            Period, is down by the 1987 Black Monday magnitude (22.6%) to
            $77,400 —and the Shield Protection Level is set at 10% i.e. the
            Shield Protection Level is $90,000, you will be paid the difference
            of $90,000 - $77,400 equal to $12,600.
          </p>
          {props.showPurchaseShield && (
            <div>
              <div>
                <Checkbox
                  className={classes.checkbox_button}
                  checked={checked}
                  onChange={handleChange}
                  color="default"
                ></Checkbox>
                <span className="accept-TC-Text">
                  I have read and accept the terms and conditions
                </span>
              </div>
              <div class="summarybtn">
                <button disabled={true}
                  className="ai-btn primary solid"
                  onClick={() => TurnShieldOn()}
                >
                  Purchase Shield
                </button>
                <button
                  type="cancel"
                  onClick={shieldSetupPage}
                  className="ai-btn secondary solid "
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withWidth()(ShieldSummary);
