import React, { useState } from "react";
import "./AdvisorDashboardStockTab.css";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import { GetAdvisorStockData } from "./../../Apis/GetAdvisorStockData";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { makeStyles } from "@material-ui/core/styles";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Popup from "../Popup/Popup";
import Tooltip from "react-tooltip-lite";
import searchicon from "../../images/search.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageLoader from "../PageLoader/PageLoader";



function AdvisorDashboardStockTab(props) {
  const [stockData, setStockData] = useState([]);
  const [sortOrder, setSortOrder] = useState("ASC");
  const [searchKey, setSearchKey] = useState("");
  const [sortIcon, setSortIcon] = useState("Assets/sort_icon.png");
  const [sortCol, setSortCol] = useState("");
  const [Flag, setFlag] = useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [filterCol, setFilterCol] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterMinValue, setfilterMinValue] = React.useState(null);
  const [filterMaxValue, setfilterMaxValue] = React.useState(null);
  const [filterFormErrors, setFilterFormErrors] = React.useState({});
  const [unfilteredStocks, setunfilteredStocks] = React.useState([]);
  const [filterList, setFilterList] = React.useState([]);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [StockApiCount, setStockApiCount] = React.useState(0);
  const [allStocks,setAllStocks] = React.useState([]);
  const [advisorStocks,setAdvisorStocks] = React.useState([]);
  const [selectedStocksFilter, setselectedStocksFilter] = React.useState("allStocks");
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  if (Flag === false && StockApiCount === 0) {
    setStockApiCount(StockApiCount + 1);
    GetAdvisorStockData().then((data) => {
      if (data !== false && data !== 0 && data !== 401) {
        console.log("Data",data)
        setFlag(true);
        var lyst1 = [];
        var lyst2 = [];
        for (let [key, value] of Object.entries(data['all_stocks'])) {
          lyst1.push({
            symbol: value.symbol,
            price: Number(value.todays_close).toFixed(2),
            pricechange: Number(
              value.todays_close - value.yesterdays_close
            ).toFixed(2),
            risk: Number(value.risk_today).toFixed(2),
            riskchange: Number(
              ((value.risk_change / value.risk_yesterday) * 100).toFixed(2)
            ),
          });
        }
        if(data['advisor_stocks'] !== false && data['advisor_stocks'].length !== 0){
          for (let [key, value] of Object.entries(data['advisor_stocks'])) {
            lyst2.push({
              symbol: value.symbol,
              price: Number(value.todays_close).toFixed(2),
              pricechange: Number(
                value.todays_close - value.yesterdays_close
              ).toFixed(2),
              risk: Number(value.risk_today).toFixed(2),
              riskchange: Number(
                ((value.risk_change / value.risk_yesterday) * 100).toFixed(2)
              ),
            });
          }
        }
        setAdvisorStocks([...lyst2]);
        setAllStocks([...lyst1]);
        setStockData([...lyst1]);
        setunfilteredStocks([...lyst1]);
        setBdOpen(false);
      } else if (data === false) {
        setFlag(true);
        setPopupState(true);
        setPopupMessage("Unable to retrieve data. Please try again or contact support@adaptive-investments.com.");
        setPopupTitle("Error");
        setBdOpen(false);
        setPopupLoginRedirect(false);
      } else if (data === 0) {
        setFlag(true);
        setPopupState(true);
        setPopupMessage(
          "Unable to fetch data from our servers at the moment, please try again later"
        );
        setPopupTitle("Error");
        setBdOpen(false);
        setPopupLoginRedirect(false);
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
      else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      }
    });
  }

  const toggleStocksData = (event) => {
    setselectedStocksFilter(event.target.value)
    if(event.target.value === "advisorStocks"){
      setStockData(advisorStocks)
      setunfilteredStocks(advisorStocks)
    }else{
      setStockData(allStocks)
      setunfilteredStocks(allStocks)
    }
  }

  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["DashboardStocks"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["DashboardStocks"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["DashboardStocks-2"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["DashboardStocks-2"];
    }
  }

  const open = Boolean(anchorEl);
  const handleClick = (event, col) => {
    setAnchorEl(event.currentTarget);
    setFilterCol(col);
  };

  const validateFilter = (event) => {
    let validFilterFlag = true;
    let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
    let error = {};
    if (!filterMinValue) {
      validFilterFlag = false;
      error.minfilter = "Please enter min value";
    }
    if (!filterMaxValue) {
      validFilterFlag = false;
      error.maxfilter = "Please enter max value";
    }
    if (
      Number(parseFloat(filterMaxValue).toFixed(2)) <
      Number(parseFloat(filterMinValue).toFixed(2))
    ) {
      error.maxfilter = "Please enter max value greater than min value";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMinValue)) {
      error.minfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    if (!pattern.test(filterMaxValue)) {
      error.maxfilter = "Please enter valid input";
      validFilterFlag = false;
    }
    setFilterFormErrors(error);
    return validFilterFlag;
  };

  const requestFilter = (e) => {
    e.preventDefault();
    if (validateFilter()) {
      const filteredData = stockData.filter(
        (data) =>
          Number(parseFloat(data[filterCol]).toFixed(2)) >=
            Number(parseFloat(filterMinValue).toFixed(2)) &&
          Number(parseFloat(data[filterCol]).toFixed(2)) <=
            Number(parseFloat(filterMaxValue).toFixed(2))
      );
      setStockData(filteredData);
      filterList.push({
        filterno: filterList.length,
        column: filterCol,
        minvalue: filterMinValue,
        maxvalue: filterMaxValue,
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setfilterMinValue(null);
    setfilterMaxValue(null);
    setFilterCol(null);
    setFilterFormErrors({});
  };

  const rendering = getRendering(props.width);
  const headers = [
    {
      tableHeader: "SYMBOL",
      sort_icon: (
        <IconButton onClick={() => requestSort("symbol")}>
          <img
            src={sortCol === "symbol" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: "",
      tooltip: "",
      classname:"stck_table_heading",
    },
    {
      tableHeader: "PRICE",
      sort_icon: (
        <IconButton onClick={() => requestSort("price")}>
          <img
            src={sortCol === "price" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "price")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      tooltip: "EOD end of day last market close price",
      classname:"stck_table_heading T_text-right",
    },
    {
      tableHeader: "PRICE CHANGE(LAST MARKET CLOSE)",
      sort_icon: (
        <IconButton onClick={() => requestSort("pricechange")}>
          <img
            src={sortCol === "pricechange" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "pricechange")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      tooltip: "Change in price vs. prior market close",
      classname:"stck_table_heading T_text-right",
    },
    {
      tableHeader: "RISK %",
      sort_icon: (
        <IconButton onClick={() => requestSort("risk")}>
          <img
            src={sortCol === "risk" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "risk")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      tooltip:
        "Analyzed risk level of each client's portfolio: semi variance measure (annualized standard deviation of downside risk or negative returns), Low = 0% - 15%, Medium = 15% - 20%, High = 20% - 30%, Very High = 30% +",
        classname:"stck_table_heading T_text-right",
    },
    {
      tableHeader: "CHANGE IN RISK(LAST CLOSE)",
      sort_icon: (
        <IconButton onClick={() => requestSort("riskchange")}>
          <img
            src={sortCol === "riskchange" ? sortIcon : "Assets/sort_icon.png"}
            alt=""
          />
        </IconButton>
      ),
      filter_icon: (
        <IconButton onClick={(e) => handleClick(e, "riskchange")}>
          <img src="Assets/filter_icon.png" alt="" />
        </IconButton>
      ),
      tooltip: "Change in risk vs. prior market close",
      classname:"stck_table_heading T_text-right",
    },
  ];

  const requestSort = (col) => {
    let sortCol = col;
    if (sortOrder === "ASC") {
      const sortedData = [...stockData];
      if (sortCol === "symbol") {
        sortedData.sort((a, b) =>
          a[sortCol].toLowerCase() > b[sortCol].toLowerCase() ? 1 : -1
        );
      } else {
        sortedData.sort((a, b) =>
          Number(a[sortCol]) > Number(b[sortCol]) ? 1 : -1
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_asc_icon.png");
      setStockData(sortedData);
      setSortOrder("DESC");
    }
    if (sortOrder === "DESC") {
      const sortedData = [...stockData];
      if (sortCol === "symbol") {
        sortedData.sort((a, b) =>
          a[sortCol].toLowerCase() < b[sortCol].toLowerCase() ? 1 : -1
        );
      } else {
        sortedData.sort((a, b) =>
          Number(a[sortCol]) < Number(b[sortCol]) ? 1 : -1
        );
      }
      setSortCol(sortCol);
      setSortIcon("Assets/sort_desc_icon.png");
      setStockData(sortedData);
      setSortOrder("ASC");
    }
  };
  const removeFilter = (data) => {
    let lyst = [...unfilteredStocks];
    var removefilterIndex = filterList
      .map(function (item) {
        return item.filterno;
      })
      .indexOf(data.filterno);
    filterList.splice(removefilterIndex, 1);
    setFilterList([...filterList]);
    if (filterList.length !== 0) {
      for (let [key, value] of Object.entries(filterList)) {
        lyst = lyst.filter(
          (data) =>
            Number(parseFloat(data[value.column]).toFixed(2)) >=
              Number(parseFloat(value.minvalue).toFixed(2)) &&
            Number(parseFloat(data[value.column]).toFixed(2)) <=
              Number(parseFloat(value.maxvalue).toFixed(2))
        );
        setStockData([...lyst]);
      }
    } else {
      setStockData([...unfilteredStocks]);
    }
  };

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
      ></Popup>
      <div className="search_bar">
      <div className="searchinput">
        <img src={searchicon}></img>
        <input
            className="ai-form-value"
            type="text"
            placeholder="Search"
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
          />
      </div>
      </div>
      <Paper className="filter-paper">
        {filterList.map((data) => (
          <Chip
            className="chip"
            label={
              data.column.startsWith("price")
                ? data.column + " $" + data.minvalue + " - $" + data.maxvalue
                : data.column +
                  " " +
                  data.minvalue +
                  "% - " +
                  data.maxvalue +
                  "%"
            }
            variant="outlined"
            sx={{ color: "#E58B76" }}
            deleteIcon={<img src="Assets/filter_delete_icon.png" alt="" />}
            onDelete={() => removeFilter(data)}
          />
        ))}
      </Paper>
      <div className="stockfilters">
        <input id="stocks" type="radio" value="allStocks" name="stockfilter" checked={selectedStocksFilter === "allStocks"} onChange={toggleStocksData}/>
        <label for="stocks">Show All Stocks</label>
        <input id="holdings" type="radio" value="advisorStocks" name="stockfilter" checked={selectedStocksFilter === "advisorStocks"} onChange={toggleStocksData} />{" "}
        <label for="holdings">Show Only Client Holdings</label>
      </div>
      <div className="table_holder">
        <TableContainer className="table_height">
          <Table  stickyHeader aria-label="sticky table">
            {
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableCell>
                      <Tooltip
                        content={header.tooltip}
                        className={header.classname}
                      >
                        {header.tableHeader}
                        {header.sort_icon}
                        {header.filter_icon}
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            }
            <TableBody>
              {stockData
                .filter((val) => {
                  if (searchKey === "") {
                    return val;
                  } else if (
                    val.symbol.toLowerCase().includes(searchKey.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((row) => (
                  <TableRow >
                    <TableCell >{row.symbol}</TableCell>
                    <TableCell  className="mono">${row.price.toLocaleString("en-US")}</TableCell>
                    <TableCell  className="mono">${row.pricechange.toLocaleString("en-US")}</TableCell>
                    <TableCell  className="mono">{row.risk}%</TableCell>
                    <TableCell  className="mono">{row.riskchange}%</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <div className="ai-form-group popoutbox">
            <input
              type="text"
              className="ai-form-value"
              placeholder="Min Value"
              onChange={(event) => setfilterMinValue(event.target.value)}
            />
            <p className="error-message">{filterFormErrors.minfilter}</p>
            <p className="filter-label">To</p>
            <input
              type="text"
              className="ai-form-value"
              placeholder="Max Value"
              onChange={(event) => setfilterMaxValue(event.target.value)}
            />
            <p className="error-message">{filterFormErrors.maxfilter}</p>
            <div className="button-div">
              <button
                type="Submit"
                className="ai-btn primary small solid"
                onClick={requestFilter}
              >
                Apply
              </button>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default withWidth()(AdvisorDashboardStockTab);
