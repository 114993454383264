import React from "react";
import MultilineChart from "./views/MultilineChart";
import Legend from "./components/Legend";
import "./Graph.css";
import { GetBacktestGraphData } from "../../Apis/GetBacktestGraphData";


function Graph(props) {
  var array1 = [];
  var array2 = [];
  var array3 = [];

  const defaults = {
    data: {
      TradeDate: [],
      TotalUnhedged: [],
      TotalHedged: [],
    },
  };
  var arrayOfObject = [];
  if (props.data.length === 0) {
    array1 = [];
    array2 = [];
    array3 = [];
    arrayOfObject = [];
  } else {
    // console.log("props", typeof props.data);
    array1 = props.data.TradeDate;
    array2 = props.data.TotalUnhedged;
    array3 = props.data.TotalHedged;
  }

  arrayOfObject = array1.map(function (value, index) {
    return {
      date: value,
      TotalUnhedged: array2[index],
      TotalHedged: array3[index],
    };
  });

  const HedgedData = {
    name: "Hedged",
    color: "#32CD32",
    items: arrayOfObject.map((d) => ({
      value: d.TotalHedged,
      date: new Date(d.date),
      label: d.TotalHedged,
    })),
  };
  const UnhedgedData = {
    name: "Unhedged",
    color: "#d53e4f",
    items: arrayOfObject.map((d) => ({
      value: d.TotalUnhedged,
      date: new Date(d.date),
      label: d.TotalUnhedged,
    })),
  };

  // console.log(arrayOfObject);

  const [selectedItems, setSelectedItems] = React.useState([
    "Hedged",
    "Unhedged",
  ]);
  const legendData = [HedgedData, UnhedgedData];
  const chartData = [
    HedgedData,
    ...[UnhedgedData].filter((d) => selectedItems.includes(d.name)),
  ];
  // console.log(chartData);
  const onChangeSelection = (name) => {
    const newSelectedItems = selectedItems.includes(name)
      ? selectedItems.filter((item) => item !== name)
      : [...selectedItems, name];
    setSelectedItems(newSelectedItems);
  };

  return (
    <div className="App">
      {props.data.length === 0 ? (
        <div></div>
      ) : (
        <div>
          <Legend
            data={legendData}
            selectedItems={selectedItems}
            onChange={onChangeSelection}
          />
          <MultilineChart data={chartData} is_backtest={true}/>
        </div>
      )}
    </div>
  );
}

// getDefaultProps() {
//   return {
//     note: '',
//     onCancel: this.handleClose.this(bind),
//     onSave: this.handleClose.this(bind),
//     onEditChange: this.handleChange.this(bind)
//   };
// }

Graph.defaultProps = {
  data: {
    TradeDate: [],
    TotalUnhedged: [],
    TotalHedged: [],
  },
};

export default Graph;
