import React from "react";
import "./ConcentratedShield.scss";
import mar1 from "../../images/market_shield-1.svg";
import Popup from "reactjs-popup";
import CustomPopup from "../Popup/Popup";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import ProjectionCone from "../../Components/Graph/ProjectionCone";
import CircularProgress from "@material-ui/core/CircularProgress";
import MarketShieldSummary from "../../Components/MarketShieldSummary/MarketShieldSummary";
import close from "../../images/close.svg";
import { Checkout } from "../../Apis/Checkout";
import { addZeroes } from "../Utilities/AddZeros";
import { selectedPeriodBackend } from "../Utilities/GetProtectionPeriodValue";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Tooltip from "react-tooltip-lite";
import TagManager from "react-gtm-module";
import { TabContainer, TabPane, TabContent } from 'react-bootstrap';
import BellCurve from "../TerminalBellCurve/TerminalBellCurve";
import PageLoader from "../PageLoader/PageLoader";
import Checkbox from "@material-ui/core/Checkbox";


function ConcentratedShield(props) {

  const useStyles = makeStyles((theme) => ({
    button: {
      color: "white",
      marginTop: "2px",
    },
    checkbox_button: {
      color: "#60034c",
      width: "14px",
      height: "14px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const [bdopen, setBdOpen] = React.useState(false);
  const selectedPortfolioTotalValue = sessionStorage.getItem("PortfolioTotalValue");
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [PurchaseShield, setPurchaseShield] = React.useState(false);
  const [checkoutData, setCheckoutData] = React.useState([]);
  const [marketOpenFlag, setMarketOpenFlag] = React.useState(false);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [shieldType, setShieldType] = React.useState("");

  var risk_percent = 0;
  const { period_str } = selectedPeriodBackend(props.period_value);

  if (props.level_value === 100) {
    risk_percent = "1.0";
  } else {
    risk_percent = addZeroes(
      Number((props.level_value / 100).toFixed(2).toString())
    );
  }

  function handleTabChange(tabvalue) {
    props.setTabValue(tabvalue);
  }

  function TurnShieldOn(shield_type) {
    setBdOpen(false);
    setPurchaseShield(true);
    setShowPopup(true);
    setShieldType(shield_type);
  }

  const handleClose = (mesg) => {
    setShieldType("")
    setPopupState(true);
    setPopupMessage(mesg);
    setPopupTitle("Purchase Shield");
    setAlertType("error");
    setBdOpen(false);
  };

  const closeMarketSummaryPopup = () => {
    setShowPopup(false);
    setBdOpen(false);
  };

  const [marketShieldContracts,setMarketShieldContracts] = React.useState(props.MultiShieldContracts);
  const [reselectFlag,setReselectFlag] = React.useState(false);

  React.useEffect(() => {
    if(reselectFlag){
      props.MultiShieldSelectedContrats(marketShieldContracts)
      setReselectFlag(false);
    }
  },[marketShieldContracts])

  const handleMarketShieldContractChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setMarketShieldContracts({ ...marketShieldContracts, [e.target.name]: value });
    setReselectFlag(true)
  };

  function GetRiskBarColor(risk_value) {
    if (risk_value <= 15) {
      return "#38bb55";
    } else if (risk_value <= 20 && risk_value > 15) {
      return "#ffa654";
    } else if (risk_value <= 30 && risk_value > 20) {
      return "#ed6663";
    } else if (risk_value > 30) {
      return "#aa4a48";
    }
  }

  return (
    <div className="ShieldProtect-holder">
      <PageLoader bdopen={bdopen}/>
      <CustomPopup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      {props.MultiShieldSelectedData &&
        <div className="shieldcompare_page portsolution">
          <div className="shildcompare">
            <div className="shildhead">
              <div>duration</div>
              <div>Protection Level
              </div>
              <div>Protection Price</div>
            </div>
            <div className="shieldcard info">
              <div>
                <h3>{props.period_value}</h3>
                <p>{props.MultiShieldSelectedData["ExpirationDate"]}</p>
              </div>
              <div>
                <h3>{props.level_value}% <Tooltip content="The shield price is adjusted as per the new protection level"><img src="Assets/risk-info.png"></img></Tooltip></h3>
              </div>
              <div>
                <h3>{Number(
                  addZeroes(Number(parseFloat(props.MultiShieldSelectedData["Cost"]).toFixed(2)))
                ).toLocaleString("en-US", currObj)}
                </h3>
                <p>{Number(
                  addZeroes(Number(parseFloat((props.MultiShieldSelectedData["Cost"] / selectedPortfolioTotalValue) * 100).toFixed(2)))
                )}%</p>
              </div>
              <div className="btncard">
                {props.showRefresh === true ? (
                  <button className="ai-btn ai-disabled small primary solid " ><img src="Assets/product-shield.png" />Buy Market Shield Concentrated</button>
                ) : (
                  <button className="ai-btn small primary solid "
                    id="buymsc"
                    disabled={!props.MultiShieldSelectedData}
                    onClick={() => TurnShieldOn("msc")}><img src="Assets/product-shield.png" />Buy Market Shield Concentrated</button>
                )}
              </div>
            </div>
          </div>
          <div className="shieldstates">
            <div className="contentshield">
              <div className="table_holder">
                <div className="tableheading">Portfolio Shield Details</div>
                <TableContainer className="table_height">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Contract</TableCell>
                        <TableCell>quantity</TableCell>
                        <TableCell>notional value</TableCell>
                        <TableCell>Protected value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody id="msccontracts">
                      {props.MultiShieldSelectedData && props.MultiShieldSelectedData.ClosePrice
                        && props.MultiShieldSelectedData.ClosePrice.length > 0
                        && Object.keys(props.MultiShieldContracts).map((_, i) => (
                          <tr key={i}>
                            <td>
                              {['XND','SSP','MRUT'].some(word => Object.keys(props.MultiShieldContracts)[i].startsWith(word))? "" :
                                <Checkbox 
                                  name={Object.keys(props.MultiShieldContracts)[i]} 
                                  value={Object.keys(props.MultiShieldContracts)[i]}
                                  checked={Object.values(props.MultiShieldContracts)[i]}
                                  onChange={(e) => handleMarketShieldContractChange(e)}>
                                </Checkbox>
                              }
                            </td>
                            <td>{i + 1}</td>
                            <td>{Object.keys(props.MultiShieldContracts)[i]}</td>
                            <td>{Object.values(props.MultiShieldContracts)[i] && props.MultiShieldSelectedData.ConQty[i] !== undefined ? props.MultiShieldSelectedData.ConQty[i] : "-"}</td>
                            <td>{Object.values(props.MultiShieldContracts)[i] ? '$'+Number(addZeroes(Number(parseFloat(props.MultiShieldSelectedData.NotionalVal[i]).toFixed(2)))
                            ).toLocaleString() : "-"}</td>
                            <td>{Object.values(props.MultiShieldContracts)[i] ? '$'+Number(addZeroes(Number(parseFloat(
                              props.MultiShieldSelectedData.ProtectedVal[i]).toFixed(2)))).toLocaleString() : "-"}</td>
                          </tr>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan="4">Total:</TableCell>
                        <TableCell>${Number(
                          addZeroes(Number(parseFloat(props.MultiShieldSelectedData["TotalNotionalVal"]).toFixed(2)))
                        ).toLocaleString()}</TableCell>
                        <TableCell>${Number(
                          addZeroes(Number(parseFloat(props.MultiShieldSelectedData["TotalProtectedVal"]).toFixed(2)))
                        ).toLocaleString()}</TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="solutiontrack">
              <div className="trackstates">
                <h3>portfolio analytics <Tooltip content="Compare the trade-offs between protection cost and fit for different Shield solutions, as measured by tracking error and correlation."><img src="Assets/risk-info.png"></img></Tooltip></h3>
                <div className="statesview">
                  <div className="trackrisk">
                    <img src={mar1}></img>
                    <p id="trackingerror">
                      Tracking error
                      <span>{Number(
                        addZeroes(
                          Number(parseFloat(props.MSConcentratedData["tracking_error"] * 100).toFixed(2))
                        )
                      ).toLocaleString()}%</span>
                    </p>
                  </div>
                  {props.risk_dial_data && props.risk_dial_data["month"] && props.risk_dial_data["month"][risk_percent] ? (
                    <div className="portfolio-bars">
                      <div className="portfolio-risk-bar">
                        <div className="market-risk-progress">
                          <div
                            className="market-risk-bar"
                            style={{
                              opacity: 1,
                              background:
                                props.risk_dial_data["month"][risk_percent]["market_risk"] !== undefined
                                  ? GetRiskBarColor(props.risk_dial_data["month"][risk_percent]["market_risk"] * 100)
                                  : 0,
                              width:
                                props.risk_dial_data["month"][risk_percent]["market_risk"] !== undefined
                                  ? `${props.risk_dial_data["month"][risk_percent]["market_risk"] * 200}%`
                                  : 0,
                            }}
                          ></div>
                        </div>
                        <p>
                          Market risk
                          <Tooltip content={(
                            <div className="tiptext">
                              <p>VIX (CBOE S&P500 Implied Volatility Index).</p>
                              <p>Low = 0% - 15%</p>
                              <p>Medium = 15% - 20%</p>
                              <p>High = 20% - 30%</p>
                              <p>Very High = 30% +</p>
                            </div>
                          )}><img src="Assets/risk-info.png"></img></Tooltip>
                        </p>
                      </div>
                      <div className="portfolio-risk-bar">
                        <div className="market-risk-progress">
                          <div
                            className="progress-done-2"
                            style={{
                              opacity: 1,
                              background:
                                props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] !== undefined
                                  ? GetRiskBarColor(props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] * 100)
                                  : 0,
                              width:
                                props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] !== undefined
                                  ? `${props.risk_dial_data["month"][risk_percent]["risk_wo_shield"] * 200}%`
                                  : 0,
                            }}
                          ></div>
                        </div>
                        <p>
                          Portfolio risk
                          <Tooltip content={(
                            <div className="tiptext">
                              <p>Analyzed risk level of portfolio: </p>
                              <p>semi variance measure (annualized standard deviation of downside risk or negative returns) </p>
                              <p>Low = 0% - 15% </p>
                              <p>Medium = 15% - 20% </p>
                              <p>High = 20% - 30% </p>
                              <p>Very High = 30% +</p>
                            </div>
                          )}><img src="Assets/risk-info.png"></img></Tooltip>
                        </p>
                      </div>
                      <div className="portfolio-risk-bar">
                        <div className="market-risk-progress">
                          <div
                            className="progress-done-3"
                            style={{
                              opacity: 1,
                              background:
                                props.risk_dial_data["month"][risk_percent]["risk_w_shield"] !== undefined
                                  ? GetRiskBarColor(props.risk_dial_data["month"][risk_percent]["risk_w_shield"] * 100)
                                  : 0,
                              width:
                                props.risk_dial_data["month"][risk_percent]["risk_w_shield"] !== undefined
                                  ? `${props.risk_dial_data["month"][risk_percent]["risk_w_shield"] * 200}%`
                                  : 0,
                            }}
                          ></div>
                        </div>
                        <p>
                          Shielded risk
                          <Tooltip content={(
                            <div className="tiptext">
                              <p>Analyzed risk level of portfolio, accounting for any Shield in place.</p>
                              <p>Low = 0% - 15%</p>
                              <p>Medium = 15% - 20% </p>
                              <p>High = 20% - 30% </p>
                              <p>Very High = 30% +</p>
                            </div>
                          )}><img src="Assets/risk-info.png"></img></Tooltip>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <p>Risk Data Not Found.</p>
                    </div>
                  )}
                </div>
              </div>
              {props.projection_cone_data[period_str] && props.projection_cone_data[period_str][props.level_value] ? (
                <>
                  <div className="shieldstates">
                    <div className="contentshield">
                      <ul className="subnavbar-menu navtab">
                        <li>
                          <a className={props.tabvalue === "Projected Value" ? "SUBITEM-selected " : "SUBITEM"} eventKey="Projected Value" onClick={() => handleTabChange('Projected Value')} > Projected Value <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                        <li>
                          <a  id="terminaldistribution" className={props.tabvalue === "Terminal Distribution" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Terminal Distribution" onClick={() => handleTabChange('Terminal Distribution')}>Terminal Distribution <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <TabContainer defaultActiveKey={props.tabvalue}>
                    <TabContent>
                      <TabPane key="Projected Value" eventKey="Projected Value" active={props.tabvalue === 'Projected Value'} >
                        <ProjectionCone
                          key={new Date().getTime()}
                          data={props.projection_cone_data[period_str][props.level_value]}
                          portfolioValue={Array(
                            props.projection_cone_data[period_str][props.level_value]["upper_bound"].length
                          ).fill(Number(selectedPortfolioTotalValue))}
                          protectedValue={Array(
                            props.projection_cone_data[period_str][props.level_value]["upper_bound"].length
                          ).fill(Number(selectedPortfolioTotalValue) * 0.90)}
                        />
                      </TabPane>
                      <TabPane key="Terminal Distribution" eventKey="Terminal Distribution" active={props.tabvalue === 'Terminal Distribution'}>
                        {
                          Object.keys(props.TerminalData).length === 0 ?
                            <div style={{ textAlign: "center" }}>
                              <br />
                              <p>No Data found for Terminal Risk</p>
                            </div> :
                            <div id = "terminalbellcurve" className="barchart">
                              <BellCurve data={props.TerminalData} />
                            </div>
                        }
                      </TabPane>
                    </TabContent>
                  </TabContainer>
                </>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <br />
                  <p>No Data found for Projection Cone</p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      {shieldType === "msc" && (
        <Popup id="PortfolioPopUp" open={showPopup} modal>
          <div className="add-portfolio-popup">
            <IconButton
              onClick={() => setShowPopup(false)}
              className="closepopup"
            >
              <img src={close}></img>
            </IconButton>
            <MarketShieldSummary checkoutdata={props.MultiShieldSelectedData}
              ShieldData={props.MSConcentratedData}
              buyShield={PurchaseShield}
              marketShield={true} agreementContractData={props.contractData}
              showPurchaseShield={true} AgreementID={props.AgreementID}
              selectedPeriod={props.period_value} contractData={props.contractData}
              handleClose={handleClose}
              PopupTitle="Market Shield Concentracted Purchase Agreement Summary"
              chosenIndex={props.ms_chosenIndex}
              customerInfo={props.customerInfo}
              shield_type={"msc"}
              period_str={period_str}
              closeMarketSummaryPopup={closeMarketSummaryPopup} />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default ConcentratedShield;
