import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function DeleteExternalAccount(account_id) {
    const Token = sessionStorage.getItem("Token");

    const header = { Authorization: Token };

    // const session_id = sessionStorage.getItem("sessionID");

    var raw = JSON.stringify({ account_id: account_id });

    var requestOptions = {
        method: "DELETE",
        body: raw,
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
        try {

            const response = await fetchWithTimeout(
                process.env.REACT_APP_DELETE_EXTERNAL_ACCOUNT_API,
                requestOptions
            );
            if (!response.ok) {
                console.log("Could not delete this account");
                return false;
            } else {
                const message = await response.json();
                if (message['result'] !== "") {
                    return message['result'];
                }
                else {
                    console.log(message['error']);
                    return false;
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("The request timed out, Please try again");
                // window.location.reload();
                return 0
            }
            else {
                return 0
            }
        }
    }else{
        return 401
    }
    
}
