import React from "react";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import "./ADClientPortfolioTabScreen.css";
import Navbar from "../../Components/Navbar/Navbar_official";
import AdvisorClientPortfolioTab from "../../Components/AdvisorClientPortfolioTab/AdvisorClientPortfolioTab";
import { Container, Row, Col } from 'react-bootstrap';

function AdvisorDashboardStockTabScreen(props) {
    return (
        <div className="ai-content-part advisor">
            <Navbar />
            <section className="portfolio-table-wrapper">
                    <AdvisorClientPortfolioTab />
            </section>
        </div>
    );
}

export default withWidth()(AdvisorDashboardStockTabScreen);
