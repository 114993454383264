import React from "react";
import "./PortfolioDetails.css";
import Popup from "../Popup/Popup";
import { DeletePortfolio } from "../../Apis/DeletePortfolio";
import { UpdateCsvToDb } from "../../Apis/UpdateCsvToDb";
import ConfirmPopup from "../ConfirmationPopup/confirmPopup";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { useHistory } from "react-router-dom";
import { addZeroes } from "../Utilities/AddZeros";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import morebtn from "../../images/more.svg"
import refresh from "../../images/refresh.svg"
import editicon from "../../images/edit.svg"
import deleticon from "../../images/delete.svg"
import check from "../../images/check_w.svg"
import close from "../../images/close_w.svg";
import PageLoader from "../PageLoader/PageLoader";

function PortfolioDetails(props) {
  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["infocard-wrap"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["infocard-wrap", <div></div>];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["infocard-wrap", <div></div>];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["infocard-wrap", <div></div>];
    }
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const rendering = getRendering(props.width);
  const history = useHistory();
  // const forwardClick = () => history.push({
  //   pathname: props.routeTo,
  //   state: {
  //     portfolioid: location.state.portfolioid,
  //     stocksData: ( props.stocksData === undefined) ? location.state.stocksData : props.stocksData
  //   }
  // });

  const forwardClick = () => {
    const stocksData = props.stocksData === undefined ? location.state.stocksData : props.stocksData;

    if (stocksData.number_of_lineitems === undefined || stocksData.number_of_lineitems === 0) {
      setBdOpen(false);
      setPopupState(true);
      setPopupMessage("Please add atleast one stock to your portfolio to proceed.");
      setPopupLoginRedirect(false);
      return false;
    }
    else {
      history.push({
        pathname: props.routeTo,
        state: {
          portfolioid: location.state.portfolioid,
          stocksData: stocksData,
        },
      });
    }
  };

  const location = useLocation();
  React.useEffect(() => {
  }, [location]);

  const portfolioName = sessionStorage.getItem("PortfolioName");
  const selectedPortfolioTotalStocks = sessionStorage.getItem(
    "PortfolioTotalStocks"
  );
  const selectedPortfolioTotalValue = sessionStorage.getItem(
    "PortfolioTotalValue"
  );

  const [alertType, setAlertType] = React.useState("");
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [confirmPopup, setConfirmPopup] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(false);
  const [confirmPopupMessage, setConfirmPopupMessage] = React.useState("");

  const PortfolioSummaryScreen = () => history.push("./PortfolioSummaryScreen");
  const PortfolioTable = () => history.push({
    pathname: "/PortfolioTable",
    state: {
      portfolioid: location.state.portfolioid,
    }
  })
  const portfolioShieldStatus = sessionStorage.getItem("PortfolioShieldStatus");
  const EditPortfolioScreen = () => history.push({
    pathname: "/EditPortfolioScreen",
    state: {
      portfolioid: location.state.portfolioid,
    }
  });
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);

  const confirmDelete = () => {
    setConfirmPopup(false);
    setBdOpen(true);
    DeletePortfolio(location.state.portfolioid).then((data) => {
      if (data === false) {
        setPopupState(true);
        setPopupMessage("Unable to delete portfolio. Please try again or contact support@adaptive-investments.com.");
        setAlertType("error");
        setBdOpen(false);
      } else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are facing problems connecting to our servers, please try again later");
        setAlertType("error");
        setBdOpen(false);
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      }
      else {
        setBdOpen(false);
        setShowDeleteConfirm(true);
        TagManager.dataLayer({
          dataLayer: {
            event: 'Portfolio Deleted',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
          },
        });
      }
    });
  };

  const UpdateCustomerCsvToDB = () => {
    setConfirmPopup(false);
    setBdOpen(true);
    UpdateCsvToDb().then((data) => {
      if (data === false) {
        setPopupState(true);
        setPopupMessage("Unable to update portfolio. Please try again or contact support@adaptive-investments.com.");
        setAlertType("error");
        setBdOpen(false);
      } else if (data === 0) {
        setPopupState(true);
        setPopupMessage("We are facing problems connecting to our servers, please try again later");
        setAlertType("error");
        setBdOpen(false);
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
      } else {
        setBdOpen(false);
        setShowUpdateConfirm(true);
        TagManager.dataLayer({
          dataLayer: {
            event: 'Portfolio Updated',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
          },
        });
      }
    });
  };

  const handleDeleteSuccess = () => {
    console.log(" dev");
    PortfolioSummaryScreen();
  }

  const handleUpdateSuccess = () => {
    PortfolioTable();
  }

  const handlePortfolioDelete = () => {
    setConfirmPopupMessage("Are you sure you want to delete this portfolio?");
    setConfirmPopup(true);
  };

  const route_button = (
    <button
      id="forwardroutebutton"
      className="ai-btn solid secondary"
      onClick={() => forwardClick()}
      disabled={props.active}
    >
      <img className="ai-img" src={props.imgSrc} />
      {props.routeText}
    </button>
  );

  const confirm_csv_button = (
    <>
      <button
        className="ai-btn solid primary"
        onClick={() => UpdateCustomerCsvToDB()}
      >
        <img className="ai-img" src={check} />
        Confirm
      </button>
      <button
        className="ai-btn solid secondary"
        onClick={() => PortfolioTable()}
      >
        <img className="ai-img" src={close} />
        Cancel
      </button>
    </>
  );

  const delete_button = (
    <button
      onClick={() => handlePortfolioDelete()}
      disabled={props.active}
    >
      <img src={deleticon}></img>
      Delete Portfolio
    </button>
  );

  const enable_shield_button = (
    <button className="ai-btn primary">
      <img className="ai-img" src="Assets/buyshield.png" />
      Enable Shield
    </button>
  );

  const forward_route = () => {
    if (props.forward_route === true) {
      return route_button;
    } else {
      return null;
    }
  };

  const confirm_csv_route = () => {
    if (props.confirm_csv_route === true) {
      return confirm_csv_button;
    } else {
      return null;
    }
  };

  const show_delete = () => {
    if (props.delete_button === true) {
      return delete_button;
    } else {
      return null;
    }
  };

  const show_enable = () => {
    if (props.enable_shield === true) {
      return enable_shield_button;
    } else {
      return null;
    }
  };
  function handleTabChange(tabvalue) {
    props.onChange(tabvalue);
  }

  return (
    <div className="port_detail">
      <PageLoader bdopen={bdopen} />
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title="Portfolio Details"
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
      />
      <ConfirmPopup
        trigger={confirmPopup}
        setTriggerYes={() => confirmDelete()}
        setTriggerNo={setConfirmPopup}
        popupMessage={confirmPopupMessage}
      />
      <div className="details_wrap">
        <div className={rendering[0]}>
          <div className="infocard">
            <h3>PORTFOLIO</h3>
            <p>{portfolioName}</p>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>HOLDINGS</h3>
            <p id="holdings">{selectedPortfolioTotalStocks}</p>
          </div>
          <div class="saprator"></div>
          <div className="infocard">
            <h3>PORTFOLIO VALUE</h3>
            <p>
              ${Number(
                addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))
              ).toLocaleString("en-US")}
            </p>
          </div>
        </div>
        {props.showTabs && (
          <nav className="subnavbar" id="navigation">
            {props.show_refresh_button &&
              <p className="last_updated_text"><img src="Assets/ibutton.png" /> Last Updated on {props.LastUpdated}</p>
            }
            <div className="button_wrapper">
              {props.show_refresh_button &&
                <a className="ai-btn primary line"><img src={refresh}></img>Refresh</a>
              }
              {forward_route()}
              {confirm_csv_route()}
              {props.show_refresh_button &&
                <div id="moreoptions" className="dropdown">
                  <a className="more ai-btn round small br-white"><img src={morebtn}></img></a>
                  <ul className="dropoption">
                    <li><button onClick={() => EditPortfolioScreen()}><img src={editicon}></img>Edit Portfolio</button></li>
                    {portfolioShieldStatus !== "active" &&
                      portfolioShieldStatus !== "partial" && (
                        <li>{show_delete()}</li>
                      )}
                  </ul>
                </div>
              }
            </div>
          </nav>
        )}
        {!props.showTabs && (
          <div className="button_wrapper">
            {props.show_refresh_button &&
              <a className="ai-btn primary line"><img src={refresh}></img>Refresh</a>
            }
            {forward_route()}
            {props.show_refresh_button &&
              <div className="dropdown">
                <a className="more ai-btn round small br-white"><img src={morebtn}></img></a>
                <ul className="dropoption">
                  <li><button onClick={() => EditPortfolioScreen()}><img src={editicon}></img>Edit Portfolio</button></li>
                  {portfolioShieldStatus !== "active" &&
                    portfolioShieldStatus !== "partial" && (
                      <li>{show_delete()}</li>
                    )}
                </ul>
              </div>
            }
          </div>
        )}
      </div>
      {props.showTabs && (
        <ul className="subnavbar-menu navtab">
          <li>
            <a className={props.tabvalue === "hedgeable" ? "SUBITEM-selected " : "SUBITEM"} eventKey="hedgeable" onClick={() => handleTabChange('hedgeable')} >Hedgeable Assets</a>
          </li>
          <li>
            <a className={props.tabvalue === "unhedgeable" ? "SUBITEM-selected" : "SUBITEM"} eventKey="unhedgeable" onClick={() => handleTabChange('unhedgeable')}>Unhedgeable Assets</a>
          </li>
        </ul>
      )}
      {showDeleteConfirm && (
        <div>
          <div className="popup" id="deleteconfirm">
            <div className="popup-object">
              <div className="confirm-heading">
                <CheckCircleOutlineRoundedIcon className="icon" />
                Success
              </div>
              <div className="body-message">
                <p>Portfolio Deleted Successfully </p>
              </div>
              <button
                className="ai-btn primary solid "
                onClick={handleDeleteSuccess}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {showUpdateConfirm && (
        <div>
          <div className="popup" id="updateconfirm">
            <div className="popup-object">
              <div className="confirm-heading">
                <CheckCircleOutlineRoundedIcon className="icon" />
                Success
              </div>
              <div className="body-message">
                <p>Portfolio Updated Successfully </p>
              </div>
              <button
                className="ai-btn primary solid "
                onClick={handleUpdateSuccess}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withWidth()(PortfolioDetails);
