import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popup from "../Popup/Popup";
import { makeStyles } from "@material-ui/core/styles";
import AdvisorCsvLineItemChangesTable from "../AdvisorCsvLineItemChangesTable/AdvisorCsvLineItemChangesTable";
import { AddCsvToDb } from "../../Apis/AddCsvToDb";
import searchicon from "../../images/search.svg";
import { useHistory } from "react-router-dom";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";


export default function AdvisorCsvLineItemChanges() {
  const [searchKey, setSearchKey] = useState("");
  const [Flag, setFlag] = useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [unfilteredStocks, setunfilteredStocks] = React.useState([]);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  var ChangesData = JSON.parse(sessionStorage.getItem("AdvisorCsvData"));
  const [AdvisorCsvPortfolioChange, setAdvisorCsvPortfolioChange] =
    React.useState([]);
  const history = useHistory();
  const goToDashboard = () => history.push("./AdvisorDashboard");
  const [DisableImportFlag,setDisableImportFlag] = React.useState(false);
  const [popupLoginRedierct , setPopupLoginRedirect] = React.useState(false);
  const [confirmSuccess , setConfirmSuccess] = useState(false)

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  if (Flag === false) {
    console.log(" in client ", ChangesData);
    if (ChangesData !== null && ChangesData !== "") {
      if (
        typeof Object.entries(ChangesData["changes"]) != "undefined" &&
        Object.entries(ChangesData["changes"]).length > 0
      ) {
        setGotData(true);
        if (Object.keys(ChangesData["changes"]["portfolio_line_items"]).length > 0) {
        setAdvisorCsvPortfolioChange(
          Object.entries(ChangesData["changes"]["portfolio_line_items"]).map(
            ([key, value]) => ({ [key]: value })
          )
        );} else {
          setPopupState(true);
          setPopupMessage("No Line Item Level Changes Found");
          setPopupTitle("Import Status");
        }
      } else {
        setPopupState(true);
        setPopupMessage("No Changes Were Detetected In The CSV File");
        setPopupTitle("Import Status");
        setGotData(true);
      }
      if(Object.keys(ChangesData["errors"]).length > 0){
        setDisableImportFlag(true);
        setPopupState(true);
        setPopupMessage("CSV file has errors. Please correct the errors and try again.");
        setPopupTitle("Import Status");
        setGotData(true);
      }
    } else {
      setPopupState(true);
      setPopupMessage("Please import new CSV");
      setPopupTitle("Import Status");
      setGotData(true);
      setDisableImportFlag(true);
      sessionStorage.removeItem("AdvisorCsvData");
    }
    if (GotData === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  const cofirmCsvImport = () => {
    setBdOpen(true);
    AddCsvToDb().then((data) => {
      if (data === true) {
        console.log("data_status", data);
        setConfirmSuccess(true)
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("CSV imported successfully");
        setPopupTitle("Import Status");
        setAlertType("success");
        sessionStorage.removeItem("AdvisorCsvData");
        TagManager.dataLayer({
          dataLayer: {
            event: 'Advisor CSV Imported',
            userType:sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
          },
        });
        
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      }else {
        // goToDashboard()
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("CSV import failed");
        setPopupTitle("Import Status");
        setAlertType("error");
        setPopupLoginRedirect(false);
        
      }
    });
  };

  const abortCsvImport = () => {
    sessionStorage.removeItem("AdvisorCsvData");
    window.location.reload();
  }

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect = {popupLoginRedierct}
        onSuccessRouteTo = {confirmSuccess ? "/ClientPortfolio": undefined}
      ></Popup>
      <div className="search_bar">
        <div className="searchinput">
          <img src={searchicon}></img>
          <input
            className="ai-form-value"
            type="text"
            placeholder="Search Clients"
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
          />
        </div>
        <button
          className="ai-btn primary solid"
          onClick={cofirmCsvImport}
          disabled={DisableImportFlag}
        >
          Confirm
        </button>
        <button className="ai-btn primary solid" onClick={abortCsvImport}>
          Abort
        </button>
      </div>
      <div>
        {AdvisorCsvPortfolioChange.map((client, index) =>
          client[Object.keys(client)]["account_changes"].map(
            (account, index) => (
              <AdvisorCsvLineItemChangesTable
                accountId={Object.keys(account)[0]}
                clientId={Object.keys(client)[0]}
                clientName={client[Object.keys(client)[0]].client_name}
                AccountName={account[Object.keys(account)].portfolioname}
                LineItemChanges={
                  account[Object.keys(account)].line_item_changes
                }
              />
            )
          )
        )}
      </div>
    </div>
  );
}
