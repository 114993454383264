import React from "react";
import "./CsvStockSummaryScreen.css";
import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../../Components/Navbar/Navbar_official";
import PageHeader from "../../Components/PageHeader/PageHeader";
import StocksSummary from "../../Components/StocksSummary/StocksSummary";

export default function CsvStocksScreen() {
    const steps = ["Step 1", "Step 2", "Step 3"];
    const useStyles = makeStyles(() => ({
        root: {
            "& .MuiStepIcon-active": { color: "#E58B76" },
            "& .MuiStepIcon-completed": { color: "#E58B76" },
        },
    }));

    const classes = useStyles();

    return (
        <div className="ai-content-part">
            <Navbar />
            <PageHeader
                heading="Review of Stocks"
                backButton={true}
                routeTo="./PortfolioSummaryScreen"
                exportButton={true}
                importType="csv"
            />
            <div className="plaidstock_page-holder">
                <Box sx={{ width: "100%" }}>
                    <Stepper className={classes.root} activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <StocksSummary
                    data_source="csv"
                />
            </div>
        </div>
    );
}
