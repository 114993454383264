import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function AddPortfolioLineItems(portfolioid) {

  const Token = sessionStorage.getItem("Token");

  const header = { Authorization: Token };
  // const session_id = sessionStorage.getItem("sessionID");

  var raw = JSON.stringify({
    session_id: Token,
    portfolio_id: portfolioid,
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
    headers: header,
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetch(
        process.env.REACT_APP_ADD_PLAID_USER_LINE_ITEMS,
        requestOptions
      );
      if (!response.ok) {
        console.log("Could not add your portfolio from plaid/csv");
        return false;
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          console.log(message["result"]);
          return message["result"];
        } else {
          console.log(message["error"]);
          //alert("Could not add this portfolio");
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else{
        return 0
      }
    }
  }
  else{
    return 401
  }
}
