import React, { useState } from "react";
import Popup from "../Popup/Popup";
import "./AdvisorCsvClientChange.css";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import { AddCsvToDb } from "../../Apis/AddCsvToDb"
import { useHistory } from "react-router-dom"
import searchicon from "../../images/search.svg";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";

function AdvisorCsvClientStatus(props) {
  const [AdvisorCsvClientChange, setAdvisorCsvClientChange] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [Flag, setFlag] = useState(false);
  const [bdopen, setBdOpen] = React.useState(true);
  const [GotData, setGotData] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const AdvisorCsvData = JSON.parse(sessionStorage.getItem("AdvisorCsvData"));
  const sessionId = sessionStorage.getItem("sessionID");
  const history = useHistory();
  const goToDashboard = () => history.push("./AdvisorDashboard");
  const [DisableImportFlag, setDisableImportFlag] = React.useState(false);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [confirmSuccess , setConfirmSuccess] = useState(false);


  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  if (Flag === false) {
    console.log(sessionId);
    console.log(" in client ", AdvisorCsvData);
    if (AdvisorCsvData !== null && AdvisorCsvData !== "") {
      if (
        (typeof Object.entries(AdvisorCsvData['changes']) != "undefined") &&
        (Object.entries(AdvisorCsvData['changes']).length > 0)) {
        if (Object.keys(AdvisorCsvData["changes"]["customers"]).length > 0) {
          var lyst = [];
          for (let [key, value] of Object.entries(
            AdvisorCsvData["changes"]["customers"]
          )) {
            lyst.push({
              clientid: value.externalid,
              clientname: value.fullname,
              action: value.version,
            });
            setAdvisorCsvClientChange([...lyst]);
          }
        } else {
          setPopupState(true);
          setPopupMessage("No Client Level Changes Found");
          setPopupTitle("Import Status");
        }
        setGotData(true);
      } else {
        setPopupState(true);
        setPopupMessage("No Changes Were Detetected In The CSV File");
        setPopupTitle("Import Status");
        setGotData(true);
      }
      if (Object.keys(AdvisorCsvData["errors"]).length > 0) {
        setDisableImportFlag(true);
        setPopupState(true);
        setPopupMessage("CSV file has errors. Please correct the errors and try again.");
        setPopupTitle("Import Status");
        setGotData(true);
      }
    } else {
      setPopupState(true);
      setPopupMessage("Please Import A Csv To Proceed");
      setPopupTitle("Import Status");
      setGotData(true);
      setDisableImportFlag(true);
      sessionStorage.removeItem("AdvisorCsvData");
    }
    if (GotData === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  const cofirmCsvImport = () => {
    setBdOpen(true);
    AddCsvToDb().then((data) => {
      if (data === true) {
        console.log("data_status", data);
        sessionStorage.removeItem("AdvisorCsvData");
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("CSV imported successfully");
        setPopupTitle("Import Status");
        setAlertType("success");
        setConfirmSuccess(true);
        TagManager.dataLayer({
          dataLayer: {
            event: 'Advisor CSV Imported',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
          },
        });
      }
      else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      } else {
        // goToDashboard()
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("CSV import failed");
        setPopupTitle("Import Status");
        setAlertType("error");
        setPopupLoginRedirect(false);

      }
    });
  };


  const abortCsvImport = () => {
    sessionStorage.removeItem("AdvisorCsvData");
    window.location.reload();
  }


  function getRendering(screenWidth) {
    if (isWidthUp("lg", screenWidth)) {
      return ["DashboardStocks"];
    } else if (isWidthDown("lg", screenWidth) && isWidthUp("md", screenWidth)) {
      return ["DashboardStocks"];
    } else if (isWidthDown("md", screenWidth) && isWidthUp("sm", screenWidth)) {
      return ["DashboardStocks-2"];
    } else if (isWidthDown("sm", screenWidth) && isWidthUp("xs", screenWidth)) {
      return ["DashboardStocks-2"];
    }
  }

  const rendering = getRendering(props.width);
  const headers = ["CLIENT ID", "CLIENT NAME", " "];

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
        onSuccessRouteTo = {confirmSuccess ? "/ClientPortfolio": undefined}
      ></Popup>
      <div className="search_bar">
        <div className="searchinput">
          <img src={searchicon}></img>
          <input
            className="ai-form-value"
            type="text"
            placeholder="Search Clients"
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
          />
        </div>
        <button
          className="ai-btn primary solid"
          onClick={cofirmCsvImport}
          disabled={DisableImportFlag}
        >
          Confirm
        </button>
        <button className="ai-btn primary solid" onClick={abortCsvImport}>Abort</button>
      </div>
      <div className="table_holder">
        <table className={rendering[0]}>
          {
            <thead>
              <tr>
                {headers.map((header, i) => (
                  <th>
                    <div className="stck_table_heading">{header}</div>
                  </th>
                ))}
              </tr>
            </thead>
          }
          <tbody>
            {AdvisorCsvClientChange.filter((val) => {
              if (searchKey === "") {
                return val;
              } else if (
                val.clientname.toLowerCase().includes(searchKey.toLowerCase())
              ) {
                return val;
              }
            }).map((row) => (
              <tr>
                <td>{row.clientid}</td>
                <td>{row.clientname}</td>
                <td>{row.action}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default withWidth()(AdvisorCsvClientStatus);
