import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function UpdatePortfolioRisk() {
    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };
    // const session_id = sessionStorage.getItem("sessionID");

    var raw = JSON.stringify({
        session_id: Token,
    });

    var requestOptions = {
        method: "PATCH",
        body: raw,
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
        try {

            const response = await fetchWithTimeout(
                process.env.REACT_APP_PORTFOLIO_RISK,
                requestOptions
            );
            if (!response.ok) {
                //alert("Could not update portoflio risk");
            } else {
                return true;
            }
        } catch (error) {
            if (error.name === "AbortError") {
                //alert("The request timed out, Please try again");
                window.location.reload();
            }
            else {
                return 0
            }
        }
    }
    else{
        return 401
    }
}
