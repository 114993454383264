import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetSelfAssemblyData(selected_portfolio_id) {

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    portfolio_id: selected_portfolio_id
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)){
    try {

      const response = await fetchWithTimeout(
        process.env.REACT_APP_SELF_ASSEMBLY_WO_SESSIONID,
        requestOptions
      );
      if (!response.ok) {
        console.log("Could fetch Self Assembly Data for selected porfolio ID");
        return false;
      } else {
        const data = await response.json();
        if (data["result"] !== "") {
          return data["result"];
        } else {
          console.log(data["error"]);
          console.log("Could fetch data for selected porfolio ID");
          return false;
        }
      }
    }catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else{
        return 0
      }
    }
  }
  else{
    return 401
  }
}
