import React, { useState } from 'react';
import { addZeroes } from "../Utilities/AddZeros";
import "./BackTestV2.scss";
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid, Tooltip,
    ResponsiveContainer,
    Scatter
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{
                backgroundColor: '#FFF',
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px'
            }}>
                <p>{`${label}`}</p>
                {payload.map((pay, index) => {
                    const originalKey = `original${pay.name.replace(/\s/g, "")}`;
                    const valueToDisplay = pay.payload[originalKey] !== undefined ? pay.payload[originalKey] : pay.value;
                    if (pay.dataKey === 'payoff' || pay.dataKey === "compoundedShieldPayoff") {
                        return (
                            <p key={index} style={{ color: `${payload[index].color}` }}>
                                {`${pay.name}: ${Number(
                                    addZeroes(Number(parseFloat(valueToDisplay).toFixed(2)))
                                ).toLocaleString("en-US", currObj)}`}
                            </p>
                        );
                    } else {
                        return (
                            <p key={index} style={{ color: `${payload[index].color}` }}>
                                {`${pay.name}: ${(valueToDisplay * 100).toFixed(2)}%`}
                            </p>
                        );
                    }
                })}
            </div>
        );
    }

    return null;
};


var currObj = {
    style: "currency",
    currency: "USD",
};

function BacktestV2Graph({ rawData }) {
    const [showPortfolio, setShowPortfolio] = useState(true);
    const [showLimit, setShowLimit] = useState(true);
    const [show6040, setShow6040] = useState(true);
    const [showSP500, setShowSP500] = useState(true);
    // const [showNamePrice, setShowNamePrice] = useState(true);
    // const [showLimitRisk, setShowLimitRisk] = useState(true);

    const riskReturnData = {
        'portfolioShield': 'Data1',
        'sp500': 'Data2',
        'portfolioWithoutShield': 'Data3',
        'riskEquivalent': 'Data4'
    };

    let currentYear = null;

    const payoffDetailsMap = rawData['payout_details'].reduce((acc, item) => {
        acc[item.payoff_date] = item;
        return acc;
    }, {});

    const portfolioValues = Object.values(rawData['portfolio-without-shield']);
    const limitValues = Object.values(rawData['limit-drawdown-shield']);
    const data6040Values = Object.values(rawData['60/40']);
    const sp500Values = Object.values(rawData['S&P500']);

    const allValues = [...portfolioValues, ...limitValues, ...data6040Values, ...sp500Values];
    let minAllData = Math.min(...allValues);

    const data = Object.keys(rawData['portfolio-without-shield']).map(date => {
        let item = {
            date: date,
            portfolio: rawData['portfolio-without-shield'][date],
            limit: rawData['limit-drawdown-shield'][date],
            data6040: rawData['60/40'][date],
            sp500: rawData['S&P500'][date],
        };

        if (payoffDetailsMap[date]) {
            item.originalPayoff = payoffDetailsMap[date].payoff;
            item.originalCompoundedShieldPayoff = payoffDetailsMap[date].compounded_shield_payoff;
            item.payoff = minAllData * 0.95;
            item.compoundedShieldPayoff = minAllData;
        }
        return item;
    });

    const formatXAxis = (tickItem) => {
        const year = new Date(tickItem).getFullYear();
        const month = new Date(tickItem).getMonth();
        if (year !== currentYear) {
            currentYear = year;
            return year;
        }
        else {
            return '';
        }
    }

    function SimulationEndValues({ simulationEndValues }) {
        const dataKeyToValue = {
            "Backtest Starting Value": rawData["backtest-starting-value"],
            "Portfolio Without Shield": simulationEndValues['portfolio-without-shield'],
            "Portfolio With Shield": simulationEndValues['limit-drawdown-shield'],
            // "Name Your Price Shield": simulationEndValues['name-your-price-shield'],
            // "Dail Down Risk": simulationEndValues['limit-risk-shield'],
            "S&P 500": simulationEndValues['sp500'],

        };

        return (
            <div style={{ padding: '10px' }}>
                {Object.entries(dataKeyToValue).map(([key, value]) => {
                    let formattedValue = Number(
                        Number(parseFloat(value).toFixed(2))
                    ).toLocaleString("en-US", currObj);

                    if (key === "S&P 500") {
                        return (
                            <p>{`${key.charAt(0).toUpperCase() + key.slice(1)}: ${Number(parseFloat(value).toFixed(2)).toLocaleString("en-US")}`}</p>
                        );
                    } else {
                        return (
                            <p>{`${key.charAt(0).toUpperCase() + key.slice(1)}: ${formattedValue}`}</p>
                        );
                    }
                })}
            </div>
        );
    }

    return (
        <div>
            <div style={{ display: "flex", width: "100%" }}>
                <div id="backtestgraph" style={{ width: "90%", marginRight: "20px" }}>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid stroke="#ccc" vertical={false} />
                            <XAxis dataKey="date" tickFormatter={formatXAxis} interval='preserveStartEnd' tickLine={false} />
                            <YAxis yAxisId="left" tickFormatter={(tick) => `${tick * 100}%`} axisLine={false} />
                            {/* <YAxis yAxisId="payoff" orientation="bottom" hide={true} domain={[minPayoff, maxPayoff]} /> */}
                            <Tooltip content={<CustomTooltip />} />
                            {showLimit &&
                                <Line
                                    yAxisId="left"
                                    dot={false}
                                    type="monotone"
                                    dataKey="limit"
                                    name="Portfolio With Shield"
                                    stroke="#800000"
                                />}
                            {showPortfolio && <Line yAxisId="left" dot={false} type="monotone" dataKey="portfolio" name="Portfolio without Shield" stroke="#54C1FF" />}
                            {show6040 && <Line yAxisId="left" dot={false} type="monotone" dataKey="data6040" name="Risk Equivalent Portfolio: 60-40" stroke="#ED6663" />}
                            {showSP500 && <Line yAxisId="left" dot={false} type="monotone" dataKey="sp500" name="S&P 500" stroke="#FFA654" />}
                            {/* {showNamePrice && <Line yAxisId="left" dot={false} type="monotone" dataKey="name_your_price" name="Name-Your-Price Shield" stroke="#96BE25" />} */}
                            {/* {showLimitRisk && <Line yAxisId="left" dot={false} type="monotone" dataKey="limit_risk" name="Dial Down Risk" stroke="#EC4899" />} */}
                            <Scatter yAxisId="left" dataKey="payoff" name="Payoff" fill="#60034C" />
                            <Scatter yAxisId="left" dataKey="compoundedShieldPayoff" name="Compounded Shield Payoff" fill="#E58B76" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                <div id="backtestsimulation" style={{ width: "10%" }}>
                    <SimulationEndValues simulationEndValues={rawData['simulation-end-values']} />
                </div>
            </div>
            <div id='backtestcheckbox' style={{ textAlign: "center", padding: "20px 0" }}>
                <label style={{ color: "#800000", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#800000" }} checked={showLimit} onChange={(e) => setShowLimit(e.target.checked)} />
                    Portfolio with shield
                </label>
                <label style={{ color: "#FFA654", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#FFA654" }} checked={showSP500} onChange={(e) => setShowSP500(e.target.checked)} />
                    S&P 500
                </label>
                <label style={{ color: "#54C1FF", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#54C1FF" }} checked={showPortfolio} onChange={(e) => setShowPortfolio(e.target.checked)} />
                    Portfolio without Shield
                </label>
                <label style={{ color: "#ED6663", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#ED6663" }} checked={show6040} onChange={(e) => setShow6040(e.target.checked)} />
                    Risk Equivalent Portfolio: 60-40
                </label>
                {/* <label style={{ color: "#96BE25", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#96BE25" }} checked={showNamePrice} onChange={(e) => setShowNamePrice(e.target.checked)} />
                    Name Your Price Shield
                </label>
                <label style={{ color: "#EC4899", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#EC4899" }} checked={showLimitRisk} onChange={(e) => setShowLimitRisk(e.target.checked)} />
                    Dial Down Risk
                </label> */}
            </div>
            <div id='backtestriskreturnratio' style={{ textAlign: "center", padding: "20px 0" }}>
                <table className="risk-return-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Portfolio with Shield</th>
                            <th>S&P 500</th>
                            <th>Portfolio without Shield</th>
                            <th>Risk Equivalent Portfolio: 60-40</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Risk Return Ratio</td>
                            <td>{parseFloat(rawData['return_ratio']['shielded_ratio']).toFixed(2)}</td>
                            <td>{parseFloat(rawData['return_ratio']['sp500_ratio']).toFixed(2)}</td>
                            <td>{parseFloat(rawData['return_ratio']['unshielded_ratio']).toFixed(2)}</td>
                            <td>{parseFloat(rawData['return_ratio']['6040_ratio']).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BacktestV2Graph;
