import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function AddStocks(Stock, Quantity, portfolioid) {
  // const session_id = sessionStorage.getItem("sessionID");
  const Token = sessionStorage.getItem("Token");

  const header = { Authorization: Token };

  var raw = JSON.stringify({
    session_id: Token,
    symbol: Stock.toUpperCase(),
    quantity: Quantity,
    buyprice: 1,
    portfolio_id: portfolioid
  });

  if (Stock === "" || Quantity === "") {
    // alert("Enter Valid Values");
    return null;
  }

  Quantity = Number(Quantity);
  if (isNaN(Quantity)||(Quantity<0)) {
    // alert("Enter Valid Quantity");
    return;
  }

  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
  };
  if (!CheckSessionTimeout(Token)){
    try {
      const response = await fetchWithTimeout(
        process.env.REACT_APP_PORTFOLIO_LINEITEM_API,
        requestOptions
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      } else {
        const message = await response.json();
        if (message["result"] !== "") {
          return message["result"];
        }else if (message["error"]==="Token Verification Failed"){
          return 404
        }
        else {
          console.log(message["error"]);
          console.log("There was an error fetching your data");
          return false;
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        return 0
      }
    }
  }
  else{
    return 401
  }
}
