import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./App.scss";
import "./global.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AgreementTextProvider } from '../src/Components/AgreementContent/AgreementTextProvider';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    maxBreadcrumbs: 10,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    relsease:1.0,
    beforeBreadcrumb(breadcrumbs,hint){
      if(breadcrumbs.category === "xhr" || breadcrumbs.category === "fetch"){
        return null
      }
      return breadcrumbs;
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <AgreementTextProvider>
        <App />
      </AgreementTextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
