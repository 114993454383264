import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import Popup from "../Popup/Popup";
import { AddCsvToDb } from "../../Apis/AddCsvToDb";
import "./AdvisorErrors.css";
import searchicon from "../../images/search.svg";
import TagManager from "react-gtm-module";
import PageLoader from "../PageLoader/PageLoader";


export default function AdvisorErrors() {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const [bdopen, setBdOpen] = React.useState(false);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [popupTitle, setPopupTitle] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [searchKey, setSearchKey] = useState("");
  const [Flag, setFlag] = useState(false);
  const [GotData, setGotData] = React.useState(false);
  const AdvisorCsvData = JSON.parse(sessionStorage.getItem("AdvisorCsvData"));
  const [DisableImportFlag, setDisableImportFlag] = React.useState(false);
  const [AdvisorClientErrors, setAdvisorClientErrors] = React.useState([]);
  const [AdvisorAccountErrors, setAdvisorAccountErrors] = React.useState([]);
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false)

  if (Flag === false) {
    console.log(" in client  error", AdvisorCsvData);
    if (AdvisorCsvData !== null && AdvisorCsvData !== "") {
      if (
        typeof Object.entries(AdvisorCsvData["errors"]) !== "undefined" &&
        Object.entries(AdvisorCsvData["errors"]).length > 0
      ) {
        setGotData(true);
        var lyst = [];
        var lyst1 = [];
        for (let [key, value] of Object.entries(AdvisorCsvData["errors"]["client_errors"])) {
          lyst.push({
            externalid: value.externalid,
            errormessage: value.errormessage
          });
        }
        for (let [key, value] of Object.entries(AdvisorCsvData["errors"]["account_errors"])) {
          lyst1.push({
            externalid: value.externalid,
            errormessage: value.errormessage
          });
        }
        setAdvisorClientErrors([...lyst]);
        setAdvisorAccountErrors([...lyst1]);
      } else if (
        typeof Object.entries(AdvisorCsvData["changes"]) === "undefined" ||
        Object.entries(AdvisorCsvData["changes"]).length === 0
      ) {
        setPopupState(true);
        setPopupMessage("No changes were detected in the CSV file");
        setPopupTitle("Import Status");
        setGotData(true);
        setDisableImportFlag(true);
      } else {
        setPopupState(true);
        setPopupMessage("No errors found, Ready for import");
        setPopupTitle("Import Status");
        setGotData(true);
      }
      if (Object.keys(AdvisorCsvData["errors"]).length > 0) {
        setDisableImportFlag(true);
      }
    } else {
      setPopupState(true);
      setPopupMessage("Please import CSV to proceed");
      setPopupTitle("Import Status");
      setGotData(true);
      setDisableImportFlag(true);
      sessionStorage.removeItem("AdvisorCsvData");
    }
    if (GotData === true) {
      setFlag(true);
      setBdOpen(false);
    }
  }

  const cofirmCsvImport = () => {
    setBdOpen(true);
    AddCsvToDb().then((data) => {
      console.log("data_status", data);
      if (data === true) {
        setConfirmSuccess(true);
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("CSV imported successfully");
        setPopupTitle("Import Status");
        setAlertType("success");
        sessionStorage.removeItem("AdvisorCsvData");
        TagManager.dataLayer({
          dataLayer: {
            event: 'Advisor CSV Imported',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
          },
        });
      } else if (data === 401) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session timed out for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
      } else if (data === 404) {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage(
          "Your session was terminated for security reasons. Please sign-in again."
        );
        setAlertType("error");
        setPopupLoginRedirect(true);
        setPopupTitle("Session Expired");
        return;
      } else {
        setBdOpen(false);
        setPopupState(true);
        setPopupMessage("CSV import failed");
        setPopupTitle("Import Status");
        setAlertType("error");
      }
    });
  };

  const abortCsvImport = () => {
    sessionStorage.removeItem("AdvisorCsvData");
  };

  function AdvisorCsvDataErrors() {
    if (AdvisorCsvData !== null) {
      if (Object.keys(AdvisorCsvData["errors"]).length === 0) {
        return <div>No errors found, ready to import</div>
      }
      else {
        return <div>
          <div className="heading_text_errors">
            Client Errors
            <div className="errors_table_holder">
              <table className="errors_table">
                <thead>
                  <th>Client ID</th>
                  <th>Error</th>
                </thead>
                <tbody>
                  {AdvisorClientErrors.map((error) => (
                    <tr>
                      <td>{error.externalid}</td>
                      <td>{error.errormessage}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="heading_text_errors">
            Account Errors
            <div className="errors_table_holder">
              <table className="errors_table">
                <thead>
                  <th>Account ID</th>
                  <th>Error</th>
                </thead>
                <tbody>
                  {AdvisorAccountErrors.map((error) => (
                    <tr>
                      <td>{error.externalid}</td>
                      <td>{error.errormessage}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
    } else {
      return <div>No errors found, ready to import</div>
    }
  }

  return (
    <div>
      <PageLoader bdopen={bdopen}/>
      <Popup
        trigger={popupState}
        setTrigger={setPopupState}
        title={popupTitle}
        content={popupMessage}
        alertType={alertType}
        loginRedirect={popupLoginRedierct}
        onSuccessRouteTo={confirmSuccess ? "/ClientPortfolio" : undefined}
      ></Popup>
      <div className="search_bar">
        <div className="searchinput">
          <img src={searchicon}></img>
          <input
            className="ai-form-value"
            type="text"
            placeholder="Search Clients"
            onChange={(event) => {
              setSearchKey(event.target.value);
            }}
          />
        </div>
        <button
          className="ai-btn primary solid"
          onClick={cofirmCsvImport}
          disabled={DisableImportFlag}
        >
          Confirm
        </button>
        <button className="ai-btn primary solid" onClick={abortCsvImport}>
          Abort
        </button>
      </div>
      <div>
        {AdvisorCsvDataErrors()}
      </div>
    </div>
  );
}
