import React, { Component } from 'react';

class AiFormError extends Component {
    render() {
        return (
            <div className="ai-error-message">
                <p>{this.props.errormessage}</p>
            </div>
        );
    }
}

export default AiFormError;